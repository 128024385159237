<template>
	<div class="fd-button-group">
		<button-view
			v-for="button of config.values"
			:key="button.value || button.key"
			:config="button"
			:selected="
				config.mode == 'radio' && button.value == value
				||
				config.mode == 'check' && value[button.key]
			"
			@click="handleClick(button)"
		></button-view>
	</div>
</template>

<script>
import ButtonView from './Button.vue';

export default {
	name: 'ButtonGroupInput',
	props: ['value', 'config'],
	components: { ButtonView },
	methods: {
		handleClick(button) {
			if(this.config.mode == 'radio') {
				return this.$emit('input', button.value)
			}

			if(this.config.mode == 'check') {
				return this.$emit('input', {
					...this.value,
					[button.key]: !this.value[button.key]
				})
			}
		}
	}
}
</script>

<style lang="scss">
.fd-button-group {
	width: 100%;

	display: flex;

	.fd-button {
		margin-left: 5px;
		padding: 4px 0;

		&:first-child {
			margin-left: 0;
		}

		i {
			margin: 0;
		}
	}
}
</style>
