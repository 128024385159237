<template>
	<div class="fd-preloader-view" :class="{finished: finish}">
		<i class="spinner"></i>
	</div>
</template>

<script>
import '../../scss/fonts/icomoon.scss';
import CONFIG from '../Config.js';

export default {
	name: 'PreloaderView',
	data() {
		return {
			finish: false
		}
	},
	async mounted() {
		// Await needed images on load
		const neededImages = [
		  CONFIG.BASEPATH + '/img/cursor_rotate.png',
		  CONFIG.BASEPATH + '/img/pattern.png'
		];

		// Add background image if exists
		if(
			this.$store.state.data.background.image &&
			this.$store.state.data.background.image.path
		) {
		  neededImages.push(
		    this.$store.state.data.background.image.path
		  );
		}

		await Promise.all([
			...neededImages,
			...this.$store.state.data.preload
		].map(path => this.loadImage(path)));

		if(this.$store.state.mode == 'show') {
			this.finish = true;
			return;
		}

		// Load maybe later used images async
		const sharedFiles = this.$store.state.shared.files;
		[
		  CONFIG.BASEPATH + '/img/colorpicker_colorbg.png',

		  // Preload thumbnails
		  ...sharedFiles.map(file => file.thumb),
		].forEach(path => this.loadImage(path));

		this.finish = true;
	},
	methods: {
		loadImage(path) {
			return new Promise((resolve) => {
				const image = new Image();
				image.onerror = image.onabort = image.onload = () => {
					resolve();
				};
				image.src = path;

				if(!this.$el.querySelector(`img[src="${path}"]`)) {
					this.$el.appendChild(image);
				}
			});
		}
	}
}
</script>

<style lang="scss">
.fd-preloader-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: black;
	color: white;
	font-size: 34px;

	&.finished {
		opacity: 0;
		pointer-events: none;
	}

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -17px;
		margin-left: -17px;
	}

	img {
		position: absolute;
		right: -20px;
		width: 1px;
		height: 1px;
	}
}
</style>
