<template>
  <div class="fd-iframe-module">
    <iframe
      frameborder="0"
      scrolling="no"
      v-bind="attributes"
      :style="style"
    ></iframe>
  </div>
</template>

<script>
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if (!props.sandbox) {
		props.sandbox = false;
	}
}

export default {
	name: 'iFrameModule',
	extends: BaseModule,
	normalize,
	computed: {
		attributes() {
			return {
				src: this.module.props.src,
				sandbox: this.module.props.sandbox ? '' : null,
			};
		},
		style() {
			const width = this.module.width / (this.module.props.zoom / 100) + 20;
			const height = this.module.height / (this.module.props.zoom / 100) + 20;

			const transform = `scale(
        ${this.module.props.zoom / 100}
      ) translate(
        ${this.module.props.offset.x}px,
        ${this.module.props.offset.y}px
      )`;

			return {
				width: this.module.props.resizing ? width + 'px' : '1920px',
				height: height - this.module.props.offset.y + 50 + 'px',
				transform: transform,
				transformOrigin: '0px 0px 0px',
			};
		},
	},
};
</script>

<style lang="scss">
.fd-iframe-module {
	background: rgba(255, 255, 255, 0.8);

	position: relative;
	overflow: hidden;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		z-index: 1;
		pointer-events: none;
	}
}
</style>
