<script>
import Fly from './Fly.vue';
import ConfigItemView from '../components/ConfigItem.vue';
import SettingsView from '../components/Settings.vue';

export default {
	name: 'FlyConfigView',
	extends: SettingsView,
	components: { ConfigItemView },
	computed: {
		icon() {
			return 'airplane2';
		},
		headline() {
			return this.$_('fd.animation.fly.desc');
		},
		config() {
			return [{
				type: 'Select',
				label: this.$_('fd.animations.settings.reference'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].reference || 'r',
				values: [{
						label: this.$_('fd.animations.settings.reference.top'),
						value: 't',
					}, {
						label: this.$_('fd.animations.settings.reference.bottom'),
						value: 'b',
					}, {
						label: this.$_('fd.animations.settings.reference.right'),
						value: 'r',
					}, {
						label: this.$_('fd.animations.settings.reference.left'),
						value: 'l',
					}],
				events: {
					input: this.setReference
				}
			}, {
				type: 'Select',
				label: this.$_('fd.animations.settings.direction'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].direction || 'in',
				values: [{
						label: this.$_('fd.animations.settings.direction.in'),
						value: 'in',
					}, {
						label: this.$_('fd.animations.settings.direction.out'),
						value: 'out',
					}],
				events: {
					input: this.setDirection
				}
			}, {
				type: 'Select',
				label: this.$_('fd.animations.settings.mode'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].mode || 'easing',
				values: [{
						label: this.$_('fd.animations.settings.mode.easing'),
						value: 'easing',
					}, {
						label: this.$_('fd.animations.settings.mode.linear'),
						value: 'linear',
					}],
				events: {
					input: this.setMode
				}
			}, {
				type: 'Number',
				label: this.$_('fd.animations.settings.duration'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].duration || 1,
				events: {
					input: this.setDuration
				}
			}, {
				type: 'Number',
				label: this.$_('fd.animations.settings.delay'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].delay || 0,
				events: {
					input: this.setDelay
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play'),
				events: {
					click: this.startAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.delete'),
				icon: 'bin',
				events: {
					click: this.deleteAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play.all'),
				events: {
					click: this.startAll
				}
				}];
		}
	},
	methods: {
		setReference(reference) {
			this.$root.$emit('changeAnimation', {
				type: 'reference',
				value: reference,
				self: this,
			});
		},
		setDirection(direction) {
			this.$root.$emit('changeAnimation', {
				type: 'direction',
				value: direction,
				self: this,
			});
		},
		setMode(mode) {
			this.$root.$emit('changeAnimation', {
				type: 'mode',
				value: mode,
				self: this,
			});
		},
		setDuration(duration) {
			this.$root.$emit('changeAnimation', {
				type: 'duration',
				value: duration,
				self: this,
			});
		},
		setDelay(delay) {
			this.$root.$emit('changeAnimation', {
				type: 'delay',
				value: delay,
				self: this,
			});
		},
		startAnimation() {
			this.$root.$emit('startAnimation');
		},
		deleteAnimation() {
			this.$store.dispatch('deleteAnimation', this.module);
			this.$root.$emit('update');
		},
		startAll() {
			this.$root.$emit('startAllAnimations');
		},
	}
}

</script>
