<template>
	<div
		class="fd-module"
		:id="module.mid"
		:class="[classes.root, { highlight: isHighlighted }]"
		:style="positionStyleExtra"
		:data-active="isActive"
	>
		<animation-view :animation="module.animation" :rect="moduleRect">
			<div class="fd-module-wrapper" :style="designStyle">
				<component
					class="fd-module-inner"
					:is="module.class"
					:module="module"
					:class="classes.inner"
				></component>
			</div>
		</animation-view>
		<div
			class="fd-decorator"
			:logo="module.logo"
			@click.stop
			@click="deleteDrawElement"
			@dblclick="openSettings"
			@contextmenu.prevent.stop="contextmenu"
			v-movable
			@move:start="moveStart"
			@move="move"
			@move:end="moveEnd"
		>
			<div
				class="rotate"
				v-if="rotatable"
				v-movable
				@move:start="rotateStart"
				@move="rotate"
				@move:end="rotateEnd"
			></div>

			<div
				class="resize t l"
				v-if="resizeMode === 'all' || typeof resizeMode === 'number'"
				v-movable
				@move:start="resizeStart($event, 'tl')"
				@move="resize($event, 'tl')"
				@move:end="resizeEnd($event, 'tl')"
			></div>
			<div
				class="resize t"
				v-if="resizeMode === 'all' || resizeMode === 'height'"
				v-movable
				@move:start="resizeStart($event, 't')"
				@move="resize($event, 't')"
				@move:end="resizeEnd($event, 't')"
			></div>
			<div
				class="resize t r"
				v-if="resizeMode === 'all' || typeof resizeMode === 'number'"
				v-movable
				@move:start="resizeStart($event, 'tr')"
				@move="resize($event, 'tr')"
				@move:end="resizeEnd($event, 'tr')"
			></div>
			<div
				class="resize l"
				v-if="resizeMode === 'all' || resizeMode === 'width'"
				v-movable
				@move:start="resizeStart($event, 'l')"
				@move="resize($event, 'l')"
				@move:end="resizeEnd($event, 'l')"
			></div>
			<div
				class="resize r"
				v-if="resizeMode === 'all' || resizeMode === 'width'"
				v-movable
				@move:start="resizeStart($event, 'r')"
				@move="resize($event, 'r')"
				@move:end="resizeEnd($event, 'r')"
			></div>
			<div
				class="resize b l"
				v-if="resizeMode === 'all' || typeof resizeMode === 'number'"
				v-movable
				@move:start="resizeStart($event, 'bl')"
				@move="resize($event, 'bl')"
				@move:end="resizeEnd($event, 'bl')"
			></div>
			<div
				class="resize b"
				v-if="resizeMode === 'all' || resizeMode === 'height'"
				v-movable
				@move:start="resizeStart($event, 'b')"
				@move="resize($event, 'b')"
				@move:end="resizeEnd($event, 'b')"
			></div>
			<div
				class="resize b r"
				v-if="
					resizeMode === 'all' ||
						typeof resizeMode === 'number' ||
						resizeMode === 'logo'
				"
				v-movable
				@move:start="resizeStart($event, 'br')"
				@move="resize($event, 'br')"
				@move:end="resizeEnd($event, 'br')"
			></div>

			<div v-if="isActive && module.class == 'DrawModule'">
				<draw-module-extra-item-view
					v-show="notDeleting"
					v-for="(item, i) of editPoints"
					:key="'point_' + i"
					:item="item"
					:config="moduleConfig"
				/>
			</div>

			<div v-if="isActive">
				<button
					icon="wrench"
					:style="{ left: left(), top: top(-3) }"
					class="fd-module-button fd-module-button-wrench"
					@click="$store.dispatch('editModule')"
				>
					<i class="fd-module-button-icon wrench"></i>
				</button>
				<button
					icon="bin"
					:style="{ left: left(), top: top(-2) }"
					class="fd-module-button fd-module-button-bin"
					v-if="
						!(module.class == 'WatermarkModule' && $store.state.type != 'pro')
					"
					@click="$store.dispatch('deleteModules')"
				>
					<i class="fd-module-button-icon bin"></i>
				</button>
				<button
					icon="clapboard"
					:style="{ left: left(), top: top(-1) }"
					class="fd-module-button fd-module-button-clapboard"
					v-if="
						!(module.class == 'WatermarkModule' || $store.state.type == 'logo')
					"
					@click="$store.dispatch('editAnimation')"
				>
					<i class="fd-module-button-icon clapboard"></i>
				</button>
				<button
					icon="clapboard"
					class="fd-module-button"
					:style="{ left: left(), top: top(index) }"
					v-for="(animation, index) in module.animation"
					:key="index"
					@click="openAnimation(index, animation)"
				>
					{{ animation.index }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import ModuleView from './Module.vue';
import DrawModuleExtraItemView from '../modules/DrawModuleExtraItem.vue';
import * as MODULES from '../modules/_MODULES_EXTRA.js';
import * as ANIMATIONS from '../animations/_ANIMATIONS_EXTRA.js';
import { _exists, _deepCopy } from '../util/helper.js';
import { _getNearestEdges } from '../util/Snap.js';

export default {
	extends: ModuleView,
	components: { ...MODULES, ...ANIMATIONS, DrawModuleExtraItemView },
	data: () => ({
		moveStartValues: {},
		moved: false,
		resizeStartValues: {},
		rotateStartValues: {},
		snapX: 0,
		snapY: 0,
	}),
	computed: {
		activeModules() {
			return this.$store.state.activeModules;
		},
		isActive() {
			if (this.module.locked && this.module.class != 'WatermarkModule') {
				return;
			}
			return this.$store.state.activeModules.includes(this.module.mid);
		},
		isHighlighted() {
			return this.$store.state.highlightedModules.includes(this.module.mid);
		},
		editPoints() {
			if (
				this.module.class == 'DrawModule' &&
				this.module.props.drawingElements.length > 0 &&
				this.module.props.image != true &&
				!this.$children[0].$children[0].isDrawing
			) {
				return this.$children[0].$children[0].editPoints;
			}
		},
		notDeleting() {
			if (typeof this.$children[0] != 'undefined') {
				if (typeof this.$children[0].$children[0] != 'undefined') {
					return !this.$children[0].$children[0].isDeleting;
				} else {
					return true;
				}
			}
		},
		moduleConfig() {
			return this.module;
		},
		positionStyleExtra() {
			const style = {
				...this.positionStyle,
			};

			if (this.autoHeight) {
				delete style.height;
			}

			return style;
		},
		resizeMode() {
			let resize = MODULES[this.module.class].meta.resize;

			if (resize instanceof Function) {
				resize = resize(this.module.props);
			}

			if (
				!['all', 'none', 'width', 'height', 'logo'].includes(resize) &&
				typeof resize !== 'number'
			) {
				resize = null;
			}

			if (
				this.$store.state.type == 'logo' &&
				this.module.class == 'ImageModule'
			) {
				return 'logo';
			}

			return resize || 'all';
		},
		autoHeight() {
			let autoHeight = MODULES[this.module.class].meta.autoHeight;
			if (autoHeight instanceof Function) {
				autoHeight = autoHeight(this.module.props);
			}

			return !!autoHeight;
		},
		rotatable() {
			return MODULES[this.module.class].meta.rotate !== false;
		},
		movable() {
			return MODULES[this.module.class].meta.move !== false;
		},
		moduleAutoHeightWatcher() {
			return {
				autoHeight: this.autoHeight,
				width: this.module.width,
				padding: this.module.style.padding,
			};
		},
	},
	mounted() {
		if (this.$store.state.type == 'logo') {
			this.$root.$on('resizeStart', () => {
				this.$store.dispatch(
					'subNav/components',
					MODULES['ImageModule'].ConfigView
				);
			});
		}
	},
	watch: {
		async moduleAutoHeightWatcher() {
			if (!this.autoHeight) return;

			await this.$nextTick();

			const height = this.$el.offsetHeight;

			this.$store.commit('changeModules', {
				type: 'set',
				modules: [this.module.mid],
				values: { height },
				auto: true
			});
		},
	},
	methods: {
		setActive() {
			if (this.$children[0].$children[0].isDeleting) return;

			this.$store.commit('activeModules', [this.module.mid]);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch(
				'subNav/components',
				this.$store.state.subNav.fixed
					? MODULES[this.module.class].ConfigView
					: null
			);
		},
		toggleActive() {
			if (
				this.module.class != 'WatermarkModule' &&
				this.$store.getters.activeModules[0].class != 'WatermarkModule'
			) {
				this.$store.commit('activeModules', this.module.mid);
				this.$store.commit('highlightedModules', []);
				this.$store.dispatch('subNav/components', false);
			}
		},
		openSettings() {
			if (this.module.locked == true && this.module.class != 'WatermarkModule')
				return;

			this.$store.commit('activeModules', [this.module.mid]);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch(
				'subNav/components',
				MODULES[this.module.class].ConfigView
			);
		},

		deleteDrawElement(evt) {
			if (!this.$children[0].$children[0].isDeleting) return;

			let drawingElements = this.module.props.drawingElements;
			for (let i = 0; i < drawingElements.length; i++) {
				if (drawingElements[i].shape == 'rect') {
					let xtr = drawingElements[i].x + drawingElements[i].w,
						xtl = drawingElements[i].x,
						ytl = drawingElements[i].y,
						ybl = drawingElements[i].y + drawingElements[i].h;

					if (
						evt.offsetX > xtl &&
						evt.offsetY > ytl &&
						evt.offsetX < xtr &&
						drawingElements[i].w > 0 &&
						evt.offsetY < ybl &&
						drawingElements[i].h > 0
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					} else if (
						evt.offsetX > xtl &&
						evt.offsetY < ytl &&
						evt.offsetX < xtr &&
						drawingElements[i].w > 0 &&
						evt.offsetY > ybl &&
						drawingElements[i].h < 0
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					} else if (
						evt.offsetX < xtl &&
						evt.offsetY > ytl &&
						evt.offsetX > xtr &&
						drawingElements[i].w < 0 &&
						evt.offsetY < ybl &&
						drawingElements[i].h > 0
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					} else if (
						evt.offsetX < xtl &&
						evt.offsetY < ytl &&
						evt.offsetX > xtr &&
						drawingElements[i].w < 0 &&
						evt.offsetY > ybl &&
						drawingElements[i].h < 0
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					}
				} else if (drawingElements[i].shape == 'circle') {
					let dx = evt.offsetX - drawingElements[i].x;
					let dy = evt.offsetY - drawingElements[i].y;
					let d = dx * dx + dy * dy;
					let r =
						drawingElements[i].rx * drawingElements[i].rx +
						drawingElements[i].ry * drawingElements[i].ry;

					if (d < r) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					}
				} else if (
					drawingElements[i].shape == 'stroke' &&
					drawingElements[i].points.length == 2
				) {
					let xp_0 = drawingElements[i].points[0].x,
						yp_0 = drawingElements[i].points[0].y,
						xp_1 = drawingElements[i].points[1].x,
						yp_1 = drawingElements[i].points[1].y;

					let tolerance = 7;

					let dxp = xp_1 - xp_0 == 0 ? 1 : xp_1 - xp_0;
					let dyp = yp_1 - yp_0 == 0 ? 1 : yp_1 - yp_0;

					let t =
						((evt.offsetX - xp_0) * dxp + (evt.offsetY - yp_0) * dyp) /
						(dxp * dxp + dyp * dyp);

					let lineX = xp_0 + t * (xp_1 - xp_0);
					let lineY = yp_0 + t * (yp_1 - yp_0);

					let dx = evt.offsetX - lineX;
					let dy = evt.offsetY - lineY;

					let distance = Math.abs(Math.sqrt(dx * dx + dy * dy));

					if (
						distance < tolerance &&
						((evt.offsetX > xp_0 && evt.offsetX < xp_1) ||
							(evt.offsetX < xp_0 && evt.offsetX > xp_1) ||
							xp_0 == xp_1) &&
						((evt.offsetY > yp_0 && evt.offsetY < yp_1) ||
							(evt.offsetY < yp_0 && evt.offsetY > yp_1) ||
							yp_0 == yp_1)
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					}
				} else if (
					drawingElements[i].shape == 'stroke' &&
					drawingElements[i].points.length > 2 &&
					drawingElements[i].points.length < 15
				) {
					let xh = 0,
						xl = 1920,
						yh = 0,
						yl = 1080;
					drawingElements[i].points.forEach(point => {
						if (point.x > xh) {
							xh = point.x;
						}
						if (point.x < xl) {
							xl = point.x;
						}
						if (point.y > yh) {
							yh = point.y;
						}
						if (point.y < yl) {
							yl = point.y;
						}
					});
					if (
						evt.offsetX < xh &&
						evt.offsetX > xl &&
						evt.offsetY < yh &&
						evt.offsetY > yl
					) {
						drawingElements.splice(i, 1);
						this.$store.commit('dirty');
					}
				} else {
					this.editPoints.forEach(point => {
						if (
							drawingElements[i].shape == point.shape &&
							drawingElements[i].did == point.did
						) {
							let xmr = point.x + point.w / 2,
								xm = point.x,
								ym = point.y,
								ymb = point.y + point.h / 2;

							if (
								evt.offsetX <= xmr &&
								evt.offsetX >= xmr - point.w &&
								evt.offsetY <= ymb &&
								evt.offsetY >= ymb - point.h
							) {
								drawingElements.splice(i, 1);
								this.$store.commit('dirty');
							}
						}
					});
				}
			}
		},

		moveStart(evt) {
			this.$emit('move:start', evt);
			if (!evt.returnValue) {
				return;
			}

			if (this.$store.state.type == 'logo') {
				this.$store.dispatch('subNav/components', null);
			}
			this.moveStartValues = { ...this.position };
			this.$store.commit('moduleMoveStart', this.module.mid);
		},
		move(evt) {
			if (this.module.locked) {
				return;
			}

			let position = {
				x: null,
				y: null,
			};

			this.$emit('move', evt);
			if (!evt.returnValue) {
				return;
			}

			let {
				detail: { moveX, moveY },
			} = evt;

			if (!this.movable || this.module.props.drawing) return false;

			if (!this.moved && !this.isActive) this.setActive();
			this.moved = true;

			// The modules are scaled but the mouse coords ar not
			moveX /= this.$store.state.intern.scale;
			moveY /= this.$store.state.intern.scale;

			if (this.$store.state.user.snap && this.activeModules.length <= 1) {
				_getNearestEdges(this.$store.state, evt, nearest => {
					if (typeof nearest != 'undefined') {
						if (
							Math.abs(nearest.X.distance) < 30 ||
							Math.abs(nearest.Y.distance) < 30
						) {
							if (nearest.X.middle) {
								document.getElementById('selectorX').classList.add('middle');
							} else {
								document.getElementById('selectorX').classList.remove('middle');
							}
							if (nearest.Y.middle) {
								document.getElementById('selectorY').classList.add('middle');
							} else {
								document.getElementById('selectorY').classList.remove('middle');
							}

							this.$root.$emit('setPoints' + this.module.mid, nearest);
							if (this.module.moveDirection.includes('r'))
								nearest.X.distance *= -1;
							if (this.module.moveDirection.includes('b'))
								nearest.Y.distance *= -1;

							if (Math.abs(nearest.X.distance) <= 20) {
								if (Math.abs(this.snapX) > 20 || Math.abs(this.snapX) != 0) {
									this.snapX = 0;
									position.x = nearest.X.distance;
								} else {
									this.snapX +=
										this.moveStartValues.x + moveX - this.position.x;
									if (Math.abs(this.snapX) > 20) {
										position.x = this.snapX;
										this.snapX = 0;
									}
								}
							} else {
								position.x = this.moveStartValues.x + moveX - this.position.x;
							}

							if (Math.abs(nearest.Y.distance) <= 20) {
								if (Math.abs(this.snapY) > 20 || Math.abs(this.snapY) != 0) {
									this.snapY = 0;
									position.y = nearest.Y.distance;
								} else {
									this.snapY +=
										this.moveStartValues.y + moveY - this.position.y;
									if (Math.abs(this.snapY) > 20) {
										position.y = this.snapY;
										this.snapY = 0;
									}
								}
							} else {
								position.y = this.moveStartValues.y + moveY - this.position.y;
							}
						} else {
							this.$root.$emit('moveEnd');
							position.x = this.moveStartValues.x + moveX - this.position.x;
							position.y = this.moveStartValues.y + moveY - this.position.y;
						}

						this.$store.commit('changeModules', {
							type: 'offset',
							modules: this.activeModules,
							values: {
								x: position.x,
								y: position.y,
							},
						});
						this.$store.commit('moduleMove', this.module.mid);
					}
				});
			} else {
				position.x = this.moveStartValues.x + moveX - this.position.x;
				position.y = this.moveStartValues.y + moveY - this.position.y;
				this.$store.commit('changeModules', {
					type: 'offset',
					modules: this.activeModules,
					values: {
						x: position.x,
						y: position.y,
					},
				});
				this.$store.commit('moduleMove', this.module.mid);
			}
		},
		moveEnd(event) {
			this.$root.$emit('moveEnd');
			this.$store.state.lastMoveX = undefined;
			this.$store.state.lastMoveY = undefined;

			this.$emit('move:end', event);
			if (!event.returnValue) {
				return;
			}
			this.$store.state.lastOperation = null;

			const { detail: evt } = event;

			evt.original.stopPropagation();

			if (this.moved) {
				this.moved = false;
				this.$store.commit('moduleMoveEnd', this.module.mid);
				return;
			}

			if (evt.original.ctrlKey) this.toggleActive();
			else if (evt.original.button === 0) this.setActive();
		},

		resizeStart() {
			if (['ImageModule', 'EnergyFlowModule'].includes(this.module.class)) {
				this.$root.$emit('resizeStart');
			}
			this.resizeStartValues = {
				...this.position,
				width: this.module.width,
				height: this.module.height,
			};
		},
		resize(
			{
				detail: { moveX, moveY },
			},
			direction
		) {
			let evt = new Object();
			evt = {
				detail: { moveX, moveY },
			};
			evt.direction = direction;
			evt.target = {
				parentNode: {
					id: this.module.mid,
				},
			};
			// The modules are scaled but the mouse coords ar not
			moveX /= this.$store.state.intern.scale;
			moveY /= this.$store.state.intern.scale;

			if (typeof this.resizeMode === 'number') {
				moveX = moveY * this.resizeMode;
				if (direction === 'tr' || direction === 'bl') {
					moveX *= -1;
				}
			}

			let newValues = { ...this.resizeStartValues };
			let oldValues = _deepCopy(newValues);

			if (direction.includes('t')) {
				if (newValues.height - moveY < 30) {
					moveY = newValues.height - 30;
				}
				newValues.y += moveY;
				newValues.height -= moveY;
			}
			if (direction.includes('l')) {
				if (newValues.width - moveX < 30) {
					moveX = newValues.width - 30;
				}
				newValues.x += moveX;
				newValues.width -= moveX;
			}
			if (direction.includes('r')) {
				if (newValues.width + moveX < 30) {
					moveX = 30 - newValues.width;
				}
				newValues.width += moveX;
			}
			if (direction.includes('b')) {
				if (newValues.height + moveY < 30) {
					moveY = 30 - newValues.height;
				}
				newValues.height += moveY;
			}

			if (this.$store.state.user.snap && this.activeModules.length == 1) {
				_getNearestEdges(this.$store.state, evt, nearest => {
					if (typeof nearest != 'undefined') {
						if (nearest.X.middle) {
							document.getElementById('selectorX').classList.add('middle');
						} else {
							document.getElementById('selectorX').classList.remove('middle');
						}
						if (nearest.Y.middle) {
							document.getElementById('selectorY').classList.add('middle');
						} else {
							document.getElementById('selectorY').classList.remove('middle');
						}
						if (nearest.X.distance < 30 || nearest.Y.distance < 30) {
							this.$root.$emit('setPoints' + this.module.mid, nearest);
							const tmpValues = _deepCopy(newValues);

							if (direction.includes('l')) nearest.X.distance *= -1;
							if (direction.includes('t')) nearest.Y.distance *= -1;
							if (
								Math.abs(nearest.X.distance) <= 15 &&
								Math.abs(nearest.X.distance) != 0
							) {
								if (direction.includes('r'))
									newValues.width = nearest.X.x - newValues.x;

								if (direction.includes('l'))
									newValues.width += newValues.x - nearest.X.x;
								if (direction.includes('l')) newValues.x = nearest.X.x;
							}

							if (
								Math.abs(newValues.width - (nearest.X.x - newValues.x)) <= 15 &&
								direction.includes('r')
							)
								newValues.width = nearest.X.x - newValues.x;
							if (
								Math.abs(newValues.x - nearest.X.x) <= 15 &&
								direction.includes('l')
							) {
								newValues.width += newValues.x - nearest.X.x;
								newValues.x = nearest.X.x;
							}

							if (
								Math.abs(nearest.Y.distance) <= 15 &&
								Math.abs(nearest.Y.distance) != 0
							) {
								if (direction.includes('b'))
									newValues.height = nearest.Y.y - newValues.y;

								if (direction.includes('t'))
									newValues.height += newValues.y - nearest.Y.y;
								if (direction.includes('t')) newValues.y = nearest.Y.y;
							}

							if (
								Math.abs(newValues.height - (nearest.Y.y - newValues.y)) <=
									15 &&
								direction.includes('b')
							)
								newValues.height = nearest.Y.y - newValues.y;
							if (
								Math.abs(newValues.y - nearest.Y.y) <= 15 &&
								direction.includes('t')
							) {
								newValues.height += newValues.y - nearest.Y.y;
								newValues.y = nearest.Y.y;
							}
						}
						this.$store.commit('changeModules', {
							type: 'set',
							modules: [this.module.mid],
							values: newValues,
						});
					}
				});
			} else {
				this.$store.commit('changeModules', {
					type: 'set',
					modules: [this.module.mid],
					values: newValues,
				});
			}
			this.$root.$emit('resize');
		},
		resizeEnd({ detail: evt }) {
			this.$root.$emit('resizeEnd');
			evt.original.stopPropagation();
		},

		rotateStart({ detail: evt }) {
			// Save module center
			const size = evt.target.parentNode.getBoundingClientRect();
			this.rotateStartValues = {
				x: size.x + size.width / 2,
				y: size.y + size.height / 2,
			};
		},
		rotate({ detail: evt }) {
			// Calc angle to center
			let angle =
				((Math.atan2(
					evt.currentY - this.rotateStartValues.y,
					evt.currentX - this.rotateStartValues.x
				) +
					Math.PI / 2) *
					180) /
				Math.PI;

			// Add stuck points for better handling
			const stuckDegrees = 3;
			if (Math.abs(angle) % 45 < stuckDegrees) {
				angle -= angle % 45;
			}
			if (Math.abs(angle) % 45 > 45 - stuckDegrees) {
				if (angle < 0) {
					angle -= 45 + (angle % 45);
				}
				if (angle > 0) {
					angle += 45 - (angle % 45);
				}
			}

			this.$store.commit('changeModules', {
				type: 'set',
				modules: [this.module.mid],
				values: { r: angle / 0.3 },
			});
		},
		rotateEnd({ detail: evt }) {
			evt.original.stopPropagation();
		},

		contextmenu(evt) {
			if (
				this.module.class == 'WatermarkModule' ||
				(typeof this.module.logo !== 'undefined' && this.module.logo)
			) {
				return;
			}

			if (
				this.$store.getters.activeModules.every(m => m.mid !== this.module.mid)
			) {
				this.$store.commit('activeModules', [this.module.mid]);
			}

			let key = 1;

			const x = _exists(evt.pageX) ? evt.pageX : evt.touches[0].pageX;
			const y = _exists(evt.pageY) ? evt.pageY : evt.touches[0].pageY;
			this.$store.commit('contextmenu/open', {
				createPoint: { x, y },
				entrys: [
					{
						type: 'entry',
						label: this.$_('fd.context.edit'),
						icon: 'wrench',
						visible: this.$store.state.activeModules.length == 1,
						click: () => this.$store.dispatch('editModule'),
					},
					{
						type: 'entry',
						label: this.$_('fd.context.duplicate'),
						icon: 'copy3',
						click: () => {
							this.$store.dispatch('duplicateModules');
							setTimeout(() => {
								this.$root.$emit('initModuleSnapping');
							}, 10);
						},
					},
					{
						type: 'separator',
						key: key++,
					},
					{
						type: 'menu',
						label: this.$_('fd.context.arrange'),
						icon: 'subtract',
						visible: this.$store.state.activeModules.length == 1,
						entrys: [
							{
								type: 'entry',
								label: this.$_('fd.context.arrange.forward'),
								icon: 'subtract',
								click: () => this.$store.dispatch('arrangeForward'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.arrange.behind'),
								icon: 'subtract',
								click: () => this.$store.dispatch('arrangeBehind'),
							},
						],
					},
					{
						type: 'menu',
						label: this.$_('fd.context.align'),
						icon: 'align-left',
						entrys: [
							{
								type: 'entry',
								label: this.$_('fd.context.align.left'),
								icon: 'align-left',
								click: () => this.$store.dispatch('alignLeft'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.center.horizontal'),
								icon: 'align-center-horizontal',
								click: () => this.$store.dispatch('alignCenterHorizontal'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.right'),
								icon: 'align-right',
								click: () => this.$store.dispatch('alignRight'),
							},
							{
								type: 'separator',
								key: key++,
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.top'),
								icon: 'align-top',
								click: () => this.$store.dispatch('alignTop'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.center.vertical'),
								icon: 'align-center-vertical',
								click: () => this.$store.dispatch('alignCenterVertical'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.bottom'),
								icon: 'align-bottom',
								click: () => this.$store.dispatch('alignBottom'),
							},
							{
								type: 'separator',
								key: key++,
								visible: this.$store.state.activeModules.length > 2,
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.spread.horizontal'),
								icon: 'spread-horizontal',
								visible: this.$store.state.activeModules.length > 2,
								click: () => this.$store.dispatch('spreadHorizontal'),
							},
							{
								type: 'entry',
								label: this.$_('fd.context.align.spread.vertical'),
								icon: 'spread-vertical',
								visible: this.$store.state.activeModules.length > 2,
								click: () => this.$store.dispatch('spreadVertical'),
							},
						],
					},
					{
						type: 'entry',
						label: this.$_('fd.context.group'),
						icon: 'make-group',
						visible:
							this.$store.state.activeModules.length > 1 &&
							this.$store.getters.activeModules.every(
								m => m.class !== 'GroupModule'
							),
						click: () => this.$store.dispatch('group'),
					},
					{
						type: 'entry',
						label: this.$_('fd.context.ungroup'),
						icon: 'ungroup',
						visible:
							this.$store.state.activeModules.length === 1 &&
							this.$store.getters.activeModules[0].class === 'GroupModule',
						click: () => this.$store.dispatch('ungroup'),
					},
					{
						type: 'separator',
						key: key++,
					},
					{
						type: 'entry',
						label: this.$_('fd.context.delete'),
						icon: 'bin',
						click: () => this.$store.dispatch('deleteModules'),
					},
				],
			});
		},
		top(index) {
			let pixel = 136 + 45 * index;
			if (this.module.y < -28) {
				pixel = this.module.height + 45 * index;
			}
			return pixel + 'px';
		},
		left() {
			if (this.module.x < 36) {
				return -3 + this.module.width + 'px';
			} else {
				return '-43px';
			}
		},
		openAnimation(index, animation) {
			this.$store.commit('activeModules', [this.module.mid]);
			this.$store.commit('activeAnimation', animation.index);
			this.$store.commit('activeAnimationIndex', index);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', ANIMATIONS[animation.name]);
		},
		getAnimationCount() {
			if (typeof this.$store.animationCount == 'undefined') {
				this.$store.animationCount = 0;
			}
			return this.$store.animationCount++;
		},
	},
};
</script>

<style lang="scss">
.fd-module {
	&[data-active='true'] {
		z-index: 99 !important;
		cursor: move;
	}

	.fd-decorator {
		box-sizing: border-box;

		position: absolute;
		top: -6px;
		left: -6px;
		width: calc(100% + 12px);
		height: calc(100% + 12px);
		z-index: 30;

		.resize,
		.rotate {
			display: none;

			position: absolute;
			width: 10px;
			height: 10px;
			background: #eee;
			border: 1px solid #000;
			border-radius: 5px;
			top: 50%;
			left: 50%;
			margin-top: -5px;
			margin-left: -5px;

			&:before {
				content: '';
				position: absolute;
				top: -10px;
				bottom: -10px;
				left: -10px;
				right: -10px;
			}
		}

		.rotate {
			background: green;
			cursor: url('../../img/cursor_rotate.png') 10 10, auto;
			top: -30px;

			&:after {
				content: '';
				position: absolute;
				top: 9px;
				left: 50%;
				width: 1px;
				margin-left: -1px;
				height: 22px;
				background: #333;
			}
		}

		.resize {
			&.t {
				top: -4px;
			}
			&.b {
				top: initial;
				bottom: -6px;
			}
			&.l {
				left: -4px;
			}
			&.r {
				left: initial;
				right: -6px;
			}

			&.t.l {
				cursor: nw-resize;
			}
			&.t {
				cursor: n-resize;
			}
			&.t.r {
				cursor: ne-resize;
			}
			&.l {
				cursor: w-resize;
			}
			&.r {
				cursor: e-resize;
			}
			&.b.l {
				cursor: sw-resize;
			}
			&.b {
				cursor: s-resize;
			}
			&.b.r {
				cursor: se-resize;
			}
		}

		.fd-module-button:hover {
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			cursor: pointer;
		}
		.fd-module-button {
			position: absolute;
			width: 40px;
			height: 40px;
			margin: 0;
			padding: 5px;
			font-size: 20px;
			border: none;
			border-radius: 0;
			text-align: center;
			color: hsla(0, 0, 255, 0.4);
			background: rgba(0, 0, 0, 0.2);
			.fd-module-button-icon {
				font-family: icomoon;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				letter-spacing: 0;
				-webkit-font-feature-settings: 'liga';
				-moz-font-feature-settings: 'liga=1';
				-moz-font-feature-settings: 'liga';
				-ms-font-feature-settings: 'liga' 1;
				-o-font-feature-settings: 'liga';
				font-feature-settings: 'liga';
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
	}

	&:hover > .fd-decorator {
		border: 3px dashed #4affff;
		filter: drop-shadow(0 0 1px black);
	}

	&[data-active='true'] > .fd-decorator,
	&.highlight > .fd-decorator {
		border: 3px dashed #4affff;
		filter: unset;
	}

	.fd-decorator[logo='true'] {
		border: none;
	}

	&[data-active='true'] > .fd-decorator {
		top: 0px;
		left: 0px;
		width: calc(100% + 0px);
		height: calc(100% + 0px);

		.resize,
		.rotate {
			display: block;
		}
	}
}
</style>

<i18n lang="de_DE">
fd.context.edit: Bearbeiten
fd.context.duplicate: Duplizieren
fd.context.delete: Löschen

fd.context.arrange: Anordnen
fd.context.arrange.forward: Nach vorne
fd.context.arrange.behind: Nach hinten

fd.context.align: Ausrichten
fd.context.align.left: Linksbündig ausrichten
fd.context.align.center.horizontal: Horizontal zentriert ausrichten
fd.context.align.right: Rechtsbündig ausrichten
fd.context.align.top: Oben ausrichten
fd.context.align.center.vertical: Vertikal zentriert ausrichten
fd.context.align.bottom: Unten ausrichten
fd.context.align.spread.horizontal: Horizontal verteilen
fd.context.align.spread.vertical: Vertikal verteilen

fd.context.group: Gruppieren
fd.context.ungroup: Gruppe lösen
</i18n>
