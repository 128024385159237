<script>
import iFrameModule from './iFrameModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import { _getLocation } from '../util/helper.js';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'iFrameModule',
	icon: 'embed2',
}

export const MetaDescriptionView = {
	name: 'iFrameMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: "iFrameModule",
		x: "center",
		y: "center",
		width: 1000,
		height: 600,
		style: {
			backgroundColor: {
				r: 255,
				g: 255,
				b: 255,
				a: 70
			},
			padding: 0,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null
		},
		props: {
			src: '',
			zoom: 100,
			offset: {
				x: 0,
				y: 0
			},
			resizing: false,
			sandbox: true
		}
	};
}

export const description = {
	extends: DescriptionView,
	data() {
		const src = _getLocation(this.module.props.src);
		return {
			description: src.hostname + src.pathname || module.props.src
		}
	}
}

export const ConfigView = {
	name: 'iFrameModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [
				{
					type: 'Text',
					label: this.$_('fd.iframemodule.settings.src'),
					value: this.module.props.src,
					events: {
						input: this.setSrc
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.iframemodule.settings.zoom'),
					value: this.module.props.zoom,
					min: 30,
					max: 250,
					events: {
						input: this.setZoom
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.iframemodule.settings.offsetX'),
					value: this.module.props.offset.x,
					events: {
						input: this.setOffsetX
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.iframemodule.settings.offsetY'),
					value: this.module.props.offset.y,
					events: {
						input: this.setOffsetY
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.iframemodule.settings.iframeresizing'),
					value: this.module.props.resizing,
					events: {
						input: this.setResizing
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.iframemodule.settings.sandbox'),
					help: this.$_('fd.iframemodule.settings.sandbox.help'),
					value: this.module.props.sandbox,
					events: {
						input: this.setSandbox,
					}
				}
			];
		}
	},
	methods: {
		setSrc(src) {
			src = src.trim();
			src = (src.indexOf('http') !== 0 ? 'http://' : '') + src;
			this.commitChange('props', { src });
		},
		setZoom(zoom) {
			this.commitChange('props', { zoom });
		},
		setOffsetX(offset) {
			this.commitChange('props', { offset: {
				...this.module.props.offset,
				x: offset
			} });
		},
		setOffsetY(offset) {
			this.commitChange('props', { offset: {
				...this.module.props.offset,
				y: offset
			} });
		},
		setResizing(resizing) {
			this.commitChange('props', { resizing });
		},
		setSandbox(sandbox) {
			this.commitChange('props', { sandbox });
		},
	}
}

export default {
	extends: iFrameModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.iframemodule.title: Externe Webseite
fd.iframemodule.description: >
	Mit Hilfe dieser Funktion ist es möglich, Webseiten von Dritten in Ihren
	Slide einzufügen und an einer beliebigen Stelle darzustellen.
fd.iframemodule.add: Externe Webseite hinzufügen

fd.iframemodule.settings.src: Link
fd.iframemodule.settings.zoom: Zoom
fd.iframemodule.settings.offsetX: Horizontal Scrollen
fd.iframemodule.settings.offsetY: Vertikal Scrollen
fd.iframemodule.settings.iframeresizing: Iframe einpassen
fd.iframemodule.settings.sandbox: Sandbox
fd.iframemodule.settings.sandbox.help: >
	Die externe Website läuft in einer Sandbox und hat nur wenige Rechte. Es
	werden z.B. keine Skripte oder Plugins ausgeführt. Deaktivieren Sie die
	Sandbox nur, wenn Sie wissen was sie tun!
</i18n>
