<template>
	<div class="fd-file-input">
		<div class="preview" :style="style" @click="handleFileChooser">
			<div class="info">
				<div class="name">{{file.name || $_('fd.fileinput.unknown')}}</div>
				<div class="size">{{file.size | humanizeBytes}}</div>
			</div>
			<div class="label">{{$_('fd.fileinput.change')}}</div>
		</div>
	</div>
</template>

<script>
import FileChooser from '../components/Modal/FileChooser.vue';
import { IMAGES } from '../modules/StyleModule.vue';

export default {
	name: 'FileInput',
	props: ['value', 'config'],
	computed: {
		files() {
			return this.$store.state.shared.files;
		},
		file() {
			const f = this.value instanceof Array ? this.value[0] : this.value || {};
			return this.files.find(file => file.path == f.path) || f;
		},
		style() {
			return {
				backgroundImage: `url('${this.file.thumb || this.file.path}')`,
			};
		},
	},
	mounted() {

		if(this.$store.state.type == 'image') {
			this.handleFileChooser();
		}
		this.$root.$on('openFileChooser', () => {
				setTimeout(() => {
						this.handleFileChooser();
				}, 20);
		});
	},
	methods: {
		handleFileChooser() {
			const {
				fileTypeKey,
				allowed,
				visible,
				multiselect,
				value,
				style,
			} = this.config;

			this.$store.commit('modal/create', {
				component: FileChooser,
				props: {
					options: {
						fileTypeKey,
						allowed,
						visible,
						multiselect,
						value,
						style,
						onselect: files => {
							this.$emit(
								'input',
								files.map(file => ({
									path: file.path,
								}))
							);
						},
					},
				},
			});
		},
	},
};
</script>

<style lang="scss">
.fd-config-item.File {
	flex-direction: column;

	& > * {
		width: 100% !important;

		&.label {
			margin-bottom: 3px;
		}
	}
}
.fd-config-item.File .fd-file-input {
	width: 100%;
	background-image: url('../img/colorpicker_colorbg.png');
	cursor: pointer;

	.preview {
		height: 150px;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;

		.info {
			color: #000;
			background: hsla(0, 0%, 100%, 0.8);
			padding: 5px;
			font-size: 15px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: stretch;

			& > * {
				text-align: center;
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.label {
			flex: 0 0 30px;
			background: rgba(0, 0, 0, 0.6);

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>

<i18n lang="de_DE">
fd.fileinput.unknown: Unbekannt
fd.fileinput.change: ändern
</i18n>
