import { backgrounds, styles, colors } from '../FileManager.js';

export function inject($store) {
	injectDefaultFiles($store);
}

function injectDefaultFiles($store) {
	$store.state.shared.files = [
		...backgrounds,
		...styles,
		...colors,
		...$store.state.shared.files,
	];
}
