<script>
import FeedModule from './FeedModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import { _getLocation } from '../util/helper.js';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'FeedModule',
	icon: 'feed2',
	resize: 'width',
}

export const MetaDescriptionView = {
	name: 'FeedMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'FeedModule',
		x: 'center',
		y: 'center',
		width: 1337,
		height: 62,
		style: {
			fontFamily: null,
			fontSize: 36,
			color: null,
			backgroundColor: null,
			padding: null,
			border: null,
			shadow: null,
			advanced: null,
		},
		props: {
			uri:  'http://feeds.bbci.co.uk/news/rss.xml?edition=int',
			text: '',
			speed: 50,
			onlyTitles: false
		},
	}
}

export const description = {
	extends: DescriptionView,
	data() {
		if(!this.module.props.uri) {
			return {
				description: this.module.props.text
			}
		}

		const uri = _getLocation(this.module.props.uri);
		return {
			description: uri.hostname + uri.pathname || this.module.props.uri
		}
	}
}

export const ConfigView = {
	name: 'FeedModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [{
				type: 'Text',
				label: this.$_('fd.feedmodule.settings.uri'),
				value: this.module.props.uri,
				events: {
					input: this.setUri
				}
			}, {
				type: 'Textarea',
				label: this.$_('fd.feedmodule.settings.text'),
				value: this.module.props.text,
				events: {
					input: this.setText
				}
			}, {
				type: 'Boolean',
				label: this.$_('fd.feedmodule.settings.only.title'),
				value: this.module.props.onlyTitles,
				events: {
					input: this.setOnlyTitle
				}
			}, {
				type: 'Number',
				label: this.$_('fd.feedmodule.settings.speed'),
				value: this.module.props.speed,
				events: {
					input: this.setSpeed
				}
			}];
		}
	},
	methods: {
		setUri(uri) {
			this.commitChange('props', { uri: uri.trim() });
		},
		setText(text) {
			this.commitChange('props', { text });
		},
		setOnlyTitle(onlyTitles) {
			this.commitChange('props', { onlyTitles });
		},
		setSpeed(speed) {
			this.commitChange('props', { speed });
		},
	}
}

export default {
	extends: FeedModule,

	data() {
		return {
			running: true
		}
	},
	methods: {
		start() {},
		stop() {}
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.feedmodule.title: Feed
fd.feedmodule.description: >
	Mit der News-Feed Funktion können Sie Meldungen von einer anderen Webseite in
	Form eines Newstickers anbinden.
fd.feedmodule.add: Feed anzeigen

fd.feedmodule.settings.uri: Feed URL
fd.feedmodule.settings.text: Feed Text (wenn URL leer)
fd.feedmodule.settings.speed: Geschwindigkeit
fd.feedmodule.settings.only.title: Zeige nur Titel
fd.feedmodule.message.error.nofeed: Kein Feed vorhanden!
fd.feedmodule.message.error.onload: Fehler beim Laden!
fd.feedmodule.message.error.xml: Der Feed hat kein gültiges Format!
fd.feedmodule.message.feedloading: Lade den Feed...
</i18n>
