<template>
	<div class="fd-tabbed-pane-view">
		<div class="tabs">
			<div class="tab"
				v-for="tab of children"
				:key="tab.label"
				:class="{selected: tab.isActive}"
				@click="setActive(tab)"
			>{{tab.label}}</div>
		</div>
		<div class="panes">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export { default as PaneView } from './Pane.vue';
export default {
	name: 'TabbedPaneView',
	data: () => ({
		children: []
	}),
	mounted() {
		this.children = this.$children;
		this.children.some(child => child.isActive = true);
	},
	methods: {
		setActive(activeChild) {
			this.children.forEach(child => child.isActive = false);
			activeChild.isActive = true;
		}
	}
}
</script>
