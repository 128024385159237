<script>
import ClockModule, { timezones } from './ClockModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'ClockModule',
	icon: 'clock',
}

export const MetaDescriptionView = {
	name: 'ClockMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'ClockModule',
		x: 'center',
		y: 'center',
		width: 320,
		height: 240,
		style: {
			backgroundColor: {
				r: 0, g: 0, b: 0, a: 0
			},
			padding: null,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			markerType: 'circle',
			fontSize: 40,
			numberDistance: 9,
			timezoneOffset: 0,
			timezoneName: 'W. Europe Standard Time',
			showSeconds: true,
			digital: false,
			color: {
				second: {
					r: 0, g: 0, b: 0, a: 100
				},
				minute: {
					r: 0, g: 0, b: 0, a: 100
				},
				hour: {
					r: 0, g: 0, b: 0, a: 100
				},
				marker: {
					r: 0, g: 0, b: 0, a: 100
				},
				number: {
					r: 0, g: 0, b: 0, a: 100
				},
			},
		},
	}
}

export const description = {
	extends: DescriptionView,
	data() {
		return {
			description: this.$_(
				`fd.clockmodule.${
					this.module.props.digital ? 'digital' : 'analog'
				}clock`
			)
		}
	}
}

export const ConfigView = {
	name: 'ClockModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [
				{
					type: 'Select',
					label: this.$_('fd.clockmodule.settings.timezone'),
					value: this.module.props.timezoneName,
					values: timezones.map(zone => ({
						label: zone.text,
						value: zone.name,
					})),
					events: {
						input: this.setTimezone
					}
				},
				{
					type: 'Select',
					label: this.$_('fd.clockmodule.settings.markerType'),
					value: this.module.props.markerType,
					values: [{
						label:  this.$_('fd.clockmodule.settings.markerType.stroke'),
						value: 'stroke'
					},{
						label:  this.$_('fd.clockmodule.settings.markerType.circle'),
						value: 'circle'
					},{
						label:  this.$_('fd.clockmodule.settings.markerType.numbers'),
						value: 'number'
					}],
					events: {
						input: this.setMarkerType
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.clockmodule.settings.digital'),
					value: this.module.props.digital,
					events: {
						input: this.setType
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.clockmodule.settings.hasSeconds'),
					value: this.module.props.showSeconds,
					events: {
						input: this.setSecondsStatus
					}
				},
				{
					type: 'Color',
					label: this.$_('fd.clockmodule.settings.colorNumber'),
					value: this.module.props.color.number,
					events: {
						input: this.setColorNumber
					}
				},
				{
					type: 'Color',
					label: this.$_('fd.clockmodule.settings.colorMarker'),
					value: this.module.props.color.marker,
					events: {
						input: this.setColorSplitter
					}
				},
				{
					type: 'Color',
					label: this.$_('fd.clockmodule.settings.colorSecond'),
					value: this.module.props.color.second,
					events: {
						input: this.setColorSecond
					}
				},
				{
					type: 'Color',
					label: this.$_('fd.clockmodule.settings.colorMinute'),
					value: this.module.props.color.minute,
					events: {
						input: this.setColorMinute
					}
				},
				{
					type: 'Color',
					label: this.$_('fd.clockmodule.settings.colorHour'),
					value: this.module.props.color.hour,
					events: {
						input: this.setColorHour
					}
				}
			];
		}
	},
	methods: {
		setTimezone(timezoneName) {
			this.commitChange('props', { timezoneName });
		},
		setMarkerType(markerType) {
			this.commitChange('props', { markerType });
		},
		setType(digital) {
			this.commitChange('props', { digital });
		},
		setSecondsStatus(showSeconds) {
			this.commitChange('props', { showSeconds });
		},
		setColorNumber(color) {
			this.commitChange('props', { 'color.number': color });
		},
		setColorSplitter(color) {
			this.commitChange('props', { 'color.marker': color });
		},
		setColorSecond(color) {
			this.commitChange('props', { 'color.second': color });
		},
		setColorMinute(color) {
			this.commitChange('props', { 'color.minute': color });
		},
		setColorHour(color) {
			this.commitChange('props', { 'color.hour': color });
		},
	}
}

export default {
	extends: ClockModule,

	mounted() {
		this.start();
	},

	computed: {
		timezoneWatcher() {
			return this.module.props.timezoneName;
		}
	},
	watch: {
		timezoneWatcher() {
			this.$options.clock.refreshTimezoneOffset();
			this.$options.clock.draw();
		}
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.clockmodule.title: Uhr
fd.clockmodule.description: >
	Fügt eine analoge oder digitale Uhr an einer beliebigen Stelle ein.
fd.clockmodule.add: Uhr hinzufügen

fd.clockmodule.analogClock: Analoge Uhr
fd.clockmodule.digitalClock: Digitale Uhr

fd.clockmodule.settings.markerType: Aussehen
fd.clockmodule.settings.markerType.numbers: Zahlen
fd.clockmodule.settings.markerType.none: Keine
fd.clockmodule.settings.markerType.stroke: Striche
fd.clockmodule.settings.markerType.circle: Kreise

fd.clockmodule.settings.colorMarker: Stunden
fd.clockmodule.settings.colorHour: Stundenzeiger
fd.clockmodule.settings.colorMinute: Minutenzeiger
fd.clockmodule.settings.colorSecond: Sekundenzeiger
fd.clockmodule.settings.digital: Analog / Digital
fd.clockmodule.settings.showNumbers: Zeige Stunden
fd.clockmodule.settings.colorNumber: Minuten
fd.clockmodule.settings.hasSeconds: Sekunden anzeigen
fd.clockmodule.settings.timezone: Zeitzone
</i18n>
