<template>
	<modal-view class="fd-export-modal-view"
		:size="550"
		icon="database-export"
		:title="$_('fd.export.modal.title')"
		:buttons="buttons"
	>
		<p>{{$_('fd.export.modal.label')}}:</p>
		<input type="text" v-model="filename"/>
		<a class="invisible"
			:href="dataURL"
			:download="filename + '.json'"
			ref="download"
		></a>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';

export default {
	name: 'ExportModalView',
	components: { ModalView },
	data() {
		return {
			filename: 'FoxDesigner-' + Date.now(),
			dataURL: '',
			buttons: [{
				label: this.$_('fd.export.modal.actions.download'),
				css: 'primary',
				icon: 'database-export',
				click: () => this.export() && false
			}, {
				label: this.$_('fd.export.modal.actions.cancel')
			}]
		}
	},
	methods: {
		async export() {
			this.dataURL = URL.createObjectURL(new Blob(
				[JSON.stringify(this.$store.state.data)],
				{ type: 'application/json' }
			));

			await this.$nextTick();

			this.$refs.download.click();

			await this.$nextTick();

			this.$store.commit('modal/close');

			setTimeout(() => URL.revokeObjectURL(this.dataURL), 5000);
		}
	}
}
</script>

<style lang="scss">
.fd-export-modal-view {
	.body {
		flex-direction: column;
	}

	input {
		padding: 6px 12px;
	}

	.invisible {
		position: absolut;
		pointer-events: none;
		opacity: 0;
	}
}
</style>

<i18n lang="de_DE">
fd.export.modal.title: Foxdesigner - Export
fd.export.modal.label: Geben Sie einen Dateinamen ein
fd.export.modal.actions.download: Herunterladen
fd.export.modal.actions.cancel: Abbrechen
</i18n>
