<template>
	<modal-window-view class="fd-calc-chooser"
		:id="id"
		:size="750"
		:title="$_('fd.calcchooser.title')"
		:buttons="buttons"
	>
		<div class="calc-chooser">
			<div class="section calc">
				<div class="head">
					{{$_('fd.calcchooser.section.calc')}}:
				</div>
				<div class="body">
					<input type="text" v-model="calc" ref="calc">
					<button class="fd-button primary"
						@click="insertVariable"
					>{{$_('fd.calcchooser.insertvar')}}</button>
				</div>
			</div>
			<div class="section settings">
				<div class="head">
					{{$_('fd.calcchooser.section.settings')}}:
				</div>
				<div class="body">
					<div class="nsep">
						<label>
							{{$_('fd.calcchooser.nsep')}}:
							<select v-model="options.nsep">
								<option value="">-</option>
								<option value=".">. ({{$_('fd.calcchooser.dot')}})</option>
								<option value=",">, ({{$_('fd.calcchooser.comma')}})</option>
								<option value="‘">‘ ({{$_('fd.calcchooser.apostrophe')}})</option>
							</select>
						</label>
					</div>
					<div class="msep">
						<label>
							{{$_('fd.calcchooser.msep')}}:
							<select v-model="options.msep">
								<option value=".">. ({{$_('fd.calcchooser.dot')}})</option>
								<option value=",">, ({{$_('fd.calcchooser.comma')}})</option>
							</select>
						</label>
					</div>
					<div class="dcas">
						<label>
							{{$_('fd.calcchooser.dcas')}}:
							<input type="number" min="0" max="9" v-model="options.dcas">
						</label>
					</div>
				</div>
			</div>
		</div>
	</modal-window-view>
</template>

<script>
import ModalWindowView from './ModalWindow.vue';
import VariableChooser from './VariableChooser.vue';
import NoVariablesModalView from '../Modal/NoVariablesModal.vue';

export default {
	name: 'CalcChooserModalView',
	props: ['id', 'value', 'onChoose'],
	components: { ModalWindowView },
	data() {
		return {
			calc: '(1+2)/7',
			options: {
				nsep: '',
				msep: ',',
				dcas: '2',
			},
			buttons: [{
				label: this.$_('fd.calcchooser.actions.ok'),
				css: 'primary',
				click: () => this.ok()
			}, {
				label: this.$_('fd.calcchooser.actions.cancel')
			}]
		}
	},
	computed: {
		variables() {
			return this.$store.state.shared.variables;
		}
	},
	mounted() {
		if(this.value) {
			const [calc, options] = this.value.split('|');
			this.calc = calc;
			this.options = this.evalOptions(options || ',2');
		}
	},
	methods: {
		evalOptions(options) {
			let nsep = '';
			let msep = ',';
			let dcas = '2';

			switch(options.length) {
				case 1:
					if(!isNaN(parseInt(options[0]))) {
						dcas = parseInt(options[0]);
					}
					else {
						msep = options[0];
					}
					break;

				case 2:
					if(!isNaN(parseInt(options[1]))) {
						msep = options[0];
						dcas = parseInt(options[1]);
					}
					else {
						nsep = options[0];
						msep = options[1];
					}
					break;

				case 3:
					nsep = options[0];
					msep = options[1];
					dcas = options[2];
					break;
			}

			return { nsep, msep, dcas };
		},
		insertVariable() {
			// Check if variables available
			if(!this.variables || Object.keys(this.variables).length < 1) {
				this.$store.commit('modal/create', {
					component: NoVariablesModalView
				});
				return;
			}

			// Save selection indices
			const selectionStart = this.$refs.calc.selectionStart;
			const selectionEnd = this.$refs.calc.selectionEnd;

			// Save text before and after the inserted variable
			const textBefore = this.calc.substr(0, selectionStart);
			const selection = this.calc.substr(selectionStart, selectionEnd);
			const textAfter = this.calc.substr(selectionEnd);

			// Open var chooser
			this.$store.commit('window/create', {
				id: this.id + ':vc',
				component: VariableChooser,
				props: {
					filter: v => v.includes('Raw'),
					preselect: selection,
					onChoose: (variable) => {
						if(!variable) return false;

						this.calc = textBefore + variable + textAfter;
					}
				}
			});
		},
		ok() {
			return this.onChoose(
				this.calc
					+ '|'
					+ this.options.nsep
					+ this.options.msep
					+ this.options.dcas
			);
		}
	}
}
</script>

<style lang="scss">
$yellow: #fec107;

.fd-calc-chooser {

	input, select {
		display: block;
		padding: 6px 12px;
		font-size: 14px;
		color: #555;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		width: 100%;
		outline: none;
		margin: 0;
		box-sizing: border-box;
	}

	.window > .body {
		flex-direction: column;

		.calc-chooser {
			flex: 1;

			.section {

				.head {
					border-bottom: 1px solid $yellow;
					margin-bottom: 10px;
				}

				.body {
					input, select {
						font-family: "Courier New", Consolas, monospace;
					}

					button {
						width: initial;
						background: $yellow;
						color: white;
						font-size: 0.9em;
						text-transform: uppercase;
						text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					}
				}

				&.calc {
					.body {
						display: flex;

						input {
							flex: 1;
							margin-right: 5px;
						}
					}
				}

				&.settings {
					.head {
						margin-top: 25px;
					}

					.body {
						display: flex;
						justify-content: space-between;

						& > div {
							width: 31%;
						}

						label {
							display: block;
							font-size: smaller;
						}

						input {
							padding: 7px 12px;
						}

						select, input {
							height: 32px;
						}
					}
				}
			}
		}
	}
}
</style>

<i18n lang="de_DE">
fd.calcchooser.title: Konfigurieren Sie eine Berechnung...

fd.calcchooser.section.calc: Berechnung
fd.calcchooser.insertvar: Variable einfügen
fd.calcchooser.section.settings: Formatierung
fd.calcchooser.nsep: Tausendertrenner
fd.calcchooser.msep: Nachkommazeichen
fd.calcchooser.dcas: Nachkommastellen
fd.calcchooser.dot: Punkt
fd.calcchooser.comma: Komma
fd.calcchooser.apostrophe: Apostroph

fd.calcchooser.actions.ok: Fertig
fd.calcchooser.actions.cancel: Abbrechen
</i18n>
