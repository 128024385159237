import CONFIG from './Config.js';

/**
 * The background images
 */
export const backgrounds = [
  {
    id: 'imgbgstandardjpg',
    name: 'Simple Grey (default)',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 185341,
    thumb: CONFIG.BASEPATH + '/img/bg/standard.jpg',
    path: CONFIG.BASEPATH + '/img/bg/standard.jpg',
  }, {
    id: 'imgbgsample_bgjpg',
    name: 'Foliage',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 223051,
    thumb: CONFIG.BASEPATH + '/img/bg/sample_bg.jpg',
    path: CONFIG.BASEPATH + '/img/bg/sample_bg.jpg',
  }, {
    id: 'imgbgBG01jpg',
    name: 'Simple Grey',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 164903,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-01.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-01.jpg',
  }, {
    id: 'imgbgBG02jpg',
    name: 'Blured Lights',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 536920,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-02.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-02.jpg',
  }, {
    id: 'imgbgBG03jpg',
    name: 'Blured Lines',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 302109,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-03.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-03.jpg',
  }, {
    id: 'imgbgBG04jpg',
    name: 'Circles',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 1013611,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-04.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-04.jpg',
  }, {
    id: 'imgbgBG05jpg',
    name: 'Circles 2',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 387699,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-05.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-05.jpg',
  }, {
    id: 'imgbgBG06jpg',
    name: 'Aluminium',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 658581,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-06.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-06.jpg',
  }, {
    id: 'imgbgBG07jpg',
    name: 'Aluminium 2',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 929558,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-07.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-07.jpg',
  }, {
    id: 'imgbgBG08jpg',
    name: 'Blue',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 166892,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-08.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-08.jpg',
  }, {
    id: 'imgbgBG09jpg',
    name: 'Green Bubbles',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 426215,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-09.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-09.jpg',
  }, {
    id: 'imgbgBG10jpg',
    name: 'Wood',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 1578752,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-10.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-10.jpg',
  }, {
    id: 'imgbgBG11jpg',
    name: 'Black Honeycombs',
    type: 'image/jpeg',
    group: 'public',
    visible: 'every',
    size: 932598,
    thumb: CONFIG.BASEPATH + '/img/bg/BG-11.jpg',
    path: CONFIG.BASEPATH + '/img/bg/BG-11.jpg',
  }
];

/**
 * The style images
 */
export const styles = [
  {
    id: 'sample',
    name: 'sample',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 31174,
    thumb: CONFIG.BASEPATH + '/img/sample.png',
    path: CONFIG.BASEPATH + '/img/sample.png',
  }, {
    id: 'foxdesigner',
    name: 'FoxDesigner',
    type: 'image/png',
    group: 'public',
    visible: 'hidden',
    thumb: CONFIG.BASEPATH + '/img/FoxDesigner-Icon.png',
    path: CONFIG.BASEPATH + '/img/FoxDesigner-Icon.png',
  }, {
    id: 'highsquared',
    name: 'High squared',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 42319,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-01.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-01.png',
  }, {
    id: 'highblank',
    name: 'High blank',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 18064,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-02.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-02.png',
  }, {
    id: 'postit2',
    name: 'Post it 2',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 16563,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-03.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-03.png',
  }, {
    id: 'squarelined',
    name: 'Square lined',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 40411,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-04.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-04.png',
  }, {
    id: 'squarelinedsmall',
    name: 'Square lined',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 39951,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-04-small.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-04-small.png',
  }, {
    id: 'squareblank',
    name: 'Square blank',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 69282,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-05.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-05.png',
  }, {
    id: 'squareblanksmall',
    name: 'Square blank',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 73328,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-05-small.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-05-small.png',
  }, {
    id: 'crosswiseblank',
    name: 'Crosswise blank',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 65147,
    thumb: CONFIG.BASEPATH + '/img/style/Paper-06.png',
    path: CONFIG.BASEPATH + '/img/style/Paper-06.png',
  }, {
    id: 'polaroid',
    name: 'Polaroid',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 91241,
    thumb: CONFIG.BASEPATH + '/img/style/Polaroid.png',
    path: CONFIG.BASEPATH + '/img/style/Polaroid.png',
  }, {
    id: 'postit',
    name: 'Post-it',
    type: 'image/png',
    group: 'public',
    visible: 'normal',
    size: 738155,
    thumb: CONFIG.BASEPATH + '/img/style/Post-it.png',
    path: CONFIG.BASEPATH + '/img/style/Post-it.png',
  }
];

/**
 * The colors
 */
export const colors = [
  'ffebee', 'ffcdd2', 'ef9a9a', 'e57373', 'ef5350', 'f44336', 'e53935', 'd32f2f', 'c62828', 'b71c1c',
  'fce4ec', 'f8bbd0', 'f48fb1', 'f06292', 'ec407a', 'e91e63', 'd81b60', 'c2185b', 'ad1457', '880e4f',
  'f3e5f5', 'e1bee7', 'ce93d8', 'ba68c8', 'ab47bc', '9c27b0', '8e24aa', '7b1fa2', '6a1b9a', '4a148c',
  'ede7f6', 'd1c4e9', 'b39ddb', '9575cd', '7e57c2', '673ab7', '5e35b1', '512da8', '4527a0', '311b92',
  'e8eaf6', 'c5cae9', '9fa8da', '7986cb', '5c6bc0', '3f51b5', '3949ab', '303f9f', '283593', '1a237e',
  'e3f2fd', 'bbdefb', '90caf9', '64b5f6', '42a5f5', '2196f3', '1e88e5', '1976d2', '1565c0', '0d47a1',
  'e1f5fe', 'b3e5fc', '81d4fa', '4fc3f7', '29b6f6', '03a9f4', '039be5', '0288d1', '0277bd', '01579b',
  'e0f7fa', 'b2ebf2', '80deea', '4dd0e1', '26c6da', '00bcd4', '00acc1', '0097a7', '00838f', '006064',
  'e0f2f1', 'b2dfdb', '80cbc4', '4db6ac', '26a69a', '009688', '00897b', '00796b', '00695c', '004d40',
  'e8f5e9', 'c8e6c9', 'a5d6a7', '81c784', '66bb6a', '4caf50', '43a047', '388e3c', '2e7d32', '1b5e20',
  'f1f8e9', 'dcedc8', 'c5e1a5', 'aed581', '9ccc65', '8bc34a', '7cb342', '689f38', '558b2f', '33691e',
  'f9fbe7', 'f0f4c3', 'e6ee9c', 'dce775', 'd4e157', 'cddc39', 'c0ca33', 'afb42b', '9e9d24', '827717',
  'fffde7', 'fff9c4', 'fff59d', 'fff176', 'ffee58', 'ffeb3b', 'fdd835', 'fbc02d', 'f9a825', 'f57f17',
  'fff8e1', 'ffecb3', 'ffe082', 'ffd54f', 'ffca28', 'ffc107', 'ffb300', 'ffa000', 'ff8f00', 'ff6f00',
  'fff3e0', 'ffe0b2', 'ffcc80', 'ffb74d', 'ffa726', 'ff9800', 'fb8c00', 'f57c00', 'ef6c00', 'e65100',
  'fbe9e7', 'ffccbc', 'ffab91', 'ff8a65', 'ff7043', 'ff5722', 'f4511e', 'e64a19', 'd84315', 'bf360c',
  'efebe9', 'd7ccc8', 'bcaaa4', 'a1887f', '8d6e63', '795548', '6d4c41', '5d4037', '4e342e', '3e2723',
  'fafafa', 'f5f5f5', 'eeeeee', 'e0e0e0', 'bdbdbd', '9e9e9e', '757575', '616161', '424242', '212121',
  'eceff1', 'cfd8dc', 'b0bec5', '90a4ae', '78909c', '607d8b', '546e7a', '455a64', '37474f', '263238',
  'ffffff', '000000',
].map(hex => ({
  id: hex,
  name: '#' + hex,
  type: 'image/svg+xml',
  group: 'color',
  visible: 'every',
  size: 144,
  thumb: CONFIG.BASEPATH + '/img/colors/%23' + hex + '.svg',
  path: CONFIG.BASEPATH + '/img/colors/%23' + hex + '.svg',
}));

export const icons = [
  ['home', ['home', 'house']],
  ['home2', ['home2', 'house2']],
  ['home3', ['home3', 'house3']],
  ['home4', ['home4', 'house4']],
  ['home5', ['home5', 'house5']],
  ['home6', ['home6', 'house6']],
  ['home7', ['home7', 'house7']],
  ['home8', ['home8', 'house8']],
  ['home9', ['home9', 'house9']],
  ['office', ['office', 'buildings']],
  ['city', ['city', 'buildings2']],
  ['newspaper', ['newspaper', 'news']],
  ['magazine', ['magazine', 'newspaper2']],
  ['design', ['design', 'pencil-ruler']],
  ['pencil', ['pencil', 'write']],
  ['pencil2', ['pencil2', 'write2']],
  ['pencil3', ['pencil3', 'write3']],
  ['pencil4', ['pencil4', 'write4']],
  ['pencil5', ['pencil5', 'write5']],
  ['pencil6', ['pencil6', 'write6']],
  ['pencil7', ['pencil7', 'write7']],
  ['eraser', ['eraser', 'rubber']],
  ['eraser2', ['eraser2', 'rubber2']],
  ['eraser3', ['eraser3', 'rubber3']],
  ['quill', ['quill', 'feather']],
  ['quill2', ['quill2', 'feather2']],
  ['quill3', ['quill3', 'feather3']],
  ['quill4', ['quill4', 'feather4']],
  ['pen', ['pen', 'write8']],
  ['pen-plus', ['pen-plus', 'pen-add']],
  ['pen-minus', ['pen-minus', 'pen-remove']],
  ['pen2', ['pen2', 'write9']],
  ['pen3', ['pen3', 'write10']],
  ['pen4', ['pen4', 'write11']],
  ['pen5', ['pen5', 'write12']],
  ['marker', ['marker', 'write13']],
  ['marker2', ['marker2', 'write14']],
  ['blog', ['blog', 'pen6']],
  ['blog2', ['blog2', 'pencil8']],
  ['pen6', ['pen7', 'write15']],
  ['brush', ['brush', 'paint']],
  ['spray', ['spray', 'paint2']],
  ['palette', ['palette', 'color']],
  ['palette2', ['palette2', 'color2']],
  ['color-sampler', ['color-sampler', 'colors']],
  ['toggle', ['toggle', 'switch']],
  ['bucket', ['bucket', 'color3']],
  ['bucket2', ['bucket2', 'color4']],
  ['gradient', ['gradient', 'color5']],
  ['eyedropper', ['eyedropper', 'color6']],
  ['eyedropper2', ['eyedropper2', 'color7']],
  ['eyedropper3', ['eyedropper3', 'color8']],
  ['droplet', ['droplet', 'color9']],
  ['droplet2', ['droplet2', 'color10']],
  ['droplet-contrast', ['droplet-contrast', 'droplet3']],
  ['color-clear', ['color-clear', 'droplet4']],
  ['paint-format', ['paint-format', 'format']],
  ['stamp', ['stamp', 'pattern']],
  ['images', ['images', 'pictures']],
  ['image', ['image', 'picture']],
  ['image2', ['image2', 'picture2']],
  ['image-compare', ['image-compare', 'diff']],
  ['images2', ['images2', 'pictures2']],
  ['image3', ['image3', 'picture3']],
  ['images3', ['images3', 'pictures3']],
  ['image4', ['image4', 'picture4']],
  ['image5', ['image5', 'picture5']],
  ['image6', ['image6', 'picture6']],
  ['camera', ['camera', 'photo']],
  ['camera2', ['camera2', 'photo2']],
  ['camera3', ['camera3', 'photo3']],
  ['shutter', ['shutter', 'camera4']],
  ['headphones', ['headphones', 'headset']],
  ['headset', ['headset2', 'support']],
  ['music', ['music', 'song']],
  ['music2', ['music2', 'song2']],
  ['music3', ['music3', 'song3']],
  ['music4', ['music4', 'song4']],
  ['music5', ['music5', 'song5']],
  ['music6', ['music6', 'song6']],
  ['album', ['album', 'songs']],
  ['cassette', ['cassette', 'tape']],
  ['tape', ['tape2']],
  ['piano', ['piano', 'music7']],
  ['guitar', ['guitar', 'music8']],
  ['trumpet', ['trumpet', 'music9']],
  ['speakers', ['speakers', 'music10']],
  ['play', ['play', 'video']],
  ['play2', ['play2', 'video2']],
  ['clapboard-play', ['clapboard-play', 'video3']],
  ['clapboard', ['clapboard', 'video4']],
  ['media', ['media', 'photo-music']],
  ['presentation', ['presentation', 'board-play']],
  ['movie', ['movie', 'video5']],
  ['film', ['film', 'video6']],
  ['film2', ['film2', 'video7']],
  ['film3', ['film3', 'video8']],
  ['film4', ['film4', 'video9']],
  ['video-camera', ['video-camera', 'video10']],
  ['video-camera2', ['video-camera2', 'video11']],
  ['video-camera-slash', ['video-camera-slash', 'video12']],
  ['video-camera3', ['video-camera3', 'video13']],
  ['video-camera4', ['video-camera4', 'video14']],
  ['video-camera-slash2', ['video-camera-slash2', 'video15']],
  ['video-camera5', ['video-camera5', 'video16']],
  ['recording', ['recording', 'video17']],
  ['dice', ['dice', 'game']],
  ['gamepad', ['gamepad', 'game2']],
  ['gamepad2', ['gamepad2', 'game3']],
  ['gamepad3', ['gamepad3', 'game4']],
  ['pacman', ['pacman', 'game5']],
  ['spades', ['spades', 'cards']],
  ['clubs', ['clubs', 'cards2']],
  ['diamonds', ['diamonds', 'cards3']],
  ['chess-king', ['chess-king', 'chess']],
  ['chess-queen', ['chess-queen', 'chess2']],
  ['chess-rock', ['chess-rock', 'chess3']],
  ['chess-bishop', ['chess-bishop', 'chess4']],
  ['chess-knight', ['chess-knight', 'chess5']],
  ['chess-pawn', ['chess-pawn', 'chess6']],
  ['chess', ['chess7', 'grid']],
  ['bullhorn', ['bullhorn', 'megaphone']],
  ['megaphone', ['megaphone2', 'bullhorn2']],
  ['new', ['new', 'news2']],
  ['connection', ['connection', 'wifi']],
  ['connection2', ['connection2', 'wifi2']],
  ['connection3', ['connection3', 'wifi3']],
  ['connection4', ['connection4', 'wifi4']],
  ['podcast', ['podcast', 'broadcast']],
  ['station', ['station', 'radio']],
  ['station2', ['station2', 'radio2']],
  ['antenna', ['antenna', 'wifi5']],
  ['satellite-dish', ['satellite-dish']],
  ['satellite-dish2', ['satellite-dish2']],
  ['feed', ['feed', 'wave']],
  ['podcast2', ['podcast2', 'broadcast2']],
  ['antenna2', ['antenna2', 'wifi6']],
  ['mic', ['mic', 'microphone']],
  ['mic-off', ['mic-off', 'microphone2']],
  ['mic2', ['mic2', 'microphone3']],
  ['mic-off2', ['mic-off2', 'microphone4']],
  ['mic3', ['mic3', 'microphone5']],
  ['mic4', ['mic4', 'microphone6']],
  ['mic5', ['mic5', 'microphone7']],
  ['book', ['book', 'read']],
  ['book2', ['book2', 'read2']],
  ['book-play', ['book-play', 'book3']],
  ['book3', ['book4', 'read3']],
  ['bookmark', ['bookmark', 'book5']],
  ['books', ['books', 'library']],
  ['archive', ['archive', 'office2']],
  ['reading', ['reading', 'library2']],
  ['library', ['library3', 'bank']],
  ['library2', ['library4', 'bank2']],
  ['graduation', ['graduation', 'education']],
  ['file-text', ['file-text', 'file']],
  ['profile', ['profile', 'file2']],
  ['file-empty', ['file-empty', 'file3']],
  ['file-empty2', ['file-empty2', 'file4']],
  ['files-empty', ['files-empty', 'files']],
  ['files-empty2', ['files-empty2', 'files2']],
  ['file-plus', ['file-plus', 'file5']],
  ['file-plus2', ['file-plus2', 'file6']],
  ['file-minus', ['file-minus', 'file7']],
  ['file-minus2', ['file-minus2', 'file8']],
  ['file-download', ['file-download', 'file9']],
  ['file-download2', ['file-download2', 'file10']],
  ['file-upload', ['file-upload', 'file11']],
  ['file-upload2', ['file-upload2', 'file12']],
  ['file-check', ['file-check', 'file13']],
  ['file-check2', ['file-check2', 'file14']],
  ['file-eye', ['file-eye', 'file15']],
  ['file-eye2', ['file-eye2', 'file16']],
  ['file-text2', ['file-text2', 'file17']],
  ['file-text3', ['file-text3', 'file18']],
  ['file-picture', ['file-picture', 'file19']],
  ['file-picture2', ['file-picture2', 'file20']],
  ['file-music', ['file-music', 'file21']],
  ['file-music2', ['file-music2', 'file22']],
  ['file-play', ['file-play', 'file23']],
  ['file-play2', ['file-play2', 'file24']],
  ['file-video', ['file-video', 'file25']],
  ['file-video2', ['file-video2', 'file26']],
  ['copy', ['copy', 'duplicate']],
  ['copy2', ['copy2', 'duplicate2']],
  ['file-zip', ['file-zip', 'file27']],
  ['file-zip2', ['file-zip2', 'file28']],
  ['file-xml', ['file-xml', 'file29']],
  ['file-xml2', ['file-xml2', 'file30']],
  ['file-css', ['file-css', 'file31']],
  ['file-css2', ['file-css2', 'file32']],
  ['file-presentation', ['file-presentation', 'file33']],
  ['file-presentation2', ['file-presentation2', 'file34']],
  ['file-stats', ['file-stats', 'file35']],
  ['file-stats2', ['file-stats2', 'file36']],
  ['file-locked', ['file-locked', 'file37']],
  ['file-locked2', ['file-locked2', 'file38']],
  ['file-spreadsheet', ['file-spreadsheet', 'file39']],
  ['file-spreadsheet2', ['file-spreadsheet2', 'file40']],
  ['copy3', ['copy3', 'duplicate3']],
  ['copy4', ['copy4', 'duplicate4']],
  ['paste', ['paste', 'clipboard-file']],
  ['paste2', ['paste2', 'clipboard-file2']],
  ['paste3', ['paste3', 'clipboard-file3']],
  ['paste4', ['paste4', 'clipboard-file4']],
  ['stack', ['stack', 'files3']],
  ['stack2', ['stack2', 'layers']],
  ['stack3', ['stack3', 'layers2']],
  ['folder', ['folder', 'directory']],
  ['folder-search', ['folder-search', 'dicrectory']],
  ['folder-download', ['folder-download', 'directory2']],
  ['folder-upload', ['folder-upload', 'directory3']],
  ['folder-plus', ['folder-plus', 'directory4']],
  ['folder-plus2', ['folder-plus2', 'directory5']],
  ['folder-minus', ['folder-minus', 'directory6']],
  ['folder-minus2', ['folder-minus2', 'directory7']],
  ['folder-check', ['folder-check', 'directory8']],
  ['folder-heart', ['folder-heart', 'directory9']],
  ['folder-remove', ['folder-remove', 'directory10']],
  ['folder2', ['folder2', 'directory11']],
  ['folder-open', ['folder-open', 'directory12']],
  ['folder3', ['folder3', 'directory13']],
  ['folder4', ['folder4', 'directory14']],
  ['folder-plus3', ['folder-plus3', 'directory15']],
  ['folder-minus3', ['folder-minus3', 'directory16']],
  ['folder-plus4', ['folder-plus4', 'directory17']],
  ['folder-minus4', ['folder-minus4', 'directory18']],
  ['folder-download2', ['folder-download2', 'directory19']],
  ['folder-upload2', ['folder-upload2', 'directory20']],
  ['folder-download3', ['folder-download3', 'directory21']],
  ['folder-upload3', ['folder-upload3', 'directory22']],
  ['folder5', ['folder5', 'directory23']],
  ['folder-open2', ['folder-open2', 'directory24']],
  ['folder6', ['folder6', 'directory25']],
  ['folder-open3', ['folder-open3', 'directory26']],
  ['certificate', ['certificate', 'license']],
  ['cc', ['cc', 'creative-commons']],
  ['price-tag', ['price-tag']],
  ['price-tag2', ['price-tag2']],
  ['price-tags', ['price-tags']],
  ['price-tag3', ['price-tag3']],
  ['price-tags2', ['price-tags2']],
  ['price-tag4', ['price-tag4']],
  ['price-tags3', ['price-tags3']],
  ['price-tag5', ['price-tag5']],
  ['price-tags4', ['price-tags4']],
  ['barcode', ['barcode']],
  ['barcode2', ['barcode2']],
  ['qrcode', ['qrcode']],
  ['ticket', ['ticket', 'theater']],
  ['ticket2', ['ticket2', 'theater2']],
  ['theater', ['theater3', 'show']],
  ['store', ['store', 'shop']],
  ['store2', ['store2', 'shop2']],
  ['cart', ['cart', 'purchase']],
  ['cart2', ['cart2', 'purchase2']],
  ['cart3', ['cart3', 'purchase3']],
  ['cart4', ['cart4', 'purchase4']],
  ['cart5', ['cart5', 'purchase5']],
  ['cart-add', ['cart-add', 'cart6']],
  ['cart-add2', ['cart-add2', 'cart7']],
  ['cart-remove', ['cart-remove', 'cart8']],
  ['basket', ['basket', 'cart9']],
  ['bag', ['bag', 'cart10']],
  ['bag2', ['bag2', 'cart11']],
  ['percent', ['percent', 'discount']],
  ['coins', ['coins', 'money']],
  ['coin-dollar', ['coin-dollar', 'money2']],
  ['coin-euro', ['coin-euro', 'money3']],
  ['coin-pound', ['coin-pound', 'money4']],
  ['coin-yen', ['coin-yen', 'money5']],
  ['piggy-bank', ['piggy-bank', 'money6']],
  ['wallet', ['wallet', 'money7']],
  ['cash', ['cash', 'money8']],
  ['cash2', ['cash2', 'money9']],
  ['cash3', ['cash3', 'money10']],
  ['cash4', ['cash4', 'money11']],
  ['credit-card', ['credit-card', 'money12']],
  ['credit-card2', ['credit-card2', 'money13']],
  ['calculator', ['calculator', 'compute']],
  ['calculator2', ['calculator2', 'compute2']],
  ['calculator3', ['calculator3', 'compute3']],
  ['chip', ['chip', 'cpu']],
  ['lifebuoy', ['lifebuoy', 'support2']],
  ['phone', ['phone', 'telephone']],
  ['phone2', ['phone2', 'telephone2']],
  ['phone-slash', ['phone-slash', 'telephone3']],
  ['phone-wave', ['phone-wave', 'telephone4']],
  ['phone-plus', ['phone-plus', 'telephone5']],
  ['phone-minus', ['phone-minus', 'telephone6']],
  ['phone-plus2', ['phone-plus2', 'telephone7']],
  ['phone-minus2', ['phone-minus2', 'telephone8']],
  ['phone-incoming', ['phone-incoming', 'telephone9']],
  ['phone-outgoing', ['phone-outgoing', 'telephone10']],
  ['phone3', ['phone3', 'telephone11']],
  ['phone-slash2', ['phone-slash2', 'telephone12']],
  ['phone-wave2', ['phone-wave2', 'telephone13']],
  ['phone-hang-up', ['phone-hang-up', 'telephone14']],
  ['phone-hang-up2', ['phone-hang-up2', 'telephone15']],
  ['address-book', ['address-book', 'contact']],
  ['address-book2', ['address-book2', 'contacts']],
  ['address-book3', ['address-book3', 'contact2']],
  ['notebook', ['notebook', 'notes']],
  ['at-sign', ['at-sign', 'email']],
  ['envelop', ['envelop', 'mail']],
  ['envelop2', ['envelop2', 'mail2']],
  ['envelop3', ['envelop3', 'mail3']],
  ['envelop4', ['envelop4', 'mail4']],
  ['envelop5', ['envelop5', 'mail5']],
  ['mailbox', ['mailbox', 'mail6']],
  ['pushpin', ['pushpin', 'pin']],
  ['pushpin2', ['pushpin2', 'pin2']],
  ['location', ['location', 'map-marker']],
  ['location2', ['location2', 'map-marker2']],
  ['location3', ['location3', 'map-marker3']],
  ['location4', ['location4', 'map-marker4']],
  ['location5', ['location5', 'map-marker5']],
  ['location6', ['location6', 'map-marker6']],
  ['location7', ['location7', 'map-marker7']],
  ['compass', ['compass', 'direction']],
  ['compass2', ['compass2', 'direction2']],
  ['compass3', ['compass3', 'direction3']],
  ['compass4', ['compass4', 'direction4']],
  ['compass5', ['compass5', 'direction5']],
  ['compass6', ['compass6', 'direction6']],
  ['map', ['map', 'guide']],
  ['map2', ['map2', 'guide2']],
  ['map3', ['map3', 'guide3']],
  ['map4', ['map4', 'guide4']],
  ['map5', ['map5', 'guide5']],
  ['direction', ['direction7', 'address']],
  ['reset', ['reset']],
  ['history', ['history', 'time']],
  ['clock', ['clock', 'time2']],
  ['clock2', ['clock2', 'time3']],
  ['clock3', ['clock3', 'time4']],
  ['clock4', ['clock4', 'time5']],
  ['watch', ['watch', 'time6']],
  ['watch2', ['watch2', 'time7']],
  ['alarm', ['alarm', 'time8']],
  ['alarm-add', ['alarm-add', 'alarm2']],
  ['alarm-check', ['alarm-check', 'alarm3']],
  ['alarm-cancel', ['alarm-cancel', 'alarm4']],
  ['bell', ['bell', 'alarm5']],
  ['bell2', ['bell2', 'alarm6']],
  ['bell3', ['bell3', 'alarm7']],
  ['bell-plus', ['bell-plus', 'alarm8']],
  ['bell-minus', ['bell-minus', 'alarm9']],
  ['bell-check', ['bell-check', 'alarm10']],
  ['bell-cross', ['bell-cross', 'alarm11']],
  ['stopwatch', ['stopwatch', 'time9']],
  ['calendar', ['calendar', 'date']],
  ['calendar2', ['calendar2', 'date2']],
  ['calendar3', ['calendar3', 'date3']],
  ['calendar4', ['calendar4', 'date4']],
  ['calendar5', ['calendar5', 'date5']],
  ['printer', ['printer', 'print']],
  ['printer2', ['printer2', 'print2']],
  ['printer3', ['printer3', 'print3']],
  ['printer4', ['printer4', 'print4']],
  ['shredder', ['shredder', 'destroy']],
  ['mouse', ['mouse', 'click']],
  ['mouse2', ['mouse2', 'click2']],
  ['mouse-left', ['mouse-left', 'click3']],
  ['mouse-right', ['mouse-right', 'click4']],
  ['keyboard', ['keyboard', 'typing']],
  ['keyboard2', ['keyboard2', 'typing2']],
  ['typewriter', ['typewriter', 'typing3']],
  ['display', ['display', 'screen']],
  ['display2', ['display2', 'screen2']],
  ['display3', ['display3', 'screen3']],
  ['display4', ['display4', 'screen4']],
  ['laptop', ['laptop', 'computer']],
  ['mobile', ['mobile', 'cell-phone']],
  ['mobile2', ['mobile2', 'cell-phone2']],
  ['tablet', ['tablet', 'mobile3']],
  ['mobile3', ['mobile4', 'cell-phone3']],
  ['tv', ['tv', 'television']],
  ['radio', ['radio3']],
  ['cabinet', ['cabinet', 'drawers']],
  ['drawer', ['drawer', 'box']],
  ['drawer2', ['drawer2', 'box2']],
  ['drawer-out', ['drawer-out', 'box3']],
  ['drawer-in', ['drawer-in', 'box4']],
  ['drawer3', ['drawer3', 'box5']],
  ['box', ['box6', 'inbox']],
  ['box-add', ['box-add', 'box7']],
  ['box-remove', ['box-remove', 'box8']],
  ['download', ['download', 'save']],
  ['upload', ['upload', 'load']],
  ['floppy-disk', ['floppy-disk', 'save2']],
  ['floppy-disks', ['floppy-disks', 'save3']],
  ['floppy-disks2', ['floppy-disks2', 'save4']],
  ['floppy-disk2', ['floppy-disk2', 'save5']],
  ['floppy-disk3', ['floppy-disk3', 'save6']],
  ['disk', ['disk', 'disc']],
  ['disk2', ['disk2', 'disc2']],
  ['usb-stick', ['usb-stick', 'flash-memory']],
  ['drive', ['drive', 'save7']],
  ['server', ['server', 'drives']],
  ['database', ['database', 'db']],
  ['database2', ['database2', 'db2']],
  ['database3', ['database3', 'db3']],
  ['database4', ['database4', 'db4']],
  ['database-menu', ['database-menu', 'db5']],
  ['database-add', ['database-add', 'db6']],
  ['database-remove', ['database-remove', 'db7']],
  ['database-insert', ['database-insert', 'db8']],
  ['database-export', ['database-export', 'db9']],
  ['database-upload', ['database-upload', 'db10']],
  ['database-refresh', ['database-refresh', 'db11']],
  ['database-diff', ['database-diff', 'db12']],
  ['database-edit', ['database-edit', 'db13']],
  ['database-edit2', ['database-edit2', 'db14']],
  ['database-check', ['database-check', 'db15']],
  ['database-arrow', ['database-arrow', 'db16']],
  ['database-time', ['database-time', 'db17']],
  ['database-time2', ['database-time2', 'db18']],
  ['undo', ['undo', 'ccw']],
  ['redo', ['redo', 'cw']],
  ['rotate-ccw', ['rotate-ccw', 'ccw2']],
  ['rotate-cw', ['rotate-cw', 'cw2']],
  ['rotate-ccw2', ['rotate-ccw2', 'ccw3']],
  ['rotate-cw2', ['rotate-cw2', 'cw3']],
  ['rotate-ccw3', ['rotate-ccw3', 'ccw4']],
  ['rotate-cw3', ['rotate-cw3', 'cw4']],
  ['flip-vertical', ['flip-vertical', 'mirror']],
  ['flip-vertical2', ['flip-vertical2', 'mirror2']],
  ['flip-horizontal', ['flip-horizontal', 'mirror3']],
  ['flip-horizontal2', ['flip-horizontal2', 'mirror4']],
  ['flip-vertical3', ['flip-vertical3', 'mirror5']],
  ['flip-vertical4', ['flip-vertical4', 'mirror6']],
  ['angle', ['angle']],
  ['shear', ['shear']],
  ['unite', ['unite', 'boolean-operation']],
  ['subtract', ['subtract', 'boolean-operation2']],
  ['interset', ['interset', 'boolean-operation3']],
  ['exclude', ['exclude', 'boolean-operation4']],
  ['align-left', ['align-left', 'align']],
  ['align-center-horizontal', ['align-center-horizontal', 'align2']],
  ['align-right', ['align-right', 'align3']],
  ['align-top', ['align-top', 'align4']],
  ['align-center-vertical', ['align-center-vertical', 'align5']],
  ['align-bottom', ['align-bottom', 'align6']],
  ['undo2', ['undo2', 'left']],
  ['redo2', ['redo2', 'right']],
  ['forward', ['forward', 'right2']],
  ['reply', ['reply', 'left2']],
  ['reply-all', ['reply-all', 'left3']],
  ['bubble', ['bubble', 'comment']],
  ['bubbles', ['bubbles', 'comments']],
  ['bubbles2', ['bubbles2', 'comments2']],
  ['bubble2', ['bubble2', 'comment2']],
  ['bubbles3', ['bubbles3', 'comments3']],
  ['bubbles4', ['bubbles4', 'comments4']],
  ['bubble-notification', ['bubble-notification', 'bubble3']],
  ['bubbles5', ['bubbles5', 'comments5']],
  ['bubbles6', ['bubbles6', 'comments6']],
  ['bubble3', ['bubble4', 'comment3']],
  ['bubble-dots', ['bubble-dots', 'bubble5']],
  ['bubble-lines', ['bubble-lines', 'bubble6']],
  ['bubble4', ['bubble7', 'comment4']],
  ['bubble-dots2', ['bubble-dots2', 'bubble8']],
  ['bubble-lines2', ['bubble-lines2', 'bubble9']],
  ['bubble5', ['bubble10', 'comment5']],
  ['bubble6', ['bubble11', 'comment6']],
  ['bubbles7', ['bubbles7', 'comments7']],
  ['bubble7', ['bubble12', 'comment7']],
  ['bubbles8', ['bubbles8', 'comments8']],
  ['bubble8', ['bubble13', 'comment8']],
  ['bubble-dots3', ['bubble-dots3', 'bubble14']],
  ['bubble-lines3', ['bubble-lines3', 'bubble15']],
  ['bubble9', ['bubble16', 'comment9']],
  ['bubble-dots4', ['bubble-dots4', 'bubble17']],
  ['bubble-lines4', ['bubble-lines4', 'bubble18']],
  ['bubbles9', ['bubbles9', 'comments9']],
  ['bubbles10', ['bubbles10', 'comments10']],
  ['bubble-blocked', ['bubble-blocked', 'bubble19']],
  ['bubble-quote', ['bubble-quote', 'bubble20']],
  ['bubble-user', ['bubble-user', 'bubble21']],
  ['bubble-smiley', ['bubble-smiley', 'bubble22']],
  ['bubble-check', ['bubble-check', 'bubble23']],
  ['bubble-video', ['bubble-video', 'bubble24']],
  ['bubble-link', ['bubble-link', 'bubble25']],
  ['bubble-lock', ['bubble-lock', 'bubble26']],
  ['bubble-star', ['bubble-star', 'bubble27']],
  ['bubble-heart', ['bubble-heart', 'bubble28']],
  ['bubble-paperclip', ['bubble-paperclip', 'bubble29']],
  ['bubble-cancel', ['bubble-cancel', 'bubble30']],
  ['bubble-plus', ['bubble-plus', 'bubble31']],
  ['bubble-minus', ['bubble-minus', 'bubble32']],
  ['bubble-notification2', ['bubble-notification2', 'bubble33']],
  ['bubble-left', ['bubble-left', 'bubble34']],
  ['bubble-right', ['bubble-right', 'bubble35']],
  ['bubble-first', ['bubble-first', 'bubble36']],
  ['bubble-last', ['bubble-last', 'bubble37']],
  ['bubble-reply', ['bubble-reply', 'bubble38']],
  ['bubble-forward', ['bubble-forward', 'bubble39']],
  ['bubble-reply2', ['bubble-reply2', 'bubble40']],
  ['bubble-forward2', ['bubble-forward2', 'bubble41']],
  ['user', ['user', 'profile2']],
  ['users', ['users', 'group']],
  ['user-plus', ['user-plus', 'user2']],
  ['user-minus', ['user-minus', 'user3']],
  ['user-cancel', ['user-cancel', 'user4']],
  ['user-block', ['user-block', 'user5']],
  ['user-lock', ['user-lock', 'user6']],
  ['user-check', ['user-check', 'user7']],
  ['users2', ['users2', 'group2']],
  ['user2', ['user8', 'profile3']],
  ['users3', ['users3', 'group3']],
  ['user-plus2', ['user-plus2', 'user9']],
  ['user-minus2', ['user-minus2', 'user10']],
  ['user-cancel2', ['user-cancel2', 'user11']],
  ['user-block2', ['user-block2', 'user12']],
  ['user-lock2', ['user-lock2', 'user13']],
  ['user-check2', ['user-check2', 'user14']],
  ['user3', ['user15', 'profile4']],
  ['user4', ['user16', 'profile5']],
  ['user5', ['user17', 'profile6']],
  ['user6', ['user18', 'profile7']],
  ['users4', ['users4', 'group4']],
  ['user-tie', ['user-tie', 'user19']],
  ['user7', ['user20', 'profile8']],
  ['users5', ['users5', 'group5']],
  ['collaboration', ['collaboration', 'group6']],
  ['vcard', ['vcard', 'card']],
  ['hat', ['hat']],
  ['bowtie', ['bowtie']],
  ['tie', ['tie']],
  ['hanger', ['hanger', 'coat-hanger']],
  ['tshirt', ['tshirt', 'shirt']],
  ['pants', ['pants', 'trousers']],
  ['shorts', ['shorts']],
  ['sock', ['sock', 'footwear']],
  ['laundry', ['laundry', 'washing-machine']],
  ['quotes-left', ['quotes-left', 'ldquo']],
  ['quotes-right', ['quotes-right', 'rdquo']],
  ['quotes-left2', ['quotes-left2', 'ldquo2']],
  ['quotes-right2', ['quotes-right2', 'rdquo2']],
  ['hour-glass', ['hour-glass', 'loading']],
  ['hour-glass2', ['hour-glass2', 'loading2']],
  ['hour-glass3', ['hour-glass3', 'loading3']],
  ['spinner', ['spinner', 'loading4']],
  ['spinner2', ['spinner2', 'loading5']],
  ['spinner3', ['spinner3', 'loading6']],
  ['spinner4', ['spinner4', 'loading7']],
  ['spinner5', ['spinner5', 'loading8']],
  ['spinner6', ['spinner6', 'loading9']],
  ['spinner7', ['spinner7', 'loading10']],
  ['spinner8', ['spinner8', 'loading11']],
  ['spinner9', ['spinner9', 'loading12']],
  ['spinner10', ['spinner10', 'loading13']],
  ['spinner11', ['spinner11', 'loading14']],
  ['microscope', ['microscope', 'research']],
  ['binoculars', ['binoculars', 'lookup']],
  ['binoculars2', ['binoculars2', 'lookup2']],
  ['search', ['search', 'magnifier']],
  ['zoom-in', ['zoom-in', 'magnifier2']],
  ['zoom-out', ['zoom-out', 'magnifier3']],
  ['search2', ['search2', 'magnifier4']],
  ['zoom-in2', ['zoom-in2', 'magnifier5']],
  ['zoom-out2', ['zoom-out2', 'magnifier6']],
  ['search3', ['search3', 'magnifier7']],
  ['search4', ['search4', 'magnifier8']],
  ['zoom-in3', ['zoom-in3', 'magnifier9']],
  ['zoom-out3', ['zoom-out3', 'magnifier10']],
  ['search5', ['search5', 'magnifier11']],
  ['search6', ['search6', 'magnifier12']],
  ['enlarge', ['enlarge', 'expand']],
  ['shrink', ['shrink', 'collapse']],
  ['enlarge2', ['enlarge2', 'expand2']],
  ['shrink2', ['shrink2', 'collapse2']],
  ['enlarge3', ['enlarge3', 'expand3']],
  ['shrink3', ['shrink3', 'collapse3']],
  ['enlarge4', ['enlarge4', 'expand4']],
  ['shrink4', ['shrink4', 'collapse4']],
  ['enlarge5', ['enlarge5', 'expand5']],
  ['shrink5', ['shrink5', 'collapse5']],
  ['enlarge6', ['enlarge6', 'expand6']],
  ['shrink6', ['shrink6', 'collapse6']],
  ['enlarge7', ['enlarge7', 'expand7']],
  ['shrink7', ['shrink7', 'collapse7']],
  ['key', ['key', 'password']],
  ['key2', ['key2', 'password2']],
  ['key3', ['key3', 'password3']],
  ['key4', ['key4', 'password4']],
  ['key5', ['key5', 'password5']],
  ['keyhole', ['keyhole', 'entrance']],
  ['lock', ['lock', 'secure']],
  ['lock2', ['lock2', 'secure2']],
  ['lock3', ['lock3', 'secure3']],
  ['lock4', ['lock4', 'secure4']],
  ['unlocked', ['unlocked', 'lock-open']],
  ['lock5', ['lock5', 'secure5']],
  ['unlocked2', ['unlocked2', 'lock-open2']],
  ['safe', ['safe', 'vault']],
  ['wrench', ['wrench', 'tool']],
  ['wrench2', ['wrench2', 'tool2']],
  ['wrench3', ['wrench3', 'tool3']],
  ['equalizer', ['equalizer', 'sliders']],
  ['equalizer2', ['equalizer2', 'sliders2']],
  ['equalizer3', ['equalizer3', 'sliders3']],
  ['equalizer4', ['equalizer4', 'sliders4']],
  ['cog', ['cog', 'gear']],
  ['cogs', ['cogs', 'gears']],
  ['cog2', ['cog2', 'gear2']],
  ['cog3', ['cog3', 'gear3']],
  ['cog4', ['cog4', 'gear4']],
  ['cog5', ['cog5', 'gear5']],
  ['cog6', ['cog6', 'gear6']],
  ['cog7', ['cog7', 'gear7']],
  ['factory', ['factory', 'manufacture']],
  ['hammer', ['hammer', 'tool4']],
  ['hammer-wrench', ['hammer-wrench', 'tool5']],
  ['knife', ['knife', 'tool6']],
  ['screwdriver', ['screwdriver', 'tool7']],
  ['screwdriver2', ['screwdriver2', 'tool8']],
  ['magic-wand', ['magic-wand', 'wizard']],
  ['magic-wand2', ['magic-wand2', 'wizard2']],
  ['pulse', ['pulse', 'health']],
  ['pulse2', ['pulse2', 'health2']],
  ['aid-kit', ['aid-kit', 'health3']],
  ['aid-kit2', ['aid-kit2', 'health4']],
  ['stethoscope', ['stethoscope', 'health5']],
  ['ambulance', ['ambulance', 'health6']],
  ['alarm2', ['alarm12', 'siren']],
  ['pandage', ['pandage', 'health7']],
  ['pandage2', ['pandage2', 'health8']],
  ['bug', ['bug', 'virus']],
  ['bug2', ['bug2', 'virus2']],
  ['syringe', ['syringe', 'health9']],
  ['syringe2', ['syringe2', 'health10']],
  ['pill', ['pill', 'health11']],
  ['construction', ['construction', 'maintenance']],
  ['traffic-cone', ['traffic-cone', 'construction2']],
  ['traffic-lights', ['traffic-lights', 'lights']],
  ['pie-chart', ['pie-chart', 'stats']],
  ['pie-chart2', ['pie-chart2', 'stats2']],
  ['pie-chart3', ['pie-chart3', 'stats3']],
  ['pie-chart4', ['pie-chart4', 'stats4']],
  ['pie-chart5', ['pie-chart5', 'stats5']],
  ['pie-chart6', ['pie-chart6', 'stats6']],
  ['pie-chart7', ['pie-chart7', 'stats7']],
  ['stats-dots', ['stats-dots', 'stats8']],
  ['stats-bars', ['stats-bars', 'stats9']],
  ['pie-chart8', ['pie-chart8', 'stats10']],
  ['stats-bars2', ['stats-bars2', 'stats11']],
  ['stats-bars3', ['stats-bars3', 'stats12']],
  ['stats-bars4', ['stats-bars4', 'stats13']],
  ['stats-bars5', ['stats-bars5', 'stats14']],
  ['stats-bars6', ['stats-bars6', 'stats15']],
  ['chart', ['chart', 'stats16']],
  ['stats-growth', ['stats-growth', 'stats17']],
  ['stats-decline', ['stats-decline', 'stats18']],
  ['stats-growth2', ['stats-growth2', 'stats19']],
  ['stats-decline2', ['stats-decline2', 'stats20']],
  ['stairs-up', ['stairs-up', 'ascend']],
  ['stairs-down', ['stairs-down', 'descend']],
  ['stairs', ['stairs', 'steps']],
  ['stairs2', ['stairs2', 'steps2']],
  ['ladder', ['ladder', 'ranking']],
  ['rating', ['rating', 'ranking2']],
  ['rating2', ['rating2', 'ranking3']],
  ['rating3', ['rating3', 'ranking4']],
  ['cake', ['cake', 'birthday']],
  ['podium', ['podium', 'ranking5']],
  ['stars', ['stars']],
  ['medal-star', ['medal-star', 'badge']],
  ['medal', ['medal', 'badge2']],
  ['medal2', ['medal2', 'badge3']],
  ['medal-first', ['medal-first', 'badge4']],
  ['medal-second', ['medal-second', 'badge5']],
  ['medal-third', ['medal-third', 'badge6']],
  ['crown', ['crown', 'king']],
  ['trophy', ['trophy', 'cup']],
  ['trophy2', ['trophy2', 'cup2']],
  ['trophy3', ['trophy3', 'cup3']],
  ['diamond', ['diamond', 'gem']],
  ['diamond2', ['diamond2', 'gem2']],
  ['trophy4', ['trophy4', 'gem3']],
  ['gift', ['gift', 'present']],
  ['gift2', ['gift2', 'present2']],
  ['balloon', ['balloon', 'party']],
  ['pipe', ['pipe', 'hipster']],
  ['mustache', ['mustache', 'hipster2']],
  ['mustache2', ['mustache2', 'hipster3']],
  ['glass', ['glass', 'drink']],
  ['glass2', ['glass2', 'drink2']],
  ['glass3', ['glass3', 'drink3']],
  ['glass4', ['glass4', 'drink4']],
  ['bottle', ['bottle', 'drink5']],
  ['bottle2', ['bottle2', 'drink6']],
  ['bottle3', ['bottle3', 'drink7']],
  ['bottle4', ['bottle4', 'drink8']],
  ['mug', ['mug', 'drink9']],
  ['fork-knife', ['fork-knife', 'food']],
  ['spoon-knife', ['spoon-knife', 'food2']],
  ['cook', ['cook', 'chef']],
  ['plate', ['plate', 'food3']],
  ['steak', ['steak', 'food4']],
  ['fish', ['fish', 'food5']],
  ['chicken', ['chicken', 'food6']],
  ['hamburger', ['hamburger', 'food7']],
  ['pizza', ['pizza', 'food8']],
  ['cheese', ['cheese', 'food9']],
  ['bread', ['bread', 'food10']],
  ['icecream', ['icecream', 'dessert']],
  ['milk', ['milk', 'drink10']],
  ['cup', ['cup4', 'drink11']],
  ['cup2', ['cup5', 'drink12']],
  ['coffee-baen', ['coffee-baen', 'coffee']],
  ['coffee', ['coffee2', 'drink13']],
  ['teapot', ['teapot', 'drink14']],
  ['leaf', ['leaf', 'nature']],
  ['leaf2', ['leaf2', 'nature2']],
  ['apple', ['apple', 'fruit']],
  ['grapes', ['grapes', 'fruit2']],
  ['cherry', ['cherry', 'fruit3']],
  ['tree', ['tree', 'nature3']],
  ['tree2', ['tree2', 'nature4']],
  ['tree3', ['tree3', 'nature5']],
  ['tree4', ['tree4', 'nature6']],
  ['cactus', ['cactus', 'nature7']],
  ['mountains', ['mountains', 'nature8']],
  ['paw', ['paw', 'animal']],
  ['footprint', ['footprint', 'steps3']],
  ['tent', ['tent', 'camping']],
  ['flower', ['flower', 'nature9']],
  ['flower2', ['flower2', 'nature10']],
  ['rocket', ['rocket', 'jet']],
  ['meter', ['meter', 'gauge']],
  ['meter2', ['meter2', 'gauge2']],
  ['meter-slow', ['meter-slow', 'gauge3']],
  ['meter-medium', ['meter-medium', 'gauge4']],
  ['meter-fast', ['meter-fast', 'gauge5']],
  ['meter3', ['meter3', 'gauge6']],
  ['hammer2', ['hammer2', 'gavel']],
  ['balance', ['balance', 'justice']],
  ['scale', ['scale', 'weight']],
  ['bomb', ['bomb', 'explosive']],
  ['fire', ['fire', 'flame']],
  ['fire2', ['fire2', 'flame2']],
  ['lab', ['lab', 'beta']],
  ['atom', ['atom', 'science']],
  ['atom2', ['atom2', 'science2']],
  ['magnet', ['magnet', 'attract']],
  ['magnet2', ['magnet2', 'attract2']],
  ['dumbbell', ['dumbbell', 'gym']],
  ['skull', ['skull', 'skeleton']],
  ['skull2', ['skull2', 'skeleton2']],
  ['lamp', ['lamp', 'light']],
  ['lamp2', ['lamp2', 'light2']],
  ['lamp3', ['lamp3', 'light3']],
  ['spotlight', ['spotlight', 'light4']],
  ['lamp4', ['lamp4', 'light5']],
  ['lamp5', ['lamp5', 'light6']],
  ['lamp6', ['lamp6', 'light7']],
  ['lamp7', ['lamp7', 'light8']],
  ['lamp8', ['lamp8', 'light9']],
  ['broom', ['broom', 'clean']],
  ['toiletpaper', ['toiletpaper', 'toilet']],
  ['bin', ['bin', 'trashcan']],
  ['bin2', ['bin2', 'trashcan2']],
  ['bin3', ['bin3', 'trashcan3']],
  ['bin4', ['bin4', 'trashcan4']],
  ['bin5', ['bin5', 'trashcan5']],
  ['briefcase', ['briefcase', 'portfolio']],
  ['briefcase2', ['briefcase2', 'portfolio2']],
  ['briefcase3', ['briefcase3', 'portfolio3']],
  ['airplane', ['airplane', 'travel']],
  ['airplane2', ['airplane2', 'travel2']],
  ['airplane3', ['airplane3', 'travel3']],
  ['airplane4', ['airplane4', 'arrival']],
  ['paperplane', ['paperplane', 'send']],
  ['car', ['car', 'travel4']],
  ['steering-wheel', ['steering-wheel', 'drive2']],
  ['car2', ['car2', 'travel5']],
  ['gas', ['gas', 'fuel']],
  ['bus', ['bus', 'travel6']],
  ['truck', ['truck', 'transit']],
  ['bike', ['bike', 'travel7']],
  ['road', ['road', 'asphalt']],
  ['train', ['train', 'travel8']],
  ['train2', ['train2', 'travel9']],
  ['ship', ['ship', 'travel10']],
  ['boat', ['boat', 'travel11']],
  ['chopper', ['chopper', 'travel12']],
  ['chopper2', ['chopper2', 'travel13']],
  ['cube', ['cube', '3d']],
  ['cube2', ['cube2', '3d2']],
  ['cube3', ['cube3', '3d3']],
  ['cube4', ['cube4', '3d4']],
  ['pyramid', ['pyramid']],
  ['pyramid2', ['pyramid2', 'dualtone']],
  ['package', ['package', 'box9']],
  ['puzzle', ['puzzle', 'puzzle-piece']],
  ['puzzle2', ['puzzle2', 'puzzle-piece2']],
  ['puzzle3', ['puzzle3', 'puzzle-piece3']],
  ['puzzle4', ['puzzle4', 'puzzle-piece4']],
  ['glasses-3d', ['glasses-3d']],
  ['glasses-3d2', ['glasses-3d2']],
  ['glasses', ['glasses', 'prescription-glasses']],
  ['glasses2', ['glasses2', 'sunglasses']],
  ['brain', ['brain', 'head']],
  ['accessibility', ['accessibility']],
  ['accessibility2', ['accessibility2', 'disabled']],
  ['strategy', ['strategy', 'plan']],
  ['target', ['target', 'goal']],
  ['target2', ['target2', 'goal2']],
  ['target3', ['target3', 'goal3']],
  ['ammo', ['ammo', 'bullets']],
  ['gun', ['gun', 'weapon']],
  ['gun-forbidden', ['gun-forbidden', 'gun-ban']],
  ['shield', ['shield', 'security']],
  ['shield-check', ['shield-check', 'security2']],
  ['shield-notice', ['shield-notice', 'security3']],
  ['shield2', ['shield2', 'security4']],
  ['shield3', ['shield3', 'security5']],
  ['shield4', ['shield4', 'security6']],
  ['soccer', ['soccer', 'sports']],
  ['football', ['football', 'soprts']],
  ['archery', ['archery', 'soprts2']],
  ['bow', ['bow', 'soprts3']],
  ['sword', ['sword', 'weapon2']],
  ['tennis', ['tennis', 'sports2']],
  ['pingpong', ['pingpong', 'soprts4']],
  ['baseball', ['baseball', 'soprts5']],
  ['basketball', ['basketball', 'soprts6']],
  ['golf', ['golf', 'sports3']],
  ['golf2', ['golf2', 'sports4']],
  ['hockey', ['hockey', 'sports5']],
  ['racing', ['racing', 'soprts7']],
  ['finish', ['finish', 'soprts8']],
  ['eight-ball', ['eight-ball', 'soprts9']],
  ['bowling', ['bowling', 'soprts10']],
  ['bowling2', ['bowling2', 'soprts11']],
  ['power', ['power', 'lightning']],
  ['power2', ['power2', 'lightning2']],
  ['power3', ['power3', 'lightning3']],
  ['switch', ['switch2']],
  ['switch2', ['switch3']],
  ['power-cord', ['power-cord', 'plugin']],
  ['power-cord2', ['power-cord2', 'plugin2']],
  ['outlet', ['outlet', 'power4']],
  ['clipboard', ['clipboard', 'board']],
  ['clipboard2', ['clipboard2', 'board2']],
  ['clipboard3', ['clipboard3', 'board3']],
  ['clipboard4', ['clipboard4', 'board4']],
  ['clipboard5', ['clipboard5', 'board5']],
  ['clipboard6', ['clipboard6', 'board6']],
  ['playlist', ['playlist', 'music11']],
  ['playlist-add', ['playlist-add', 'music12']],
  ['list-numbered', ['list-numbered', 'options']],
  ['list', ['list', 'todo']],
  ['list2', ['list2', 'todo2']],
  ['more', ['more', 'dots']],
  ['more2', ['more2', 'dots2']],
  ['grid', ['grid2', 'icons']],
  ['grid2', ['grid3', 'icons2']],
  ['grid3', ['grid4', 'icons3']],
  ['grid4', ['grid5', 'icons4']],
  ['grid5', ['grid6', 'icons5']],
  ['grid6', ['grid7', 'icons6']],
  ['grid7', ['grid8', 'icons7']],
  ['tree5', ['tree5', 'branches']],
  ['tree6', ['tree6', 'branches2']],
  ['tree7', ['tree7', 'branches3']],
  ['lan', ['lan', 'network']],
  ['lan2', ['lan2', 'network2']],
  ['lan3', ['lan3', 'network3']],
  ['menu', ['menu', 'dots3']],
  ['circle-small', ['circle-small']],
  ['menu2', ['menu2', 'list3']],
  ['menu3', ['menu3', 'list4']],
  ['menu4', ['menu4', 'options2']],
  ['menu5', ['menu5', 'options3']],
  ['menu6', ['menu6', 'options4']],
  ['menu7', ['menu7', 'list5']],
  ['menu8', ['menu8', 'options5']],
  ['menu9', ['menu9', 'options6']],
  ['menu10', ['menu10', 'options7']],
  ['cloud', ['cloud', 'weather']],
  ['cloud-download', ['cloud-download', 'cloud2']],
  ['cloud-upload', ['cloud-upload', 'cloud3']],
  ['cloud-check', ['cloud-check', 'cloud4']],
  ['cloud2', ['cloud5', 'weather2']],
  ['cloud-download2', ['cloud-download2', 'cloud6']],
  ['cloud-upload2', ['cloud-upload2', 'cloud7']],
  ['cloud-check2', ['cloud-check2', 'cloud8']],
  ['download2', ['download2', 'save8']],
  ['download3', ['download3', 'save9']],
  ['upload2', ['upload2', 'load2']],
  ['upload3', ['upload3', 'load3']],
  ['import', ['import', 'load4']],
  ['import2', ['import2', 'load5']],
  ['download4', ['download4', 'save10']],
  ['upload4', ['upload4', 'load6']],
  ['download5', ['download5', 'save11']],
  ['upload5', ['upload5', 'load7']],
  ['download6', ['download6', 'save12']],
  ['upload6', ['upload6', 'load8']],
  ['download7', ['download7', 'save13']],
  ['upload7', ['upload7', 'load9']],
  ['download8', ['download8', 'save14']],
  ['upload8', ['upload8', 'load10']],
  ['download9', ['download9', 'save15']],
  ['upload9', ['upload9', 'load11']],
  ['download10', ['download10', 'save16']],
  ['upload10', ['upload10', 'load12']],
  ['sphere', ['sphere', 'globe']],
  ['sphere2', ['sphere2', 'globe2']],
  ['sphere3', ['sphere3', 'globe3']],
  ['planet', ['planet', 'globe4']],
  ['planet2', ['planet2', 'globe5']],
  ['earth', ['earth', 'globe6']],
  ['earth2', ['earth2', 'globe7']],
  ['earth3', ['earth3', 'globe8']],
  ['link', ['link', 'chain']],
  ['unlink', ['unlink', 'link2']],
  ['link2', ['link3', 'chain2']],
  ['unlink2', ['unlink2', 'link4']],
  ['link3', ['link5', 'chain3']],
  ['unlink3', ['unlink3', 'link6']],
  ['link4', ['link7', 'chain4']],
  ['unlink4', ['unlink4', 'link8']],
  ['link5', ['link9', 'chain5']],
  ['unlink5', ['unlink5', 'link10']],
  ['anchor', ['anchor', 'link11']],
  ['flag', ['flag', 'report']],
  ['flag2', ['flag2', 'report2']],
  ['flag3', ['flag3', 'report3']],
  ['flag4', ['flag4', 'report4']],
  ['flag5', ['flag5', 'report5']],
  ['flag6', ['flag6', 'report6']],
  ['flag7', ['flag7', 'report7']],
  ['flag8', ['flag8', 'report8']],
  ['attachment', ['attachment', 'paperclip']],
  ['attachment2', ['attachment2', 'paperclip2']],
  ['eye', ['eye', 'views']],
  ['eye-plus', ['eye-plus', 'views2']],
  ['eye-minus', ['eye-minus', 'views3']],
  ['eye-blocked', ['eye-blocked', 'views4']],
  ['eye2', ['eye2', 'views5']],
  ['eye-blocked2', ['eye-blocked2', 'views6']],
  ['eye3', ['eye3', 'views7']],
  ['eye-blocked3', ['eye-blocked3', 'views8']],
  ['eye4', ['eye4', 'views9']],
  ['bookmark2', ['bookmark2', 'ribbon']],
  ['bookmark3', ['bookmark3', 'ribbon2']],
  ['bookmarks', ['bookmarks', 'ribbons']],
  ['bookmark4', ['bookmark4', 'book6']],
  ['spotlight2', ['spotlight2', 'featured']],
  ['starburst', ['starburst']],
  ['snowflake', ['snowflake', 'weather3']],
  ['temperature', ['temperature', 'weather4']],
  ['temperature2', ['temperature2', 'weather5']],
  ['weather-lightning', ['weather-lightning', 'weather6']],
  ['weather-lightning2', ['weather-lightning2', 'weather7']],
  ['weather-rain', ['weather-rain', 'weather8']],
  ['weather-rain2', ['weather-rain2', 'weather9']],
  ['weather-snow', ['weather-snow', 'weather10']],
  ['weather-snow2', ['weather-snow2', 'weather11']],
  ['weather-cloud-wind', ['weather-cloud-wind', 'weather12']],
  ['weather-cloud-wind2', ['weather-cloud-wind2', 'weather13']],
  ['weather-cloud-sun', ['weather-cloud-sun', 'weather14']],
  ['weather-cloud-sun2', ['weather-cloud-sun2', 'weather15']],
  ['weather-cloudy', ['weather-cloudy', 'weather16']],
  ['weather-cloudy2', ['weather-cloudy2', 'weather17']],
  ['weather-sun-wind', ['weather-sun-wind', 'weather18']],
  ['weather-sun-wind2', ['weather-sun-wind2', 'weather19']],
  ['sun', ['sun', 'weather20']],
  ['sun2', ['sun2', 'weather21']],
  ['moon', ['moon', 'night']],
  ['day-night', ['day-night', 'sun-moon']],
  ['day-night2', ['day-night2', 'sun-moon2']],
  ['weather-windy', ['weather-windy', 'weather22']],
  ['fan', ['fan', 'wind']],
  ['umbrella', ['umbrella', 'weather23']],
  ['sun3', ['sun3', 'brightness']],
  ['contrast', ['contrast']],
  ['brightness-contrast', ['brightness-contrast']],
  ['brightness-high', ['brightness-high', 'brightness2']],
  ['brightness-medium', ['brightness-medium', 'brightness3']],
  ['brightness-low', ['brightness-low', 'brightness4']],
  ['bed', ['bed', 'sleep']],
  ['bed2', ['bed2', 'sleep2']],
  ['furniture', ['furniture', 'couch']],
  ['chair', ['chair']],
  ['star-empty', ['star-empty', 'rate']],
  ['star-empty2', ['star-empty2', 'rate2']],
  ['star-full', ['star-full', 'rate3']],
  ['star-empty3', ['star-empty3', 'rate4']],
  ['star-half', ['star-half', 'rate5']],
  ['star-full2', ['star-full2', 'rate6']],
  ['star', ['star', 'rate7']],
  ['heart', ['heart', 'like']],
  ['heart2', ['heart2', 'like2']],
  ['heart3', ['heart3', 'like3']],
  ['heart4', ['heart4', 'like4']],
  ['heart-broken', ['heart-broken', 'heart5']],
  ['heart5', ['heart6', 'like5']],
  ['heart6', ['heart7', 'like6']],
  ['heart-broken2', ['heart-broken2', 'heart8']],
  ['heart7', ['heart9', 'like7']],
  ['heart8', ['heart10', 'like8']],
  ['heart-broken3', ['heart-broken3', 'heart11']],
  ['lips', ['lips', 'mouth']],
  ['lips2', ['lips2', 'mouth2']],
  ['thumbs-up', ['thumbs-up', 'like9']],
  ['thumbs-up2', ['thumbs-up2', 'like10']],
  ['thumbs-down', ['thumbs-down', 'dislike']],
  ['thumbs-down2', ['thumbs-down2', 'dislike2']],
  ['thumbs-up3', ['thumbs-up3', 'like11']],
  ['thumbs-down3', ['thumbs-down3', 'dislike3']],
  ['height', ['height']],
  ['man', ['man', 'male']],
  ['woman', ['woman', 'female']],
  ['man-woman', ['man-woman', 'toilet2']],
  ['male', ['male2', 'gender']],
  ['female', ['female2', 'gender2']],
  ['peace', ['peace']],
  ['yin-yang', ['yin-yang']],
  ['happy', ['happy', 'emoticon']],
  ['happy2', ['happy2', 'emoticon2']],
  ['smile', ['smile', 'emoticon3']],
  ['smile2', ['smile2', 'emoticon4']],
  ['tongue', ['tongue', 'emoticon5']],
  ['tongue2', ['tongue2', 'emoticon6']],
  ['sad', ['sad', 'emoticon7']],
  ['sad2', ['sad2', 'emoticon8']],
  ['wink', ['wink', 'emoticon9']],
  ['wink2', ['wink2', 'emoticon10']],
  ['grin', ['grin', 'emoticon11']],
  ['grin2', ['grin2', 'emoticon12']],
  ['cool', ['cool', 'emoticon13']],
  ['cool2', ['cool2', 'emoticon14']],
  ['angry', ['angry', 'emoticon15']],
  ['angry2', ['angry2', 'emoticon16']],
  ['evil', ['evil', 'emoticon17']],
  ['evil2', ['evil2', 'emoticon18']],
  ['shocked', ['shocked', 'emoticon19']],
  ['shocked2', ['shocked2', 'emoticon20']],
  ['baffled', ['baffled', 'emoticon21']],
  ['baffled2', ['baffled2', 'emoticon22']],
  ['confused', ['confused', 'emoticon23']],
  ['confused2', ['confused2', 'emoticon24']],
  ['neutral', ['neutral', 'emoticon25']],
  ['neutral2', ['neutral2', 'emoticon26']],
  ['hipster', ['hipster4', 'emoticon27']],
  ['hipster2', ['hipster5', 'emoticon28']],
  ['wondering', ['wondering', 'emoticon29']],
  ['wondering2', ['wondering2', 'emoticon30']],
  ['sleepy', ['sleepy', 'emoticon31']],
  ['sleepy2', ['sleepy2', 'emoticon32']],
  ['frustrated', ['frustrated', 'emoticon33']],
  ['frustrated2', ['frustrated2', 'emoticon34']],
  ['crying', ['crying', 'emoticon35']],
  ['crying2', ['crying2', 'emoticon36']],
  ['cursor', ['cursor', 'pointer']],
  ['cursor2', ['cursor2', 'pointer2']],
  ['lasso', ['lasso']],
  ['lasso2', ['lasso2']],
  ['select', ['select', 'pointer3']],
  ['select2', ['select2', 'pointer4']],
  ['point-up', ['point-up', 'finger']],
  ['point-right', ['point-right', 'finger2']],
  ['point-down', ['point-down', 'finger3']],
  ['point-left', ['point-left', 'finger4']],
  ['pointer', ['pointer5', 'finger5']],
  ['reminder', ['reminder', 'finger6']],
  ['drag-left-right', ['drag-left-right', 'finger7']],
  ['drag-left', ['drag-left', 'finger8']],
  ['drag-right', ['drag-right', 'finger9']],
  ['touch', ['touch', 'finger10']],
  ['multitouch', ['multitouch', 'finger11']],
  ['touch-zoom', ['touch-zoom', 'finger12']],
  ['touch-pinch', ['touch-pinch', 'finger13']],
  ['hand', ['hand', 'fingers']],
  ['grab', ['grab', 'fist']],
  ['stack-empty', ['stack-empty', 'files4']],
  ['stack-plus', ['stack-plus', 'files5']],
  ['stack-minus', ['stack-minus', 'files6']],
  ['stack-star', ['stack-star', 'files7']],
  ['stack-picture', ['stack-picture', 'files8']],
  ['stack-down', ['stack-down', 'files9']],
  ['stack-up', ['stack-up', 'files10']],
  ['stack-cancel', ['stack-cancel', 'files11']],
  ['stack-check', ['stack-check', 'files12']],
  ['stack-text', ['stack-text', 'files13']],
  ['stack-clubs', ['stack-clubs', 'files14']],
  ['stack-spades', ['stack-spades', 'files15']],
  ['stack-hearts', ['stack-hearts', 'files16']],
  ['stack-diamonds', ['stack-diamonds', 'files17']],
  ['stack-user', ['stack-user', 'files18']],
  ['stack4', ['stack4', 'files19']],
  ['stack-music', ['stack-music', 'files20']],
  ['stack-play', ['stack-play', 'files21']],
  ['move', ['move', 'drag']],
  ['dots', ['dots4', 'resize-handle']],
  ['warning', ['warning', 'sign']],
  ['warning2', ['warning2', 'sign2']],
  ['notification', ['notification', 'warning3']],
  ['notification2', ['notification2', 'warning4']],
  ['question', ['question', 'help']],
  ['question2', ['question2', 'help2']],
  ['question3', ['question3', 'help3']],
  ['question4', ['question4', 'help4']],
  ['question5', ['question5', 'help5']],
  ['question6', ['question6', 'help6']],
  ['plus', ['plus', 'add']],
  ['minus', ['minus', 'subtract2']],
  ['plus2', ['plus2', 'add2']],
  ['minus2', ['minus2', 'subtract3']],
  ['plus3', ['plus3', 'add3']],
  ['minus3', ['minus3', 'subtract4']],
  ['plus-circle', ['plus-circle', 'add4']],
  ['minus-circle', ['minus-circle', 'remove']],
  ['plus-circle2', ['plus-circle2', 'add5']],
  ['minus-circle2', ['minus-circle2', 'remove2']],
  ['info', ['info', 'information']],
  ['info2', ['info2', 'information2']],
  ['cancel-circle', ['cancel-circle', 'close']],
  ['cancel-circle2', ['cancel-circle2', 'close2']],
  ['blocked', ['blocked', 'forbidden']],
  ['cancel-square', ['cancel-square', 'close3']],
  ['cancel-square2', ['cancel-square2', 'close4']],
  ['cancel', ['cancel', 'close5']],
  ['spam', ['spam', 'notice']],
  ['cross', ['cross', 'cancel2']],
  ['cross2', ['cross2', 'cancel3']],
  ['cross3', ['cross3', 'cancel4']],
  ['checkmark', ['checkmark', 'tick']],
  ['checkmark2', ['checkmark2', 'tick2']],
  ['checkmark3', ['checkmark3', 'tick3']],
  ['checkmark4', ['checkmark4', 'tick4']],
  ['checkmark5', ['checkmark5', 'tick5']],
  ['spell-check', ['spell-check', 'spelling']],
  ['spell-check2', ['spell-check2', 'spelling2']],
  ['enter', ['enter', 'signin']],
  ['exit', ['exit', 'signout']],
  ['enter2', ['enter2', 'signin2']],
  ['exit2', ['exit2', 'signout2']],
  ['enter3', ['enter3', 'signin3']],
  ['exit3', ['exit3', 'signout3']],
  ['wall', ['wall', 'bricks']],
  ['fence', ['fence']],
  ['play3', ['play3', 'player']],
  ['pause', ['pause', 'player2']],
  ['stop', ['stop', 'player3']],
  ['previous', ['previous', 'player4']],
  ['next', ['next', 'player5']],
  ['backward', ['backward', 'player6']],
  ['forward2', ['forward2', 'player7']],
  ['play4', ['play4', 'player8']],
  ['pause2', ['pause2', 'player9']],
  ['stop2', ['stop2', 'player10']],
  ['backward2', ['backward2', 'player11']],
  ['forward3', ['forward3', 'player12']],
  ['first', ['first', 'player13']],
  ['last', ['last', 'player14']],
  ['previous2', ['previous2', 'player15']],
  ['next2', ['next2', 'player16']],
  ['eject', ['eject', 'player17']],
  ['volume-high', ['volume-high', 'volume']],
  ['volume-medium', ['volume-medium', 'volume2']],
  ['volume-low', ['volume-low', 'volume3']],
  ['volume-mute', ['volume-mute', 'volume4']],
  ['speaker-left', ['speaker-left', 'volume5']],
  ['speaker-right', ['speaker-right', 'volume6']],
  ['volume-mute2', ['volume-mute2', 'volume7']],
  ['volume-increase', ['volume-increase', 'volume8']],
  ['volume-decrease', ['volume-decrease', 'volume9']],
  ['volume-high2', ['volume-high2', 'volume10']],
  ['volume-medium2', ['volume-medium2', 'volume11']],
  ['volume-medium3', ['volume-medium3', 'volume12']],
  ['volume-low2', ['volume-low2', 'volume13']],
  ['volume-mute3', ['volume-mute3', 'volume14']],
  ['volume-increase2', ['volume-increase2', 'volume15']],
  ['volume-decrease2', ['volume-decrease2', 'volume16']],
  ['volume-decrease3', ['volume-decrease3', 'volume17']],
  ['volume-5', ['volume-5', 'volume18']],
  ['volume-4', ['volume-4', 'volume19']],
  ['volume-3', ['volume-3', 'volume20']],
  ['volume-2', ['volume-2', 'volume21']],
  ['volume-1', ['volume-1', 'volume22']],
  ['volume-0', ['volume-0', 'volume23']],
  ['volume-mute4', ['volume-mute4', 'volume24']],
  ['volume-mute5', ['volume-mute5', 'volume25']],
  ['loop', ['loop', 'repeat']],
  ['loop2', ['loop2', 'repeat2']],
  ['loop3', ['loop3', 'repeat3']],
  ['infinite-square', ['infinite-square']],
  ['infinite', ['infinite']],
  ['infinite2', ['infinite2']],
  ['loop4', ['loop4', 'repeat4']],
  ['shuffle', ['shuffle', 'random']],
  ['shuffle2', ['shuffle2', 'random2']],
  ['wave', ['wave2']],
  ['wave2', ['wave3']],
  ['split', ['split', 'arrows']],
  ['merge', ['merge', 'arrows2']],
  ['first2', ['first2', 'player18']],
  ['last2', ['last2', 'player19']],
  ['arrow-up', ['arrow-up', 'up']],
  ['arrow-up2', ['arrow-up2', 'up2']],
  ['arrow-up3', ['arrow-up3', 'up3']],
  ['arrow-up4', ['arrow-up4', 'up4']],
  ['arrow-right', ['arrow-right', 'right3']],
  ['arrow-right2', ['arrow-right2', 'right4']],
  ['arrow-right3', ['arrow-right3', 'right5']],
  ['arrow-right4', ['arrow-right4', 'right6']],
  ['arrow-down', ['arrow-down', 'down']],
  ['arrow-down2', ['arrow-down2', 'down2']],
  ['arrow-down3', ['arrow-down3', 'down3']],
  ['arrow-down4', ['arrow-down4', 'down4']],
  ['arrow-left', ['arrow-left', 'left4']],
  ['arrow-left2', ['arrow-left2', 'left5']],
  ['arrow-left3', ['arrow-left3', 'left6']],
  ['arrow-left4', ['arrow-left4', 'left7']],
  ['arrow-up5', ['arrow-up5', 'up5']],
  ['arrow-right5', ['arrow-right5', 'right7']],
  ['arrow-down5', ['arrow-down5', 'down5']],
  ['arrow-left5', ['arrow-left5', 'left8']],
  ['arrow-up-left', ['arrow-up-left', 'up-left']],
  ['arrow-up6', ['arrow-up6', 'up6']],
  ['arrow-up-right', ['arrow-up-right', 'up-right']],
  ['arrow-right6', ['arrow-right6', 'right8']],
  ['arrow-down-right', ['arrow-down-right', 'down-right']],
  ['arrow-down6', ['arrow-down6', 'down6']],
  ['arrow-down-left', ['arrow-down-left', 'down-left']],
  ['arrow-left6', ['arrow-left6', 'left9']],
  ['arrow-up-left2', ['arrow-up-left2', 'up-left2']],
  ['arrow-up7', ['arrow-up7', 'up7']],
  ['arrow-up-right2', ['arrow-up-right2', 'up-right2']],
  ['arrow-right7', ['arrow-right7', 'right9']],
  ['arrow-down-right2', ['arrow-down-right2', 'down-right2']],
  ['arrow-down7', ['arrow-down7', 'down7']],
  ['arrow-down-left2', ['arrow-down-left2', 'down-left2']],
  ['arrow-left7', ['arrow-left7', 'left10']],
  ['arrow-up-left3', ['arrow-up-left3', 'up-left3']],
  ['arrow-up8', ['arrow-up8', 'up8']],
  ['arrow-up-right3', ['arrow-up-right3', 'up-right3']],
  ['arrow-right8', ['arrow-right8', 'right10']],
  ['arrow-down-right3', ['arrow-down-right3', 'down-right3']],
  ['arrow-down8', ['arrow-down8', 'down8']],
  ['arrow-down-left3', ['arrow-down-left3', 'down-left3']],
  ['arrow-left8', ['arrow-left8', 'left11']],
  ['circle-up', ['circle-up', 'up9']],
  ['circle-right', ['circle-right', 'right11']],
  ['circle-down', ['circle-down', 'down9']],
  ['circle-left', ['circle-left', 'left12']],
  ['circle-up2', ['circle-up2', 'up10']],
  ['circle-right2', ['circle-right2', 'right12']],
  ['circle-down2', ['circle-down2', 'down10']],
  ['circle-left2', ['circle-left2', 'left13']],
  ['circle-up-left', ['circle-up-left', 'up-left4']],
  ['circle-up3', ['circle-up3', 'up11']],
  ['circle-up-right', ['circle-up-right', 'up-right4']],
  ['circle-right3', ['circle-right3', 'right13']],
  ['circle-down-right', ['circle-down-right', 'down-right4']],
  ['circle-down3', ['circle-down3', 'down11']],
  ['circle-down-left', ['circle-down-left', 'down-left4']],
  ['circle-left3', ['circle-left3', 'left14']],
  ['circle-up-left2', ['circle-up-left2', 'up-left5']],
  ['circle-up4', ['circle-up4', 'up12']],
  ['circle-up-right2', ['circle-up-right2', 'up-right5']],
  ['circle-right4', ['circle-right4', 'right14']],
  ['circle-down-right2', ['circle-down-right2', 'down-right5']],
  ['circle-down4', ['circle-down4', 'down12']],
  ['circle-down-left2', ['circle-down-left2', 'down-left5']],
  ['circle-left4', ['circle-left4', 'left15']],
  ['arrow-resize', ['arrow-resize', 'diagonal']],
  ['arrow-resize2', ['arrow-resize2', 'diagonal2']],
  ['arrow-resize3', ['arrow-resize3', 'horizontal']],
  ['arrow-resize4', ['arrow-resize4', 'vertical']],
  ['arrow-resize5', ['arrow-resize5', 'diagonal3']],
  ['arrow-resize6', ['arrow-resize6', 'diagonal4']],
  ['arrow-resize7', ['arrow-resize7', 'horizontal2']],
  ['arrow-resize8', ['arrow-resize8', 'vertical2']],
  ['square-up-left', ['square-up-left', 'up-left6']],
  ['square-up', ['square-up', 'up13']],
  ['square-up-right', ['square-up-right', 'up-right6']],
  ['square-right', ['square-right', 'right15']],
  ['square-down-right', ['square-down-right', 'down-right6']],
  ['square-down', ['square-down', 'down13']],
  ['square-down-left', ['square-down-left', 'down-left6']],
  ['square-left', ['square-left', 'left16']],
  ['arrow-up9', ['arrow-up9', 'up14']],
  ['arrow-right9', ['arrow-right9', 'right16']],
  ['arrow-down9', ['arrow-down9', 'down14']],
  ['arrow-left9', ['arrow-left9', 'left17']],
  ['arrow-up10', ['arrow-up10', 'up15']],
  ['arrow-right10', ['arrow-right10', 'right17']],
  ['arrow-down10', ['arrow-down10', 'down15']],
  ['arrow-left10', ['arrow-left10', 'left18']],
  ['arrow-up-left4', ['arrow-up-left4', 'up-left7']],
  ['arrow-up11', ['arrow-up11', 'up16']],
  ['arrow-up-right4', ['arrow-up-right4', 'up-right7']],
  ['arrow-right11', ['arrow-right11', 'right18']],
  ['arrow-down-right4', ['arrow-down-right4', 'down-right7']],
  ['arrow-down11', ['arrow-down11', 'down16']],
  ['arrow-down-left4', ['arrow-down-left4', 'down-left7']],
  ['arrow-left11', ['arrow-left11', 'left19']],
  ['arrow-up-left5', ['arrow-up-left5', 'up-left8']],
  ['arrow-up12', ['arrow-up12', 'up17']],
  ['arrow-up-right5', ['arrow-up-right5', 'up-right8']],
  ['arrow-right12', ['arrow-right12', 'right19']],
  ['arrow-down-right5', ['arrow-down-right5', 'down-right8']],
  ['arrow-down12', ['arrow-down12', 'down17']],
  ['arrow-down-left5', ['arrow-down-left5', 'down-left8']],
  ['arrow-left12', ['arrow-left12', 'left20']],
  ['arrow-up13', ['arrow-up13', 'up18']],
  ['arrow-right13', ['arrow-right13', 'right20']],
  ['arrow-down13', ['arrow-down13', 'down18']],
  ['arrow-left13', ['arrow-left13', 'left21']],
  ['arrow-up14', ['arrow-up14', 'up19']],
  ['arrow-right14', ['arrow-right14', 'right21']],
  ['arrow-down14', ['arrow-down14', 'down19']],
  ['arrow-left14', ['arrow-left14', 'left22']],
  ['circle-up5', ['circle-up5', 'up20']],
  ['circle-right5', ['circle-right5', 'right22']],
  ['circle-down5', ['circle-down5', 'down20']],
  ['circle-left5', ['circle-left5', 'left23']],
  ['circle-up6', ['circle-up6', 'up21']],
  ['circle-right6', ['circle-right6', 'right23']],
  ['circle-down6', ['circle-down6', 'down21']],
  ['circle-left6', ['circle-left6', 'left24']],
  ['arrow-up15', ['arrow-up15', 'up22']],
  ['arrow-right15', ['arrow-right15', 'right24']],
  ['arrow-down15', ['arrow-down15', 'down22']],
  ['arrow-left15', ['arrow-left15', 'left25']],
  ['arrow-up16', ['arrow-up16', 'up23']],
  ['arrow-right16', ['arrow-right16', 'right25']],
  ['arrow-down16', ['arrow-down16', 'down23']],
  ['arrow-left16', ['arrow-left16', 'left26']],
  ['menu-open', ['menu-open', 'arrows3']],
  ['menu-open2', ['menu-open2', 'arrows4']],
  ['menu-close', ['menu-close', 'arrows5']],
  ['menu-close2', ['menu-close2', 'arrows6']],
  ['enter4', ['enter4', 'return']],
  ['enter5', ['enter5', 'return2']],
  ['esc', ['esc', 'escape']],
  ['enter6', ['enter6', 'arrow']],
  ['backspace', ['backspace', 'delete']],
  ['backspace2', ['backspace2', 'delete2']],
  ['tab', ['tab', 'arrows7']],
  ['transmission', ['transmission', 'arrows8']],
  ['transmission2', ['transmission2', 'arrows9']],
  ['sort', ['sort', 'arrows10']],
  ['sort2', ['sort2', 'arrows11']],
  ['move-up', ['move-up', 'sort3']],
  ['move-down', ['move-down', 'sort4']],
  ['sort-alpha-asc', ['sort-alpha-asc', 'arrange']],
  ['sort-alpha-desc', ['sort-alpha-desc', 'arrange2']],
  ['sort-numeric-asc', ['sort-numeric-asc', 'arrange3']],
  ['sort-numberic-desc', ['sort-numberic-desc', 'arrange4']],
  ['sort-amount-asc', ['sort-amount-asc', 'arrange5']],
  ['sort-amount-desc', ['sort-amount-desc', 'arrange6']],
  ['sort-time-asc', ['sort-time-asc', 'arrange7']],
  ['sort-time-desc', ['sort-time-desc', 'arrange8']],
  ['battery-6', ['battery-6', 'battery']],
  ['battery-5', ['battery-5', 'battery2']],
  ['battery-4', ['battery-4', 'battery3']],
  ['battery-3', ['battery-3', 'battery4']],
  ['battery-2', ['battery-2', 'battery5']],
  ['battery-1', ['battery-1', 'battery6']],
  ['battery-0', ['battery-0', 'battery7']],
  ['battery-charging', ['battery-charging', 'battery8']],
  ['key-keyboard', ['key-keyboard', 'button']],
  ['key-A', ['key-A', 'button2']],
  ['key-up', ['key-up', 'button3']],
  ['key-right', ['key-right', 'button4']],
  ['key-down', ['key-down', 'button5']],
  ['key-left', ['key-left', 'button6']],
  ['command', ['command', 'cmd']],
  ['shift', ['shift']],
  ['ctrl', ['ctrl', 'control']],
  ['opt', ['opt', 'option']],
  ['checkbox-checked', ['checkbox-checked', 'checkbox']],
  ['checkbox-unchecked', ['checkbox-unchecked', 'checkbox2']],
  ['checkbox-partial', ['checkbox-partial', 'checkbox3']],
  ['square', ['square']],
  ['triangle', ['triangle']],
  ['triangle2', ['triangle2']],
  ['diamond3', ['diamond3']],
  ['diamond4', ['diamond4']],
  ['checkbox-checked2', ['checkbox-checked2', 'checkbox4']],
  ['checkbox-unchecked2', ['checkbox-unchecked2', 'checkbox5']],
  ['checkbox-partial2', ['checkbox-partial2', 'checkbox6']],
  ['radio-checked', ['radio-checked', 'radio-button']],
  ['radio-checked2', ['radio-checked2', 'radio-button2']],
  ['radio-unchecked', ['radio-unchecked', 'radio-button3']],
  ['toggle-on', ['toggle-on']],
  ['toggle-off', ['toggle-off']],
  ['checkmark-circle', ['checkmark-circle']],
  ['circle', ['circle']],
  ['circle2', ['circle2']],
  ['circles', ['circles', 'record']],
  ['circles2', ['circles2', 'record2']],
  ['crop', ['crop', 'resize']],
  ['crop2', ['crop2', 'resize2']],
  ['make-group', ['make-group']],
  ['ungroup', ['ungroup']],
  ['vector', ['vector', 'bezier']],
  ['vector2', ['vector2', 'bezier2']],
  ['rulers', ['rulers', 'measure']],
  ['ruler', ['ruler', 'measure2']],
  ['pencil-ruler', ['pencil-ruler2', 'design2']],
  ['scissors', ['scissors', 'cut']],
  ['scissors2', ['scissors2', 'cut2']],
  ['scissors3', ['scissors3', 'cut3']],
  ['haircut', ['haircut', 'scissors-comb']],
  ['comb', ['comb']],
  ['filter', ['filter', 'funnel']],
  ['filter2', ['filter2', 'funnel2']],
  ['filter3', ['filter3', 'funnel3']],
  ['filter4', ['filter4', 'funnel4']],
  ['font', ['font', 'typeface']],
  ['ampersand', ['ampersand', 'typograhy']],
  ['ligature', ['ligature', 'typography']],
  ['ligature2', ['ligature2', 'typography2']],
  ['font-size', ['font-size', 'wysiwyg']],
  ['typography', ['typography3', 'wysiwyg2']],
  ['text-height', ['text-height', 'wysiwyg3']],
  ['text-width', ['text-width', 'wysiwyg4']],
  ['height2', ['height2', 'size']],
  ['width', ['width', 'size2']],
  ['bold', ['bold', 'wysiwyg5']],
  ['underline', ['underline', 'wysiwyg6']],
  ['italic', ['italic', 'wysiwyg7']],
  ['strikethrough', ['strikethrough', 'wysiwyg8']],
  ['strikethrough2', ['strikethrough2', 'wysiwyg9']],
  ['font-size2', ['font-size2', 'wysiwyg10']],
  ['bold2', ['bold2', 'wysiwyg11']],
  ['underline2', ['underline2', 'wysiwyg12']],
  ['italic2', ['italic2', 'wysiwyg13']],
  ['strikethrough3', ['strikethrough3', 'wysiwyg14']],
  ['omega', ['omega', 'wysiwyg15']],
  ['sigma', ['sigma', 'wysiwyg16']],
  ['nbsp', ['nbsp', 'wysiwyg17']],
  ['page-break', ['page-break', 'wysiwyg18']],
  ['page-break2', ['page-break2', 'wysiwyg19']],
  ['superscript', ['superscript', 'wysiwyg20']],
  ['subscript', ['subscript', 'wysiwyg21']],
  ['superscript2', ['superscript2', 'wysiwyg22']],
  ['subscript2', ['subscript2', 'wysiwyg23']],
  ['text-color', ['text-color', 'wysiwyg24']],
  ['highlight', ['highlight', 'wysiwyg25']],
  ['pagebreak', ['pagebreak', 'wysiwyg26']],
  ['clear-formatting', ['clear-formatting', 'wysiwyg27']],
  ['table', ['table', 'wysiwyg28']],
  ['table2', ['table2', 'wysiwyg29']],
  ['insert-template', ['insert-template', 'wysiwyg30']],
  ['pilcrow', ['pilcrow', 'wysiwyg31']],
  ['ltr', ['ltr', 'wysiwyg32']],
  ['rtl', ['rtl', 'wysiwyg33']],
  ['ltr2', ['ltr2', 'wysiwyg34']],
  ['rtl2', ['rtl2', 'wysiwyg35']],
  ['section', ['section', 'wysiwyg36']],
  ['paragraph-left', ['paragraph-left', 'wysiwyg37']],
  ['paragraph-center', ['paragraph-center', 'wysiwyg38']],
  ['paragraph-right', ['paragraph-right', 'wysiwyg39']],
  ['paragraph-justify', ['paragraph-justify', 'wysiwyg40']],
  ['paragraph-left2', ['paragraph-left2', 'wysiwyg41']],
  ['paragraph-center2', ['paragraph-center2', 'wysiwyg42']],
  ['paragraph-right2', ['paragraph-right2', 'wysiwyg43']],
  ['paragraph-justify2', ['paragraph-justify2', 'wysiwyg44']],
  ['indent-increase', ['indent-increase', 'wysiwyg45']],
  ['indent-decrease', ['indent-decrease', 'wysiwyg46']],
  ['paragraph-left3', ['paragraph-left3', 'wysiwyg47']],
  ['paragraph-center3', ['paragraph-center3', 'wysiwyg48']],
  ['paragraph-right3', ['paragraph-right3', 'wysiwyg49']],
  ['paragraph-justify3', ['paragraph-justify3', 'wysiwyg50']],
  ['indent-increase2', ['indent-increase2', 'wysiwyg51']],
  ['indent-decrease2', ['indent-decrease2', 'wysiwyg52']],
  ['share', ['share', 'out']],
  ['share2', ['share2', 'out2']],
  ['new-tab', ['new-tab', 'out3']],
  ['new-tab2', ['new-tab2', 'out4']],
  ['popout', ['popout', 'new-window']],
  ['embed', ['embed', 'code']],
  ['embed2', ['embed2', 'code2']],
  ['markup', ['markup', 'code3']],
  ['regexp', ['regexp', 'code4']],
  ['regexp2', ['regexp2']],
  ['code', ['code5', 'semicolon']],
  ['circle-css', ['circle-css', 'code6']],
  ['circle-code', ['circle-code', 'out5']],
  ['terminal', ['terminal', 'console']],
  ['unicode', ['unicode']],
  ['seven-segment-0', ['seven-segment-0', 'number']],
  ['seven-segment-1', ['seven-segment-1', 'number2']],
  ['seven-segment-2', ['seven-segment-2', 'number3']],
  ['seven-segment-3', ['seven-segment-3', 'number4']],
  ['seven-segment-4', ['seven-segment-4', 'number5']],
  ['seven-segment-5', ['seven-segment-5', 'number6']],
  ['seven-segment-6', ['seven-segment-6', 'number7']],
  ['seven-segment-7', ['seven-segment-7', 'number8']],
  ['seven-segment-8', ['seven-segment-8', 'number9']],
  ['seven-segment-9', ['seven-segment-9', 'number10']],
  ['share4', ['share4', 'social2']],
  ['mail', ['mail7', 'contact3']],
  ['mail2', ['mail8', 'contact4']],
  ['mail3', ['mail9', 'contact5']],
  ['mail4', ['mail10', 'contact6']],
  ['google', ['google', 'brand']],
  ['google-plus', ['google-plus', 'brand2']],
  ['google-plus2', ['google-plus2', 'brand3']],
  ['google-plus3', ['google-plus3', 'brand4']],
  ['google-drive', ['google-drive', 'brand5']],
  ['facebook', ['facebook', 'brand6']],
  ['facebook2', ['facebook2', 'brand7']],
  ['facebook3', ['facebook3', 'brand8']],
  ['ello', ['ello', 'brand9']],
  ['instagram', ['instagram', 'brand10']],
  ['twitter', ['twitter', 'brand11']],
  ['twitter2', ['twitter2', 'brand12']],
  ['twitter3', ['twitter3', 'brand13']],
  ['feed2', ['feed2', 'rss']],
  ['feed3', ['feed3', 'rss2']],
  ['feed4', ['feed4', 'rss3']],
  ['youtube', ['youtube', 'brand14']],
  ['youtube2', ['youtube2', 'brand15']],
  ['youtube3', ['youtube3', 'brand16']],
  ['youtube4', ['youtube4', 'brand17']],
  ['twitch', ['twitch', 'brand18']],
  ['vimeo', ['vimeo', 'brand19']],
  ['vimeo2', ['vimeo2', 'brand20']],
  ['vimeo3', ['vimeo3', 'brand21']],
  ['lanyrd', ['lanyrd', 'brand22']],
  ['flickr', ['flickr', 'brand23']],
  ['flickr2', ['flickr2', 'brand24']],
  ['flickr3', ['flickr3', 'brand25']],
  ['flickr4', ['flickr4', 'brand26']],
  ['picassa', ['picassa', 'brand27']],
  ['picassa2', ['picassa2', 'brand28']],
  ['dribbble', ['dribbble', 'brand29']],
  ['dribbble2', ['dribbble2', 'brand30']],
  ['dribbble3', ['dribbble3', 'brand31']],
  ['forrst', ['forrst', 'brand32']],
  ['forrst2', ['forrst2', 'brand33']],
  ['deviantart', ['deviantart', 'brand34']],
  ['deviantart2', ['deviantart2', 'brand35']],
  ['steam', ['steam', 'brand36']],
  ['steam2', ['steam2', 'brand37']],
  ['dropbox', ['dropbox', 'brand38']],
  ['onedrive', ['onedrive', 'brand39']],
  ['github', ['github', 'brand40']],
  ['github2', ['github2', 'brand41']],
  ['github3', ['github3', 'brand42']],
  ['github4', ['github4', 'brand43']],
  ['github5', ['github5', 'brand44']],
  ['wordpress', ['wordpress', 'brand45']],
  ['wordpress2', ['wordpress2', 'brand46']],
  ['joomla', ['joomla', 'brand47']],
  ['blogger', ['blogger', 'brand48']],
  ['blogger2', ['blogger2', 'brand49']],
  ['tumblr', ['tumblr', 'brand50']],
  ['tumblr2', ['tumblr2', 'brand51']],
  ['yahoo', ['yahoo', 'brand52']],
  ['tux', ['tux', 'brand53']],
  ['apple2', ['apple2', 'brand54']],
  ['finder', ['finder', 'brand55']],
  ['android', ['android', 'brand56']],
  ['windows', ['windows', 'brand57']],
  ['windows8', ['windows8', 'brand58']],
  ['soundcloud', ['soundcloud', 'brand59']],
  ['soundcloud2', ['soundcloud2', 'brand60']],
  ['skype', ['skype', 'brand61']],
  ['reddit', ['reddit', 'brand62']],
  ['linkedin', ['linkedin', 'brand63']],
  ['linkedin2', ['linkedin2', 'brand64']],
  ['lastfm', ['lastfm', 'brand65']],
  ['lastfm2', ['lastfm2', 'brand66']],
  ['delicious', ['delicious', 'brand67']],
  ['stumbleupon2', ['stumbleupon2', 'brand69']],
  ['stackoverflow', ['stackoverflow', 'brand70']],
  ['pinterest', ['pinterest', 'brand71']],
  ['pinterest2', ['pinterest2', 'brand72']],
  ['xing', ['xing', 'brand73']],
  ['xing2', ['xing2', 'brand74']],
  ['flattr', ['flattr', 'brand75']],
  ['foursquare', ['foursquare', 'brand76']],
  ['paypal', ['paypal', 'brand77']],
  ['paypal2', ['paypal2', 'brand78']],
  ['paypal3', ['paypal3', 'brand79']],
  ['yelp', ['yelp', 'brand80']],
  ['file-pdf', ['file-pdf', 'file41']],
  ['file-openoffice', ['file-openoffice', 'file42']],
  ['file-word', ['file-word', 'file43']],
  ['file-excel', ['file-excel', 'file44']],
  ['libreoffice', ['libreoffice', 'file45']],
  ['html5', ['html5', 'w3c']],
  ['html52', ['html52', 'w3c2']],
  ['css3', ['css3', 'w3c3']],
  ['git', ['git']],
  ['svg', ['svg']],
  ['codepen', ['codepen', 'brand81']],
  ['chrome', ['chrome', 'browser']],
  ['firefox', ['firefox', 'browser2']],
  ['IE', ['IE', 'browser3']],
  ['opera', ['opera', 'browser4']],
  ['safari', ['safari', 'browser5']],
  ['IcoMoon', ['IcoMoon', 'icomoon']]
];
