<template>
	<div class="fd-file-view"
		:title="file.name"
		v-on="$listeners"
		:class="{ selected, deleted }"
	>
		<div class="background"></div>
		<div class="thumb" :class="file.visible" :style="style"></div>
		<div class="check" v-if="selected">
			<i class="checkmark3"></i>
		</div>
		<div class="delete" v-else-if="deletable" @click.stop="deleteFile(file)">
			<i class="bin"></i>
		</div>
		<div class="label">
			<div class="name">{{file.name}}</div>
			<div class="size">{{file.size | humanizeBytes}}</div>
		</div>
	</div>
</template>

<script>
import DeleteFilesModalView from './../Modal/DeleteFilesModal.vue';

export default {
	name: 'FileView',
	props: ['file', 'selected', 'deletable', 'deleted'],
	computed: {
		style() {
			return {
				backgroundImage: `url('${this.file.thumb}')`
			}
		}
    },
    methods: {
        deleteFile(file) {
            this.$store.deleteFile = true;
            this.$store.commit('modal/create', {
                component: DeleteFilesModalView,
                props:{
                    files: [
                        file
                    ],
                }
            });
        }
    }
}
</script>

<style lang="scss">
$yellow: #fec107;

.fd-file-view {
	display: inline-block;
	min-width: 50px;

	cursor: pointer;
	&.deleted {
		cursor: inherit;
	}

	position: relative;

	border: 3px solid transparent;

	&.selected {
		border: 3px solid $yellow;
	}

	&.deleted .background {
		display: none;
	}
	&.deleted .thumb {
		// sass, why can I STILL not use css functions in sass? (This issue is known since 2014)
		filter: #{"grayscale()"} blur(5px);
		overflow: hidden;
	}

	&:after {
		content: '';
		display: block;
		padding-bottom: 80%;
	}

	.background {
		z-index: 10;

		background: url('../../../img/colorpicker_colorbg.png');
	}

	.background, .thumb {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.thumb {
		z-index: 20;

		background-size: cover;
		background-position: center;

		box-shadow: 0 0 5px rgba(0,0,0,.3);

		&.normal {
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	.check, .delete {
		position: absolute;
		right: 5px;
		bottom: 2px;
		z-index: 40;

		color: $yellow;
		font-size: 18px;
		text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	}

	.delete {
		opacity: 0.3;
		cursor: pointer;

		&:hover {
			color: red;
		}
	}

	&.deleted .delete:hover {
		color: green;
	}

	&:hover .delete {
		opacity: 1;
	}

	.label {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		margin-top: -24px;
		z-index: 30;

		pointer-events: none;

		padding: 5px 0;
		background: hsla(0, 0%, 100%, .7);

		transform: translateY(50%);
		opacity: 0;
		transition: all .2s;

		display: flex;
		flex-direction: column;

		& > * {
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 5px;
		}
	}

	&.deleted .label {
		display: none;
	}

	&:hover .label {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
