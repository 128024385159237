<script>
import Vue from 'vue';

import DrawModule from './DrawModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import { _last, _deepCopy } from '../util/helper';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'DrawModule',
	icon: 'pencil',
};

export const MetaDescriptionView = {
	name: 'DrawMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'DrawModule',
		x: 'center',
		y: 'center',
		width: 800,
		height: 500,
		style: {
			color: null,
			backgroundColor: {
				r: 0,
				g: 0,
				b: 0,
				a: 0,
			},
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			drawingElements: [],
			mode: 'fill',
			lineWidth: 10,
			canvas: { width: 800, height: 500 },
			image: false,
		},
	};
}

const configViewData = new Vue({
	data: {
		isOpen: false,
		isDrawing: false,
		isDeleting: false,
		currentShape: 'rect',
	},
});
export const ConfigView = {
	name: 'DrawModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	mounted() {
		if (typeof this.module.mid != 'undefined' && document.getElementById(this.module.mid))
			document.getElementById(this.module.mid).style.cursor = 'move';
		configViewData.isOpen = true;
	},
	beforeDestroy() {
		configViewData.isOpen = false;
		configViewData.isDrawing = false;
		configViewData.isDeleting = false;
	},
	watch: {
		activeModuleChangedWatcher() {
			configViewData.isDrawing = false;
			configViewData.isDeleting = false;
		},
	},
	computed: {
		activeModuleChangedWatcher() {
			return this.$store.state.activeModules[0];
		},
		isDrawing() {
			return configViewData.isDrawing;
		},
		isDeleting() {
			return configViewData.isDeleting;
		},
		currentShape() {
			return configViewData.currentShape;
		},
		config() {
			return [
				{
					type: 'Select',
					label: this.$_('fd.drawmodule.settings.shape'),
					visible: !this.module.props.image,
					value: this.currentShape,
					values: [
						{
							label: this.$_('fd.drawmodule.settings.shape.rect'),
							value: 'rect',
						},
						{
							label: this.$_('fd.drawmodule.settings.shape.circle'),
							value: 'circle',
						},
						{
							label: this.$_('fd.drawmodule.settings.shape.stroke'),
							value: 'stroke',
						},
						{
							label: this.$_('fd.drawmodule.settings.shape.skizz'),
							value: 'line',
						},
					],
					events: {
						input: this.setShape,
					},
				},
				{
					type: 'Select',
					label: this.$_('fd.drawmodule.settings.mode'),
					visible: !this.module.props.image,
					value: this.module.props.mode || 'stroke',
					values: [
						{
							label: this.$_('fd.drawmodule.settings.mode.fill'),
							value: 'fill',
						},
						{
							label: this.$_('fd.drawmodule.settings.mode.stroke'),
							value: 'stroke',
						},
					],
					events: {
						input: this.setMode,
					},
				},
				{
					type: 'Button',
					label: this.$_(
						'fd.drawmodule.settings.draw.' + (this.isDrawing ? 'end' : 'start')
					),
					visible: !this.module.props.image,
					events: {
						click: this.toggleDrawing,
					},
				},
				{
					type: 'Button',
					label: this.$_(
						'fd.drawmodule.settings.delete.' + (this.isDeleting ? 'end' : 'start')
					),
					visible: !this.module.props.image,
					events: {
						click: this.toggleDeleting,
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.drawmodule.settings.lineWidth'),
					value: this.module.props.lineWidth || 10,
					min: 2,
					max: 100,
					events: {
						input: this.setLineWidth,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.drawmodule.settings.asimage'),
					visible: !this.module.props.image,
					events: {
						click: this.setAsImage,
					},
				},
			];
		},
	},
	methods: {
		setShape(shape) {
			configViewData.currentShape = shape;
		},
		setMode(mode) {
			this.commitChange('props', { mode });
		},
		toggleDrawing() {
			configViewData.isDrawing = !this.isDrawing;
			configViewData.isDeleting = false;
			if (this.isDrawing)
				document.getElementById(this.module.mid).style.cursor = 'crosshair';
			else document.getElementById(this.module.mid).style.cursor = 'move';
		},
		toggleDeleting() {
			configViewData.isDeleting = !this.isDeleting;
			configViewData.isDrawing = false;
			if (this.isDeleting)
				document.getElementById(this.module.mid).style.cursor = 'pointer';
			else document.getElementById(this.module.mid).style.cursor = 'move';
		},
		setLineWidth(lineWidth) {
			this.commitChange('props', { lineWidth });
		},
		setAsImage() {
			configViewData.isDrawing = false;
			configViewData.isDeleting = false;
			this.commitChange('props', { image: true });
			this.commitChange('props', {
				canvas: {
					width: this.module.width,
					height: this.module.height,
				},
			});
		},
	},
};

let isShiftOrCtrlKeyPressed = false;
document.addEventListener('keydown', e => {
	isShiftOrCtrlKeyPressed = e.shiftKey || e.ctrlKey;
});
document.addEventListener('keyup', e => {
	isShiftOrCtrlKeyPressed = e.shiftKey || e.ctrlKey;
});

export default {
	extends: DrawModule,
	data: () => ({
		elementIndex: 0
	}),
	computed: {
		isOpen() {
			return (
				configViewData.isOpen &&
				this.$store.state.activeModules[0] === this.module.mid
			);
		},
		isDrawing() {
			return configViewData.isDrawing;
		},
		isDeleting() {
			return configViewData.isDeleting;
		},
		currentShape() {
			return configViewData.currentShape;
		},
		resizingWatcher() {
			return this.module.width + this.module.height;
		},
		editPoints() {
			return this.module.props.drawingElements.reduce((points, drawable) => {
				switch (drawable.shape) {
					case 'rect':
						points.push({
							did: drawable.did,
							shape: 'rect',
							x: drawable.x + drawable.w / 2,
							y: drawable.y + drawable.h / 2,
							horizontal: true,
							vertical: true,
						});
						points.push({
							did: drawable.did,
							shape: 'rect',
							x: drawable.x + drawable.w,
							y: drawable.y + drawable.h / 2,
							horizontal: true,
							vertical: false,
						});
						points.push({
							did: drawable.did,
							shape: 'rect',
							x: drawable.x + drawable.w / 2, 
							y: drawable.y + drawable.h,
							horizontal: false,
							vertical: true,
						});
						break;

					case 'circle':
						points.push({
							did: drawable.did,
							shape: 'circle',
							x: drawable.x,
							y: drawable.y,
							horizontal: true,
							vertical: true,
						});
						points.push({
							did: drawable.did,
							shape: 'circle',
							x: drawable.x + drawable.rx,
							y: drawable.y,
							horizontal: true,
							vertical: false,
						});
						points.push({
							did: drawable.did,
							shape: 'circle',
							x: drawable.x,
							y: drawable.y + drawable.ry,
							horizontal: false,
							vertical: true,
						});
						break;

					case 'stroke':
						if (drawable.points.length < 10) {
							drawable.points.forEach(point => {
								points.push({
									did: drawable.did,
									shape: 'stroke',
									x: point.x,
									y: point.y,
									horizontal: true,
									vertical: true,
								});
							});
						} else {
							let x = Infinity,
								y = Infinity,
								x2 = -Infinity,
								y2 = -Infinity;

							drawable.points.forEach(point => {
								if (point.y < y) y = point.y;
								if (point.x < x) x = point.x;
								if (point.x > x2) x2 = point.x;
								if (point.y > y2) y2 = point.y;
							});

							const width = x2 - x;
							const height = y2 - y;

							points.push({
								did: drawable.did,
								shape: 'stroke',
								x: x + width / 2,
								y: y + height / 2,
								horizontal: true,
								vertical: true,
								w: width,
								h: height
							});
						}
						break;
				}
				return points;
			}, []);
		},
	},
	watch: {
		async resizingWatcher() {
			await this.$nextTick();
			this.draw(this.ctx);
		},
	},
	mounted() {
		if(typeof _last(this.module.props.drawingElements) != 'undefined')
			this.elementIndex = _last(this.module.props.drawingElements).did;
		this.module.props.drawingElements.forEach(element => {
			this.elementIndex++;
		});

		const getMovableParent = function getMovableParent(vm) {
			if (vm.$parent.movable) return vm.$parent;
			return getMovableParent(vm.$parent);
		};
		this.$options.movableParent = getMovableParent(this);

		this.$options.movableParent.$on('move:start', this.moveStart);
		this.$options.movableParent.$on('move', this.move);
		this.$options.movableParent.$on('move:end', this.moveEnd);
	},
	beforeDestroy() {
		this.$options.movableParent.$off('move:start', this.moveStart);
		this.$options.movableParent.$off('move', this.move);
		this.$options.movableParent.$off('move:end', this.moveEnd);
		this.$options.movableParent = null;
	},
	methods: {
		draw(ctx) {
			DrawModule.methods.draw.call(this, ctx);
		},
		popDrawable() {
			const poppingDrawable = _last(this.module.props.drawingElements);
			this.$store.commit('changeModules', {
				type: 'props',
				modules: [this.module.mid],
				values: {
					drawingElements: this.module.props.drawingElements.slice(0, -1),
				},
			});
			return poppingDrawable;
		},
		pushDrawable(pushingDrawable) {
			this.$store.commit('changeModules', {
				type: 'props',
				modules: [this.module.mid],
				values: {
					drawingElements: this.module.props.drawingElements.concat([
						pushingDrawable,
					]),
				},
			});
		},
		moveStart(evt) {
			if (!this.isOpen || !this.isDrawing) return;

			this.module.props.drawing = true;

			const { x, y } = evt.target.getBoundingClientRect();
			this.$options.offset = { x, y };

			const { startX, startY } = evt.detail;
			this.$options.points = [
				{
					x: (startX - this.$options.offset.x) / this.$store.state.intern.scale,
					y: (startY - this.$options.offset.y) / this.$store.state.intern.scale,
				},
			];

			this['draw_' + this.currentShape + '_init'](isShiftOrCtrlKeyPressed);

			evt.returnValue = false;
		},
		move(evt) {
			if (!this.isOpen || !this.isDrawing) return;

			const { currentX, currentY } = evt.detail;
			this.$options.points.push({
				x: (currentX - this.$options.offset.x) / this.$store.state.intern.scale,
				y: (currentY - this.$options.offset.y) / this.$store.state.intern.scale,
			});

			this['draw_' + this.currentShape](isShiftOrCtrlKeyPressed);

			evt.returnValue = false;
		},
		moveEnd(evt) {
			if (!this.isOpen || !this.isDrawing) return;

			this.module.props.drawing = false;
			this.$parent.$parent.moved = true;

			this['draw_' + this.currentShape + '_finish'](isShiftOrCtrlKeyPressed);

			this.elementIndex++;

			evt.returnValue = false;
		},

		draw_rect_init(drawSymmetrical) {
			const start = this.$options.points[0];
			const end = _last(this.$options.points);

			const drawable = {
				did: this.elementIndex,
				shape: 'rect',
				x: start.x,
				y: start.y,
				w: end.x - start.x,
				h: end.y - start.y,
			};

			if (drawSymmetrical) {
				drawable.h = drawable.w;
			}

			this.pushDrawable(drawable);
		},
		draw_rect(drawSymmetrical) {
			this.popDrawable();
			this.draw_rect_init(drawSymmetrical);
		},
		draw_rect_finish() {},

		draw_circle_init(drawSymmetrical) {
			const start = this.$options.points[0];
			const end = _last(this.$options.points);

			const drawable = {
				did: this.elementIndex,
				shape: 'circle',
				x: start.x + (end.x - start.x) / 2,
				y: start.y + (end.y - start.y) / 2,
				rx: Math.abs(end.x - start.x) / 2,
				ry: Math.abs(end.y - start.y) / 2,
			};

			if (drawSymmetrical) {
				drawable.ry = drawable.rx;
			}

			this.pushDrawable(drawable);
		},
		draw_circle(drawSymmetrical) {
			this.popDrawable();
			this.draw_circle_init(drawSymmetrical);
		},
		draw_circle_finish() {},

		draw_stroke_init(drawSymmetrical) {
			const start = this.$options.points[0];
			const end = _last(this.$options.points);

			let drawable = {
				did: this.elementIndex, 
				shape: 'stroke',
				points: [
					{
						x: start.x,
						y: start.y,
					},
					{
						x: end.x,
						y: end.y,
					},
				],
			};

			const lastDrawable = _last(this.module.props.drawingElements);
			if (lastDrawable && lastDrawable.shape == 'stroke') {
				const lastPoint = _last(lastDrawable.points);

				if (
					Math.abs(lastPoint.x - start.x) + Math.abs(lastPoint.y - start.y) <
					10
				) {
					drawable = _deepCopy(lastDrawable);

					start.x = lastPoint.x;
					start.y = lastPoint.y;

					this.popDrawable();

					drawable.points.push({
						x: end.x,
						y: end.y,
					});
				}
			}

			if (drawSymmetrical) {
				const xDistance = Math.abs(end.x - start.x);
				const yDistance = Math.abs(end.y - start.y);

				if (yDistance > xDistance) {
					_last(drawable.points).x = start.x;
				} else {
					_last(drawable.points).y = start.y;
				}
			}

			this.pushDrawable(drawable);
		},
		draw_stroke(drawSymmetrical) {
			const start = this.$options.points[0];
			const end = _last(this.$options.points);

			const drawable = this.popDrawable();
			_last(drawable.points).x = end.x;
			_last(drawable.points).y = end.y;

			if (drawSymmetrical) {
				const xDistance = Math.abs(end.x - start.x);
				const yDistance = Math.abs(end.y - start.y);

				if (yDistance > xDistance) {
					_last(drawable.points).x = start.x;
				} else {
					_last(drawable.points).y = start.y;
				}
			}

			this.pushDrawable(drawable);
		},
		draw_stroke_finish(drawSymmetrical) {
			const start = this.$options.points[0];
			const end = _last(this.$options.points);

			const currentDrawable = _last(this.module.props.drawingElements);
			const firstPoint = currentDrawable.points[0];
			const lastPoint = _last(currentDrawable.points);

			if (
				Math.abs(lastPoint.x - firstPoint.x) +
					Math.abs(lastPoint.y - firstPoint.y) <
				10
			) {
				const drawable = _deepCopy(currentDrawable);

				if (drawSymmetrical) {
					const xDistance = Math.abs(end.x - start.x);
					const yDistance = Math.abs(end.y - start.y);

					if (yDistance > xDistance) {
						_last(drawable.points).y = firstPoint.y;
					} else {
						_last(drawable.points).x = firstPoint.x;
					}
				} else {
					_last(drawable.points).x = firstPoint.x;
					_last(drawable.points).y = firstPoint.y;
				}

				this.popDrawable();
				this.pushDrawable(drawable);
			}
		},

		draw_line_init() {
			const drawable = {
				did: this.elementIndex,
				shape: 'stroke',
				points: this.$options.points,
			};

			this.pushDrawable(drawable);
		},
		draw_line() {
			this.popDrawable();
			this.draw_line_init();
		},
		draw_line_finish() {},
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.drawmodule.title: Zeichnung
fd.drawmodule.description: >
	Mit der Zeichnenfunktion können Sie eigene Objekte wie z.B. Kreise, Rechtecke
	oder Geraden zeichnen und an einer gewünschten Stelle platzieren.
fd.drawmodule.add: Zeichnung hinzufügen

fd.drawmodule.settings.shape: Form
fd.drawmodule.settings.shape.rect: Rechteck
fd.drawmodule.settings.shape.circle: Kreis
fd.drawmodule.settings.shape.stroke: Gerade
fd.drawmodule.settings.shape.skizz: Skizze (freihand)

fd.drawmodule.settings.mode: Modus
fd.drawmodule.settings.mode.fill: Gefüllt
fd.drawmodule.settings.mode.stroke: Linie

fd.drawmodule.settings.draw.start: Zeichnen starten
fd.drawmodule.settings.draw.end: Zeichnen beenden

fd.drawmodule.settings.delete.start: Löschvorgang starten
fd.drawmodule.settings.delete.end: Löschvorgang beenden

fd.drawmodule.settings.lineWidth: Linienbreite
fd.drawmodule.settings.asimage: Zeichnung sperren
</i18n>
