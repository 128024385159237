<script>
import ShapeModule from './ShapeModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import ComplexListItemView from '../inputs/ComplexListItem.vue';
import { icons } from '../FileManager';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'ShapeModule',
	icon: 'star-empty',
}

export const MetaDescriptionView = {
	name: 'ShapeMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'ShapeModule',
		x: 'center',
		y: 'center',
		width: 200,
		height: 200,
		style: {
			color: null,
			backgroundColor: {r: 0, g: 0, b: 0, a: 0},
			padding: null,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			shape: 'star-full'
		},
	}
}

export const description = {
	extends: DescriptionView,
	data() {
		return {
			description: this.module.props.shape
		}
	}
}

export const ConfigView = {
	name: 'ShapeModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta, list: [] }),
	computed: {
		config() {
			return [{
				type: 'List',
				label: this.$_('fd.shapemodule.settings.shapelist'),
				search: true,
				itemView: ComplexListItemView,
				value: this.module.props.shape,
				values: this.list,
				events: {
					input: this.setShape
				}
			}];
		}
	},
	async mounted() {
		await this.$nextTick();
		await new Promise(r => setTimeout(r, 100));

		this.list = icons.map(icon => ({
			label: icon[0],
			value: icon[0],
			key: icon[0],
			search: icon[1].join(' '),
			icon: icon[0],
			actions: [],
		}));
	},
	methods: {
		setShape(shape) {
			this.commitChange('props', { shape });
		},
	}
}

export default {
	extends: ShapeModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.shapemodule.title: Shape
fd.shapemodule.description: >
	Fügt ein Symbol bzw. Icon an einer belibigen Stelle ein. Sie können Icons aus
	unserer Gallerie wählen.
fd.shapemodule.add: Shape hinzufügen

fd.shapemodule.settings.shapelist: Shapes
</i18n>
