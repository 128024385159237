<template>
	<div class="fd-raw" v-html="config.html"></div>
</template>

<script>
export default {
	name: 'RawView',
	props: ['config']
}
</script>

<style lang="scss">
.fd-config-item.Raw {
	.label {
		display: none;
	}
	.input {
		flex: 0 0 100% !important;
	}
}

.fd-raw {
	width: 100%;
}
</style>
