import {
	normalize as normalizeColor,
	compute as computeColor
} from './Color.js';
import { generateDropShadow } from '../util/SVGGen.js';

export const KEY = 'shadow';

export const CONFIG = {
	type: 'Shadow'
}

export function normalize(value) {
	value = value || {};
	return {
		x: value.x || 0,
		y: value.y || 0,
		width: value.width || 0,
		color: normalizeColor(value.color || { a: 50 }),
		show: (typeof value.show !== undefined) ? value.show : 0
	};
}

export function compute(value) {
	value = normalize(value)

	value = generateDropShadow(
		value.x,
		value.y,
		value.width,
		computeColor(value.color).color,
		value.show
	);

	return {
		filter: value
	}
}
