<template>
	<window-view class="fd-align-window-view"
		:id="id"
		icon="align-left"
		:title="$_('fd.alignwindow.title')"
		:width="180"
		:height="150"
		:resizeable="false"
	>

		<toolbar-item-view icon="align-left"
			:disabled="this.activeModules.length < 1"
			@click="alignLeft"
		/>
		<toolbar-item-view icon="align-center-horizontal"
			:disabled="this.activeModules.length < 1"
			@click="alignCenterHorizontal"
		/>
		<toolbar-item-view icon="align-right"
			:disabled="this.activeModules.length < 1"
			@click="alignRight"
		/>

		<toolbar-item-view icon="align-top"
			:disabled="this.activeModules.length < 1"
			@click="alignTop"
		/>
		<toolbar-item-view icon="align-center-vertical"
			:disabled="this.activeModules.length < 1"
			@click="alignCenterVertical"
		/>
		<toolbar-item-view icon="align-bottom"
			:disabled="this.activeModules.length < 1"
			@click="alignBottom"
		/>

		<toolbar-item-view icon="spread-horizontal"
			:disabled="this.activeModules.length < 3"
			@click="spreadHorizontal"
		/>
		<toolbar-item-view icon="spread-vertical"
			:disabled="this.activeModules.length < 3"
			@click="spreadVertical"
		/>

	</window-view>
</template>

<script>
import WindowView from './Window.vue';
import ToolbarItemView from '../ToolbarItem.vue';

export default {
	name: 'AlignWindowView',
	props: ['id'],
	components: { WindowView, ToolbarItemView },
	computed: {
		activeModules() {
			return this.$store.getters.activeModules;
		}
	},
	methods: {
		alignLeft() {
			this.$store.dispatch('alignLeft');
		},
		alignCenterHorizontal() {
			this.$store.dispatch('alignCenterHorizontal');
		},
		alignRight() {
			this.$store.dispatch('alignRight');
		},
		alignTop() {
			this.$store.dispatch('alignTop');
		},
		alignCenterVertical() {
			this.$store.dispatch('alignCenterVertical');
		},
		alignBottom() {
			this.$store.dispatch('alignBottom');
		},
		spreadHorizontal() {
			this.$store.dispatch('spreadHorizontal');
		},
		spreadVertical() {
			this.$store.dispatch('spreadVertical');
		},
	}
}
</script>

<style lang="scss">
@import '../../../scss/TextModuleInnerStyle.scss';

.fd-align-window-view {
	background: rgba(0, 0, 0, .8);

	.body {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>

<i18n lang="de_DE">
fd.alignwindow.title: Ausrichten
</i18n>
