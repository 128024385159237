export const KEY = 'advanced';

export const CONFIG = {
	type: 'Textarea'
}

/**
 * Normalize the given value
 *
 * @param {Object} value The value saved in the module object
 * @returns A normalized value to save time for checks
 */
export function normalize(value) {
	return value || {};
}

/**
 * Transfer the saved value to the input interface
 *
 * @param {Object} value The value saved in the module object
 * @returns The value given to the input interface
 */
export function prepare(value) {
	value = normalize(value);

	let text = '';
	for(const key in value) {
		text += `${
			key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
		}: ${
			value[key].css
		};\n`;
	}
	return text;
}

/**
 * Transfer the input interface given value to the save format
 *
 * @param {Function} fn Called with the new value saved into the module object
 * @returns A function witch handle the user input
 */
export function handleInput(fn) {
	return (input) => fn(
		input.replace(/;/g, '').split('\n').reduce((value, css) => {
			css = css.replace(': ', ':').split(':');

			if(css.length <= 1) {
				return value;
			}

			value[css[0].toLowerCase().trim().replace(/-(.)/g, (_, g) => {
				return g.toUpperCase();
			})] = { css: css[1].trim() };

			return value;
		}, {})
	)
}

/**
 * Transfer the saved value to the format needed to render css
 *
 * @param {Object} value The value saved in the module object
 * @returns The format used to render the css
 */
export function compute(value) {
	value = value || {};

	const advanced = {};
	for(const key in value) {
		advanced[key] = value[key].css;
	}
	return advanced;
}
