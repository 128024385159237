<script>
import VideoModule from './VideoModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'VideoModule',
	icon: 'film4',
}

export const MetaDescriptionView = {
	name: 'VideoMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'VideoModule',
		x: 'center',
		y: 'center',
		width: 800,
		height: 440,
		style: {
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			uri: '',
			usb: ''
		},
	}
}

export const description = {
	extends: DescriptionView,
	data() {
		return {
			description: (
				this.module.props.usb
				||
				this.module.props.uri.split('/').splice(-1).join('')
			)
		}
	}
}

export const ConfigView = {
	name: 'VideoModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		videos() {
			return this.$store.state.shared.files
				.filter(file => file.type.includes('video'));
		},
		config() {
			return [{
				type: 'Text',
				label: this.$_('fd.videomodule.settings.uri'),
				value: this.module.props.uri,
				events: {
					input: this.setUri
				}
			}, {
				type: 'Select',
				label: this.$_('fd.videomodule.settings.usbpaths'),
				visible: !!this.videos.length,
				value: this.module.props.usb,
				values: [{
					label:  this.$_('fd.videomodule.settings.uselink'),
					value: ''
				}, ...this.videos.map(video => ({
					label: video.name,
					value: video.path
				}))],
				events: {
					input: this.setUsbVideo
				}
			}, {
				type: 'Button',
				label: this.$_('fd.videomodule.settings.addVideo'),
				events: {
					click: this.addVideo
				}
			}];
		}
	},
	methods: {
		setUri(uri) {
			this.commitChange('props', { uri: uri.trim() });
		},
		setUsbVideo(usb) {
			this.commitChange('props', { usb });
		},
		addVideo() {
			let videoName = prompt(this.$_('fd.videomodule.settings.addVideoDescription'));
			if (videoName == null) return false;
			if (videoName.length <= 0) {
				this.throwError(this.$_('fd.videomodule.error.invalidName'), this.addVideo);
				return false;
			}

			const body = {};
			body['video_name'] = videoName;

			this.sendHttpRequest('POST', '?action=add&what=video', body).then((response) => {
				response = JSON.parse(response);
				if (response.error !== null && typeof response.error != 'undefined') {
					if (response.error.length > 0) this.throwError(response.error, this.addVideo);
					return false;
				}

				alert(this.$_('fd.videomodule.success.addVideo'))
				window.location.reload();
			});
		},
		throwError(msg, callback) {
			alert(msg);
			callback();
		},
		sendHttpRequest(method, url, body) {
			return new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.open(method, url);
				xhr.setRequestHeader('Content-type', 'application/json');
				xhr.addEventListener('load', () => {
					resolve(xhr.responseText);
				});
				xhr.addEventListener('error', (e) => {
					reject(e);
				});
				xhr.send(JSON.stringify(body));
			});
		}
	}
}

export default {
	extends: VideoModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.videomodule.title: Video
fd.videomodule.description: >
	Mit der Videofunktion können Sie Videoelemente zu Ihrem Slide hinzufügen. Sie
	benötigen hierzu allerdings eine entsprechende Geräteversion /
	Hardwareversion. (Kompatibel ab Seriennummer > SFD201705XXXXX
fd.videomodule.add: Video hinzufügen

fd.videomodule.settings.uri: Video URI
fd.videomodule.settings.usbpaths: USB Video
fd.videomodule.settings.uselink: deaktiviert
fd.videomodule.settings.addVideo: Video hinzufügen
fd.videomodule.settings.addVideoDescription: >
	Bitte geben Sie den Namen des Videos ein, welches Sie von Ihrem USB-Stick aus hinzufügen möchten.
	Die Dateiendung muss mit angegeben werden! (z.B.: "Video.mp4")
fd.videomodule.error.invalidName: Ungültiger Video-Name.
fd.videomodule.success.addVideo: Video erfolgreich hinzugefügt.
</i18n>
