<template>
	<div class="fd-window-view" :style="style">
		<div class="resizables" :class="{disable: resizeable === false}">
			<div class="resizable t l"
				v-movable
				@move:start="resizeStart($event, 'tl')"
				@move="resize($event, 'tl')"
				@move:end="resizeEnd($event, 'tl')"
			></div>
			<div class="resizable t"
				v-movable
				@move:start="resizeStart($event, 't')"
				@move="resize($event, 't')"
				@move:end="resizeEnd($event, 't')"
			></div>
			<div class="resizable t r"
				v-movable
				@move:start="resizeStart($event, 'tr')"
				@move="resize($event, 'tr')"
				@move:end="resizeEnd($event, 'tr')"
			></div>
			<div class="resizable l"
				v-movable
				@move:start="resizeStart($event, 'l')"
				@move="resize($event, 'l')"
				@move:end="resizeEnd($event, 'l')"
			></div>
			<div class="resizable r"
				v-movable
				@move:start="resizeStart($event, 'r')"
				@move="resize($event, 'r')"
				@move:end="resizeEnd($event, 'r')"
			></div>
			<div class="resizable b l"
				v-movable
				@move:start="resizeStart($event, 'bl')"
				@move="resize($event, 'bl')"
				@move:end="resizeEnd($event, 'bl')"
			></div>
			<div class="resizable b"
				v-movable
				@move:start="resizeStart($event, 'b')"
				@move="resize($event, 'b')"
				@move:end="resizeEnd($event, 'b')"
			></div>
			<div class="resizable b r"
				v-movable
				@move:start="resizeStart($event, 'br')"
				@move="resize($event, 'br')"
				@move:end="resizeEnd($event, 'br')"
			></div>
		</div>
		<div class="header">
			<div class="title"
				v-movable
				@move:start="moveStart"
				@move="move"
			>
				<i :class="icon"></i>
				{{title}}
			</div>
			<button class="close"
				@click="close"
			>
				<i class="cross"></i>
			</button>
		</div>
		<div class="body">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import '../../../scss/fonts/opensans.scss';

export default {
	name: 'WindowView',
	props: [
		'id',
		'icon',
		'title',
		'width',
		'height',
		'resizeable',
	],
	data() {
		return {
			x: 0,
			y: 0,
			currentWidth: this.width,
			currentHeight: this.height,
			minWidth: this.width - 50,
			minHeight: this.height - 50,
			startMove: null,
			startResize: null,
		}
	},
	computed: {
		style() {
			return {
				transform: `translate(${this.x}px, ${this.y}px)`,
				width: this.currentWidth + 'px',
				height: this.currentHeight + 'px',
			}
		}
	},
	mounted() {
		this.x = (this.$el.parentNode.offsetWidth - this.width) / 2;
		this.y = (this.$el.parentNode.offsetHeight - this.height) / 2;
	},
	methods: {
		moveStart({ detail: evt }) {
			this.startMove = {
				x: this.x,
				y: this.y,
			};
		},
		move({ detail: evt }) {
			this.x = this.startMove.x + evt.moveX;
			this.y = this.startMove.y + evt.moveY;
		},

		resizeStart() {
			this.startResize = {
				x: this.x,
				y: this.y,
				width: this.currentWidth,
				height: this.currentHeight,
			};
		},
		resize({ detail: { moveX, moveY } }, direction) {
			const newValues = { ...this.startResize };

			if(direction.includes('t')) {
				if(newValues.height - moveY < this.minHeight) {
					moveY = newValues.height - this.minHeight;
				}
				newValues.y += moveY;
				newValues.height -= moveY;
			}
			if(direction.includes('l')) {
				if(newValues.width - moveX < this.minWidth) {
					moveX = newValues.width - this.minWidth;
				}
				newValues.x += moveX;
				newValues.width -= moveX;
			}
			if(direction.includes('r')) {
				if(newValues.width + moveX < this.minWidth) {
					moveX = this.minWidth - newValues.width;
				}
				newValues.width += moveX;
			}
			if(direction.includes('b')) {
				if(newValues.height + moveY < this.minHeight) {
					moveY = this.minHeight - newValues.height;
				}
				newValues.height += moveY;
			}

			this.x = newValues.x;
			this.y = newValues.y;
			this.currentWidth = newValues.width;
			this.currentHeight = newValues.height;
		},
		resizeEnd({ detail: evt }) {
			evt.original.stopPropagation();
		},

		close() {
			this.$store.commit('window/close', this.id);
		}
	}
}
</script>

<style lang="scss">

.fd-window-view {
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

	display: flex;
	flex-direction: column;

	font-family: OpenSans, sans-serif;

	.resizables {
		position: absolute;
		top: -5px;
		left: -5px;
		right: -5px;
		bottom: -5px;

		pointer-events: none;

		&.disable * {
			pointer-events: none !important;
		}

		.resizable {
			position: absolute;
			pointer-events: all;

			&.t.l {
				top: 0;
				left: 0;
				width: 8px;
				height: 8px;

				right: initial;
				bottom: initial;

				cursor: nwse-resize;
			}

			&.t {
				top: 0;
				left: 8px;
				right: 8px;
				height: 6px;

				cursor: ns-resize;
			}

			&.t.r {
				top: 0;
				right: 0;
				width: 8px;
				height: 8px;

				left: initial;
				bottom: initial;

				cursor: nesw-resize;
			}

			&.l {
				left: 0;
				top: 8px;
				bottom: 8px;
				width: 6px;

				cursor: ew-resize;
			}

			&.r {
				right: 0;
				top: 8px;
				bottom: 8px;
				width: 6px;

				cursor: ew-resize;
			}

			&.b.l {
				bottom: 0;
				left: 0;
				width: 8px;
				height: 8px;

				top: initial;
				right: initial;

				cursor: nesw-resize;
			}

			&.b {
				bottom: 0;
				left: 8px;
				right: 8px;
				height: 6px;

				cursor: ns-resize;
			}

			&.b.r {
				bottom: 0;
				right: 0;
				width: 8px;
				height: 8px;

				top: initial;
				left: initial;

				cursor: nwse-resize;
			}
		}
	}

	.header {
		background: rgba(0, 0, 0, .9);

		display: flex;
		color: white;

		.title {
			flex: 1;
			font-size: 14px;
			padding: 1px 6px;

			cursor: default;

			i {
				margin-right: 5px;
			}
		}

		.close {
			border: none;
			outline: none;
			background: none;
			color: white;
			padding: 6px;
			font-size: 10px;
			cursor: pointer;
		}

	}

	.body {
		flex: 1;
	}
}
</style>
