<template>
  <div class="fd-counter-module">
    <div class="digit"
      v-for="(pos, i) of $options.positions"
      :key="pos"
      :class="'pos-' + pos"
      :value="valueArray[6 - i]"
    >
      <div class="dig-part"
        v-for="dig of $options.digits"
        :key="dig"
        :class="'dig-' + dig"
      >{{dig}}</div>
    </div>
    <div class="komma pos-0">,</div>
    <div class="digit pos-z" :value="valueArray[0]">
      <div class="dig-part"
        v-for="dig of $options.digits"
        :key="dig"
        :class="'dig-' + dig"
      >{{dig}}</div>
    </div>
  </div>
</template>

<script>
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
  name: 'CounterModule',
  extends: BaseModule,
	normalize,

  // $options
  positions: ['f', 'e', 'd', 'c', 'b', 'a'],
  digits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],

  data() {
    return {
      counter: 0
    }
  },
  computed: {
    variables() {
      return this.$store.state.shared.variables;
    },
    value() {
      return Math.abs(
        this.variables[this.module.props.variable] / this.module.props.divider + this.counter
      );
    },
    valueArray() {
      let value = parseFloat(this.value);
      value = parseInt(value*10);
      return String(value).split('').reverse();
    }
  },
  methods: {
    start() {
      this.$options.interval = setInterval(() => {
        this.counter += 0.1;
      }, this.module.props.interval * 1000);
    },
    stop() {
      clearInterval(this.$options.interval);
    }
  }
}
</script>

<style lang="scss">
.fd-counter-module {
  display: flex !important;
  overflow: hidden;

  color: white;

  .digit {
    flex: 1;

    transition: transform .6s;
    will-change: transform;
  }

  .digit.pos-z {
    margin-right: 0;
  }

  .komma, .dig-part {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pos-0, .pos-z .dig-part {
    background: red;
  }

  .dig-part {
    width: 100%;
    height: 100%;

    background: black;
  }

  .digit.pos-z,
  .digit.pos-a,
  .digit.pos-b,
  .digit.pos-c,
  .digit.pos-d,
  .digit.pos-e,
  .digit.pos-f {
    &[value="0"] {
      transform: translateY(0);
    }
    &[value="1"] {
      transform: translateY(-100%);
    }
    &[value="2"] {
      transform: translateY(-200%);
    }
    &[value="3"] {
      transform: translateY(-300%);
    }
    &[value="4"] {
      transform: translateY(-400%);
    }
    &[value="5"] {
      transform: translateY(-500%);
    }
    &[value="6"] {
      transform: translateY(-600%);
    }
    &[value="7"] {
      transform: translateY(-700%);
    }
    &[value="8"] {
      transform: translateY(-800%);
    }
    &[value="9"] {
      transform: translateY(-900%);
    }
  }
}
</style>
