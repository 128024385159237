<template>
	<div class="complex-list-item-view" v-on="$listeners">
		<i class="icon" :class="item.icon"></i>
		<div class="label">{{item.label}}</div>
		<div class="actions">
			<div
				class="action"
				v-for="action of item.actions"
				:key="action.icon"
				@click.stop="action.click"
			><i :class="action.icon"></i></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ComplexListitem',
	props: ['item']
}
</script>


<style lang="scss">
.complex-list-item-view {
	display: flex;
	align-items: center;
	padding: 4px;
	cursor: pointer;

	&:hover, &[selected] {
		background: #ddd;
		color: #000;
	}

	&[selected] {
		cursor: initial;
	}

	.icon {
		font-size: 18px;
		flex: 0;
	}

	.label {
		flex: 1;
		font-size: 14px;
		padding-left: 8px;
	}

	.actions {
		font-size: 12px;

		.action {
			height: 24px;
			width: 24px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				color: #fff;
    			background: #000;
			}
		}
	}
}
</style>
