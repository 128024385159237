<template>
	<div class="fd-basic-config-view fd-layer-view">
		<h2 class="head">
			<span class="icon"><i class="stack3"></i></span>
			<span class="separator"></span>
			<span class="title">{{$_('fd.layer.headline')}}</span>
		</h2>
		<div class="body">

			<div
				class="module"
				v-for="module of modules"
				:key="module.mid"
				@mouseover="highlight(module.mid)"
				@mouseleave="highlight()"
				@click="setActiveModule(module)"
			>
				<div class="layer">{{module.z}}</div>
				<div class="icon"><i :class="module | meta | icon"></i></div>
				<component class="name" :is="module | description" :module="module"></component>
				<div class="actions">
					<div class="action"
						@click.stop.prevent="setLock(module)"
					>
						<i :class="module.locked ? 'lock4' : 'unlocked'"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as MODULES from '../modules/_MODULES_EXTRA.js';
import DescriptionView from './Description.vue';

export default {
	name: 'LayerView',
	data: () => ({}),
	filters: {
		meta: m => MODULES[m.class].meta,
		icon: m => m.icon,
		description: m => MODULES[m.class].description || DescriptionView
	},
	mounted() {
		this.modules.forEach(module => {
			if ((this.$store.state.type != 'pro' && module.class == 'WatermarkModule') || module.logo) module.locked = true;
		});
	},
	computed: {
		modules() {
			const groupModules = this.$store.state.data.modules
				.filter(m => m.class == 'GroupModule');

			return this.$store.state.data.modules
				.filter(m => groupModules.every(
					gm => !gm.props.childs.includes(m.mid)
				))
				.sort((a, b) => b.z - a.z);
		}
	},
	methods: {
		highlight(mid) {
			this.$store.commit('highlightedModules', mid ? [mid] : null);
		},
		setActiveModule(module) {
			if (module.locked == true && module.class != 'WatermarkModule') return;

			this.$store.commit('activeModules', [module.mid]);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', MODULES[module.class].ConfigView);
		},
		setLock(module) {
			if ((this.$store.state.type != 'pro' && module.class == 'WatermarkModule') || module.logo) return;

			if (module.locked == true) {
				module.locked = false;
			} else {
				module.locked = true;
			}

			this.$store.commit('dirty');
		}
	}
}
</script>

<style lang="scss">
.fd-layer-view {
	.body {
		border-bottom: 1px solid #333;
		.module {
			-moz-user-select: -moz-none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			display: flex;
			justify-content: space-between;
			margin: 0 -7px;
			padding: 5px 7px;
			height: 25px;
			font-size: 12px;
			cursor: pointer;

			&:hover {
				background: #eee;
				color: #000;
			}

			.layer {
				flex-basis: 27px;
				text-align: center;
				padding-right: 8px;
			}

			.icon {
				flex-basis: 23px;
				text-align: center;
			}

			.name {
				flex: 1;
				margin: 0 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>

<i18n lang="de_DE">
fd.layer.headline: Ebenen
</i18n>
