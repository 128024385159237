<template>
	<div class="fd-shadow-input">

		<boolean-input-view
			:config="powerSwitch"
			:value="powerSwitch.value"
			v-on="powerSwitch.events"
		></boolean-input-view>

	</div>
</template>

<script>
import BooleanInputView from './Boolean.vue';

export default {
	name: 'ShadowInput',
	props: ['value', 'config'],
	components: { BooleanInputView },
	computed: {
		powerSwitch() {
			return {
				type: 'Boolean',
				value: !!this.value.show,
				events: {
					input: val => this.emit('show')(val ? 1 : 0),
				},
			};
		},
		settings() {
			if (!this.value.show) {
				return null;
			}

			return [
				{
					type: 'Color',
					label: this.$_('fd.inputs.shadow.color'),
					value: this.value.color,
					events: {
						input: this.emit('color'),
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.inputs.shadow.width'),
					value: this.value.width,
					min: 0,
					max: 100,
					events: {
						input: this.emit('width'),
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.inputs.shadow.x'),
					value: this.value.x,
					min: -20,
					max: 20,
					events: {
						input: this.emit('x'),
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.inputs.shadow.y'),
					value: this.value.y,
					min: -20,
					max: 20,
					events: {
						input: this.emit('y'),
					},
				},
			];
		},
		showWatcher() {
			return this.value.show;
		},
	},
	watch: {
		showWatcher(show) {
			this.$emit('extra', this.settings);
		},
	},
	mounted() {
		this.$emit('extra', this.settings);
	},
	methods: {
		emit(key) {
			return value => {
				if (key === 'show' && value === 1 && this.value.width === 0) {
					this.$emit('input', {
						...this.value,
						['width']: 20,
						[key]: value,
					});
				} else {
					this.$emit('input', {
					...this.value,
						[key]: value,
					});
				}
			};
		},
	},
};
</script>

<style lang="scss">
.fd-shadow-input {
	width: 100%;
}
</style>

<i18n lang="de_DE">
fd.inputs.shadow.color: Farbe
fd.inputs.shadow.width: Breite
fd.inputs.shadow.x: Versatz X
fd.inputs.shadow.y: Versatz Y
</i18n>
