<template>
	<div class="fd-fade-animation" :style="style">
		<slot></slot>
	</div>
</template>

<script>
import { Tween, easing } from 'mo-js';

export default {
	name: 'Fade',
	icon: 'gradient',
	props: ['animation'],
	data() {
		return {
			opacity: 1,
			duration: 1,
			classList: ['animated'],
		};
	},
	computed: {
		style() {
			return {
				transition: `opacity ${this.duration}s ease-in-out`,
				opacity: this.opacity,
			};
		},
	},
	mounted() {
		if (typeof this.animation.direction == 'undefined') {
			this.animation.direction = 'in';
		}
		if (typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if (typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
		}

		if (this.animation.direction == 'in') {
			this.opacity = 0;
		} else {
			this.opacity = 1;
		}

		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => {
			if (index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
		this.$root.$on('showAnimatedModules', _ => (this.opacity = 1));
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				delay = this.animation.delay;
			} else {
				delay = 0;
			}
			if (this.animation.direction == 'in') {
				this.duration = 0;
				this.opacity = 0;
				setTimeout(() => {
					setTimeout(() => {
						this.duration = this.animation.duration;
						this.opacity = 1;
					}, delay * 1000);
				}, 10);
			} else {
				this.duration = 0;
				this.opacity = 1;
				setTimeout(() => {
					setTimeout(() => {
						this.duration = this.animation.duration;
						this.opacity = 0;
					}, delay * 1000);
				}, 10);
			}
		},

		stop() {
			this.duration = 0;
			this.opacity = 1;
		},

		update() {
			this.$root.$on('start' + this.animation.index, index => {
				if (index == this.animation.index) {
					this.start(false);
				}
			});
		},
	},
};
</script>
