<template>
	<div class="fd-pie-chart-module" :data-source="chart"></div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import Highcharts from 'highcharts';
import { compute as getColor } from '../styles/Color.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'PieChartModule',
	extends: BaseModule,
	normalize,
	data: () => ({ isMounted: false }),
	computed: {
		datasource() {
			const datasources = this.$store.state.shared.datasources;
			if (!this.module.props.datasource) {
				this.module.props.datasource = datasources[0].id;
			}
			
			return datasources.find(d => d.id == this.module.props.datasource);
		},
		datasources() {
			if (!this.datasource) return null;

			return this.$store.state.shared.datasources
				.filter(datasource => datasource.id.split('_')[0].includes(this.datasource.id.split('_')[0]))
				.filter(datasource =>
					['10', '11', '13', '14', '16', '17'].includes(datasource.type)
				)
				.reduce((datasources, datasource) => {
					return {
						...datasources,
						[datasource.type]: datasource,
					};
				}, {});
		},
		chart() {
			if (
				!this.isMounted ||
				!this.datasource ||
				!this.datasources ||
				this.$options.chart
			)
				return -1;

			this.$options.chart = Highcharts.chart(
				this.$el,
				Highcharts.merge(this.defaultChartOptions, this.chartOptions)
			);

			return this.$options.chart.index;
		},
		defaultChartOptions() {
			return {
				chart: {
					type: 'pie',
					reflow: false,
					backgroundColor: 'rgba(0,0,0,0)',
				},
				title: {
					text: null,
				},
				yAxis: {
					title: {
						text: null,
					},
				},
				legend: {
					enabled: false,
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					enabled: false,
				},
				plotOptions: {
					series: {
						animation: false,
						enableMouseTracking: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
					pie: {
						innerSize: this.module.props.innersize + '%',
						dataLabels: {
							enabled: this.module.props.enableLabels,
							format: '<b>{point.name}</b>: {point.percentage:.1f} %',
							style: {
								fontSize: this.module.props.fontsize + 'px',
							},
						},
					},
				},
			};
		},
		chartOptions() {
			return {
				series: [
					{
						data: this.highchartsData,
					},
				],
			};
		},
		highchartsData() {
			const getDayTotal = function(datasource) {
				if (!datasource) {
					throw new Error('Required datesource does not exist!');
				}

				return Object.values(datasource.data.daily).pop();
			};

			if (!this.datasource || !this.datasources) return [];

			try {
				const gridIn = getDayTotal(this.datasources[10]);
				const gridOut = getDayTotal(this.datasources[11]);
				const totalConsumption = getDayTotal(this.datasources[13]);
				const directConsumption = getDayTotal(this.datasources[14]);
				const batteryIn = getDayTotal(this.datasources[16]);
				const batteryOut = getDayTotal(this.datasources[17]);

				const totalGeneration = directConsumption + batteryIn + gridOut;

				// consumptionDirectPercentage
				const consumptionDirectPercentage =
					this.module.props.type == 0
						? (directConsumption / totalConsumption) * 100
						: (directConsumption / totalGeneration) * 100;

				// batteryOutPercentage / batteryInPercentage
				const batteryPercentage =
					this.module.props.type == 0
						? (batteryOut / totalConsumption) * 100
						: (batteryIn / totalGeneration) * 100;

				// gridInPercentage / gridOutPercentage
				const gridPercentage =
					this.module.props.type == 0
						? (gridIn / totalConsumption) * 100
						: (gridOut / totalGeneration) * 100;

				return [
					{
						name: this.module.props.consumptiondirectDesc,
						y: consumptionDirectPercentage,
						color: getColor(this.module.props.consumptiondirectColor).color,
					},
					{
						name:
							this.module.props.type == 0
								? this.module.props.batteryoutDesc
								: this.module.props.batteryinDesc,
						y: batteryPercentage,
						color: getColor(
							this.module.props.type == 0
								? this.module.props.batteryoutColor
								: this.module.props.batteryinColor
						).color,
					},
					{
						name:
							this.module.props.type == 0
								? this.module.props.gridinDesc
								: this.module.props.gridoutDesc,
						y: gridPercentage,
						color: getColor(
							this.module.props.type == 0
								? this.module.props.gridinColor
								: this.module.props.gridoutColor
						).color,
					},
				];
			} catch (err) {
				console.error(err);
				return [];
			}
		},
	},
	mounted() {
		Highcharts.setOptions({
			chart: {
				style: {
					fontFamily: this.module.props.font,
				},
			},
		});

		this.isMounted = true;
	},
	beforeDestroy() {
		if (typeof this.$option != 'undefined') {
			this.$options.chart.destroy();
			this.$options.chart = null;
		}
	},
};
</script>

<style>
.fd-chart-module {
	width: calc(100% + 7px) !important;
	height: calc(100% + 14px) !important;
	margin: 0 0 0 -7px;
}
</style>
