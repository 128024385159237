import { EXISTS_FILTER } from '../../util/Filter.js';
import {
	_getPosition,
	_deepCopy,
	_last,
	_getMin,
	_getAvg,
	_getMax,
	_isObject,
	_rotate,
	_setValue,
} from '../../util/helper.js';
import * as STYLES from '../../styles';
import * as MODULES from '../../modules/_MODULES_EXTRA.js';
import {
	getTemplate as getGroupModuleTemplate,
	ConfigView as GroupModuleConfigView
} from '../../modules/GroupModuleExtra.vue';
import AnimationSettingsView from '../../components/AnimationSetting.vue';

const state = {
	activeModules: [],
	activeAnimation: [],
	activeAnimationIndex: [],
	highlightedModules: [],
	history: [],
	historyState: null,
	dirty: false,
	fullscreen: false,
}

function init() {
	return {
		...state
	}
}

export async function create() {
	return {
		root: true,

		state: init(),

		getters: {
			activeModules(state) {
				return state.activeModules
					.map(mid =>
						state.data.modules.find(m => m.mid == mid)
					)
					.filter(EXISTS_FILTER);
			},
			modules(state) {
				return state.data.modules.reduce((modules, m) => {
					modules[m.mid] = m;
					return modules;
				}, {});
			}
		},

		mutations: {
			moduleMoveStart() {},
			moduleMove() {},
			moduleMoveEnd() {},

			clean(state) {
				state.dirty = false;
			},
			dirty(state) {
				state.dirty = true;
			},
			fullscreen(state, val) {
				state.fullscreen = val;
			},
			activeModules(state, activeModules) {
				if(_isObject(activeModules)) {
					activeModules = activeModules.modules;
				}
				if(!activeModules) {
					state.activeModules = [];
				}
				else if(activeModules instanceof Array) {
					state.activeModules = activeModules;
				}
				else {
					if(state.activeModules.includes(activeModules)) {
						state.activeModules.splice(state.activeModules.indexOf(activeModules), 1);
					}
					else {
						state.activeModules.push(activeModules);
					}
				}
			},
			activeAnimation(state, activeAnimation) {
				state.activeAnimation = activeAnimation;
			},
			activeAnimationIndex(state, activeAnimationIndex) {
				state.activeAnimationIndex = activeAnimationIndex;
			},
			highlightedModules(state, highlightedModules) {
				if(_isObject(highlightedModules)) {
					highlightedModules = highlightedModules.modules;
				}
				if(!highlightedModules) {
					state.highlightedModules = [];
				}
				else if(highlightedModules instanceof Array) {
					state.highlightedModules = highlightedModules;
				}
				else {
					if(!state.highlightedModules.includes(highlightedModules)) {
						state.highlightedModules.push(highlightedModules);
					}
				}
			},
			changeModules: function changeModules(state, operation) {

				const moduleIndices = operation.modules.map(mid => {
					return state.data.modules.findIndex(m => m.mid == mid);
				});

				moduleIndices.forEach(index => {
					for(const key in operation.values) {
						switch(operation.type) {
							case 'offset':
								if(key === 'x' || key === 'y') {
									const position = _getPosition(
										state.data.modules[index],
										state.screensize
									);

									state.data.modules[index][key] = position[key];
								}
								state.data.modules[index][key] += operation.values[key];
								break;

							case 'style':
								const additionalOperation =
									STYLES[key].watchHook && STYLES[key].watchHook(
										operation.values[key],
										state.data.modules[index].style[key],
										// state.data.modules[index] // current module
									);

								state.data.modules[index].style[key] = operation.values[key];

								additionalOperation && changeModules(state, {
									...additionalOperation,
									modules: [state.data.modules[index].mid]
								});

								break;

							case 'props':
								_setValue({
									obj: state.data.modules[index].props,
									key: key,
									val: operation.values[key],
								});
								break;

							case 'set':
							default:
								state.data.modules[index][key] = operation.values[key];
								break;
						}
					}
				});

				if(typeof state.lastKey != 'object') state.lastKey = new Object();
				if(operation.type == 'set') operation.type = 'offset';

				if((operation.type != state.lastOperation || Object.keys(operation.values)[0] != Object.keys(state.lastKey)[0]) && operation.type != 'animation') {
					let save = false;
					if(operation.type == 'offset' && operation.type == state.lastOperation && operation.auto !== true) {
						// Needed because of every move makes one trigger
						state.history[state.history.length - 1] = {
							action: operation.type,
							content: _deepCopy(state.data)
						};
						state.dirty = true;
						return;
					}
					moduleIndices.forEach(index => {
						if(!(operation.type == 'set' && state.data.modules[index].width == operation.values.width && state.data.modules[index].height == operation.values.height)) {
							save = true;
						}
					});
					if(save) {
						if (operation.type == 'offset') {
							if (operation.auto !== true) {
								this.commit("setHistory", operation.type);
							}
						} else {
							this.commit("setHistory", operation.type);
						}
						state.lastOperation = operation.type;
						state.lastKey = operation.values;
						state.dirty = true;
					}
				} else if(operation.type == 'offset' && operation.auto !== true) {
					// Needed because of every move makes one trigger
					state.history[state.history.length - 1] = {
						action: operation.type,
						content: _deepCopy(state.data)
					};
				}
			},
			deleteModules(state, modules) {
				modules.forEach(a => {
					state.data.modules.forEach(module => {
						if (a == module.mid) {
							if (module.locked == true && a != '_m:1') {
								return;
							}
							if(!(state.type != 'pro' && a == '_m:1') || state.type == 'logo') {
								const index = state.data.modules.findIndex(b => a == b.mid);
								state.data.modules.splice(index, 1);

								this.commit("setHistory", "Delete");

							}
						}
					});
				});

				state.dirty = true;
			},
			changeBackground(state, values) {

				for(const key in values) {
					state.data.background[key] = values[key];
				}

				this.commit("setHistory", "Change Background");

				state.dirty = true;
			},
			setTitle(state, title) {
				state.data.title = title;
			},
			setLogo(state, show) {
				state.data.logo = show.logo;
				state.dirty = true;
			},
			setHistory(state, action) {
				if (state.historyState !== null) {
					state.history.length = state.historyState + 1;
					state.historyState = null;
				}
				if (state.history.length > 20) {
					state.history.shift();
				}
				state.history.push({
					action: action,
					content: _deepCopy(state.data),
				});
			}
		},

		actions: {
			centerModule({ commit, state }, module) {
				module = state.data.modules.find(m => m.mid == module);

				const position = {
					x: (state.screensize.width - module.width) / 2,
					y: (state.screensize.height - module.height) / 2,
				}

				commit('changeModules', {
					type: 'set',
					modules: [module.mid],
					values: position
				});
			},
			deleteModules({ commit, dispatch, state }, modules) {
				if(!modules) {
					modules = state.activeModules;
				}

				commit('deleteModules', modules);
				commit('activeModules', []);
				commit('highlightedModules', []);
				dispatch('subNav/components', null);
			},
			copy({ state }, modules) {

				if(!modules) {
					modules = state.activeModules;
				}

				modules = modules
					.map(mid => _deepCopy(
						state.data.modules.find(m => m.mid == mid)
					))
					.map(copy => {
						delete copy.mid;
						delete copy.z;

						return copy;
					});

				modules = JSON.stringify(modules);

				localStorage.setItem('clipboard', modules);

				if(!navigator.clipboard) return;

				navigator.clipboard.writeText(modules);
			},
			deleteAnimation({ state, dispatch, commit, root }, module) {
				dispatch('subNav/components', null);

				module.animation.splice(state.activeAnimationIndex, 1);

				state.dirty = true;

				state.data.modules.forEach(module => {
					if(module.class != 'WatermarkModule') {
						module.animation.forEach((animation, index) => {
							if(animation.index > state.activeAnimation) {
								animation.index--;
								module.animation[index] = animation;
								const type = 'animation';
								commit('changeModules', {
									type,
									modules: [module.mid],
									values: { 'animation': module.animation }
								});
							}
						});
					}
				});

				this.commit("setHistory", "animation-delete");
			},
			async paste({ commit, dispatch, state }) {
				let modules = [];

				try {
					if(navigator.permissions && navigator.clipboard) {
						modules = await navigator.permissions
							.query({ name: 'clipboard-read' })
							.then(() => navigator.clipboard.readText())
							.then(txt => JSON.parse(txt));
					}
				}
				catch(_) {}

				try {
					if(!modules.length) {
						modules = JSON.parse(localStorage.getItem('clipboard'));
					}
				}
				catch(_) {}
				let highestIndex = 0;
				state.data.modules.forEach(module => {
					module.animation.forEach(animation => {
						highestIndex++;
					});
				});

				if(!modules.length) return;

				const clazz = modules[0].class;

				modules[0].x += 25;
				modules[0].y += 25;
				modules.forEach(module => {
					module.animation.forEach(animation => {
						highestIndex++;
						animation.index = highestIndex;
					});
				});

				modules = await Promise.all(modules.map(copy => {
					return dispatch('data/module', copy);
				}));

				commit('dirty');
				commit('activeModules', modules);
				commit('highlightedModules', null);
				dispatch('subNav/components',
					state.subNav.fixed && modules.length === 1
						? MODULES[clazz].ConfigView
						: null
				);

				this.commit("setHistory", "Insert");

				return modules;
			},
			async duplicateModules({ commit, dispatch, state }, modules) {

				if(!modules) {
					modules = state.activeModules;
				}

				modules = await Promise.all(modules
					.map(mid => _deepCopy(
						state.data.modules.find(m => m.mid == mid)
					))
					.map(copy => {
						const position = _getPosition(
							copy,
							state.screensize
						);

						copy.x = position.x + 20;
						copy.y = position.y + 20;

						delete copy.mid;
						delete copy.z;

						return copy;
					})
					.map(copy => {
						return dispatch('data/module', copy);
					})
				);

				commit('dirty');
				commit('activeModules', modules);
				commit('highlightedModules', null);

				this.commit("setHistory", "Duplicate");

				return modules;
			},
			editModule({ dispatch, getters }) {
				if(getters.activeModules.length !== 1) return;

				dispatch(
					'subNav/components',
					MODULES[getters.activeModules[0].class].ConfigView
					);
				},
			editAnimation({ dispatch, getters }) {
				if(getters.activeModules.length !== 1) return;

				dispatch(
					'subNav/components',
					AnimationSettingsView
				);
			},
			alignLeft({ commit, getters, state }) {
				let x = 0;

				if(getters.activeModules.length > 1) {
					x = _getMin(getters.activeModules, m => m.x)
				}

				commit('changeModules', {
					type: 'set',
					modules: state.activeModules,
					values: { x }
				});

				this.commit("setHistory", "Align left");
			},
			alignCenterHorizontal({ commit, getters, state }) {
				let x = state.screensize.width / 2;

				if(getters.activeModules.length > 1) {
					x = _getAvg(getters.activeModules, m => m.x + m.width / 2);
				}

				getters.activeModules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { x: x - m.width / 2 }
					});
				});

				this.commit("setHistory", "Align center horizontal");
			},
			alignRight({ commit, getters, state }) {
				let x = state.screensize.width;

				if(getters.activeModules.length > 1) {
					x = _getMax(getters.activeModules, m => m.x + m.width)
				}

				getters.activeModules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { x: x - m.width }
					});
				});

				this.commit("setHistory", "Align right");
			},
			alignTop({ commit, getters, state }) {
				let y = 0;

				if(getters.activeModules.length > 1) {
					y = _getMin(getters.activeModules, m => m.y)
				}

				commit('changeModules', {
					type: 'set',
					modules: state.activeModules,
					values: { y }
				});

				this.commit("setHistory", "Align top");
			},
			alignCenterVertical({ commit, getters, state }) {
				let y = state.screensize.height / 2;

				if(getters.activeModules.length > 1) {
					y = _getAvg(getters.activeModules, m => m.y + m.height / 2);
				}

				getters.activeModules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { y: y - m.height / 2 }
					});
				});

				this.commit("setHistory", "Align center vertical");
			},
			alignBottom({ commit, getters, state }) {
				let y = state.screensize.height;

				if(getters.activeModules.length > 1) {
					y = _getMax(getters.activeModules, m => m.y + m.height)
				}

				getters.activeModules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { y: y - m.height }
					});
				});

				this.commit("setHistory", "Align bottom");
			},
			spreadHorizontal({ commit, getters, state }) {
				let modules = [...getters.activeModules];

				modules.sort((a, b) => (a.x + a.width / 2) - (b.x + b.width / 2));

				let first = modules.shift();
				let last = modules.pop();

				// Calc free space between every module
				let space = last.x - (first.x + first.width);
				modules.forEach(m => {
					space -= m.width;
				});
				space /= modules.length + 1;

				// Loop through every module and set new x
				let x = first.x + first.width + space;
				modules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { x }
					});

					x += m.width + space;
				});

				this.commit("setHistory", "Spread horizontal");
			},
			spreadVertical({ commit, getters, state }) {
				let modules = [...getters.activeModules];

				modules.sort((a, b) => (a.y + a.height / 2) - (b.y + b.height / 2));

				let first = modules.shift();
				let last = modules.pop();

				// Calc free space between every module
				let space = last.y - (first.y + first.height);
				modules.forEach(m => {
					space -= m.height;
				});
				space /= modules.length + 1;

				// Loop through every module and set new y
				let y = first.y + first.height + space;
				modules.forEach(m => {
					commit('changeModules', {
						type: 'set',
						modules: [m.mid],
						values: { y }
					});

					y += m.height + space;
				});

				this.commit("setHistory", "Spread vertical");
			},

			async group({ commit, dispatch, state }, modules) {
				if(!modules) {
					modules = state.activeModules;
				}

				let groupModule = await getGroupModuleTemplate();

				groupModule.x = Infinity;
				groupModule.y = Infinity;
				groupModule.width = 0;
				groupModule.height = 0;
				groupModule.props.childs = modules;

				modules
					.map(mid => state.data.modules.find(m => m.mid == mid))
					.filter(EXISTS_FILTER)
					.map(m => {
						if(m.x < groupModule.x) groupModule.x = m.x;
						if(m.y < groupModule.y) groupModule.y = m.y;

						return m;
					})
					.forEach(m => {
						if(m.width + m.x > groupModule.width + groupModule.x) {
							groupModule.width = m.width + m.x - groupModule.x;
						}
						if(m.height + m.y > groupModule.height + groupModule.y) {
							groupModule.height = m.height + m.y - groupModule.y;
						}
						m.isGroup = true;
					});

				const mid = await dispatch('data/module', groupModule);

				commit('activeModules', [mid]);
				commit('highlightedModules', []);
				dispatch('subNav/components',
					state.subNav.fixed ? GroupModuleConfigView : null
				);

				this.commit("setHistory", "Group");

				return mid;
			},

			ungroup({ commit, dispatch, state }, module) {
				let activeModule = null;
				if(typeof module == 'undefined') {
					activeModule = state.activeModules[0];
				} else {
					activeModule = module;
				}
				const childs = state.data.modules
					.find(m => m.mid == activeModule)
					.props.childs;

				const tempHist = _deepCopy(state.history);

				commit('changeModules', {
					type: 'props',
					modules: Array.isArray(activeModule) ? activeModule : [activeModule],
					values: {
						childs: []
					}
				});
				dispatch('deleteModules');
				commit('activeModules', childs);

				if(typeof childs != 'undefined') {
					childs.forEach(child => {
						state.data.modules.forEach(module => {
							if(child == module.mid) {
								module.isGroup = false;
							}
						});
					});
				}

				state.history = tempHist;
				if(typeof module == 'undefined') {
					this.commit("setHistory", "Ungroup");
				}
			},

			markAll({ commit, state }) {
				let activeModules = new Array();

				state.data.modules.forEach(module => {
					if(module.class != 'WatermarkModule' && !module.logo) {
						activeModules.push(module.mid);
					}
				})
				commit('activeModules', activeModules);
			},

			getModulesInRect({ state }, {x, y, x2, y2}) {
				return state.data.modules
					.filter(m => !m.props.locked && !m.logo)
					.map(({ x, y, r, width, height, ...module }) => ({
						...module,
						..._rotate(r, x, y, width, height),
					}))
					.filter(m => {
						// check if module is between start and end
						//  min                 value                  max
						return (
							(x   <=            m.x && m.x            <= x2            ) ||
							(x   <=  m.x + m.width && m.x + m.width  <= x2            ) ||
							(m.x <=              x && x              <= m.x + m.width ) ||
							(m.x <=             x2 && x2             <= m.x + m.width )
						) && (
							(y   <=            m.y && m.y            <= y2            ) ||
							(y   <= m.y + m.height && m.y + m.height <= y2            ) ||
							(m.y <=              y && y              <= m.y + m.height) ||
							(m.y <=             y2 && y2             <= m.y + m.height)
						)
					});
			},

			async arrangeForward({ commit, dispatch, getters, state }) {
				const activeModule = getters.activeModules[0];
				const modules = await dispatch('getModulesInRect', {
					x: activeModule.x,
					y: activeModule.y,
					x2: activeModule.x + activeModule.width,
					y2: activeModule.y + activeModule.height,
				});

				// Get the next element above
				const stackabove = modules.filter((m) => m.z > activeModule.z);
				stackabove.sort((a, b) => a.z - b.z);
				const above = stackabove.shift();

				if(!above) return;

				// Switch z between active module and above
				const newActiveZ = above.z;
				const newAboveZ = activeModule.z;

				commit('changeModules', {
					type: 'set',
					modules: [activeModule.mid],
					values: {
						z: newActiveZ
					}
				});
				commit('changeModules', {
					type: 'set',
					modules: [above.mid],
					values: {
						z: newAboveZ
					}
				});

				this.commit("setHistory", "Arrange forward");
			},
			async arrangeBehind({ commit, dispatch, getters, state }) {
				const activeModule = getters.activeModules[0];
				const modules = await dispatch('getModulesInRect', {
					x: activeModule.x,
					y: activeModule.y,
					x2: activeModule.x + activeModule.width,
					y2: activeModule.y + activeModule.height,
				});

				// Get the next element below
				const stackbelow = modules.filter((m) => m.z < activeModule.z);
				stackbelow.sort((a, b) => a.z - b.z);
				const below = stackbelow.pop();

				if(!below) return;

				// Switch z between active module and below
				const newActiveZ = below.z;
				const newBelowZ = activeModule.z;

				commit('changeModules', {
					type: 'set',
					modules: [activeModule.mid],
					values: {
						z: newActiveZ
					}
				});
				commit('changeModules', {
					type: 'set',
					modules: [below.mid],
					values: {
						z: newBelowZ
					}
				});

				this.commit("setHistory", "Arrange behind");
			},
		}
	}
}
