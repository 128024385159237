<template>
	<window-view class="fd-history-window-view"
		:id="id"
		icon="history"
		:title="$_('fd.historywindow.title')"
		:width="310"
		:height="null"
		:resizeable="false"
	>
		<button :key="index" class="fd-history-window-button" v-for="(value, index) in $store.state.history"
		@click="setHistoryContent(value, index)"
		>
			<div class="fd-history-window-entry">
				<i :class="icon1(value.action)"></i>
				<i :class="icon2(value.action)"></i>
			</div>
		</button>

	</window-view>
</template>

<script>
import WindowView from './Window.vue';
import ToolbarItemView from '../ToolbarItem.vue';
import { _deepCopy } from '../../util/helper.js';

export default {
	name: 'HistoryWindowView',
	props: ['id'],
	components: { WindowView, ToolbarItemView },
	mounted() {
			this.Interval = setInterval(() => {
				this.$forceUpdate();
			}, 500);
	},
	beforeDestroy() {
		clearInterval(this.Interval);
	},
	methods: {
		setHistoryContent(content, index) {
			this.$store.dispatch('subNav/components', null);
			this.$store.state.historyState = index;
			this.$store.state.setHist = true;
			if(content.action != "Group") {
				let ungroup = false;
				this.$store.state.data.modules.forEach(module => {
					if(module.class == "GroupModule" ) {
						const childs = this.$store.state.data.modules
							.find(m => m.mid == module.mid)
							.props.childs;

						childs.forEach(child => {
							content.content.modules.forEach(module => {
								if(child == module.mid && module.isGroup === false) {
									ungroup = true;
								}
							});
						});
						if(ungroup) {
							this.$store.dispatch('ungroup', module.mid);
						}
					}
				});
			}
			this.$store.dispatch('data/replace', _deepCopy(content.content));
			setTimeout(() => {
				this.$store.state.setHist = false;
			}, 50);
		},
		icon1(value) {
			switch (value) {
				case 'Add Module':
				case 'Delete':
				case 'props':
					return 'file-empty';
					break;
				case 'animation-add':
				case 'animation-change':
				case 'animation-delete':
					return 'clapboard';
					break;
				case 'offset':
				case 'set':
					return 'move';
					break;
				case 'Begin':
					return 'play3';
					break;
				case 'Change Background':
				case 'Insert':
				case 'Align left':
				case 'Align center horizontal':
				case 'Align right':
				case 'Align top':
				case 'Align center vertical':
				case 'Align bottom':
				case 'Spread horizontal':
				case 'Spread vertical':
				case 'Group':
				case 'Ungroup':
				case 'Arrange forward':
				case 'Arrange behind':
				case 'Duplicate':
					return '';
					break;
				default:
					return 'pencil-ruler'
					break;
			}
		},
		icon2(value) {
			switch (value) {
				case 'animation-add':
				case 'Add Module':
				case 'Insert':
					return 'plus3';
					break;
				case 'animation-change':
				case 'props':
				case 'Change Background':
					return 'pencil';
					break;
				case 'animation-delete':
				case 'Delete':
					return 'minus3';
					break;
				case 'Duplicate':
					return 'copy4';
					break;
				case 'Align left':
					return 'align-left';
					break;
				case 'Align center horizontal':
					return 'align-center-horizontal';
					break;
				case 'Align right':
					return 'align-right';
					break;
				case 'Align top':
					return 'align-top';
					break;
				case 'Align center vertical':
					return 'align-center-vertical';
					break;
				case 'Align bottom':
					return 'align-bottom';
					break;
				case 'Spread horizontal':
					return 'spread-horizontal';
					break;
				case 'Spread vertical':
					return 'spread-vertical';
					break;
				case 'Group':
					return 'make-group';
					break;
				case 'Ungroup':
					return 'ungroup';
					break;
				case 'Arrange forward':
					return 'arrow-up6';
					break;
				case 'Arrange behind':
					return 'arrow-down6';
					break;
				case 'offset':
				case 'set':
				case 'Begin':
					return '';
					break;
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../scss/TextModuleInnerStyle.scss';

.fd-history-window-view {
	background: rgba(0, 0, 0, .8);


	.body {
		display: grid;
		overflow-y: scroll;
		grid-template-columns: repeat(5, 1fr);
    margin-bottom: 5px;
    max-height: 350px;
	}

	.fd-history-window-button {
		height: 50px;
		width: 50px;
		position: relative;
    margin: 5px 5px 0;
    padding: 5px;
    background: #222;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
	}

	.fd-history-window-entry {
    font-size: 16px;
	}

}
</style>

<i18n lang="de_DE">
fd.historywindow.title: History
</i18n>
