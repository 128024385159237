<script>
import RssModule from './RssModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
  name: 'RssModule',
  icon: 'feed2',
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
  return {		
    animation: [],
    locked: false,
		class: 'RssModule',
		x: 'center',
		y: 'center',
		width: 800,
    height: 60,
    style: {
      fontFamily: null,
      fontSize: 36,
      color: null,
      lineHeight: null,
      backgroundColor: null,
      padding: null,
      border: null,
      shadow: null,
      opacity: null,
      advanced: null,
    },
    props: {
      link: '',
      limit: 10,
      sliderState: true,
      showImages: false,
      speedMultiplicator: 50,
    },
	}
}

export const description = {
  extends: DescriptionView,
  data() {
    return {
      description: this.module.props.link.replace('http://', '')
    }
  }
}

export const ConfigView = {
  name: 'RssModuleConfigView',
  extends: BasicConfigView,
  data: () => ({ meta }),
  computed: {
    config() {
      return [{
        type: 'Input',
        label: this.$_('fd.rssmodule.settings.content'),
        value: this.module.props.link,
        events: {
          input: this.setLink
        }
      },{
        type: 'Number',
        label: this.$_('fd.rssmodule.settings.limit'),
        value: this.module.props.limit,
        events: {
          input: this.setLimit
        }
      },{
        type: 'Boolean',
        label: this.$_('fd.rssmodule.settings.slider'),
        value: this.module.props.sliderState,
        events: {
          input: this.setSliderState
        }
      },{
        type: 'Boolean',
        label: this.$_('fd.rssmodule.settings.showImages'),
        value: this.module.props.showImages,
        events: {
          input: this.setImageStatus
        }
      },{
        type: 'Number',
        label: this.$_('fd.rssmodule.settings.speed'),
        value: this.module.props.speedMultiplicator,
        min: 1,
        max: 100,
        events: {
          input: this.setSpeed
        }
      }];
    }
  },
  methods: {
    setLink(link) {},
    setLimit(limit) {},
    setSliderState(sliderState) {},
    setImageStatus(status) {},
    setSpeed(speed) {},
  }
}

export default {
	extends: RssModule,

  // extra
  meta,
	getTemplate,
  description,
  ConfigView,
}
</script>

<i18n lang="de_DE">
fd.rssmodule.settings.content: RSS Feed URL
fd.rssmodule.settings.limit: Max. Einträge
fd.rssmodule.settings.slider: Liveticker
fd.rssmodule.settings.showImages: Bilder anzeigen
fd.rssmodule.settings.speed: Geschwindigkeit

fd.rssmodule.message.feederror: Fehler beim Laden!
fd.rssmodule.message.feedloading: Lade den Feed...
</i18n>
