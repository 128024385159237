<template>
	<div class="fd-toolbar-view">
		<toolbar-item-view
			:icon="$store.state.fullscreen ? 'shrink7' : 'enlarge7'"
			:text="getText('fullscreen')"
			@click="toggleFullscreen"
			:active="this.active"
			@mouseover="setActive($store.state.fullscreen ? 'shrink7' : 'enlarge7')"
			@mouseleave="setActive(false)"
		/>

		<div class="separator"></div>

		<!-- Overall options -->
		<toolbar-item-view icon="undo2"
			:text="getText('undo2')"
			:disabled="$store.state.historyState == 0 || $store.state.history.length <= 1"
			@click="historyBack"
			:active="this.active"
			@mouseover="setActive('undo2')"
			@mouseleave="setActive(false)"
		/>
		<toolbar-item-view icon="redo2"
			:text="getText('redo2')"
			:disabled="$store.state.historyState == $store.state.history.length - 1 || $store.state.historyState == null"
			@click="historyForward"
			:active="this.active"
			@mouseover="setActive('redo2')"
			@mouseleave="setActive(false)"
		/>
		<toolbar-item-view icon="history"
			:text="getText('history')"
			@click="openHistoryWindow"
			:active="this.active"
			@mouseover="setActive('history')"
			@mouseleave="setActive(false)"
		/>

		<div class="separator"></div>

		<toolbar-item-view icon="align-left"
			:text="getText('align-left')"
			@click="openAlignWindow"
			:active="this.active"
			@mouseover="setActive('align-left')"
			@mouseleave="setActive(false)"
		/>

		<div class="separator"></div>

		<!-- Single module options -->
		<toolbar-item-view icon="wrench"
			:text="getText('wrench')"
			:disabled="!isActiveModule"
			@click="edit"
			:active="this.active"
			@mouseover="setActive('wrench')"
			@mouseleave="setActive(false)"
		/>
		<toolbar-item-view icon="copy3"
			:text="getText('copy3')"
			:disabled="!isActiveModules"
			@click="duplicate"
			:active="this.active"
			@mouseover="setActive('copy3')"
			@mouseleave="setActive(false)"
		/>
		<toolbar-item-view icon="bin"
			:text="getText('bin')"
			:disabled="!isActiveModules"
			@click="remove"
			:active="this.active"
			@mouseover="setActive('bin')"
			@mouseleave="setActive(false)"
		/>

		<div class="separator"></div>

		<!-- Multi module options -->
		<toolbar-item-view icon="make-group"
			:text="getText('make-group')"
			v-show="!isGroupModule"
			:disabled="!isGroupable"
			@click="group"
			:active="this.active"
			@mouseover="setActive('make-group')"
			@mouseleave="setActive(false)"
		/>
		<toolbar-item-view icon="ungroup"
			:text="getText('ungroup')"
			v-show="isGroupModule"
			@click="ungroup"
			:active="this.active"
			@mouseover="setActive('ungroup')"
			@mouseleave="setActive(false)"
		/>

	</div>
</template>

<script>
import ToolbarItemView from './ToolbarItem.vue';
import * as MODULES from '../modules/_MODULES_EXTRA.js';
import { _last } from '../util/helper.js';
import AlignWindow from './Window/AlignWindow.vue';
import HistoryWindow from './Window/HistoryWindow.vue';

export default {
	name: 'ToolbarView',
	components: { ToolbarItemView },
	data() {
		return {
			active: false
		};
	},
	computed: {
		activeModules() {
			return this.$store.getters.activeModules;
		},
		isGroupModule() {
			return (
				this.activeModules.length == 1
				&&
				this.activeModules[0].class == 'GroupModule'
			)
		},
		isGroupable() {
			return (
				this.activeModules.length > 1
				&&
				this.activeModules.every(m => m.class != 'GroupModule')
			)
		},
		isActiveModule() {
			return this.activeModules.length == 1;
		},
		isActiveModules() {
			return this.activeModules.length > 0;
		},
		windows() {
			return this.$store.state.window.windows;
		},
		alignWindowOpen() {
			const index = this.windows.findIndex(
				window => window.id === 'window:align'
			);

			return index >= 0;
		},
		historyWindowOpen() {
			const index = this.windows.findIndex(
				window => window.id === 'window:history'
			);

			return index >= 0;
		}
	},
	methods: {
		toggleFullscreen() {
			this.$root.$emit('fullscreen');
		},
		historyBack() {
			this.$root.$emit('historyBack');
		},
		historyForward() {
			this.$root.$emit('historyForward');
		},
		openHistoryWindow() {
			if(this.historyWindowOpen) {
				return this.$store.commit('window/close', 'window:history');
			}
			this.$store.commit('window/create', {
				id: 'window:history',
				component: HistoryWindow
			});
		},
		edit() {
			this.$store.dispatch('editModule');
		},
		duplicate() {
			let duplicate = true;
			this.activeModules.forEach(module => {
				if(module.class == "WatermarkModule") {
					duplicate = false;
				}
			});
			if(duplicate) {
				this.$store.dispatch('duplicateModules');
				setTimeout(() => {
					this.$root.$emit('initModuleSnapping');
				}, 10);
			}
		},
		remove() {
			let remove = true;
			this.activeModules.forEach(module => {
				if(module.class == "WatermarkModule" && this.$store.state.type != 'pro') {
					remove = false;
				}
			});
			if(remove) {
				this.$store.dispatch('deleteModules');
			}
		},
		openAlignWindow() {
			if(this.alignWindowOpen) {
				return this.$store.commit('window/close', 'window:align');
			}
			this.$store.commit('window/create', {
				id: 'window:align',
				component: AlignWindow
			});
		},
		group() {
			this.$store.dispatch('group');
		},
		ungroup() {
			this.$store.dispatch('ungroup');
		},
		setActive(active) {
			this.active = active;
		},
		getText(button) {
			switch(button) {
				case 'fullscreen':
					return this.$_('fd.context.fullscreen');
					break;
				case 'undo2':
					return this.$_('fd.context.history.back');
					break;
				case 'redo2':
					return this.$_('fd.animation.custom.settings.repeat');
					break;
				case 'history':
					return this.$_('fd.historywindow.title');
					break;
				case 'align-left':
					return this.$_('fd.alignwindow.title');
					break;
				case 'wrench':
					return this.$_('fd.context.toolsettingsbutton');
					break;
				case 'copy3':
					return this.$_('fd.module.settings.duplicate');
					break;
				case 'bin':
					return this.$_('fd.module.settings.delete');
					break;
				case 'make-group':
					return this.$_('fd.context.group');
					break;
				case 'ungroup':
					return this.$_('fd.context.ungroup');
					break;
			}
		}
	},
};
</script>

<style lang="scss">
.fd-toolbar-view {
	background: black;

	display: flex;
	flex-direction: column;

	.separator {
		height: 1px;
		margin: 5px 6px;
		background: #333;
	}
}
</style>
