export default class Grid {

  constructor(options = {}) {
    this.rows = options.rows || 0;
    this.cols = options.cols || 0;

    this.space = {
      around:  0,
      between: 0
    }

    if(options.space) {
      this.space.around  = (options.space.around  || options.space) / 100;
      this.space.between = (options.space.between || options.space) / 100;
    }

    this.ratio = options.ratio || 1;

    this.elements = this.prepare(options.elements);
  }

  prepare(elements) {
    const xSpaceA = this.space.around;
    const xSpaceB = this.space.between;

    // Use horizontal and vertical the same sapce
    const ySpaceA = this.space.around * this.ratio;
    const ySpaceB = this.space.between * this.ratio;

    const xSpace = xSpaceA * 2 + xSpaceB * (this.cols - 1);
    const ySpace = ySpaceA * 2 + ySpaceB * (this.rows - 1);

    const col = (1 - xSpace) / this.cols;
    const row = (1 - ySpace) / this.rows;

    return elements.map(element => {
      return {
        x: xSpaceA + element.x * (col + xSpaceB),
        y: ySpaceA + element.y * (row + ySpaceB),
        w: element.w * col + (element.w - 1) * xSpaceB,
        h: element.h * row + (element.h - 1) * ySpaceB
      };
    });
  }

  draw(canvas, options = {}) {
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = options.color || 'white';

    if(options.style != 'thumbnail') {
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'destination-out';
    }

    this.elements.forEach(element => {
      ctx.fillRect(
        element.x * canvas.width,
        element.y * canvas.height,
        element.w * canvas.width,
        element.h * canvas.height
      );
    });

    return this;
  }
}
