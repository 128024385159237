<script>
import MeterModule from './MeterModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'MeterModule',
	icon: 'meter-slow',
	resize: 2,
	datasource: true
}

export const MetaDescriptionView = {
	name: 'MeterMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	const datasources = $store.state.shared.datasources;
	const datasource = datasources.length ? {
		id: datasources[0].id
	} : null;

	return {
		animation: [],
		locked: false,
		class: 'MeterModule',
		x: 'center',
		y: 'center',
		width: 500,
		height: 250,
		style: {
			backgroundColor: {
				r: 0,
				g: 0,
				b: 0,
				a: 0
			},
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			datasources: datasource ? [datasource] : [],
			tachoDesign: 'default',
			tachoStyle: 'current',
			color: {
				r: 242,
				g: 174,
				b: 0,
				a: 100
			},
			fontColor: {
				default: {
					r: 255,
					g: 255,
					b: 255,
					a: 50
				},
				ov: {
					r: 0,
					g: 0,
					b: 0,
					a: 100
				}
			},
			skala: false
		},
	}
}

export const ConfigView = {
	name: 'MeterModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		datasources() {
			return this.$store.state.shared.datasources;
		},
		config() {
			const datasourceSettings = [];

			const datasources = this.datasources.map(d => ({
				label: d.name,
				value: d.id,
			}));

			this.module.props.datasources.forEach((datasource, index) => {
				const n = this.module.props.datasources.length > 1
					? ' ' + (index + 1)
					: '';

				datasourceSettings.push({
					type: 'Select',
					label: this.$_('fd.chartmodule.settings.datasource') + n,
					value: datasource.id,
					values: datasources,
					events: {
						input: this.setDatasource(index)
					}
				});
			});

			return [ ...datasourceSettings, {
				type: 'Button',
				label: this.$_('fd.chartmodule.settings.add.datasource'),
				events: {
					click: this.addDatasource
				}
			}, {
				type: 'Button',
				label: this.$_('fd.chartmodule.settings.remove.datasource'),
				visible: this.module.props.datasources.length > 1,
				events: {
					click: this.removeDatasource
				}
			}, {
				type: 'Select',
				label: this.$_('fd.metermodule.settings.tacho.design'),
				value: this.module.props.tachoDesign,
				values: [{
						label: this.$_('fd.metermodule.settings.tacho.design.default'),
						value: 'default'
					}, {
						label: this.$_('fd.metermodule.settings.tacho.design.ov'),
						value: 'ov'
					}],
				events: {
					input: this.setTachoDesign,
				}
			}, {
				type: 'Select',
				label: this.$_('fd.metermodule.settings.tacho.style'),
				value: this.module.props.tachoStyle,
				values: [{
						label: this.$_('fd.metermodule.settings.tacho.style.current'),
						value: 'current',
					}, {
						label: this.$_('fd.metermodule.settings.tacho.style.dailyincome'),
						value: 'dailyincome',
					}, {
						label: this.$_('fd.metermodule.settings.tacho.style.dailyset'),
						value: 'dailyset',
					}],
				events: {
					input: this.setTachoStyle
				}
			}, {
				type: 'Color',
				label: this.$_('fd.metermodule.settings.color'),
				value: this.module.props.color,
				events: {
					input: this.setColor
				}
			}, {
				type: 'Boolean',
				label: this.$_('fd.metermodule.settings.skala'),
				value: this.module.props.skala,
				events: {
					input: this.setSkala
				}
			}, {
				type: 'Color',
				label: this.$_('fd.metermodule.settings.fontcolor'),
				visible: this.module.props.skala,
				value: this.module.props.tachoDesign == 'default' ? this.module.props.fontColor.default : this.module.props.tachoDesign == 'ov' ? this.module.props.fontColor.ov : this.module.props.fontColor.default,
				events: {
					input: this.setFontColor
				}
			}];
		}
	},
	methods: {
		setDatasource(index) {
			return (datasourceId) => {
				this.commitChange('props', {
					[`datasources[${index}].id`]: datasourceId
				});
			}
		},
		addDatasource() {
			const datasource = {
				id: this.datasources[0].id,
			};

			this.commitChange('props', {
				datasources: [
					...this.module.props.datasources,
					datasource
				]
			});
		},
		removeDatasource() {
			this.commitChange('props', {
				datasources: this.module.props.datasources.slice(0, -1)
			});
		},
		setTachoDesign(tachoDesign) {
			this.$store.dispatch('subNav/components', null);
			this.commitChange('props', { tachoDesign });
			setTimeout(() => {
				this.$store.dispatch('subNav/components', ConfigView);
			}, 200);
		},
		setTachoStyle(tachoStyle) {
			this.commitChange('props', { tachoStyle });
		},
		setColor(color) {
			this.commitChange('props', { color });
		},
		setFontColor(fontColor) {
			if (this.module.props.tachoDesign == 'default') {
				this.commitChange('props', {
					fontColor: {
						default: fontColor,
						ov: this.module.props.fontColor.ov
					}
				});
			} else if (this.module.props.tachoDesign == 'ov') {
				this.commitChange('props', {
					fontColor: {
						default: this.module.props.fontColor.default,
						ov: fontColor
					}
				});
			}
		},
		setSkala(skala) {
			this.commitChange('props', { skala });
		},
	}
}

export default {
	extends: MeterModule,

	mounted() {
		this.start();
	},
	methods: {
		stop() {},
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.metermodule.title: Tacho
fd.metermodule.description: >
	Das Tacho-Modul zeigt ihre momentane Anlagenleistung.
fd.metermodule.add: Tacho hinzufügen

fd.metermodule.settings.tacho.design: Design
fd.metermodule.settings.tacho.design.default: Standard
fd.metermodule.settings.tacho.design.ov: Analog

fd.metermodule.settings.tacho.style: Style
fd.metermodule.settings.tacho.style.current: Aktuelle Leistung
fd.metermodule.settings.tacho.style.dailyincome: Tagesertrag
fd.metermodule.settings.tacho.style.dailyset: Tagessoll

fd.metermodule.settings.color: Farbe
fd.metermodule.settings.fontcolor: Textfarbe
fd.metermodule.settings.skala: Skala

</i18n>
