<template>
  <div class="fd-roll-animation" :style="style">
		<slot></slot>
	</div>
</template>


<script>
import { Tween } from 'mo-js';

export default {
	name: 'Roll',
	icon: ' spinner11',
	props: ['animation'],
	data() {
		return {
			rotate: 0,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
				transform: `rotate3d(0, 0, 1, ${this.rotate}deg)`
			}
		},
  },
	mounted() {
		
		if(typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if(typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
		}

		initRoll(this);

		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('init' + this.animation.index, index => { 
			if(index == this.animation.index) {
				initRoll(this);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				this.$options.animation.run();
			} else {
				this.$options.animation.setProp('delay', 0);
				this.$options.animation.run();
				this.$options.animation.setProp('delay', this.animation.delay * 1000);
			}
		},

		stop() {
			this.$options.animation.stop();
    },
		
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
			this.$root.$on('init' + this.animation.index, index => { 
				if(index == this.animation.index) {
					initRoll(this);
				}
			});
		}
  }
}

function initRoll(self) {

    self.$options.animation = new Tween({
			duration: self.animation.duration * 1000,
			delay: self.animation.delay * 1000,
			onUpdate: (progress) => self.rotate = 360 * progress
		});
}
</script>
