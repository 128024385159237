const state = {
	animations: true,
	limit: 300,
	showBackground: true,
	usbvideopath: 'http://localhost/usbShare/',
}

function init(config) {
	return {
		...state,
		...config.settings
	}
}

export async function create(config) {
	return {
		namespaced: true,

		state: init(config),

		// mutations: {}
	}
}
