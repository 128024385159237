function init() {
	return {
		fixed: false,
		width: 250,
		components: [],
		colorPicker: {
			visible: false,
			offset: 0,
			color: [0, 0, 0, 1]
		}
	}
}

export async function create() {
	return {
		namespaced: true,

		state: init(),

		mutations: {
			fixed(state, fixed) {
				state.fixed = fixed;
			},
			width(state, width) {
				state.width = width;
			},
			components(state, components) {
				state.components = components;
			},
			resize: () => { },
			colorPicker(state, colorPicker) {
				if(!colorPicker.color[3] && colorPicker.color[3] !== 0) {
					colorPicker.color[3] = 1;
				}
				state.colorPicker = colorPicker;
			}
		},

		actions: {
			components({ commit }, components) {
				if(!components) {
					components = [];
				}
				if(!(components instanceof Array)) {
					components = [components];
				}

				commit('components', components);
			}
		}
	}
}
