<template>
	<modal-view class="fd-no-variables-modal-view"
		:size="400"
		icon="warning"
		:title="$_('fd.novariablesmodal.title')"
		:dialog="true"
		:buttons="buttons"
	>
		<p>
			Es stehen leider keine Variablen zur Verfügung!
		</p>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';

export default {
	name: 'NoVariablesModalView',
	components: { ModalView },
	data() {
		return {
			buttons: [{
				label: this.$_('fd.novariablesmodal.actions.ok'),
				css: 'primary'
			}]
		}
	}
}
</script>

<i18n lang="de_DE">
fd.novariablesmodal.title: Keine Variablen!
fd.novariablesmodal.actions.ok: Ok
</i18n>
