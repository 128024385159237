const knownMIMEtypes = [
	// images:
	'image/jpeg', 'image/x-png', 'image/png', 'image/gif', 'image/svg+xml',

	// other:
	'application/json',

	// TODO: Add other stuff
];

const fileChooserDomElement = createFileChooserDomElement();
let onFilesChange = () => {};

function createFileChooserDomElement() {
	const input = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('multiple', 'multiple');
	input.addEventListener('change', () => onFilesChange());
	return input;
}

function resolveFiles(options, resolve) {
	const files = [...fileChooserDomElement.files];

	if(files.length < 1) return;

	resolve(
		files
			.filter(file => !options.accept || options.accept.test(file.type))
			.map(file => {
				return resolveFile(file);
			})
	);

	fileChooserDomElement.value = '';
}

function resolveFile(file) {
	const result = {
		file: {
			id: null,
			name: file.name,
			type: file.type,
			size: file.size,
			data: null,
		},
		hooks: {
			dataReady: function() {},
		}
	}

	const reader = new FileReader();
	reader.addEventListener('loadend', () => {
		result.file.data = reader.result;
		result.hooks.dataReady();
	});
	reader.readAsDataURL(file);

	return result;
}

export function open(options) {
	return new Promise(resolve => _open(options, resolve));
}

function _open(options, resolve) {
	fileChooserDomElement.setAttribute(
		'accept',
		options.accept
			? knownMIMEtypes
				.filter(type => options.accept.test(type))
				.join(',')
			: ''
	);

	onFilesChange = () => resolveFiles(options, resolve);

	fileChooserDomElement.click();
}

export default { open }
