<template>
  <div class="fd-pulse-animation" :style="style">
		<slot></slot>
	</div>
</template>

<script>
import { Tween, easing } from 'mo-js';

export default {
	name: 'Pulse',
	icon: 'pulse2',
	props: ['animation'],
	data() {
		return {
			scale: 1,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
				transform: `scale3d(${this.scale}, ${this.scale}, 1)`
			}
		},
  },
	mounted() {
		if(typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if(typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
		}

		initPulse(this);

		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('init' + this.animation.index, index => { 
			if(index == this.animation.index) {
				initPulse(this);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				this.$options.animation.run();
			} else {
				this.$options.animation.setProp('delay', 0);
				this.$options.animation.run();
				this.$options.animation.setProp('delay', this.animation.delay * 1000);
			}
		},

		stop() {
			this.$options.animation.stop();
    },
		
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
			this.$root.$on('init' + this.animation.index, index => { 
				if(index == this.animation.index) {
					initPulse(this);
				}
			});
		}
  }
}

function initPulse(self) {

		const pulsePath = easing.path('M0,0 C6,2 7,2 15,0 C30,-6 70,-16 100,0');

		self.$options.animation = new Tween({
			duration: self.animation.duration * 1000,
			delay: self.animation.delay * 1000,
			onUpdate: (progress) => self.scale = pulsePath(progress)
		});
}
</script>
