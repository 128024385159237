<template>
	<div class="fd-config-item"
		:class="item.type"
		v-show="item.visible !== false"
	>

		<div class="label" v-if="item.label">{{item.label}}:</div>

		<tooltip-view v-if="item.help" :text="item.help">
			<i class="help question3"></i>
		</tooltip-view>

		<div class="input">
			<component
				:is="item.type + 'View'"
				:config="item"
				:value="item.value"
				v-on="item.events"
				@extra="setExtra"
			></component>
		</div>

		<div class="extra" v-if="extra">
			<config-item-view
				v-for="(child, i) of extra"
				:key="item.type + '_' + child.type + '_' + i"
				:item="child"
			/>
		</div>

	</div>
</template>

<script>
import * as INPUTS from '../inputs';
import GroupView from '../inputs/Group.vue';

import RawView from './Raw.vue';
import InfoView from './Info.vue';
import TooltipView from './Tooltip.vue';
import PreviewView from './Preview.vue';

export default {
	name: 'ConfigItemView',
	props: ['item'],
	components: {
		...INPUTS,
		GroupView,

		InfoView,
		RawView,
		TooltipView,
		PreviewView,
	},
	// https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
	beforeCreate() {
		this.$options.components.ConfigItemView = require('./ConfigItem.vue').default;
	},
	data: () => ({
		extra: null
	}),
	methods: {
		setExtra(extra) {
			this.extra = extra;
			this.$store.commit('subNav/resize');
		}
	}
}
</script>

<style lang="scss">
.fd-config-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	font-size: 11px;

	padding: 6px 0;
	border-bottom: 1px solid #333;

	.label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}

	.help {
		font-size: 13px;
		padding: 0 5px;
		cursor: help;
	}

	.input {
		display: flex;
		flex: 0 0 50%;
	}

	.extra {
		flex: 0 0 100%;
		margin-top: 10px;
		border-top: 1px dashed #555;
		padding-top: 3px;

		border-left: 1px dashed #555;
		padding-left: 15px;

		padding-bottom: 5px;
		margin-bottom: 10px;
	}
}
</style>
