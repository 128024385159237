<template>
	<div :id="module.mid" class="fd-module" :class="classes.root" :style="positionStyle">
		<animation-view :animation="module.animation" :rect="moduleRect">
			<div class="fd-module-wrapper" :style="designStyle">
				<component
					:is="module.class"
					:module="module"
					class="fd-module-inner"
					:class="classes.inner"
				></component>
			</div>
		</animation-view>
	</div>
</template>

<script>
import AnimationView from './Animation.vue';
import * as Styles from '../styles';
import * as MODULES from '../modules/_MODULES.js';

export default {
	name: 'ModuleView',
	props: ['module'],
	components: { AnimationView, ...MODULES },
	computed: {
		classes() {
			const shortName = this.module.class.replace('Module', '').toLowerCase();
			return {
				root: `fd-${shortName}-module-root`,
				inner: `fd-${shortName}-module`,
			}
		},
		position() {
			let x = this.module.x;
			let y = this.module.y;

			switch(x) {
				case 'left':
					x = 0;
					break;

				case 'center':
					x = (this.$store.state.screensize.width - this.module.width) / 2;
					break;

				case 'right':
					x = this.$store.state.screensize.width - this.module.width;
					break;
			}

			switch(y) {
				case 'top':
					y = 0;
					break;

				case 'center':
					y = (this.$store.state.screensize.height - this.module.height) / 2;
					break;

				case 'bottom':
					y = this.$store.state.screensize.height - this.module.height;
					break;
			}

			return { x, y };
		},
		positionStyle() {
			const r = this.module.r || 0;

			return {
				transform: `
					translate(${this.position.x}px, ${this.position.y}px)
					rotate(${r * 0.3}deg)
				`,
				width: this.module.width + (
					typeof this.module.width === 'number' ? 'px' : ''
				),
				height: this.module.height + (
					typeof this.module.height === 'number' ? 'px' : ''
				),
				zIndex: (!this.module.logo) ? this.module.z : this.module.zIndex,
				pointerEvents: (this.module.logo) ? 'none' : '',
			}
		},
		designStyle() {
			const designStyle = {};
			for(const styleKey in this.module.style) {
				const styleValue = this.module.style[styleKey];
				const styles = Styles[styleKey].compute(styleValue);
				for(const key in styles) {
					if(designStyle[key]) {
						designStyle[key] += ' ' + styles[key];
					}
					else {
						designStyle[key] = styles[key];
					}

				}
			}
			return designStyle;
		},
		moduleRect() {
			return {
				x: this.module.x,
				y: this.module.y,
				width: this.module.width,
				height: this.module.height,
				r: this.module.r * 0.3
			}
		}
	},
	mounted() {
		// This is because ff 19 does not add filter with vue
		this.$el.querySelector('.fd-module-wrapper').style.filter = this.designStyle.filter;
	}
}
</script>

<style>
.fd-module {
	position: absolute;
	top: 0;
	left: 0;
	outline: none;
}

.fd-module-wrapper {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.fd-module-wrapper,
.fd-module-inner {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
}
</style>
