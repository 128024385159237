<script>
import ModalView from '../Modal/Modal.vue';

export default {
	name: 'ModalWindowView',
	extends: ModalView,
	props: ['id'],
	methods: {
		close() {
			this.$store.commit('window/close', this.id);
		}
	}
}
</script>
