<template>
	<div class="fd-basic-config-view" v-if="this.module">
		<h2 class="head">
			<span class="icon"><i :class="meta.icon"></i></span>
			<span class="separator" v-if="meta.icon"></span>
			<span class="title">{{$_('fd.' + meta.name + '.title')}}</span>
		</h2>
		<div class="body">

			<group-view :label="$_('fd.config.group.layout')" :collapsed="true" v-if="layout.length">
				<config-item-view
					v-for="(item, i) of layout"
					:key="meta.name + '_layout_' + item.type + '_' + i"
					:item="item"
				/>
			</group-view>

			<group-view :label="$_('fd.config.group.config')" v-if="config.length">
				<config-item-view
					v-for="(item, i) of config"
					:key="meta.name + '_config_' + item.type + '_' + i"
					:item="item"
				/>
			</group-view>

			<group-view :label="$_('fd.config.group.style')" v-if="style.length">
				<config-item-view
					v-for="(item, i) of style"
					:key="meta.name + '_style_' + item.type + '_' + i"
					:item="item"
				/>
			</group-view>

			<group-view :label="$_('fd.config.group.other')" v-if="other.length && this.$store.state.type != 'logo'">
				<config-item-view
					v-for="(item, i) of other"
					:key="meta.name + '_other_' + item.type + '_' + i"
					:item="item"
				/>
			</group-view>

		</div>
	</div>
	<div v-else></div>
</template>

<script>
import '../../scss/fonts/opensans.scss';
import ConfigItemView from './ConfigItem.vue';
import GroupView from './Group.vue';
import * as STYLES from '../styles';
import { _getPosition } from '../util/helper.js';

export default {
	name: 'BasicConfigView',
	components: {
		ConfigItemView,
		GroupView,
	},
	computed: {
		module() {
			return this.$store.getters.activeModules[0];
		},
		resizeMode() {
			let resize = this.meta.resize;

			if(resize instanceof Function) {
				resize = resize(this.module.props);
			}

			if(![
				'all',
				'none',
				'width',
				'height',
			].includes(resize) && typeof resize !== 'number') {
				resize = null;
			}

			return resize || 'all';
		},
		rotatable() {
			return this.meta.rotate !== false;
		},
		movable() {
			return this.meta.move !== false;
		},
		changeableLayer() {
			return this.meta.changeLayer !== false;
		},
		deletable() {
			let deletable = this.meta.deletable;

			if(deletable instanceof Function) {
				deletable = deletable(this.$store.state);
			}

			return deletable;
		},
		r() {
			if(!this.module.r) return 0;

			let r = this.module.r * 0.3;
			if(r > 270) r -= 360;
			if(r < -90) r += 360;
			return Math.round(r * 10) / 10;
		},
		position() {
			return _getPosition(this.module, this.$store.state.screensize);
		},
		layout() {
			return [
				{
					type: 'Number',
					label: 'X',
					value: Math.round((this.position.x) * 100) / 100,
					editable: this.movable,
					events: {
						input: this.setX
					}
				},
				{
					type: 'Number',
					label: 'Y',
					value: Math.round((this.position.y) * 100) / 100,
					editable: this.movable,
					events: {
						input: this.setY
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.module.settings.width'),
					value: Math.round((this.module.width) * 100) / 100,
					min: 0,
					editable: this.resizeMode === 'all' || this.resizeMode === 'width',
					events: {
						input: this.setWidth
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.module.settings.height'),
					value: Math.round((this.module.height) * 100) / 100,
					min: 0,
					editable: this.resizeMode === 'all' || this.resizeMode === 'height',
					events: {
						input: this.setHeight
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.module.settings.rotate'),
					value: this.r,
					editable: this.rotatable,
					events: {
						input: this.setR
					}
				},
				{
					type: 'Group',
					label: this.$_('fd.module.settings.layer'),
					values: [
						{
							type: 'Button',
							icon: 'arrow-up6',
							events: {
								click: () => this.$store.dispatch('arrangeForward')
							}
						},
						{
							type: 'Number',
							value: this.module.z || 1,
							editable: false
						},
						{
							type: 'Button',
							icon: 'arrow-down6',
							events: {
								click: () => this.$store.dispatch('arrangeBehind')
							}
						}
					]
				},
			];
		},
		style() {
			return Object.keys(this.module.style).map(style => {
				return {
					...(
						STYLES[style].CONFIG
						||
						STYLES[style].getConfig(this.$store, this.$_)
					),
					label: this.$_('fd.module.style.' + style),
					visible: (style == 'advanced' || style == 'padding') && !this.$store.state.user.advanced ? false : true,
					value: STYLES[style].prepare
						? STYLES[style].prepare(this.module.style[style])
						: this.module.style[style],
					events: {
						input: STYLES[style].handleInput
							? STYLES[style].handleInput(
								this.setStyle(style),
								this.module,
								this.$store
							)
							: this.setStyle(style)
					}
				}
			});
		},
		other() {
			return [
				{
					type: 'Button',
					label: this.$_('fd.module.settings.duplicate'),
					icon: 'copy3',
					visible: this.deletable !== false,
					events: {
						click: () => {
							if(this.module.class != 'WatermarkModule') {
								this.$store.dispatch('duplicateModules', [this.module.mid]);
								setTimeout(() => {
									this.$root.$emit('initModuleSnapping');
								}, 10);
							}
						},
					}
				},
				{
					type: 'Button',
					label: this.$_('fd.module.settings.delete'),
					icon: 'bin',
					color: 'danger',
					visible: this.deletable !== false,
					events: {
						click: () => this.$store.dispatch('deleteModules', [this.module.mid])
					}
				}
			];
		}
	},
	methods: {
		setX(x) {
			this.commitChange('set', { x });
		},
		setY(y) {
			this.commitChange('set', { y });
		},
		setWidth(width) {
			this.commitChange('set', { width });
		},
		setHeight(height) {
			this.commitChange('set', { height });
		},
		setR(r) {
			this.commitChange('set', { r: r / 0.3 });
		},

		commitChange(type, values) {
			this.$store.commit('changeModules', {
				type,
				modules: [this.module.mid],
				values: values
			});
		},

		setStyle(style) {
			return (value) => {
				this.commitChange('style', { [style]: value });
			}
		},

		deleteModule() {
			this.$store.dispatch('deleteModules', [this.module.mid]);
		}
	}
}
</script>

<style lang="scss">
.fd-basic-config-view {
	font-family: OpenSans, sans-serif;
	color: #fff;
	margin-bottom: 25px;

	h2 {
		font-size: 1.4rem;
		padding-bottom: 4px;
		margin: 10px 0 0;
		border-bottom: 1px solid #333;
	}

	.head .separator {
		&:after {
			content: "-";
		}
		margin: 0 5px;
	}
}
</style>

<i18n lang="de_DE">
fd.config.group.layout: Größe und Position
fd.config.group.config: Modul und Inhalt
fd.config.group.style: Design
fd.config.group.other: Sonstiges

fd.module.settings.width: Breite
fd.module.settings.height: Höhe
fd.module.settings.rotate: Drehung
fd.module.settings.layer: Ebene

fd.module.settings.duplicate: Modul duplizieren
fd.module.settings.delete: Modul löschen

fd.module.style.fontFamily: Schrift
fd.module.style.fontStyle: Stil
fd.module.style.textAlign: Ausrichtung
fd.module.style.fontSize: Größe
fd.module.style.color: Farbe
fd.module.style.lineHeight: Linienhöhe
fd.module.style.padding: Innenabstand
# fd.module.style.margin: _margin
fd.module.style.backgroundColor: Hintergrund
fd.module.style.opacity: Deckkraft
fd.module.style.border: Rahmen
# fd.module.style.blur: _blur
fd.module.style.advanced: CSS
fd.module.style.shadow: Schatten

fd.font.type.serif: Serifenschriften
fd.font.type.sans-serif: Serifenlose Schriften
fd.font.type.mono: Festschrittschriften
</i18n>
