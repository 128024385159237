<template>
	<div class="fd-bounce-animation" :style="style">
		<slot></slot>
	</div>
</template>

<script>
import { Tween, easing } from 'mo-js';

export default {
	name: 'Bounce',
	icon: 'basketball',
	props: ['animation'],
	data() {
		return {
			translateY: 0,
			classList: ['animated'],
		};
	},
	computed: {
		style() {
			return {
				transform: `translate3d(0, ${this.translateY}px, 0)`,
			};
		},
	},
	mounted() {
		if (typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if (typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
		}

		initBounce(this);

		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => {
			if (index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('init' + this.animation.index, index => {
			if (index == this.animation.index) {
				initBounce(this);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				this.$options.animation.run();
			} else {
				this.$options.animation.setProp('delay', 0);
				this.$options.animation.run();
				this.$options.animation.setProp('delay', this.animation.delay * 1000);
			}
		},

		stop() {
			this.$options.animation.stop();
		},

		update() {
			this.$root.$on('start' + this.animation.index, index => {
				if (index == this.animation.index) {
					this.start(false);
				}
			});
			this.$root.$on('init' + this.animation.index, index => {
				if (index == this.animation.index) {
					initBounce(this);
				}
			});
		},
	},
};

function initBounce(self) {
	const bouncePath = mojs.easing.path(
		'M0,0 C0,100 30,100 50,0 C64,55 74,4 75,0 C82,14 89,2 91,0 C95,6 100,0 100,0'
	);

	self.$options.animation = new Tween({
		duration: self.animation.duration * 1000,
		delay: self.animation.delay * 1000,
		onUpdate: progress => (self.translateY = (bouncePath(progress) - 1) * 100),
	});
}
</script>
