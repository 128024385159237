<template>
  <div class="fd-tada-animation" :class="classList" :style="style">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'Tada',
  icon: 'cake',
	props: ['animation'],
	data() {
		return {
      triggerStyle: 0,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
        triggerStyle: this.triggerStyle,
        animationDuration: this.animation.duration + 's'
			}
    }
  },
	mounted() {
    if(typeof this.animation.duration == 'undefined') {
      this.animation.duration = 1;
    }
    if(typeof this.animation.delay == 'undefined') {
      this.animation.delay = 0;
    }
		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
      if(delay) {
        delay = this.animation.delay;
      } else {
        delay = 0;
      }
      let duration = this.animation.duration || 1;
      this.triggerStyle = 1;
      this.$options.timeout = setTimeout(_ => {
        this.classList.push(this.animationClass());
        setTimeout(_ => {
          clearTimeout(this.$options.timeout);
          this.classList = ['animated'];
        }, duration * 1000 + 100);
      }, delay * 1000);
		},

		stop() {
      this.triggerStyle = 0;
      clearTimeout(this.$options.timeout);
			this.classList = ['animated'];
    },
    
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
    },

    animationClass() {
      return 'tada';
    },
  }
}
</script>

<style>
.fd-tada-animation.tada {
  animation-name: tada;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
