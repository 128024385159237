export const KEY = 'opacity';

export const CONFIG = {
	type: 'Number',
	min: 0,
	max: 100
}

export function normalize(value) {
	return value || 100;
}

export function compute(value) {
	return {
		opacity: normalize(value) / 100
	}
}
