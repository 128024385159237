<script>
export default {
  name: 'BaseModule',
  props: ['module'],
  mounted() {
    this.$root.$on('start', _ => this.start());
    this.$root.$on('stop', _ => this.stop());
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    start() {},
    stop() {},
  }
}
</script>
