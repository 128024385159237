<template>
	<div class="fd-pane" :style="style">
		<background-view v-if="$store.state.settings.showBackground"></background-view>
		<modules-view :class="classes" :modules="modules"></modules-view>
		<canvas id="dashboard" class="dashboard"></canvas>
		<code class="fps-info" :style="fpsStyle" v-if="$root.debug">
			<h1>{{$root.fps}} fps</h1>
			fastEnough: {{$root.fastEnough}}<br>
			tooSlow: {{$root.tooSlow}}
		</code>
	</div>
</template>

<script>
import BackgroundView from './Background.vue';
import ModulesView from './Modules.vue';

export default {
	name: 'PaneView',
	components: { BackgroundView, ModulesView },
	computed: {
		scale() {
			return this.$store.state.intern.scale;
		},
		style() {
			const screensize = this.$store.state.screensize;
			return {
				width: screensize.width + 'px',
				height: screensize.height + 'px',
				marginTop: -screensize.height / 2 + 'px',
				marginLeft: -screensize.width / 2 + 'px',
				transform: `scale(${this.scale})`,
			}
		},
		modules() {
			return this.$store.state.data.modules;
		},
		classes() {
			return this.$store.state.user ? this.$store.state.user.gridlines ? 'grid' : '' : '';
		},
		fpsStyle() {
			return {
				opacity: this.$root.speedReady ? 0.5 : 1,
			}
		},
	}
}
</script>

<style lang="scss">
	.fd-pane {
		position: absolute;
		top: 50%;
		left: 50%;

		overflow: hidden;
	}

	.grid {
		background-image:
			linear-gradient(0deg, rgba(255, 255, 255, .2) 0px, rgba(255, 255, 255, .2) 1px, transparent 2px, transparent 100%),
			linear-gradient(90deg, rgba(255, 255, 255, .2) 0px, rgba(255, 255, 255, .2) 1px, transparent 2px, transparent 100%);

		background-position: center;
		background-size: 3.125% 5.555555555%;
	}

	.dashboard {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 15;
	}

	.fps-info {
		position: absolute;
		top: 0;
		right: 50px;
		z-index: 55;
		font-size: 50px;
		text-align: right;
		color: #888;
		text-shadow: 0 1px 2px #000, 0 -1px 2px #fff;
		pointer-events: none;
	}
</style>
