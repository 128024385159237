<template>
	<div class="fd-border-input">
		<boolean-input-view :config="powerSwitch" :value="powerSwitch.value" v-on="powerSwitch.events"></boolean-input-view>
	</div>
</template>

<script>
import BooleanInputView from './Boolean.vue';
import { _getMax } from '../util/helper.js';

export default {
	name: 'BorderInput',
	props: ['value', 'config'],
	components: { BooleanInputView },
	data: () => ({
		selection: 'all',
	}),
	computed: {
		all() {
			return {
				px: _getMax([
					this.value.top.px,
					this.value.left.px,
					this.value.right.px,
					this.value.bottom.px,
				]),
				style: this.value.top.style,
				color: this.value.top.color,
			};
		},
		top() {
			return this.value.top;
		},
		left() {
			return this.value.left;
		},
		right() {
			return this.value.right;
		},
		bottom() {
			return this.value.bottom;
		},
		powerSwitch() {
			return {
				type: 'Boolean',
				value: !!this.value.show,
				events: {
					input: val => this.emit('', 'show', val ? 1 : 0),
				},
			};
		},
		settings() {
			if (!this.value.show) {
				return null;
			}

			return [
				{
					type: 'Select',
					label: this.$_('fd.inputs.border.selection'),
					value: this.selection,
					values: [
						{
							label: this.$_('fd.inputs.border.selection.all'),
							value: 'all',
						},
						{
							label: this.$_('fd.inputs.border.selection.top'),
							value: 'top',
						},
						{
							label: this.$_('fd.inputs.border.selection.left'),
							value: 'left',
						},
						{
							label: this.$_('fd.inputs.border.selection.right'),
							value: 'right',
						},
						{
							label: this.$_('fd.inputs.border.selection.bottom'),
							value: 'bottom',
						},
					],
					events: {
						input: val => (this.selection = val),
					},
				},
				{
					type: 'Select',
					label: this.$_('fd.inputs.border.style'),
					value: this[this.selection].style,
					values: [
						{
							label: this.$_('fd.inputs.border.style.solid'),
							value: 'solid',
						},
						{
							label: this.$_('fd.inputs.border.style.dashed'),
							value: 'dashed',
						},
						{
							label: this.$_('fd.inputs.border.style.dotted'),
							value: 'dotted',
						},
						{
							label: this.$_('fd.inputs.border.style.double'),
							value: 'double',
						},
					],
					events: {
						input: val => this.emit(this.selection, 'style', val),
					},
				},
				{
					type: 'Color',
					label: this.$_('fd.inputs.border.color'),
					value: this[this.selection].color,
					events: {
						input: val => this.emit(this.selection, 'color', val),
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.inputs.border.width'),
					value: this[this.selection].px,
					min: 0,
					max: 100,
					events: {
						input: val => this.emit(this.selection, 'px', val),
					},
				},
			];
		},
		showWatcher() {
			return this.value.show;
		},
	},
	watch: {
		showWatcher() {
			this.$emit('extra', this.settings);
		},
	},
	mounted() {
		this.$emit('extra', this.settings);
	},
	methods: {
		emit(selection, key, value) {
			if (selection == 'all') {
				this.$emit('input', {
					top: {
						...this.value.top,
						[key]: value,
					},
					left: {
						...this.value.left,
						[key]: value,
					},
					right: {
						...this.value.right,
						[key]: value,
					},
					bottom: {
						...this.value.bottom,
						[key]: value,
					},
					show: this.value.show,
				});
				return;
			} else if (selection == '') {
				if (value == 0) {
					this.$emit('input', {
						...this.value,
						[key]: value,
						['top']: {
							...this.value['top'],
							['px']: 0,
						},
						['left']: {
							...this.value['left'],
							['px']: 0,
						},
						['right']: {
							...this.value['right'],
							['px']: 0,
						},
						['bottom']: {
							...this.value['bottom'],
							['px']: 0,
						},
					});
				} else {
					this.$emit('input', {
						...this.value,
						[key]: value,
						['top']: {
							...this.value['top'],
							['px']: 5,
						},
						['left']: {
							...this.value['left'],
							['px']: 5,
						},
						['right']: {
							...this.value['right'],
							['px']: 5,
						},
						['bottom']: {
							...this.value['bottom'],
							['px']: 5,
						},
					});
				}
			} else {
				this.$emit('input', {
					...this.value,
					[selection]: {
						...this.value[selection],
						[key]: value,
					},
				});
			}
		},
	},
};
</script>

<style lang="scss">
.fd-border-input {
	width: 100%;
}
</style>

<i18n lang="de_DE">
fd.inputs.border.selection: Rahmen
fd.inputs.border.selection.all: alle
fd.inputs.border.selection.top: oben
fd.inputs.border.selection.left: links
fd.inputs.border.selection.right: rechts
fd.inputs.border.selection.bottom: unten

fd.inputs.border.style: Stil
fd.inputs.border.style.solid:  Linie
fd.inputs.border.style.dashed: Striche
fd.inputs.border.style.dotted: Punkte
fd.inputs.border.style.double: doppelt

fd.inputs.border.color: Farbe
fd.inputs.border.width: Breite
</i18n>
