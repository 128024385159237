<template>
	<div class="fd-renderer-view fd-instance" :style="rendererStyle">
		<pane-view></pane-view>
	</div>
</template>

<script>
import PaneView from './Pane.vue';

export default {
	name: 'RendererView',
	components: { PaneView },
	computed: {
		rendererStyle() {
			const style = {};

			if(!this.$store.state.settings.showBackground) {
				style.background = 'none';
			}

			return style;
		}
	},
	async mounted() {
		await this.$nextTick();

		this.$store.commit('intern/scale', this.getScale({
			width: this.$el.offsetWidth,
			height: this.$el.offsetHeight
		}));
	},
	methods: {
		getScale(viewport) {
			const screensize = this.$store.state.screensize;

			if(!viewport.width || !viewport.height) {
				// Can not fetch viewport size! Maybe viewport is not rendered
				// yet, this is no problem due to vues smart initiation
				// process. It will properly work within the next two
				// milliseconds
				return 1;
			}

			// Calc the scale with viewport size and sf display screensize
			// sf display -> 100 % | viewport -> ? %
			// Consider that viewport is not sf display ratio

			// First check viewport ratio above sf display ratio
			if(
				viewport.width / viewport.height > screensize.width / screensize.height
			) {
				// Calc scale with the height
				return viewport.height / screensize.height;
			}
			else {
				// Calc scale with the width
				return viewport.width / screensize.width;
			}
		}
	}
}
</script>

<style lang="scss">
	$fd_background: '../img/pattern.png';

	.fd-instance,
	.fd-renderer-view {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		overflow: hidden;
	}

	.fd-instance {
		background: url($fd_background) black;
	}
</style>
