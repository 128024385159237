<script>
import WatermarkModule from './WatermarkModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'WatermarkModule',
	icon: 'wave2',
	resize: 'none',
	rotate: false,
	move: false,
	changeLayer: false,
	deletable: state => state.type === 'pro',
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate() {
	return {
		locked: true,
		class: 'WatermarkModule',
		x: 'right',
		y: 'bottom',
		width: 160,
		height: 80,
	};
}

export const ConfigView = {
	name: 'WatermarkModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		choosePositionValue() {
			if (
				this.module.x == 'left' ||
				this.module.x == 0 ||
				this.module.x < this.$store.state.screensize.width / 2
			) {
				return 0;
			} else return 'right';
		},
		config() {
			return [
				{
					type: 'Select',
					label: this.$_('fd.watermarkmodule.settings.position'),
					value: this.choosePositionValue,
					values: [
						{
							label: this.$_('fd.watermarkmodule.settings.position.left'),
							value: 0,
						},
						{
							label: this.$_('fd.watermarkmodule.settings.position.right'),
							value: 'right',
						},
					],
					events: {
						input: this.setX,
					},
				},
			];
		},
	},
};

export default {
	extends: WatermarkModule,

	// extra
	meta,
	getTemplate,
	ConfigView,
};
</script>

<style lang="scss">
.fd-watermark-module-root {
	.fd-module-button-wrench {
		top: -10px !important;
	}
	.fd-module-button-bin {
		top: 34px !important;
	}
}
</style>

<i18n lang="de_DE">
fd.watermarkmodule.title: Wasserzeichen

fd.watermarkmodule.settings.position: Position
fd.watermarkmodule.settings.position.left: Links
fd.watermarkmodule.settings.position.right: Rechts
</i18n>
