<template>
	<div class="fd-boolean" @click="toggle">
		<input type="checkbox" v-model="checked"/>
		<div class="slider"></div>
	</div>
</template>

<script>
export default {
	name: 'BooleanInput',
	props: ['value', 'config'],
	data() {
		return {
			checked: this.value
		}
	},
	methods: {
		toggle() {
			this.checked = !this.checked;
			this.$emit('input', this.checked);
		}
	}
}
</script>

<style lang="scss">
.fd-boolean {
	width: 100%;

	position: relative;
	height: 26px;
	background: hsla(0, 0%, 100%, .2);
	padding: 4px 7px;
	border-radius: 2px;
	cursor: pointer;

	input[type=checkbox] {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
		margin: 0;
	}

	.slider {
		position: absolute;
		top: 4px;
		bottom: 4px;
		left: 4px;
		width: calc(50% - 8px);
		border-radius: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .7);
		transition: all .2s;

		&:after {
			position: absolute;
			width: 100%;
			height: 100%;
			font-size: 11px;
			line-height: 175%;
			text-align: center;
			transition: all .2s;
		}
	}

	input[type=checkbox]:checked + .slider {
		background: #46a046;
		transform: translateX(calc(100% + 8px));

		&:after {
			content: "On";
			transform: translateX(calc(-100% - 8px));
		}
	}

	input[type=checkbox]:not(:checked) + .slider {
		background: #ac2925;
		transform: translateX(0);

		&:after {
			content: "Off";
			transform: translateX(calc(100% + 8px));
		}
	}
}
</style>
