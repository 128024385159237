import Vue from 'vue';

import * as Store from './store/Store.js';
import * as StoreExtra from './store/StoreExtra.js';
import { editorModules } from './store/modules';

import CONFIG from './Config.js';
import EditorView from './components/Editor.vue';
import TutorialWindow from './components/Window/TutorialWindow.vue';
import Movable from './util/Movable.js';
import * as Filter from './filter';

import Modernizr from './Modernizr';

// Some systems does not support console info and warn
if (!console.info) console.info = console.log;
if (!console.warn) console.warn = console.log;

// Polyfill for fetch
if (!window.fetch) {
	console.info('`fetch` is not supported! Using whatwg-fetch');
	require('whatwg-fetch');
}

// This logs all the unsupportet functionality checked by Modernizr
for (let key in Modernizr) {
	if (Modernizr[key] === false) console.warn('`' + key + '` is not supportet!');
}

/**
 * The foxdesigner create function should have be the following keys
 *
 * @key id
 * @type {Number}
 * The root element gets this as the id
 *
 * @key el
 * @type {String | HTMLElement}
 * @required
 * The root element or a selector for the root element
 *
 * @key mode
 * @type {String}
 * @default 'show'
 * one of 'edit' or 'show'
 * Handles the display mode
 *
 * @key type
 * @type {String}
 * @default 'demo'
 * one of 'demo', 'light' or 'pro'
 *
 * @key screensize
 * @type {Object}
 * Define the width and height of the pane
 *
 * @key screensize.width
 * @type {Number}
 * @default 1920
 * The width of the screensize
 *
 * @key screensize.height
 * @type {Number}
 * @default 1080
 * The height of the screensize
 *
 * @key lang
 * @type {Object}
 * @required on edit mode
 * @ignored on show mode
 * @default lang/de_DE.json
 * For i18n support, a key value pair translation object
 *
 * @key settings
 * @type {Object}
 * @required
 * The settings object stores some configuartion settings
 *
 * @key settings.animations
 * @type {Boolean}
 * @default true
 * Flag to enable/disable animations
 *
 * @key settings.limit
 * @type {Number}
 * @default 300
 * Limit for the modules
 *
 * @key settings.showBackground
 * @type {Boolean}
 * @default true
 * Flag to enable/disable background
 *
 * @key settings.usbvideopath
 * @type {String}
 * @default 'http://localhost/usbShare/'
 * The path for the videos on the usb stick
 *
 * @key shared
 * @type {Object}
 * @required
 * *Some not implemented yet
 * An object to serve shared data like datasources or variables
 *
 * @key shared.variables
 * @type {Object}
 * An object that stores usable variables
 *
 * @key shared.logo
 * @type {Unknown}
 * *Not implemented yet
 * Logo settings from the ov
 *
 * @key shared.fonts
 * @type {Array}
 * An array of Fonts. A font in fonts array should have been the following keys
 *
 * @key Font.name
 * @type {String}
 * This is what the user sees in a dropdown or something similar
 *
 * @key Font.type
 * @type {String}
 * e. g. 'serif', 'sans-serif', 'handwriting' or 'mono'
 * lang key is also needed in the following format: 'fd.font.type.<FONT_TYPE>'
 * type kann be any value
 *
 * @key Font.value
 * @type {String}
 * css font-family
 *
 * @key Font.source
 * @type {String}
 * In which css file is the font defined (needs to be absolute)
 *
 * @key shared.files
 * @type {Array}
 * An array of Files. A file in files array should have been the following keys
 *
 * @key File.id
 * @type {Number}
 * The file id is needed for deletion or change name request
 *
 * @key File.name
 * @type {String}
 * This is what the user see, mostly the filename on upload
 *
 * @key File.type
 * @type {String}
 * MIME type
 * e. g. 'image/jpeg', 'image/png', 'image/svg+xml',
 *       'text/comma-separated-values', 'text/calendar', application/xml
 * type kann be any value
 *
 * @key File.group
 * @type {String}
 * e. g. 'public' or 'private'
 * lang key is also needed in the following format: 'dt.file.group.<FILE_GROUP>'
 * type kann be any value
 * Only deletable if group is 'private'
 *
 * @key File.visible
 * @type {String}
 * Can be 'hidden', 'background', 'normal' or 'every'
 * For the Filemanager on different places to filter files list
 *
 * @key File.size
 * @type {Number}
 * Image size in bytes
 *
 * @key File.thumb
 * @type {String}
 * Thumbnail path of the file (html img src value)
 *
 * @key File.path
 * @type {String}
 * Original path of the file
 *
 * @key shared.datasources
 * @type {Array}
 * An array that stores some datasources
 *
 * @key shared.templates
 * @type {Array}
 * *Not implemented yet
 * An array that stores some templates. A template in templates array should
 * have been the following keys
 *
 * @key Template.name
 * @type {String}
 * The template name that the user sees
 *
 * @key Template.group
 * @type {String}
 * Can be 'private' or 'default'
 *
 * @key Template.image
 * @type {String}
 * A preview for the template. Can be a url or a base64
 *
 * @key Template.data
 * @type {String}
 * Is a foxdesigner object json url encoded
 *
 * @key actions
 * Provide some async functions as interfaces
 *
 * @key actions.getWeather
 * @type {Function}
 * @params lat, lon, city, country
 * @required
 *
 * @key actions.saveData
 * @type {Function}
 * @params data, preview
 * @required on edit mode
 *
 * @key actions.addFile
 * @type {Function}
 * @params file, progress
 * @required on edit mode
 *
 * @key actions.deleteFile
 * @type {Function}
 * @params fileid
 * @required on edit mode
 *
 * @key actions.changeFileName
 * @type {Function}
 * @params fileid, name
 * @required on edit mode
 * *Not implemented yet
 *
 * @key actions.getTemplate
 * @type {Function}
 * @params code
 * @required on edit mode
 * *Not implemented yet
 *
 * @key actions.saveTemplate
 * @type {Function}
 * @params name, data
 * @required on edit mode
 * *Not implemented yet
 *
 * @key data
 * The data like the module or background settings
 */
export function create(config) {
	return new Promise(resolve => _create(config, resolve));
}

async function _create(config, resolve) {
	const store = await Store.create(
		{
			mode: 'edit',
			...config,
		},
		editorModules
	);

	const LANG = await config.lang;

	Store.init(store, config);
	StoreExtra.inject(store);

	if (store.state.mode == 'edit') {

		// i18n mixin
		Vue.mixin({
			lang: LANG,
			methods: {
				$_(key, values) {
					key = key.toLowerCase();

					if (!this.$options.lang[key]) {
						console.warn(key, 'missing!');
						return key;
					}

					return this.$options.lang[key].replace(/#{(.*?)}/g, (_, x) => {
						return values[x];
					});
				},
			},
		});

		// i18n filter
		Vue.filter('translate', function(key, values) {
			const t = LANG[key.toLowerCase()];

			return !t ? key : t.replace(/#{(.*?)}/g, (_, x) => {
				return values[x];
			});
		});

		// Movable directive
		Vue.directive('movable', {
			bind(el) {
				Movable(el);
			}
		});

		// Register vue filters
		for(const filterKey in Filter) {
			Vue.filter(filterKey, Filter[filterKey]);
		}
	}

	new Vue({
		el: store.state.el,
		store,
		data: () => ({
			mouseX: 0,
			mouseY: 0,

			fps: 1,
			times: [],

			frameSpeedHistory: [],
			fastEnough: true,
			tooSlow: false,
			speedReady: false,

			debug: false
		}),
		render: h => h(EditorView),
		mounted() {
			if(store.state.user.tutorial) {
				store.commit('window/create', {
					id: 'tutorial',
					component: TutorialWindow
				});
			}
			this.$emit('showAnimatedModules');
			this.$nextTick(_ => resolve(this));

			requestAnimationFrame(this.fpsLoop);
			this.checkSpeed();
		},
		methods: {
			fpsLoop(timestamp) {
				while (this.times.length > 0 && this.times[0] <= timestamp - 1000) {
					this.times.shift();
				}
				this.times.push(timestamp);
				this.fps = this.times.length;
				requestAnimationFrame(this.fpsLoop);

				if (this.speedReady && !this.tooSlow && this.fps < 10) {
					this.tooSlow = true;
				}
			},
			checkSpeed() {
				this.frameSpeedHistory = [];
				this.fastEnough = true;
				this.tooSlow = false;
				this.speedReady = false;
				requestAnimationFrame(this.speedLoop);
			},
			speedLoop() {
				this.frameSpeedHistory.push(this.fps);

				if (this.frameSpeedHistory.length < 120) {
					requestAnimationFrame(this.speedLoop);
				} else if (this.fastEnough) {
					const avg = this.frameSpeedHistory.reduce((prev, curr) => prev + curr) / (this.frameSpeedHistory.length || 1);
					this.fastEnough = avg > 25;
					this.tooSlow = avg < 10;
				}

				if (this.frameSpeedHistory.length === 60) {
					const avg = this.frameSpeedHistory.reduce((prev, curr) => prev + curr) / (this.frameSpeedHistory.length || 1);
					this.fastEnough = avg > 25;
					this.tooSlow = avg < 10;
					this.speedReady = true;
				}
			},
		},
	});
}
