<template>
  <div class="fd-shape-module icon" :class="module.props.shape" :style="style"></div>
</template>

<script>
import '../../scss/fonts/icomoon.scss';
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
  name: 'ShapeModule',
  extends: BaseModule,
	normalize,
  computed: {
    size() {
      return this.module.height - this.module.style.padding * 2;
    },
    style() {
      return {
        fontSize: this.size + 'px',
        lineHeight: this.size + 'px',
      }
    }
  }
}
</script>

<style>
.fd-shape-module {
  text-align: center;
}
</style>
