<script>
import TextModule from './TextModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import TextEditorView from '../components/Window/TextEditor.vue';
import VariableLeftWindow from '../components/Window/VariableLeftWindow.vue';
import { _elementFromTemplate, _calc, _decodeHtml, _containsVariables } from '../util/helper.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'TextModule',
	icon: 'typography',
	type: 'light',
	resize: props => (props.autoresizing ? 'width' : 'all'),
	autoHeight: props => props.autoresizing,
};

export const MetaDescriptionView = {
	name: 'TextMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store, $_) {
	return {
		animation: [],
		locked: false,
		class: 'TextModule',
		x: 'center',
		y: 'center',
		width: 300,
		height: 150,
		style: {
			backgroundColor: { r: 0, g: 0, b: 0, a: 0 },
			padding: null,
			border: null,
			shadow: null,
			advanced: null,
		},
		props: {
			text: '<p>' + $_('fd.textmodule.default.text') + '</p>',
			autoresizing: true,
			factor: 24,
			useVariables: false,
		},
	};
}

export const description = {
	extends: DescriptionView,
	data() {
		const tmp = document.createElement('div');
		tmp.innerHTML = this.module.props.text;

		return {
			description: tmp.textContent || tmp.innerText,
		};
	},
};

export const ConfigView = {
	name: 'TextModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta, mid: '' }),
	computed: {
		windows() {
			return this.$store.state.window.windows;
		},
		editorOpen() {
			const index = this.windows.findIndex(
				window => window.id === this.module.mid
			);

			if (index < 0) return false;

			return this.windows[index].component.name === TextEditorView.name;
		},
		config() {
			return [
				{
					type: 'Button',
					label: this.$_(
						'fd.textmodule.settings.editor.' +
							(this.editorOpen ? 'close' : 'open')
					),
					visible: this.module.props.useVariables,
					events: {
						click: this.openEditor,
					},
				},
				{
					type: 'Boolean',
					label: this.$_('fd.textmodule.settings.autoresizing'),
					value: this.module.props.autoresizing,
					events: {
						input: this.setAutoResizing,
					},
				},
				{
					type: 'Boolean',
					label: this.$_('fd.textmodule.settings.usevariables'),
					value: this.module.props.useVariables,
					events: {
						input: this.setUseVariables,
					},
					visible: this.$store.state.type != 'logo'
				},
			];
		},
	},
	mounted() {
		// Store mid to close editor before destroy
		this.mid = this.module.mid;

		if (this.editorOpen) {
			this.$store.commit('window/close', this.module.mid);
			return;
		}

		this.$store.commit('window/create', {
			id: this.module.mid,
			component: TextEditorView,
			props: {
				value: this.module.props.text,
				onChange: text => {
					this.commitChange('props', { text });
					if (this.module.props.useVariables)
						this.$root.$emit('updateText' + this.module.mid);
				},
			},
		});

		setTimeout(() => {
			if (!this.module.props.useVariables) {
				let module = document.getElementById(this.module.mid);
				if (module) {
					let decorator = module.getElementsByClassName('fd-decorator');
					decorator[0].setAttribute('style', 'z-index: -30;');
				}
			}
		}, 10);

		this.$root.$on('removeVariables', _ =>
			this.removeVariables(this.module.props.text)
		);
		this.$root.$on('abortRemoveVariable', _ => this.abortRemoveVariable());
	},
	beforeDestroy() {
		// Sadly we have no access here to this.module
		let module = document.getElementById(this.mid);
		if (module) {
			let decorator = module.getElementsByClassName('fd-decorator');
			decorator[0].setAttribute('style', '');
		}

		this.$store.commit('window/close', this.mid);
	},
	methods: {
		openEditor() {
			if (this.editorOpen) {
				this.$store.commit('window/close', this.module.mid);
				return;
			}

			this.$store.commit('window/create', {
				id: this.module.mid,
				component: TextEditorView,
				props: {
					value: this.module.props.text,
					onChange: text => {
						this.commitChange('props', { text });
						if (this.module.props.useVariables)
							this.$root.$emit('updateText' + this.module.mid);
					},
				},
			});
		},
		setAutoResizing(autoresizing) {
			this.commitChange('props', { autoresizing });
		},
		testCondition(con) {
			con = _decodeHtml(con);

			try {
				eval('con = ' + con);
			} catch (e) {}

			return !!con;
		},
		removeVariables(text) {
			const cleanup = [];
			let match, regex;

			// Remove if else statements
			let ifregex = /\{if\|(.*?)\}/gi;
			let elseifregex = /\{elseif\|(.*?)\}/gi;
			match = ifregex.exec(text);
			if (match !== null) {
				text = text.replace(match[0], '');
				let tmpText = '';
				while ((match = elseifregex.exec(text)) !== null) {
					if (tmpText == '') {
						tmpText = text.replace(match[0], '');
					} else {
						tmpText = tmpText.replace(match[0], '');
					}
				}
				text = tmpText;
				text = text.replace('{else}', '');
				text = text.replace('{endif}', '');
			}

			// Remove span.var
			regex = /<span class="var">(.*?)<\/span>/g;
			while ((match = regex.exec(text)) !== null) {
				cleanup.push({
					needle: match[0],
					replace: match[1],
				});
			}

			// Remove span.calc
			regex = /<span class="calc">(.*?)<\/span>/g;
			while ((match = regex.exec(text)) !== null) {
				const [calc, options] = match[1].split('|');
				cleanup.push({
					needle: match[0],
					replace: `´${calc}´{${options}}`,
				});
			}

			cleanup.forEach(entry => {
				text = text.replace(entry.needle, entry.replace);
			});

			const variables = this.$store.state.shared.variables;
			for (let key in variables) {
				text = text.replace(new RegExp(key, 'g'), variables[key]);
			}

			regex = /(´|&acute;)(.*?)(´|&acute;)(\{[.,]?[.,]?[\d]?\})?/g;
			match;
			let matches = [];
			while ((match = regex.exec(text)) !== null) {
				matches.push({
					replace: match[0],
					calc: _elementFromTemplate(`<div>${match[2]}</div>`).textContent,
					settings: match[4],
				});
			}

			matches.forEach(match => {
				let nsep = '';
				let msep = ',';
				let dcas = 2;

				if (match.settings) {
					switch (match.settings.length) {
						case 3:
							if (!isNaN(parseInt(match.settings[1])))
								dcas = parseInt(match.settings[1]);
							else msep = match.settings[1];
							break;

						case 4:
							if (!isNaN(parseInt(match.settings[2]))) {
								msep = match.settings[1];
								dcas = parseInt(match.settings[2]);
							} else {
								nsep = match.settings[1];
								msep = match.settings[2];
							}
							break;

						case 5:
							nsep = match.settings[1];
							msep = match.settings[2];
							dcas = match.settings[3];
							break;
					}
				}

				text = text.replace(match.replace, _calc(match.calc, msep, dcas, nsep));
			});

			this.commitChange('props', { useVariables: false });
			this.commitChange('props', { text });

			let module = document.getElementById(this.module.mid);
			if (module) {
				let decorator = module.getElementsByClassName('fd-decorator');
				decorator[0].setAttribute('style', 'z-index: -30;');
			}

			this.$root.$emit('updateEditor');
			setTimeout(() => {
				this.$root.$emit('updateText' + this.module.mid);
			}, 10);
		},
		abortRemoveVariable() {
			this.$children[1].$children[2].$children[0].checked = true;
		},
		setUseVariables(useVariables) {

			if (!useVariables) {
				let contain = _containsVariables(this.module.props.text)
				if (contain) {
					this.$store.commit('window/create', {
						id: 'variableleft',
						component: VariableLeftWindow,
					});
				} else {
					let module = document.getElementById(this.module.mid);
					if (module) {
						let decorator = module.getElementsByClassName('fd-decorator');
						decorator[0].setAttribute('style', 'z-index: -30;');
					}

					this.commitChange('props', { useVariables });

					this.$root.$emit('updateEditor');
				}
			} else {
				let module = document.getElementById(this.module.mid);
				if (module) {
					let decorator = module.getElementsByClassName('fd-decorator');
					decorator[0].setAttribute('style', '');
				}

				this.commitChange('props', { useVariables });

				this.$root.$emit('updateEditor');
			}
		},
	},
};

export default {
	extends: TextModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.textmodule.title: Text
fd.textmodule.description: >
	Fügt ein Textelement hinzu. Sie können eigene Texte eingeben oder Texte aus
	der Zwischenablage einfügen.
fd.textmodule.add: Text hinzufügen
fd.textmodule.default.text: Klicken Sie hier für die Bearbeitung

fd.textmodule.settings.autoresizing: Autohöhe
fd.textmodule.settings.usevariables: Textvariablen
fd.textmodule.settings.editor.open: Text bearbeiten
fd.textmodule.settings.editor.close: Editor schließen

fd.textmodule.variableleft.head: Achtung!
fd.textmodule.variableleft.body: Ihr Text enthält Textvariablen. Wenn Sie diese weiter nutzen möchten, klicken Sie auf Abbrechen. Wenn Sie künftig keine Variablen benötigen, klicken Sie auf "ENTFERNEN"
fd.textmodule.variableleft.delete: Entfernen
fd.textmodule.variableleft.abort: Abbrechen

fd.textmodule.varchooser.head: Wählen Sie eine Variable...
fd.textmodule.varchooser.okbutton: Wählen
fd.textmodule.varchooser.error.head: Fehler beim Einfügen einer Variable!
fd.textmodule.varchooser.error.body: An dieser Stelle kann leider keine Variable eingefügt werden.

fd.textmodule.calcchooser.head: Konfigurieren Sie eine Berechnung...
fd.textmodule.calcchooser.okbutton: Fertig
fd.textmodule.calcchooser.error.head: Fehler beim Einfügen einer Berechnung!
fd.textmodule.calcchooser.error.body: An dieser Stelle kann leider keine Berechnung eingefügt werden.
fd.textmodule.calcchooser.section.calc: Berechnung
fd.textmodule.calcchooser.section.settings: Formatierung
fd.textmodule.calcchooser.nsep: Tausendertrenner
fd.textmodule.calcchooser.msep: Nachkommazeichen
fd.textmodule.calcchooser.dcas: Nachkommastellen
fd.textmodule.calcchooser.dot: Punkt
fd.textmodule.calcchooser.comma: Komma
</i18n>
