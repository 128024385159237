<script>
import CONFIG from '../Config.js';
import ImageModule from './ImageModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import { _loadImage } from '../util/helper.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'ImageModule',
	icon: 'image',
	type: 'light',
}

export const MetaDescriptionView = {
	name: 'ImageMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: "ImageModule",
		x: "center",
		y: "center",
		width: 500,
		height: 317,
		style: {
			backgroundColor: {r: 0, g: 0, b: 0, a: 0},
			border: null,
			shadow: {
				color: {r: 0, g: 0, b: 0, a: 0}
			},
			opacity: null,
			advanced: null
		},
		props: {
			images: [{
				path: CONFIG.BASEPATH + '/img/sample.png'
			}],
			size: 'contain',
			slide: {
				running: false,
				duration: 2,
				delay: 1000
			}
		}
	};
}

export const description = {
	extends: DescriptionView,
	mounted() {
		const files = this.$store.state.shared.files;
		const file = files.find(
			file => this.module.props.images[0].path == file.path
		);

		this.description = file && file.name;
	}
}

export const ConfigView = {
	name: 'ImageModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [
				{
					type: 'File',
					label: this.$_('fd.imagemodule.settings.image'),
					fileTypeKey: 'image',
					allowed: /^image/,
					visible: 'normal',
					multiselect: this.module.props.slide.running,
					value: this.module.props.images,
					events: {
						input: this.setImages
					}
				},
				{
					type: 'Button',
					label: this.$_('fd.imagemodule.settings.fit'),
					events: {
						click: this.fitImage
					}
				},
				{
					type: 'Select',
					label: this.$_('fd.imagemodule.settings.ratio'),
					value: this.module.props.size,
					values: [{
						label: this.$_('fd.imagemodule.settings.ratio.in'),
						value: 'contain',
					}, {
						label: this.$_('fd.imagemodule.settings.ratio.out'),
						value: 'cover',
					}, {
						label: this.$_('fd.imagemodule.settings.ratio.off'),
						value: '100% 100%',
					}],
					events: {
						input: this.setSize
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.imagemodule.settings.running'),
					value: this.module.props.slide.running,
					visible: this.$store.state.type != 'logo',
					events: {
						input: this.setRunning
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.imagemodule.settings.duration'),
					visible: this.module.props.slide.running,
					value: this.module.props.slide.duration,
					visible: this.module.props.slide.running && this.$store.state.type != 'logo',
					min: 0,
					max: 5,
					events: {
						input: this.setDuration
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.imagemodule.settings.delay'),
					visible: this.module.props.slide.running && this.$store.state.type != 'logo',
					value: this.module.props.slide.delay / 1000,
					min: 1,
					max: 60,
					events: {
						input: this.setDelay
					}
				}
			];
		}
	},
	mounted() {
		if(this.$store.state.type == 'logo') {
			this.$root.$on('resizeEnd', () => {
				this.fitImage();
			});
		}
	},
	methods: {
		setImages(images) {
			this.commitChange('props', { images });
			if(this.$store.state.type == 'logo') {
				this.fitImage();
			}
		},
		async fitImage() {
			let width = this.module.width;
			let height = this.module.height;

			// Calculate border offset
			const offsetWidth =
				this.module.style.border.left.px
				+ this.module.style.border.right.px;
			const offsetHeight =
				this.module.style.border.top.px
				+ this.module.style.border.bottom.px;

			// Remove border for correct resizing calculation
			width -= offsetWidth;
			height -= offsetHeight;

			// Resize
			const shouldRatio = await _loadImage(this.module.props.images[0].path)
				.then(img => img.width / img.height);
			const isRatio = width / height;

			const change = isRatio < shouldRatio ? {
				height: width / shouldRatio + offsetHeight
			} : {
				width: height * shouldRatio + offsetWidth
			}

			// Set new size
			this.commitChange('set', change);
		},
		setSize(size) {
			this.commitChange('props', { size });
		},
		setRunning(running) {
			this.commitChange('props', {
				slide: {
					...this.module.props.slide,
					running
				}
			});
		},
		setDuration(duration) {
			this.commitChange('props', {
				slide: {
					...this.module.props.slide,
					duration
				}
			});
		},
		setDelay(delay) {
			this.commitChange('props', {
				slide: {
					...this.module.props.slide,
					delay: delay * 1000
				}
			});
		},
	}
}

export default {
	extends: ImageModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.imagemodule.title: Bild
fd.imagemodule.description: >
	Fügt eigene Bilder und Fotos hinzu. Sie können eigene Bildmotive hochladen
	und an der gewünschten Stelle einfügen.
fd.imagemodule.add: Bild hinzufügen

fd.imagemodule.settings.image: Bild
fd.imagemodule.settings.fit: Bild einpassen
fd.imagemodule.settings.ratio: Seitenverhältnis
fd.imagemodule.settings.ratio.in: an (innerhalb)
fd.imagemodule.settings.ratio.out: an (außerhalb)
fd.imagemodule.settings.ratio.off: aus
fd.imagemodule.settings.running: Bilder wechseln
fd.imagemodule.settings.duration: Animationsdauer
fd.imagemodule.settings.delay: Bilddauer
</i18n>
