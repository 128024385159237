<template>
  <div class="fd-fade-animation" :class="classList" :style="style">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'FadeIn',
  icon: 'airplane2',
	props: ['animation'],
	data() {
		return {
			opacity: 0,
      classList: []
		}
  },
  computed: {
    style() {
			return {
        opacity: this.opacity,
        animationDuration: this.animation.duration + 's'
			}
    },
  },
	mounted() {
    if(typeof this.animation.direction == 'undefined') {
      this.animation.direction = 'b';
    }
    if(typeof this.animation.duration == 'undefined') {
      this.animation.duration = 1;
    }
    if(typeof this.animation.delay == 'undefined') {
      this.animation.delay = 0;
    }
		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
		this.$root.$on('showAnimatedModules', _ => this.opacity = 1 );
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
      if(delay) {
        delay = this.animation.delay;
      } else {
        delay = 0;
      }
      let duration = this.animation.duration || 1;
      this.$options.timeout = setTimeout(_ => {
        this.opacity = '';
        this.classList.push(this.animationClass());
        setTimeout(_ => {
          clearTimeout(this.$options.timeout);
          this.classList = ['animated'];
        }, duration * 1000 + 100);
      }, delay * 1000);
		},

		stop() {
      clearTimeout(this.$options.timeout);
      this.opacity = 0;
      this.classList = ['animated'];
    },
    
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
    },
    
    animationClass() {
      switch(this.animation.direction) {
        case 'l': return 'fadeInLeft';
        case 'r': return 'fadeInRight';
        default: return 'fadeInUp';
      }
    },
  }
}
</script>

<style lang="scss">
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fd-fade-animation {
  &.fadeInUp {
    animation-name: fadeInUp;
  }

  &.fadeInLeft {
    animation-name: fadeInLeft;
  }

  &.fadeInRight {
    animation-name: fadeInRight;
  }
}
</style>
