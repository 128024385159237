<template>
	<div class="fd-placeholder-module" :style="style">
		<i :class="meta.icon"></i>
		<div class="info">{{$_('fd.placeholdermodule.info')}}</div>
	</div>
</template>

<script>
import PlaceholderModule from './PlaceholderModule.vue';
import DescriptionView from '../components/Description.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import {
	_getMin,
	_getPosition,
} from '../util/helper.js';
import '../../scss/fonts/opensans.scss';

export const meta = {
	name: PlaceholderModule.name,
	icon: 'target',
}

export function getTemplate() {
	return {
		animation: [],
		locked: false,
		class: meta.name,
		x: 'center',
		y: 'center',
		width: 700,
		height: 450,
		style: {
			backgroundColor: { r: 255, g: 255, b: 255, a: 60 },
			border: null,
			shadow: null,
		}
	};
}

export const description = {
	extends: DescriptionView,
	data() {
		return {
			description: this.$_('fd.placeholdermodule.title')
		}
	}
}

export const ConfigView = {
	name: 'PlaceholderModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta, config: [] }),
}

export default {
	extends: PlaceholderModule,
	props: ['module'],
	data: () => ({ meta }),
	computed: {
		style() {
			const fontSize = _getMin([this.module.width, this.module.height]) * 0.5;
			return {
				fontSize: `${fontSize}px`
			}
		}
	},
	mounted() {
		this.$options.unsubscribe = this.$store.subscribe(this.onMutation);
	},
	beforeDestroy() {
		this.$options.unsubscribe();
	},
	methods: {
		onMutation({ type, payload }, state) {
			if(payload === this.module.mid) return;

			if(type === 'moduleMoveStart') {
				this.someModuleMoveStart(payload);
			}
			if(type === 'moduleMove') {
				this.someModuleMove(payload);
			}
			if(type === 'moduleMoveEnd') {
				this.someModuleMoveEnd(payload);
			}
		},
		getModule(mid) {
			// if(!this.$options.modules) {
			// 	this.$options.modules = {};
			// }

			// if(this.$options.modules[mid]) {
			// 	return this.$options.modules[mid];
			// }

			// const m = this.$store.state.data.modules.find(
			// 	m => m.mid === mid
			// );

			// this.$options.modules[mid] = m;

			// return m;
			return this.$store.getters.modules[mid];
		},
		someModuleMoveStart(mid) {
			const { width, height } = this.getModule(mid);
			this.$options.module = { width, height };
		},
		someModuleMove(mid) {
			const m = this.getModule(mid);
			if(!m) return;
			if(m.class === meta.name) return;
			if(m.z <= this.module.z) return;

			const self = this.getModule(this.module.mid);
			const { width, height } = this.$options.module;

			this.$store.commit('highlightedModules', self.mid);

			let values = null;

			if(
				// check if module is between x and width
				m.x + width / 2 > self.x + 10 &&
				m.x + width / 2 < self.x + self.width - 10 &&

				// check if module is between y and height
				m.y + height / 2 > self.y + 10 &&
				m.y + height / 2 < self.y + self.height - 10
			) {
				values = {
					x: self.x,
					y: self.y,
					width: self.width,
					height: self.height,
				}
			}

			if(
				m.x + width / 2 < self.x + 10 &&
				m.x + width / 2 > self.x - 10 ||

				m.x + width / 2 > self.x + self.width - 10 &&
				m.x + width / 2 < self.x + self.width + 10 ||

				m.y + height / 2 < self.y + 10 &&
				m.y + height / 2 > self.y - 10 ||

				m.y + height / 2 > self.y + self.height - 10 &&
				m.y + height / 2 < self.y + self.height + 10
			) {
				values = {
					width: this.$options.module.width,
					height: this.$options.module.height,
				}
			}

			if(!values) return;

			this.$store.commit('changeModules', {
				type: 'set',
				modules: [mid],
				values
			});
		},
		someModuleMoveEnd(mid) {
			this.$store.commit('highlightedModules', []);

			const m = this.getModule(mid);
			if(!m) return;
			if(m.class === meta.name) return;
			if(m.z <= this.module.z) return;

			const module = this.getModule(this.module.mid);
			const { width, height } = this.$options.module;

			if(
				// check if module is between x and width
				m.x + width / 2 > module.x + 10 &&
				m.x + width / 2 < module.x + module.width - 10 &&

				// check if module is between y and height
				m.y + height / 2 > module.y + 10 &&
				m.y + height / 2 < module.y + module.height - 10 &&

				// check z index
				m.z > module.z
			) {} else return;

			const style = {};

			for (const key in this.module.style) {
				if(m.style.hasOwnProperty(key)) {
					style[key] = this.module.style[key];
				}
			}

			this.$store.commit('changeModules', {
				type: 'style',
				modules: [mid],
				values: style
			});

			this.$store.commit('deleteModules', [module.mid]);

			this.$store.commit('changeModules', {
				type: 'set',
				modules: [mid],
				values: {
					mid: module.mid
				}
			});

			this.$store.commit('activeModules', [module.mid]);
		},
	},

	// extra
	meta,
	getTemplate,
	description,
	ConfigView,
}
</script>

<style lang="scss">
.fd-placeholder-module {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: rgba(0, 0, 0, 0.6);

	.info {
		font-family: OpenSans, sans-serif;
		margin: 25px 40px;
		font-size: 24px;
	}
}
</style>


<i18n lang="de_DE">
fd.placeholdermodule.title: Platzhalter
fd.placeholdermodule.info: >
	Dies ist ein Platzhalterelement. Ziehen Sie ein anderes Modul an diese
	Stelle, um es hier zu positionieren.
</i18n>
