<script>
import SimpleConfigView from './SimpleConfig.vue';
import * as MODULES from '../modules/_MODULES_EXTRA.js';
import { _last, _deepCopy } from '../util/helper.js';

export default {
	name: 'BasicFullDescriptionView',
	extends: SimpleConfigView,
	computed: {
		config() {
			return [
				{
					type: 'Info',
					text: this.$_('fd.' + this.meta.name + '.description'),
				},
				{
					type: 'Button',
					label: this.$_('fd.' + this.meta.name + '.add'),
					events: {
						click: () => this.addModule(),
					},
				},
			];
		},
	},
	methods: {
		async addModule() {
			let add = false;
			if (this.$store.state.type == 'light') {
				if (MODULES[this.meta.name].meta.type == 'light') {
					add = true;
				} else {
					this.$root.$emit('proInformation');
				}
			} else {
				if(MODULES[this.meta.name].meta.datasource && this.$store.state.shared.datasources.length == 0) {
					this.$root.$emit('noDatasource');
				} else {
					add = true;
				}
			}
			if (add) {
				const template = MODULES[this.meta.name].getTemplate(this.$store, this.$_);
				const mid = await this.$store.dispatch('data/module', template);
				this.$store.commit('activeModules', [mid]);
				this.$store.commit('highlightedModules', []);
				this.$store.dispatch(
					'subNav/components',
					MODULES[this.meta.name].ConfigView
				);

				this.$store.commit('setHistory', 'Add Module');
				this.$root.$emit('initModuleSnapping');
			}
		},
		async addModuleGroup(modules) {
			if(this.$store.state.type != 'light') {
				modules = await Promise.all(
					modules.map(template => this.$store.dispatch('data/module', template))
				);

				const groupModule = await this.$store.dispatch('group', modules);

				this.$store.dispatch('subNav/components', MODULES.GroupModule.ConfigView);
				this.$root.$emit('initModuleSnapping');

				return groupModule;
			} else {
				this.$root.$emit('proInformation');
			}
		},
	},
};
</script>
