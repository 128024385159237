export const KEY = 'lineHeight';

export const CONFIG = {
	type: 'Number',
	min: 10,
	max: 300
}

export function normalize(value) {
	return value || 100;
}

export function compute(value) {
	return {
		lineHeight: normalize(value) / 100
	}
}
