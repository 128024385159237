function init() {
	return {
		createPoint: null,
		entrys: []
	}
}

export async function create() {
	return {
		namespaced: true,

		state: init(),

		mutations: {
			open(state, payload) {
				state.createPoint = payload.createPoint;
				state.entrys = payload.entrys;
			},
			close(state) {
				state.entrys = [];
			}
		}
	}
}
