<script>
import Custom from './Custom.vue';
import ConfigItemView from '../components/ConfigItem.vue';
import SettingsView from '../components/Settings.vue';

export default {
	name: 'CustomConfigView',
	extends: SettingsView,
	components: { ConfigItemView },
	computed: {
		icon() {
			return 'star';
		},
		headline() {
			return this.$_('fd.animation.custom.desc');
		},
		config() {
			return [{
				type: 'Number',
				label: this.$_('fd.animations.settings.speed'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].duration || 1,
				events: {
					input: this.setDuration
				}
			}, {
				type: 'Number',
				label: this.$_('fd.animations.settings.delay'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].delay || 0,
				events: {
					input: this.setDelay
				}
			}, {
				type: 'Number',
				label: this.$_('fd.animation.custom.settings.repeat'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].repeat || 0,
				events: {
					input: this.setRepeat
				}
			}, {
				type: 'Text',
				label: this.$_('fd.animation.custom.settings.path'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].path || 'M0,0 L10,100 L20,100 L30,0 L50,0 L60,100 L70,100 L80,0 L80,0',
				events: {
					input: this.setPath
				}
			}, {
				type: 'Textarea',
				label: this.$_('fd.animation.custom.settings.progress'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].progress || 'scaleX: $progress',
				events: {
					input: this.setProgress
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play'),
				events: {
					click: this.startAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.delete'),
				icon: 'bin',
				events: {
					click: this.deleteAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play.all'),
				events: {
					click: this.startAll
				}
				}];
		}
	},
	methods: {
		setDuration(duration) {
			this.$root.$emit('changeAnimation', {
				type: 'duration',
				value: duration,
				self: this,
			});
		},
		setDelay(delay) {
			this.$root.$emit('changeAnimation', {
				type: 'delay',
				value: delay,
				self: this,
			});
		},
		setRepeat(repeat) {
			this.$root.$emit('changeAnimation', {
				type: 'repeat',
				value: repeat,
				self: this,
			});
		},
		setPath(path) {
			this.$root.$emit('changeAnimation', {
				type: 'path',
				value: path,
				self: this,
			});
		},
		setProgress(progress) {
			this.$root.$emit('changeAnimation', {
				type: 'progress',
				value: progress,
				self: this,
			});
		},
		startAnimation() {
			this.$root.$emit('startAnimation');
		},
		deleteAnimation() {
			this.$store.dispatch('deleteAnimation', this.module);
			this.$root.$emit('update');
		},
		startAll() {
			this.$root.$emit('startAllAnimations');
		},
	}
}

</script>
