import { _evalDefault } from '../util/helper.js';

export const KEY = 'padding';

export const CONFIG = {
	type: 'Number',
	min: 0,
	max: 200
}

export function normalize(value) {
	return _evalDefault(value, 10);
}

export function watchHook(newPadding, oldPadding) {
	const offset = newPadding - oldPadding;
	return {
		type: 'offset',
		values: {
			x: -offset,
			y: -offset,
			width: offset * 2,
			height: offset * 2,
		}
	}
}

export function compute(value) {
	return {
		padding: normalize(value) + 'px'
	}
}
