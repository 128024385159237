<template>
  <div class="fd-flip-animation" :class="classList" :style="style">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'Flip',
  icon: 'spinner11',
	props: ['animation'],
	data() {
		return {
			opacity: this.animation.direction == 'in' ? 0 : 1,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
        opacity: this.opacity,
				transition: `opacity ${this.duration}s ease-in-out`,
        animationDuration: this.animation.duration + 's'
			}
    },
  },
	mounted() {
    if(typeof this.animation.direction == 'undefined') {
      this.animation.direction = 'in';
    }
    if(typeof this.animation.duration == 'undefined') {
      this.animation.duration = 1;
    }
    if(typeof this.animation.delay == 'undefined') {
      this.animation.delay = 0;
    }
    this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
		this.$root.$on('showAnimatedModules', _ => this.opacity = 1 );
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
      if(delay) {
        delay = this.animation.delay;
      } else {
        delay = 0;
      }
      this.$options.timeout = setTimeout(_ => {
        let duration = this.animation.duration ? this.animation.duration : 1;
        this.opacity = '';
        this.classList.push(this.animationClass());
        setTimeout(_ => {
          clearTimeout(this.$options.timeout);
          this.duration = this.animation.duration / 2;
          this.opacity = this.animation.direction == 'in' ? 1 : 0;
          this.classList = ['animated'];
        }, duration * 1000 + 100);
      }, delay * 1000);
		},

		stop() {
      clearTimeout(this.$options.timeout);
      this.opacity = this.animation.direction == 'in' ? 0 : 1;
			this.classList = ['animated'];
    },
    
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
    },

    animationClass() {
      switch(this.animation.direction) {
        case 'in': return 'flipInX';
        default: return 'flipOutX';
      }
    }
  }
}
</script>

<style lang="scss">
.fd-flip-animation {
  &.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
  }

  &.flipOutX {
    animation-duration: 0.75s;
    animation-name: flipOutX;
    backface-visibility: visible !important;
  }
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0
  }
}
</style>
