<script>
import CloudModule from './CloudModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'CloudModule',
	icon: 'cloud2',
	resize: 2,
};

export const MetaDescriptionView = {
	name: 'CloudMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'CloudModule',
		x: 'center',
		y: 'center',
		width: 200,
		height: 100,
		style: {
			opacity: 80,
			advanced: null,
		},
	};
}

export const ConfigView = {
	name: 'CloudModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [];
		},
	},
};

export default {
	extends: CloudModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.cloudmodule.title: Wolke
fd.cloudmodule.description: >
	Das Wolke-Modul zeigt eine einzelne Wolke.
fd.cloudmodule.add: Wolke hinzufügen
</i18n>
