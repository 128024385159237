
function init(config) {
	const state = {
		logo_modules: [],
		variables: {},
		fonts: [],
		files: [],
		datasources: [],
		templates: [],
	};

	for(const key in config.shared) {
		if(config.shared[key] instanceof Promise) {
			config.shared[key].then(result => {
				if(result instanceof Array) {
					state[key] = [
						...state[key],
						...result
					];
				}
				else {
					state[key] = {
						...state[key],
						...result
					};
				}
			});
		}
		else {
			state[key] = config.shared[key];
		}
	}

	return state;
}

export async function create(config) {
	return {
		namespaced: true,

		state: init(config),

		mutations: {
			mutation(state, mutation) {
				for(const key in mutation) {
					state[key] = mutation[key];
				}
			}
		}
	}
}
