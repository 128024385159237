import { _exists } from './helper.js';

const _ = function(element) {
	if(this.init) this.init();

	element.movable = true;

	return element;
};

_.init = function() {
	this.addCorrectBinding();
	this.initEventListener();
	this.init = false;
};

_.addCorrectBinding = function() {
	this.start = this.start.bind(this);
	this.move = this.move.bind(this);
	this.progress = this.progress.bind(this);
	this.end = this.end.bind(this);
}

_.initEventListener = function() {
	document.addEventListener('mousedown', this.start, {passive: true});
	document.addEventListener('mousemove', this.move, {passive: true});
	document.addEventListener('mouseup', this.end, true);

	document.addEventListener('touchstart', this.start, {passive: true});
	document.addEventListener('touchmove', this.move, {passive: true});
	document.addEventListener('touchend', this.end, true);
}

_.current = false;

_.start = function(evt) {
	if(this.current) return;

	if(!evt.target.movable) return;

	const startX = _exists(evt.pageX) ? evt.pageX : evt.touches[0].pageX;
	const startY = _exists(evt.pageY) ? evt.pageY : evt.touches[0].pageY;

	this.current = {
		target: evt.target,
		startX: startX,
		startY: startY,
		currentX: startX,
		currentY: startY,
		moveX: 0,
		moveY: 0
	};

	document.body.style.userSelect = 'none';
	this.current.target.dispatchEvent(new CustomEvent('move:start', {
		detail: {
			...this.current,
			original: evt,
			type: 'move:start'
		}
	}));
}

_.move = function(evt) {
	if(!this.current) return;

	this.current.currentX = _exists(evt.pageX) ? evt.pageX : evt.touches[0].pageX;
	this.current.currentY = _exists(evt.pageY) ? evt.pageY : evt.touches[0].pageY;

	this.current.moveX = this.current.currentX - this.current.startX;
	this.current.moveY = this.current.currentY - this.current.startY;

	// Sometimes move was fired, but there is no move
	if(this.current.moveX === 0 && this.current.moveY === 0) return;

	requestAnimationFrame(this.progress);
}

_.progress = function() {
	if(!this.current) return;

	this.current.target.dispatchEvent(new CustomEvent('move', {
		detail: {
			...this.current,
			type: 'move'
		}
	}));
}

_.end = function(evt) {
	if(!this.current) return;

	document.body.style.userSelect = '';
	this.current.target.dispatchEvent(new CustomEvent('move:end', {
		detail: {
			...this.current,
			original: evt,
			type: 'move:end'
		}
	}));

	this.current = false;
}

export default _.bind(_);
