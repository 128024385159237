import * as FontFamily from './FontFamily.js';
import * as FontStyle from './FontStyle.js';
import * as TextAlign from './TextAlign.js';
import * as FontSize from './FontSize.js';
import * as Color from './Color.js';
import * as LineHeight from './LineHeight.js';
import * as Padding from './Padding.js';
import * as Margin from './Margin.js';
import * as BackgroundColor from './BackgroundColor.js';
import * as Opacity from './Opacity.js';
import * as Border from './Border.js';
import * as Blur from './Blur.js';
import * as Advanced from './Advanced.js';
import * as Shadow from './Shadow.js';

export const fontFamily = FontFamily;
export const fontStyle = FontStyle;
export const textAlign = TextAlign;
export const fontSize = FontSize;
export const color = Color;
export const lineHeight = LineHeight;
export const padding = Padding;
export const margin = Margin;
export const backgroundColor = BackgroundColor;
export const opacity = Opacity;
export const border = Border;
export const blur = Blur;
export const advanced = Advanced;
export const shadow = Shadow;
