<template>
  <canvas
    class="fd-clock-module"
    :width="size.width"
    :height="size.height"
    :date-time="time"
  ></canvas>
</template>

<style>
.fd-clock-module {
  display: block !important;
  margin: 0 auto;
  width: initial !important;
  height: initial !important;
}
</style>

<script>
import { _execTemplate } from '../util/helper.js';
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
  name: 'ClockModule',
  extends: BaseModule,
	normalize,
  data() {
    return {
      clock: null
    }
  },
  computed: {
    size() {
      const height = this.module.height - this.module.style.padding * 2;
      const width = this.module.width - this.module.style.padding * 2;

      return {
        width: !this.module.props.digital && width > height ? height : width,
        height: !this.module.props.digital && width < height ? width : height
      }
    },
    time() {
      if(!this.clock) return null;

      this.$options.clock.draw();
      return this.$store.state.intern.timestamp;
    }
  },
  mounted() {
    // TODO: Integrate into a full vue module
    this.$options.clock = new Clock(this);
    this.clock = true;
  },
  beforeDestroy() {
    this.clock = false;
    this.$options.clock.destroy();
    this.$options.clock = null;
  },
  methods: {
    start() {
      this.$store.dispatch('intern/startTimestampInterval');
    },
    stop() {
      this.$store.dispatch('intern/stopTimestampInterval');
    }
  }
}

export const timezones = [
  {
    'name': 'Dateline Standard Time',
    'abbr': 'DST',
    'value': -12,
    'isdst': false,
    'text': '(UTC-12:00) International Date Line West'
  },
  {
    'name': 'UTC-11',
    'abbr': 'U',
    'value': -11,
    'isdst': false,
    'text': '(UTC-11:00) Coordinated Universal Time-11'
  },
  {
    'name': 'Hawaiian Standard Time',
    'abbr': 'HST',
    'value': -10,
    'isdst': false,
    'text': '(UTC-10:00) Hawaii'
  },
  {
    'name': 'Alaskan Standard Time',
    'abbr': 'AKDT',
    'value': -8,
    'isdst': true,
    'text': '(UTC-09:00) Alaska'
  },
  {
    'name': 'Pacific Standard Time (Mexico)',
    'abbr': 'PDT',
    'value': -7,
    'isdst': true,
    'text': '(UTC-08:00) Baja California'
  },
  {
    'name': 'Pacific Standard Time',
    'abbr': 'PDT',
    'value': -7,
    'isdst': true,
    'text': '(UTC-08:00) Pacific Time (US & Canada)'
  },
  {
    'name': 'US Mountain Standard Time',
    'abbr': 'UMST',
    'value': -7,
    'isdst': false,
    'text': '(UTC-07:00) Arizona'
  },
  {
    'name': 'Mountain Standard Time (Mexico)',
    'abbr': 'MDT',
    'value': -6,
    'isdst': true,
    'text': '(UTC-07:00) Chihuahua, La Paz, Mazatlan'
  },
  {
    'name': 'Mountain Standard Time',
    'abbr': 'MDT',
    'value': -6,
    'isdst': true,
    'text': '(UTC-07:00) Mountain Time (US & Canada)'
  },
  {
    'name': 'Central America Standard Time',
    'abbr': 'CAST',
    'value': -6,
    'isdst': false,
    'text': '(UTC-06:00) Central America'
  },
  {
    'name': 'Central Standard Time',
    'abbr': 'CDT',
    'value': -5,
    'isdst': true,
    'text': '(UTC-06:00) Central Time (US & Canada)'
  },
  {
    'name': 'Central Standard Time (Mexico)',
    'abbr': 'CDT',
    'value': -5,
    'isdst': true,
    'text': '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
  },
  {
    'name': 'Canada Central Standard Time',
    'abbr': 'CCST',
    'value': -6,
    'isdst': false,
    'text': '(UTC-06:00) Saskatchewan'
  },
  {
    'name': 'SA Pacific Standard Time',
    'abbr': 'SPST',
    'value': -5,
    'isdst': false,
    'text': '(UTC-05:00) Bogota, Lima, Quito'
  },
  {
    'name': 'Eastern Standard Time',
    'abbr': 'EDT',
    'value': -4,
    'isdst': true,
    'text': '(UTC-05:00) Eastern Time (US & Canada)'
  },
  {
    'name': 'US Eastern Standard Time',
    'abbr': 'UEDT',
    'value': -4,
    'isdst': true,
    'text': '(UTC-05:00) Indiana (East)'
  },
  {
    'name': 'Venezuela Standard Time',
    'abbr': 'VST',
    'value': -4.5,
    'isdst': false,
    'text': '(UTC-04:30) Caracas'
  },
  {
    'name': 'Paraguay Standard Time',
    'abbr': 'PST',
    'value': -4,
    'isdst': false,
    'text': '(UTC-04:00) Asuncion'
  },
  {
    'name': 'Atlantic Standard Time',
    'abbr': 'ADT',
    'value': -3,
    'isdst': true,
    'text': '(UTC-04:00) Atlantic Time (Canada)'
  },
  {
    'name': 'Central Brazilian Standard Time',
    'abbr': 'CBST',
    'value': -4,
    'isdst': false,
    'text': '(UTC-04:00) Cuiaba'
  },
  {
    'name': 'SA Western Standard Time',
    'abbr': 'SWST',
    'value': -4,
    'isdst': false,
    'text': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
  },
  {
    'name': 'Pacific SA Standard Time',
    'abbr': 'PSST',
    'value': -4,
    'isdst': false,
    'text': '(UTC-04:00) Santiago'
  },
  {
    'name': 'Newfoundland Standard Time',
    'abbr': 'NDT',
    'value': -2.5,
    'isdst': true,
    'text': '(UTC-03:30) Newfoundland'
  },
  {
    'name': 'E. South America Standard Time',
    'abbr': 'ESAST',
    'value': -3,
    'isdst': false,
    'text': '(UTC-03:00) Brasilia'
  },
  {
    'name': 'Argentina Standard Time',
    'abbr': 'AST',
    'value': -3,
    'isdst': false,
    'text': '(UTC-03:00) Buenos Aires'
  },
  {
    'name': 'SA Eastern Standard Time',
    'abbr': 'SEST',
    'value': -3,
    'isdst': false,
    'text': '(UTC-03:00) Cayenne, Fortaleza'
  },
  {
    'name': 'Greenland Standard Time',
    'abbr': 'GDT',
    'value': -2,
    'isdst': true,
    'text': '(UTC-03:00) Greenland'
  },
  {
    'name': 'Montevideo Standard Time',
    'abbr': 'MST',
    'value': -3,
    'isdst': false,
    'text': '(UTC-03:00) Montevideo'
  },
  {
    'name': 'Bahia Standard Time',
    'abbr': 'BST',
    'value': -3,
    'isdst': false,
    'text': '(UTC-03:00) Salvador'
  },
  {
    'name': 'UTC-02',
    'abbr': 'U',
    'value': -2,
    'isdst': false,
    'text': '(UTC-02:00) Coordinated Universal Time-02'
  },
  {
    'name': 'Mid-Atlantic Standard Time',
    'abbr': 'MDT',
    'value': -1,
    'isdst': true,
    'text': '(UTC-02:00) Mid-Atlantic - Old'
  },
  {
    'name': 'Azores Standard Time',
    'abbr': 'ADT',
    'value': 0,
    'isdst': true,
    'text': '(UTC-01:00) Azores'
  },
  {
    'name': 'Cape Verde Standard Time',
    'abbr': 'CVST',
    'value': -1,
    'isdst': false,
    'text': '(UTC-01:00) Cape Verde Is.'
  },
  {
    'name': 'Morocco Standard Time',
    'abbr': 'MDT',
    'value': 1,
    'isdst': true,
    'text': '(UTC) Casablanca'
  },
  {
    'name': 'UTC',
    'abbr': 'CUT',
    'value': 0,
    'isdst': false,
    'text': '(UTC) Coordinated Universal Time'
  },
  {
    'name': 'GMT Standard Time',
    'abbr': 'GDT',
    'value': 1,
    'isdst': true,
    'text': '(UTC) Dublin, Edinburgh, Lisbon, London'
  },
  {
    'name': 'Greenwich Standard Time',
    'abbr': 'GST',
    'value': 0,
    'isdst': false,
    'text': '(UTC) Monrovia, Reykjavik'
  },
  {
    'name': 'W. Europe Standard Time',
    'abbr': 'WEDT',
    'value': 2,
    'isdst': true,
    'text': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  {
    'name': 'Central Europe Standard Time',
    'abbr': 'CEDT',
    'value': 2,
    'isdst': true,
    'text': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  {
    'name': 'Romance Standard Time',
    'abbr': 'RDT',
    'value': 2,
    'isdst': true,
    'text': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
  },
  {
    'name': 'Central European Standard Time',
    'abbr': 'CEDT',
    'value': 2,
    'isdst': true,
    'text': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
  },
  {
    'name': 'W. Central Africa Standard Time',
    'abbr': 'WCAST',
    'value': 1,
    'isdst': false,
    'text': '(UTC+01:00) West Central Africa'
  },
  {
    'name': 'Namibia Standard Time',
    'abbr': 'NST',
    'value': 1,
    'isdst': false,
    'text': '(UTC+01:00) Windhoek'
  },
  {
    'name': 'GTB Standard Time',
    'abbr': 'GDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Athens, Bucharest'
  },
  {
    'name': 'Middle East Standard Time',
    'abbr': 'MEDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Beirut'
  },
  {
    'name': 'Egypt Standard Time',
    'abbr': 'EST',
    'value': 2,
    'isdst': false,
    'text': '(UTC+02:00) Cairo'
  },
  {
    'name': 'Syria Standard Time',
    'abbr': 'SDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Damascus'
  },
  {
    'name': 'E. Europe Standard Time',
    'abbr': 'EEDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) E. Europe'
  },
  {
    'name': 'South Africa Standard Time',
    'abbr': 'SAST',
    'value': 2,
    'isdst': false,
    'text': '(UTC+02:00) Harare, Pretoria'
  },
  {
    'name': 'FLE Standard Time',
    'abbr': 'FDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
  },
  {
    'name': 'Turkey Standard Time',
    'abbr': 'TDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Istanbul'
  },
  {
    'name': 'Israel Standard Time',
    'abbr': 'JDT',
    'value': 3,
    'isdst': true,
    'text': '(UTC+02:00) Jerusalem'
  },
  {
    'name': 'Libya Standard Time',
    'abbr': 'LST',
    'value': 2,
    'isdst': false,
    'text': '(UTC+02:00) Tripoli'
  },
  {
    'name': 'Jordan Standard Time',
    'abbr': 'JST',
    'value': 3,
    'isdst': false,
    'text': '(UTC+03:00) Amman'
  },
  {
    'name': 'Arabic Standard Time',
    'abbr': 'AST',
    'value': 3,
    'isdst': false,
    'text': '(UTC+03:00) Baghdad'
  },
  {
    'name': 'Kaliningrad Standard Time',
    'abbr': 'KST',
    'value': 3,
    'isdst': false,
    'text': '(UTC+03:00) Kaliningrad, Minsk'
  },
  {
    'name': 'Arab Standard Time',
    'abbr': 'AST',
    'value': 3,
    'isdst': false,
    'text': '(UTC+03:00) Kuwait, Riyadh'
  },
  {
    'name': 'E. Africa Standard Time',
    'abbr': 'EAST',
    'value': 3,
    'isdst': false,
    'text': '(UTC+03:00) Nairobi'
  },
  {
    'name': 'Iran Standard Time',
    'abbr': 'IDT',
    'value': 4.5,
    'isdst': true,
    'text': '(UTC+03:30) Tehran'
  },
  {
    'name': 'Arabian Standard Time',
    'abbr': 'AST',
    'value': 4,
    'isdst': false,
    'text': '(UTC+04:00) Abu Dhabi, Muscat'
  },
  {
    'name': 'Azerbaijan Standard Time',
    'abbr': 'ADT',
    'value': 5,
    'isdst': true,
    'text': '(UTC+04:00) Baku'
  },
  {
    'name': 'Russian Standard Time',
    'abbr': 'RST',
    'value': 4,
    'isdst': false,
    'text': '(UTC+04:00) Moscow, St. Petersburg, Volgograd'
  },
  {
    'name': 'Mauritius Standard Time',
    'abbr': 'MST',
    'value': 4,
    'isdst': false,
    'text': '(UTC+04:00) Port Louis'
  },
  {
    'name': 'Georgian Standard Time',
    'abbr': 'GST',
    'value': 4,
    'isdst': false,
    'text': '(UTC+04:00) Tbilisi'
  },
  {
    'name': 'Caucasus Standard Time',
    'abbr': 'CST',
    'value': 4,
    'isdst': false,
    'text': '(UTC+04:00) Yerevan'
  },
  {
    'name': 'Afghanistan Standard Time',
    'abbr': 'AST',
    'value': 4.5,
    'isdst': false,
    'text': '(UTC+04:30) Kabul'
  },
  {
    'name': 'West Asia Standard Time',
    'abbr': 'WAST',
    'value': 5,
    'isdst': false,
    'text': '(UTC+05:00) Ashgabat, Tashkent'
  },
  {
    'name': 'Pakistan Standard Time',
    'abbr': 'PST',
    'value': 5,
    'isdst': false,
    'text': '(UTC+05:00) Islamabad, Karachi'
  },
  {
    'name': 'India Standard Time',
    'abbr': 'IST',
    'value': 5.5,
    'isdst': false,
    'text': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
  },
  {
    'name': 'Sri Lanka Standard Time',
    'abbr': 'SLST',
    'value': 5.5,
    'isdst': false,
    'text': '(UTC+05:30) Sri Jayawardenepura'
  },
  {
    'name': 'Nepal Standard Time',
    'abbr': 'NST',
    'value': 5.75,
    'isdst': false,
    'text': '(UTC+05:45) Kathmandu'
  },
  {
    'name': 'Central Asia Standard Time',
    'abbr': 'CAST',
    'value': 6,
    'isdst': false,
    'text': '(UTC+06:00) Astana'
  },
  {
    'name': 'Bangladesh Standard Time',
    'abbr': 'BST',
    'value': 6,
    'isdst': false,
    'text': '(UTC+06:00) Dhaka'
  },
  {
    'name': 'Ekaterinburg Standard Time',
    'abbr': 'EST',
    'value': 6,
    'isdst': false,
    'text': '(UTC+06:00) Ekaterinburg'
  },
  {
    'name': 'Myanmar Standard Time',
    'abbr': 'MST',
    'value': 6.5,
    'isdst': false,
    'text': '(UTC+06:30) Yangon (Rangoon)'
  },
  {
    'name': 'SE Asia Standard Time',
    'abbr': 'SAST',
    'value': 7,
    'isdst': false,
    'text': '(UTC+07:00) Bangkok, Hanoi, Jakarta'
  },
  {
    'name': 'N. Central Asia Standard Time',
    'abbr': 'NCAST',
    'value': 7,
    'isdst': false,
    'text': '(UTC+07:00) Novosibirsk'
  },
  {
    'name': 'China Standard Time',
    'abbr': 'CST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
  },
  {
    'name': 'North Asia Standard Time',
    'abbr': 'NAST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Krasnoyarsk'
  },
  {
    'name': 'Singapore Standard Time',
    'abbr': 'MPST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Kuala Lumpur, Singapore'
  },
  {
    'name': 'W. Australia Standard Time',
    'abbr': 'WAST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Perth'
  },
  {
    'name': 'Taipei Standard Time',
    'abbr': 'TST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Taipei'
  },
  {
    'name': 'Ulaanbaatar Standard Time',
    'abbr': 'UST',
    'value': 8,
    'isdst': false,
    'text': '(UTC+08:00) Ulaanbaatar'
  },
  {
    'name': 'North Asia East Standard Time',
    'abbr': 'NAEST',
    'value': 9,
    'isdst': false,
    'text': '(UTC+09:00) Irkutsk'
  },
  {
    'name': 'Tokyo Standard Time',
    'abbr': 'TST',
    'value': 9,
    'isdst': false,
    'text': '(UTC+09:00) Osaka, Sapporo, Tokyo'
  },
  {
    'name': 'Korea Standard Time',
    'abbr': 'KST',
    'value': 9,
    'isdst': false,
    'text': '(UTC+09:00) Seoul'
  },
  {
    'name': 'Cen. Australia Standard Time',
    'abbr': 'CAST',
    'value': 9.5,
    'isdst': false,
    'text': '(UTC+09:30) Adelaide'
  },
  {
    'name': 'AUS Central Standard Time',
    'abbr': 'ACST',
    'value': 9.5,
    'isdst': false,
    'text': '(UTC+09:30) Darwin'
  },
  {
    'name': 'E. Australia Standard Time',
    'abbr': 'EAST',
    'value': 10,
    'isdst': false,
    'text': '(UTC+10:00) Brisbane'
  },
  {
    'name': 'AUS Eastern Standard Time',
    'abbr': 'AEST',
    'value': 10,
    'isdst': false,
    'text': '(UTC+10:00) Canberra, Melbourne, Sydney'
  },
  {
    'name': 'West Pacific Standard Time',
    'abbr': 'WPST',
    'value': 10,
    'isdst': false,
    'text': '(UTC+10:00) Guam, Port Moresby'
  },
  {
    'name': 'Tasmania Standard Time',
    'abbr': 'TST',
    'value': 10,
    'isdst': false,
    'text': '(UTC+10:00) Hobart'
  },
  {
    'name': 'Yakutsk Standard Time',
    'abbr': 'YST',
    'value': 10,
    'isdst': false,
    'text': '(UTC+10:00) Yakutsk'
  },
  {
    'name': 'Central Pacific Standard Time',
    'abbr': 'CPST',
    'value': 11,
    'isdst': false,
    'text': '(UTC+11:00) Solomon Is., New Caledonia'
  },
  {
    'name': 'Vladivostok Standard Time',
    'abbr': 'VST',
    'value': 11,
    'isdst': false,
    'text': '(UTC+11:00) Vladivostok'
  },
  {
    'name': 'New Zealand Standard Time',
    'abbr': 'NZST',
    'value': 12,
    'isdst': false,
    'text': '(UTC+12:00) Auckland, Wellington'
  },
  {
    'name': 'UTC+12',
    'abbr': 'U',
    'value': 12,
    'isdst': false,
    'text': '(UTC+12:00) Coordinated Universal Time+12'
  },
  {
    'name': 'Fiji Standard Time',
    'abbr': 'FST',
    'value': 12,
    'isdst': false,
    'text': '(UTC+12:00) Fiji'
  },
  {
    'name': 'Magadan Standard Time',
    'abbr': 'MST',
    'value': 12,
    'isdst': false,
    'text': '(UTC+12:00) Magadan'
  },
  {
    'name': 'Kamchatka Standard Time',
    'abbr': 'KDT',
    'value': 13,
    'isdst': true,
    'text': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
  },
  {
    'name': 'Tonga Standard Time',
    'abbr': 'TST',
    'value': 13,
    'isdst': false,
    'text': '(UTC+13:00) Nuku\'alofa'
  },
  {
    'name': 'Samoa Standard Time',
    'abbr': 'SST',
    'value': 13,
    'isdst': false,
    'text': '(UTC+13:00) Samoa'
  }
];

class Clock {

  constructor($vm) {
    this.$vm = $vm;

    this.refreshTimezoneOffset();
  }

  destroy() {
    this.$vm = null;
  }

  /**
   * Returns the status of seconds
   * true = seconds enabled
   * false = seconds disabled
   * @return {Boolean} Returns true if seconds are enabled
   */
  isSecondsEnabled() {
    return this.$vm.module.props.showSeconds;
  }

  /**
   * Returns the type of the marker.
   * @return {Boolean} Returns true if markers are circle
   */
  getMarkerType() {
    return this.$vm.module.props.markerType;
  }

  /**
   * Return the color of the numbers
   * @return {Object} RGBA object
   */
  getColorNumber() {
    return this.$vm.module.props.color.number;
  }

  /**
   * Return the color of the numbers
   * @return {Object} RGBA Object
   */
  getColorSplitter() {
    return this.$vm.module.props.color.marker;
  }

  /**
   * Return the color of the seconds pointer
   * @return {String} RGBA String
   */
  getColorSecond(){
    return this.$vm.module.props.color.second;
  }

  /**
   * Return the color of the minute pointer
   * @return {String} RGBA String
   */
  getColorMinute() {
    return this.$vm.module.props.color.minute;
  }

  /**
   * Return the color of the hour pointer
   * @return {Object} RGBA Object
   */
  getColorHour() {
    return this.$vm.module.props.color.hour;
  }

  /**
   * Sets the timezone offset
   * type = short term for timezone name
   * @param {Number} type Offset value
   */
  setTimezoneOffset(offset){
    this.timezoneOffset = offset;
  }

  /**
   * Returns true of needle is found in haystack
   * @return {Bool} Found in haystack
   */
  refreshTimezoneOffset(){
    let hasDst = false;
    let abbr = "";
    timezones.forEach(function(val){
      if (val.name == this.getTimezone()) {
        hasDst = val.isdst;
        abbr = val.abbr;
        this.setTimezoneOffset(val.value);
      }
    }.bind(this));

    /*
     * Based on http://www.webexhibits.org/daylightsaving/i.html and
     * https://en.wikipedia.org/wiki/Template:Daylight_saving_in_time_zone/techdoc
     */
    let USAdst = ["HAT","AKT","PT","MT","CT","ET","AT","NT"];
    let currentYear = new Date().getFullYear();
    let from, to;
    if (hasDst){
      // We have DST so subtract 1 hour
      this.setTimezoneOffset(this.getTimezone(true) - 1);
      // Calculate real offset out of it
      if (this.inArray(abbr, USAdst)){
        // We're in the US!
        let dayInMarch = Math.round(14 - (1+currentYear*5/4) % 7);
        let endInNov = Math.round(7 - (1 + 5*currentYear/4) % 7);
        from = new Date(currentYear, 2, dayInMarch, 2, 0,0);
        to = new Date(currentYear, 10, endInNov, 2, 0,0);
      } else {
        // Use Europe- standard (WET,CET,EET)
        let dayInMarch = Math.round(31 - (4 + 5*currentYear/4 ) % 7);
        let endInNov = Math.round(31 - (1 + 5*currentYear/4) % 7);
        from = new Date(currentYear, 2, dayInMarch, 2, 0,0);
        to = new Date(currentYear, 9, endInNov, 2, 0,0);
      }
    }

    // If DST is not active anymore, revert the subtraction.
    if (new Date() > from && new Date() < to) this.setTimezoneOffset(this.getTimezone(true) + 1);
  }

  /**
   * Returns true of needle is found in haystack
   * @return {Bool} Found in haystack
   */
  inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == needle) return true;
    }
    return false;
  }

  /**
   * Returns the offset of the desired timezone.
   * @param {Bool} True will return the timezone offset as int
   * @return {Mixed} The offset or the name of the zimezone
   */
  getTimezone(offset) {
    if (!offset) return this.$vm.module.props.timezoneName;
    return this.timezoneOffset;
  }

  /**
   * Returns the type of the clock.
   * @return {Boolean} true if clock is digital
   */
  getType() {
    return this.$vm.module.props.digital;
  }

  /**
   * Returns the type of the marker.
   * @return {Boolean} true if markers are circle
   */
  getFontSize() {
    return (this.$vm.module.props.fontSize) ? this.$vm.module.props.fontSize : '40';
  }

  /**
   * Converts an rgba object to rgba string
   * @param  {Object} rgba Input rgba
   * @return {String} Output string rgba
   */
  convertColorToString(color) {
    return _execTemplate({
      ...color,
      a: color.a/100,
      tpl: 'rgba(#{r}, #{g}, #{b}, #{a})'
    });
  }

  /**
   * Launch clock and check which type of click should be rendered
   */
  draw() {
    const canvas = this.$vm.$el;
    const ctx = canvas.getContext('2d');
    const { width, height } = canvas;

    if(this.$vm.module.props.digital) {
      this.drawDigital(ctx, { width, height });
    } else {
      this.drawCanvas(ctx, { width, height });
    }
  }

  /**
   * This will draw a digital clock
   */
  drawDigital(context, canvas) {
    let  center = canvas.width / 2,
            now = this.getTimezoneTime(),
              h = now.getHours(),
              m = now.getMinutes(),
              s = now.getSeconds();

    context.font = this.getFontSize() + 'px arial';

    let timeString = this.fillNumbers(h) + ':' + this.fillNumbers(m);
    timeString += (this.isSecondsEnabled()) ? ':' + this.fillNumbers(s) : '';
    let fontS = 0;

    if(canvas.height > Math.ceil(canvas.width / (timeString.length-2)))
      fontS = Math.ceil(canvas.width / (timeString.length-2));
    else
      fontS = canvas.height;

    context.font = fontS + 'px arial';
    context.textBaseline='middle';

    let startPointX = center - (context.measureText(timeString).width / 2),
        startPointY = (canvas.height / 2);

    context.clearRect(0, 0, canvas.width, canvas.height);

    for(let i = 0; i <= timeString.length; ++i) {
      let ch = timeString.charAt(i), color = this.getColorNumber();

      if(i == 2 || i == 5) color = this.getColorNumber();
      else if(i <= 1)      color = this.getColorHour();
      else if(i <= 4)      color = this.getColorMinute();
      else if(i <= 7)      color = this.getColorSecond();

      context.fillStyle = this.convertColorToString(color);
      context.fillText(ch, startPointX, startPointY);
      startPointX += context.measureText(ch).width;
    }
  }

  /**
   * Puts a 0 in front of value below 10
   * @param  {Number} number Input number
   * @return {Number}        Output number
   */
  fillNumbers(number) {
    return (number < 10) ? '0' + number : number;
  }

  /**
   * Gets the timezoned Date Object
   * @return {Date} Dateobject with timezone
   */
  getTimezoneTime() {
    let perf = new Date();

    let now = new Date();
    let timezone = now.getTimezoneOffset();
    let timer;

    // First, we convert the current timezone to UTC +0
    if (timezone < 0)
      timer = new Date(now.getTime() + timezone*60*1000);
    else if(timezone > 0)
      timer = new Date(now.getTime() - timezone*60*1000);
    else
      timer = now;

    // Now, we convert the UTC timezone to the target timezone
    if (this.getTimezone(true) < 0)
      now = new Date(timer.getTime() - (this.getTimezone(true)*60)*60*1000);
    else if(this.getTimezone(true) > 0)
      now = new Date(timer.getTime() + (this.getTimezone(true)*60)*60*1000);
    else
      now = timer;

    return now;
  }

  /**
   * Draw the absolutely wonderfull clock as analog!
   */
  drawCanvas(context, canvas) {
    let now = this.getTimezoneTime();

    let h = now.getHours(),
        m = now.getMinutes(),
        s = now.getSeconds(),
        ms = now.getMilliseconds();

    let clockY = canvas.width / 2,
        clockX = canvas.width / 2,
        clockRadius = canvas.width,
        numberRadius = clockRadius;

    // Delete everything inside canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    Math.TAU = 2 * Math.PI;

    let c = 5,
        numberCounter = 24;

    // Draw circle
    for(let i=0; i < 60; i++) {
      let armRadians = (Math.TAU * (i/60)) - (Math.TAU/4),
      length = 0.05,
      start  = 0.35,
      lWidth = 5,
      lColor = this.convertColorToString(this.getColorNumber());

      // Reset Number to show 12 - 1
      if(numberCounter==25)
        numberCounter=13;


      if(this.getMarkerType() == 'stroke') {
        start = 0.37;
        length = 0.04;
      }

      // Necessary for splitters, every 5 steps one different size and color.
      if(i%5==0) {
        start = 0.32;
        length = 0.09;

        lWidth = 7;
        lColor = this.convertColorToString(this.getColorSplitter());

        numberCounter++;
      }

      let targetX = clockX + Math.cos(armRadians) * ((start+length) * clockRadius),
          targetY = clockY + Math.sin(armRadians) * ((start+length) * clockRadius),
          startX =  clockX + Math.cos(armRadians) * (start * clockRadius),
          startY =  clockY + Math.sin(armRadians) * (start * clockRadius);

      // Prepare color and width
      context.beginPath();
      context.lineWidth = clockRadius * (lWidth * 2) / 1000;
      context.strokeStyle = lColor;

      if (this.getMarkerType() != 'number'){
        // Check if circle or strokes
        if(this.getMarkerType() == 'stroke' || i%5 == 0) {
          context.moveTo(startX, startY);
          context.lineTo(targetX, targetY);
          context.stroke();
        } else if(this.getMarkerType() == 'circle' || i%5 != 0) {
          context.arc(
            targetX,
            targetY,
            clockRadius * (lWidth * 2) / 1000,
            0,
            Math.TAU,
            false
          );
          context.fillStyle = lColor;
          context.fill();
        }
      }
    }

    // Draw numbers
    for(let i=12; i > 0; i--) {
      let armRadians = (Math.TAU * (i/12)) - (Math.TAU/4),
          length     = 0.05,
          start      = 0.35,
          lWidth     = 5,
          lColor     = this.convertColorToString(this.getColorSplitter()),
          targetX    = clockX +
                       Math.cos(armRadians) * (start+length) * numberRadius,
          targetY    = clockY +
                       Math.sin(armRadians) * (start+length) * numberRadius;

      // Prepare color and width
      context.beginPath();
      context.lineWidth   = lWidth;
      context.strokeStyle = lColor;
      context.font        = clockRadius * 0.05 + 'px arial';
      context.fillStyle   = this.convertColorToString(this.getColorSplitter());
      context.textBaseline='middle';
      context.textAlign   ='center';

      // Draw all numbers
      if(this.getMarkerType() == 'number')
        context.fillText(i.toString(), targetX, targetY);

      context.stroke();
    }

    /**
     * This function will draw a clock hand
     * @param  {Number} progress     Progress, the arm should be drawn as
     * @param  {Number} armThickness How thick the arm should be
     * @param  {[type]} armLength    [description]
     * @param  {[type]} armColor     [description]
     * @return {[type]}              [description]
     */
    function drawArm(progress, armThickness, armLength, armColor) {

      let armRadians = (Math.TAU * progress) - (Math.TAU/4);

      let targetX = clockX + Math.cos(armRadians) * (armLength * clockRadius);
      let targetY = clockY + Math.sin(armRadians) * (armLength * clockRadius);

      context.lineWidth = armThickness;
      context.strokeStyle = armColor;

      context.beginPath();
      context.moveTo(clockX, clockY); // Start at the center
      context.lineTo(targetX, targetY); // Draw a line outwards
      context.stroke();
    }

    // Hour
    drawArm(
      (h + (m / 60)) / 12.0,
      clockRadius * 20 / 1000,
      0.25,
      this.convertColorToString(this.getColorHour())
    );

    // Minute
    drawArm(
      m / 60.0,
      clockRadius * 10 / 1000,
      0.32,
      this.convertColorToString(this.getColorMinute())
    );

    // Second
    if(this.isSecondsEnabled()) drawArm(
      s / 60.0,
      clockRadius * 6 / 1000,
      0.35,
      this.convertColorToString(this.getColorSecond())
    );

    // Draw a small circle in the middle. Just because it's fancy.
    context.beginPath();
    context.fillStyle   = this.convertColorToString(this.getColorHour());
    context.arc(clockX, clockY, clockRadius * 12 / 1000, 0, Math.TAU, false);
    context.fill();
  }
}
</script>
