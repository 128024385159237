<template>

	<div class="resize-module"
		v-bind:class="{ m: move, r: right, b: bottom}"
		:style="style"
		v-movable
		@move:start="moveDrawStart"
		@move="moveDraw"
		@move:end="moveDrawEnd"
	></div>

</template>

<script>
import { _last, _deepCopy } from '../util/helper';

export default {
	name: 'DrawModuleExtraItemView',
	props: [ 'item', 'config' ],
	data: () => ({
		move: false,
		right: false,
		bottom: false,
		element: null
	}),
	mounted() {
		if (this.item.horizontal == true && this.item.vertical == true) this.move = true;
		else if (this.item.horizontal == true && this.item.vertical == false) this.right = true;
		else this.bottom = true;
	},
	computed: {
		style() {
			return {
				left:	`${this.item.x}px`,
				top:	`${this.item.y}px`
			}
		},
	},
	methods: {
		popDrawable() {
			this.$store.commit('changeModules', {
				type: 'props',
				modules: [this.config.mid],
				values: {
					drawingElements: element,
				},
			});
		},
		moveDrawStart(evt) {
			if (evt.target.classList[1] == 'm') this.element = 1; // move element x, y
			else if (evt.target.classList[1] == 'r') this.element = 2; // change element w
			else this.element = 3; // change element h

			this.config.props.drawingElements.forEach(element => {

				if (element.shape == this.item.shape && element.did == this.item.did) {
					if (element.shape == 'rect') {
						this.$options.points = [
							{
								x: element.x,
								y: element.y,
								w: element.w,
								h: element.h
							}
						];
					} else if (element.shape == 'circle') {
						this.$options.points = [
							{
								x: element.x,
								y: element.y,
								rx: element.rx,
								ry: element.ry
							}
						];
					} else if (element.shape == 'stroke' && element.points.length == 2) {

						element.points.forEach(point => {
							if (point.x == this.item.x && point.y == this.item.y) {

								this.$options.points = [
									{
										x: point.x,
										y: point.y
									}
								];

							}
						});

					} else {
						let sketch = [];
						element.points.forEach(point => {
							sketch.push({
								x: point.x,
								y: point.y
							});
						});
						this.sketch = sketch;
					}

				}

			});
		},
		moveDraw(evt) {

			this.$emit('move', evt);
			if (!evt.returnValue) {
				return;
			}

			const { moveX, moveY } = evt.detail;

			let canvasWidth = this.config.width;
			let canvasHeight = this.config.height;
			let horizontal;
			let vertical

			if (this.element == 1) {

				this.config.props.drawingElements.forEach(element => {

					if (element.shape == this.item.shape && element.did == this.item.did) {

						if (element.shape == 'rect' || element.shape == 'circle' || element.shape == 'stroke' && element.points.length == 2) {
							horizontal = (moveX / this.$store.state.intern.scale) + this.$options.points[0].x;
							vertical = (moveY / this.$store.state.intern.scale) + this.$options.points[0].y;
						}

						if (element.shape == 'rect') {

							if (element.w >= 0) {

								if (horizontal >= 0 && horizontal <= canvasWidth - element.w) {
									element.x = horizontal
								} else if (horizontal < 0) {
									element.x = 0;
								} else {
									element.x = canvasWidth - element.w;
								}

							} else {

								if (element.w + horizontal >= 0 && element.w + horizontal <= canvasWidth + element.w) {
									element.x = horizontal;
								} else if (element.w + horizontal < 0) {
									element.x = Math.abs(element.w);
								} else {
									element.x = canvasWidth;
								}

							}

							if (element.h >= 0) {

								if (vertical >= 0 && vertical <= canvasHeight - element.h) {
									element.y = vertical;
								} else if (vertical < 0) {
									element.y = 0;
								} else {
									element.y = canvasHeight - element.h;
								}

							} else {

								if (element.h + vertical >= 0 && element.h + vertical <= canvasHeight + element.h) {
									element.y = vertical;
								} else if (element.h + vertical < 0) {
									element.y = Math.abs(element.h);
								} else {
									element.y = canvasHeight;
								}

							}

						} else if (element.shape == 'circle') {

							if (horizontal >= 0 && horizontal <= canvasWidth) {
								element.x = horizontal
							} else if (horizontal < 0) {
								element.x = 0;
							} else {
								element.x = canvasWidth;
							}

							if (vertical >= 0 && vertical <= canvasHeight) {
								element.y = vertical;
							} else if (vertical < 0) {
								element.y = 0;
							} else {
								element.y = canvasHeight;
							}

						} else if (element.shape == 'stroke' && element.points.length == 2) {
							element.points.forEach(point => {
								if (point.x == this.item.x && point.y == this.item.y) {

									if (horizontal >= 0 && horizontal <= canvasWidth) {
										point.x = horizontal;
									} else if (horizontal < 0) {
										point.x = 0;
									} else {
										point.x = canvasWidth;
									}

									if (vertical >= 0 && vertical <= canvasHeight) {
										point.y = vertical;
									} else if (vertical < 0) {
										point.y = 0;
									} else {
										point.y = canvasHeight;
									}

								}
							});

						} else if (element.shape == 'stroke' && element.points.length > 2 && element.points.length < 10) {

							for (let i = 0; i < element.points.length; i++) {

								if (element.points[i].x == this.item.x && element.points[i].y == this.item.y) {

									horizontal = (moveX / this.$store.state.intern.scale) + this.sketch[i].x;
									vertical = (moveY / this.$store.state.intern.scale) + this.sketch[i].y;

									if (horizontal >= 0 && horizontal <= canvasWidth) {
										element.points[i].x = horizontal;
									} else if (horizontal < 0) {
										element.points[i].x = 0;
									} else {
										element.points[i].x = canvasWidth;
									}

									if (vertical >= 0 && vertical <= canvasHeight) {
										element.points[i].y = vertical;
									} else if (vertical < 0) {
										element.points[i].y = 0;
									} else {
										element.points[i].y = canvasHeight;
									}

								}
							}

						} else {

							for (let i = 0; i < element.points.length; i++) {
								element.points[i].x = (moveX / this.$store.state.intern.scale) + this.sketch[i].x;
								element.points[i].y = (moveY / this.$store.state.intern.scale) + this.sketch[i].y;
							}

						}
					}

				});

			} else if (this.element == 2) {

				this.config.props.drawingElements.forEach(element => {

					if (element.shape == this.item.shape && element.did == this.item.did) {

						if (element.shape == 'rect') {
							let range = canvasWidth - ((moveX / this.$store.state.intern.scale) + this.$options.points[0].w + element.x);

							if (range >= 0 && range <= canvasWidth) {
								element.w = (moveX / this.$store.state.intern.scale) + this.$options.points[0].w;
							} else if (range < 0) {
								element.w = canvasWidth - element.x;
							} else {
								element.w = -element.x
							}

						} else if (element.shape == 'circle') {
							element.rx = (moveX / this.$store.state.intern.scale) + this.$options.points[0].rx;
						}

					}

				});

			} else if (this.element == 3) {

				this.config.props.drawingElements.forEach(element => {

					if (element.shape == this.item.shape && element.did == this.item.did) {

						if (element.shape == 'rect') {
							let range = canvasHeight - ((moveY / this.$store.state.intern.scale) + this.$options.points[0].h + element.y);

							if (range >= 0 && range <= canvasHeight) {
								element.h = (moveY / this.$store.state.intern.scale) + this.$options.points[0].h;
							} else if (range < 0) {
								element.h = canvasHeight - element.y;
							} else {
								element.h = -element.y
							}

						} else if (element.shape == 'circle') {
							element.ry = (moveY / this.$store.state.intern.scale) + this.$options.points[0].ry;
						}

					}

				});

			}

			this.$store.commit('dirty');
		},
		moveDrawEnd(evt) {

		},
	}
};
</script>

<style lang="scss">
.fd-module {
	.fd-decorator {
		.resize-module {
			display: none;

			position: absolute;
			width: 10px;
			height: 10px;
			background: #eee;
			border: 1px solid #000;
			border-radius: 5px;
			margin-left: -9px;
			margin-top: -9px;
			&:before {
				content: '';
				position: absolute;
				top: -10px;
				bottom: -10px;
				left: -10px;
				right: -10px;
			}
			&.r {
				cursor: e-resize;
			}
			&.b {
				cursor: s-resize;
			}
			&.m {
				cursor: move;
			}
		}
	}
	&[data-active='true'] > .fd-decorator {
		.resize-module {
			display: block;
		}
	}
}
</style>