<template>
	<div
		v-movable
		@move:start="moveLayerStart"
		@move="moveLayer"
		@move:end="moveLayerEnd"
	>
		{{valid}}
	</div>
</template>

<script>
export default {
	name: 'DescriptionView',
	props: ['module'],
	data() {
		return {
			description: '',
			selectedIndex: 0,
		}
	},
	computed: {
		valid() {
			if (this.module.logo) {
				this.description = "Logo";
			}
			return this.description || this.$_(`fd.${this.module.class}.title`);
		}
	},
	methods: {
		moveLayerStart(evt) {
			if (this.module.class == 'WatermarkModule' || this.module.logo) return;

			let element = evt.target.parentNode;

			for (let i = 0; i < document.getElementsByClassName('module').length; i++) {
				if (document.getElementsByClassName('module')[i] == element) {
					this.amount = this.selectedIndex = i;
					this.startPosition = element.clientHeight * i;
				}
			}

			this.counter = 0;
			this.$store.state.data.modules.forEach(module => {
				if (module.class == "WatermarkModule" || (module.logo && !module.isGroup) || (module.class == 'GroupModule' && module.logo)) {
					this.counter += 1;
				}
			});

			element.style.position = 'relative';
			element.style.top = '0px';
			element.style.transform = 'rotate(1deg)';
			element.style.background = '#eee';
			element.style.color = '#000';
		},
		moveLayer(evt) {
			if (this.module.class == 'WatermarkModule' || this.module.logo) return;

			this.$emit('move', evt);
			if (!evt.returnValue) {
				return;
			}

			let { detail: { moveY }, } = evt;

			let range = moveY + (25 * this.selectedIndex);

			let element = evt.target.parentNode;
			let module_arr = document.getElementsByClassName('module');

			let rangeHeight = (this.counter > 0 ? (this.counter * 25) - 2 : 0);
			let layerHeight = ((module_arr.length - 1) * element.clientHeight) + 2;

			if (range >= rangeHeight && range <= layerHeight) {

				if (range >= rangeHeight && typeof module_arr[this.amount + 1] != 'undefined') {

					element.style.top = (range - (element.clientHeight * this.amount)) + 'px';
					if (range >= this.startPosition + element.clientHeight) {

						this.startPosition = this.startPosition + element.clientHeight;
						this.amount++;
						element.style.top = '0px';
						element.parentNode.insertBefore(element, module_arr[this.amount + 1]);


						this.$store.state.data.modules.forEach(module => {
							let nextZ = this.module.z - 1;
							if (nextZ == module.z) {
								module.z++;
							}
						});
						this.module.z--;

						this.$store.commit('dirty');
					}
				}

				if (range >= rangeHeight && typeof module_arr[this.amount - 1] != 'undefined') {

					element.style.top = (range - (element.clientHeight * this.amount)) + 'px';
					if (range <= this.startPosition - element.clientHeight) {

						this.startPosition = this.startPosition - element.clientHeight;
						element.style.top = '0px';
						element.parentNode.insertBefore(element, module_arr[this.amount - 1]);
						this.amount--;

						this.$store.state.data.modules.forEach(module => {
							let previousZ = this.module.z + 1;
							if (previousZ == module.z) {
								module.z--;
							}
						});
						this.module.z++;

						this.$store.commit('dirty');
					}
				}
			} else {
				// set element on last place and readjust module.z indices
				if (range > layerHeight) {

					element.style.top = '0px';
					element.parentNode.insertBefore(element, module_arr[module_arr.length]);

					let zIndex = 0;
					this.$store.state.data.modules.forEach(module => {
						if (module.z < this.module.z) {
							module.z++;
							zIndex++;
						}
					});

					this.module.z = this.module.z - zIndex;
					this.amount = module_arr.length - 1;
					this.startPosition = element.clientHeight * this.amount;

					this.$store.commit('dirty');
				}
				// set element on first available place and readjust module.z indices
				if (range < rangeHeight) {

					element.style.top = '0px';
					element.parentNode.insertBefore(element, module_arr[this.counter]);

					let zIndex = 0;
					this.$store.state.data.modules.forEach(module => {
						if (module.z > this.module.z) {
							module.z--;
							zIndex++;
						}
					});

					this.module.z = this.module.z + zIndex;
					this.amount = this.counter;
					this.startPosition = element.clientHeight * this.amount;

					this.$store.commit('dirty');
				}
			}
		},
		moveLayerEnd(evt) {
			if (this.module.class == 'WatermarkModule' || this.module.logo) return;

			let element = evt.target.parentNode;
			element.style.position = element.style.transform = element.style.top = element.style.background = element.style.color = '';
		}
	}
}
</script>