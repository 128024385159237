<template>
	<div class="fd-group-view">
		<div class="head" @click="isCollapsed = !isCollapsed">
			<div class="icon"><i :class="icon"></i></div>
			<div class="label">{{label}}</div>
		</div>
		<div class="body" v-show="!isCollapsed">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GroupView',
	props: ['label', 'collapsed'],
	data() {
		return {
			isCollapsed: this.collapsed === true
		}
	},
	computed: {
		icon() {
			this.$store.commit('subNav/resize');
			return this.isCollapsed ? 'arrow-right3' : 'arrow-down3'
		}
	}
}
</script>

<style lang="scss">
.fd-group-view {
	margin-top: 15px;
	margin-bottom: 30px;

	.head {
		display: flex;
		align-items: center;
		font-size: 12px;
		cursor: pointer;

		.icon {
			font-size: 10px;
			width: 20px;
		}
	}

	.body {
		margin-top: 3px;
		border-top: 1px dashed #555;
		padding-top: 5px;
		padding-left: 5px;
	}
}
</style>
