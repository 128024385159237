let div = null;

export function _parseColor(input) {
	if(!div) {
		div = document.createElement('div');
	}

	// Browser does the magic of parsing input
	div.style.color = input;
	const color = div.style.color;

	if(!color) return [255, 255, 255, 1];

	return _rgbaToArray(color);
}

export function _rgbaToArray(color) {
	return color
		.split('(')[1]
		.split(')')[0]
		.split(',')
		.map(parseFloat)

		// Sometimes color is rgb not rgba, make sure [3] has always a correct
		// value
		.concat([1]);
}

export function _arrayToRGBA(color) {
	if(!color[3] && color[3] !== 0 || color[3] == 1) {
		return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
	}
	return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
}

export function _rgbToHSV([r, g, b, a = 1]) {
	r /= 255,
	g /= 255,
	b /= 255;

	const max = Math.max(r, g, b),
	      min = Math.min(r, g, b);
	let h, s, v = max;

	const d = max - min;
	s = max == 0 ? 0 : d / max;

	if(max == min) {
		h = 0; // achromatic
	}
	else {
		switch(max) {
			case r: h = (g - b) / d + (g < b ? 6 : 0); break;
			case g: h = (b - r) / d + 2; break;
			case b: h = (r - g) / d + 4; break;
		}

		h /= 6;
	}

	return [h * 360, s, v, a];
}

export function _hsvToRGB([h, s, v, a = 1]) {
	h /= 360;

	const i = Math.floor(h * 6);
	const f = h * 6 - i;
	const p = v * (1 - s);
	const q = v * (1 - f * s);
	const t = v * (1 - (1 - f) * s);

	let r, g, b;

	switch(i % 6) {
		case 0: [r, g, b] = [v, t, p]; break;
		case 1: [r, g, b] = [q, v, p]; break;
		case 2: [r, g, b] = [p, v, t]; break;
		case 3: [r, g, b] = [p, q, v]; break;
		case 4: [r, g, b] = [t, p, v]; break;
		case 5: [r, g, b] = [v, p, q]; break;
	}

	return [
		Math.round(r * 255),
		Math.round(g * 255),
		Math.round(b * 255),
	];
}
