<template>
	<div class="fd-animation-list-input">
		<animation-list-item-view
			v-for="(item, i) of config"
			:key="'animation_' + item.name + '_' + i "
			:item="item"
		/>
	</div>
</template>

<script>
import AnimationListItemView from '../inputs/AnimationListItem.vue';

export default {
	name: 'AnimationListView',
	props: ['value', 'config'],
	components: { AnimationListItemView },
	data: () => ({}),
	computed: {},
	methods: {},
};
</script>

<style lang="scss">
.fd-animation-list-input {
	div.input {
		flex: none !important;
		width: 100%;
	}
	font-size: 12px;
	width: 100%;
}
</style>