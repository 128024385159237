<template>
  <div class="fd-rss-module content">
    <div class="marquee">
      <div class="items"></div>
    </div>
  </div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import CONFIG from '../Config.js';
import {
  _validateMinMax,
  _squeezeText,
  _decodeHtml,
} from '../util/helper.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
  name: 'RssModule',
  extends: BaseModule,
	normalize,
  methods: {
    async start() {
      this.$options.rssModule = new RssModule(
        this.$el,
        this.module.props,
        this.module.style.padding
      );
      await this.$nextTick();
      this.$options.rssModule.render();
    },
    stop() {
      if(!this.$options.rssModule) return;

      this.$options.rssModule.stop();
      this.$options.rssModule = null;
    }
  }
}

class RssModule {

  constructor($el, props, padding) {
    this.$el = $el;

    // Set default values
    this.props = {
      link:  '',
      limit: 10,
      sliderState: true,
      showImages: false,
      speedMultiplicator: 50,

      ...props
    };

    this.style = { padding };

    // temp vars
    this.runningAnimation = false;
    this.swipeTask = null;
    this.xmlDOM = null;
  }

  /**
   * Gets the speed in percent
   * @return {String} The speed
   */
  getSpeed() {
    return this.props.speedMultiplicator;
  }

  /**
   * Gets the image status
   * @return {Boolean} Returns true if image is allowed
   */
  getImageStatus() {
    return this.props.showImages;
  }

  /**
   * Gets the feed url
   * @return {String} The feed url
   */
  getLink() {
    return this.props.link;
  }

  /**
   * Get limit of news entries
   * @return {Number}  Returns number of items to be shown
   */
  getLimit() {
    return this.props.limit;
  }

  /**
   * Gets the slider state
   * @return {Boolean} True if slider is active
   */
  getSliderState() {
    return this.props.sliderState;
  }

  /**
   * Will remove any HTML Tag
   * @param  {String} str HTML content
   * @return {String}     Text content
   */
  strip(str) {
    return str.replace(/&lt;/g, '<')
              .replace(/&gt;/g, '>')
              .replace(/<(.|\n)*?>/g, '');
  }

  /**
   * Starts the fade animation
   */
  startFadeAnimation() {
    let items = this.$el.querySelector('.items');
    if(items !== null) {
      let activeItem = items.querySelector('.active');
      let nextItem = null;

      if(activeItem === null) {
        activeItem = items.querySelector('.item:first-child');
        activeItem.className = 'item active';
      }

      nextItem = activeItem.nextSibling;
      if(!nextItem) nextItem = items.querySelector('.item:first-child');

      if(this.runningAnimation) {
        //     max. 30s              min 1s
        let speed = 30-this.getSpeed()*0.3;

        this.swipeTask = setTimeout(() => {
          this.runFadeAnimation(activeItem, nextItem);
        }, _validateMinMax(speed, 1, 30)*1000);
      }
    }
  }

  /**
   * Managing animation and classes between fades
   * @param  {Element} from DOM Element which will be fade out
   * @param  {Element} to   DOM Element which will be fade in
   */
  runFadeAnimation(from, to) {
    from.style.display = 'none';
    to.style.display = null;

    from.classList.remove('active');
    to.classList.add('active');

    if(this.runningAnimation) this.startFadeAnimation();
  }

  /**
   * Will put content into HTML element with classes and additional
   * @param  {String} tag        HTML Tag for Element
   * @param  {String} content    Content for HTML Element
   * @param  {Array}  classes    Array of classes to set
   * @param  {Object}  additional Any other attribute that needs to be set.
   *                             [{tag: 'content'}]
   * @return {[String]}          Returns the built HTML Tag
   */
  wrapInTag(tag, content = null, classes = [], additional = {}) {
    let output = '<' + tag;

    if(classes.length !== 0)
      output += ' class="' + classes.join(' ') + '"';

    for (let key in additional) {
      output += ' ' + key + '="' + additional[key] + '"';
    }

    if(content === null) return output + ' />';
    return output + '>' + content + '</' + tag + '>';
  }

  /**
   * Tries to set an image, will call php file for work
   * @param  {String} link Link of the news article for the image
   * @param  {String} id The element id, the image will be set into
   */
  requestImage(link, id) {
    let element = this.$el.querySelector('#'+id);
    let xhr = new XMLHttpRequest();
    let params = 'url='+encodeURIComponent(link);

    xhr.open('POST', CONFIG.BASEPATH + '/lib/lib.get_image.php');
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = () => {
      let response = JSON.parse(xhr.responseText);

      if(response.status == 'success') {
        element.style.backgroundImage = `url('${response.result}')`;

        let i = new Image();
        i.onload = () => {
          if(i.width > 400 &&
             i.height > 300 &&
             this.$el.classList.contains('no-slider') &&
             this.getImageStatus()) {
            element.style.display = 'block';
            element.parentNode.querySelector('.context').style.width = '50%';
          }

          element = null;
        };
        i.src = response.result;
      }
    };

    if(element.style.backgroundImage === '' ||
      element.style.backgroundImage === null) {
      element.parentNode.querySelector('.context').style.width = '100%';
      element.style.display = 'none';
      xhr.send(params);
    }
  }

  /**
   * Writing an Error Message into the HTML
   * @param  {String} message of the status message
   * @param  {String} status of the message
   */
  setStatus(message, status) {
    this.$el.innerHTML = this.wrapInTag(
      'span', message, ['status', 'status-' + status]
    );
  }

  /**
   * Remove status message and clean up for a good render
   */
  removeStatus() {
    this.$el.innerHTML = this.wrapInTag(
      'div', this.wrapInTag('div', ' ', ['items']), ['marquee']
    );
  }

  /**
   * Loading and processing XML File
   * @param  {String} link Link of the xml file
   * @param  {String} element The element to render into
   */
  setRssFeed(local) {
    if(local !== false) {
      // Load ready XML file
      if(this.xmlDOM) this.processXML(this.xmlDOM);
      else this.setRssFeed(false);

      return;
    }

    let xhr = new XMLHttpRequest();
    xhr.open('POST', CONFIG.BASEPATH + '/lib/lib.rss.php' + '&t=' + new Date().getTime());
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = () => {
      let xml = null;
      if(xhr.responseXML !== null)
        xml = xhr.responseXML;
      else {
        xml = xhr.responseText;
        let parser = new DOMParser();
        xml = parser.parseFromString(xml, 'text/html');
      }

      if(!xml)
        this.setStatus('Loading failed!', 'red');

      this.xmlDOM = xml;
      this.processXML(xml);
    };

    // Set loading message
    this.setStatus('Loading ...', 'green');

    // Start request
    xhr.send('url='+encodeURIComponent(this.getLink()));
  }

  /**
   * Process and render rss content
   * @param  {String} xml DOM file
   */
  processXML(xml) {
    // Important! Clean up!
    this.removeStatus();

    let element  = this.$el.querySelector('.items');

    // Select ifRSS or ATOM, set variables
    let isRss  = xml.querySelector('feed') === null,
        isAtom = xml.querySelector('rss')  === null;

    if(!isRss && !isAtom){
      console.log('Error in XML:', xml);
      this.setStatus('Loading failed!', 'red');
      return;
    }

    //                             RSS?            Atom?
    let channelSelector = isRss ? 'channel'     : 'feed',
        itemTag         = isRss ? 'item'        : 'entry',
        descSelector    = isRss ? 'description' : 'summary',
        imageSelector   = '[medium=image]',
        content         = '',
        missingImages   = [];


    let items = xml.querySelector(channelSelector);
    if(items)
      items = items.getElementsByTagName(itemTag);
    else
      return this.setStatus('Loading failed!', 'red');

    // set the copyright text
    let copyright = xml.querySelector('copyright');
    if(copyright) {
      copyright = _squeezeText(copyright.textContent);
    }
    else {
      let uri = this.getLink().match(/\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
      let uriElements = uri[1].split('.');
      uri = uriElements[uriElements.length-2] + '.' +
            uriElements[uriElements.length-1];
      copyright = '&copy; ' + new Date().getFullYear() + ' ' + uri;
    }


    for (let i = 0; i < this.getLimit() && i < items.length; i++) {
      let image = '', desc, title, link;

      try {
        // Set Description var
        desc  = this.strip(items[i].querySelector(descSelector).innerHTML),
        title = items[i].querySelector('title').innerHTML,
        link  = '';
      }
      catch(e) {
        return this.setStatus('Loading failed!', 'red');
      }

      if(items[i].querySelector('link'))
        link = items[i].querySelector('link').innerHTML;

      if(!link) link = items[i].querySelector('link').getAttribute('href');
      if(!link) link = items[i].querySelector('link').nextSibling.data;

      // Set html contents
      let titleWrap = this.wrapInTag('span', title, ['title']),
          imageId   = Math.floor((Math.random() * 1000) + 98),
          imageOpt  = {id: 'id'+imageId};

      missingImages.push({id: 'id' + imageId, link: link});

      image = this.wrapInTag('div', this.wrapInTag(
        'div', copyright, ['copyright']
      ), ['newsImage'], imageOpt);

      let hasOps   = !this.getImageStatus() ? {style: 'width:100%;'} : {};
      let itemHTML = this.wrapInTag(
        'div', image + this.wrapInTag(
          'div', this.wrapInTag(
            'span', '&nbsp;+++&nbsp;', ['spacer']
          ) + titleWrap + this.wrapInTag(
            'span', ': ', ['spacer']
          ) + _decodeHtml(desc), ['context'], hasOps
        ), ['item', 'clearfix']
      );

      content += itemHTML;
    }

    element.innerHTML = this.wrapInTag('span', content, ['feed', 'clearfix']);

    for(let i = 0; i < missingImages.length; i++) {
      this.requestImage(missingImages[i].link, missingImages[i].id);
    }

    this.renderSpeedSettings();

    //Keep rendering stuff
    this.renderLayout(this.getSliderState());
    this.renderPaddings();
  }

  /**
   * Set settings for speed
   */
  renderSpeedSettings() {
    if(!this.getSliderState()) {
      clearTimeout(this.swipeTask);
      this.startFadeAnimation();
      return;
    }

    let element    = this.$el.querySelector('.items'),
        getLetters = this.$el.querySelector('.items .feed');

    if(element !== null) {
      let width = getLetters.offsetWidth;
      let speed = this.getSpeed();

      let duration = width / (0.75 * speed + 25);

      element.style.webkitAnimationDuration = duration + 's';
      element.style.animationDuration = duration + 's';
    }
  }

  /**
   * Select html content and call setRssFeed
   * @param  {String} load local file
   */
  renderContent(local) {
    this.setRssFeed(local);
  }

  /**
   * Managing Switches between Slider and Fader
   * @param  {boolean} sliderState Status of slider
   */
  renderLayout(sliderState) {
    if(sliderState) {
      this.$el.classList.remove('no-slider');
      this.$el.classList.add('slider');

      // Remove Slider
      if(this.swipeTask) clearTimeout(this.swipeTask);

      let items = this.$el.getElementsByClassName('item');
      let activeItem = this.$el.querySelector('.items .active');

      if(activeItem !== null) activeItem.classList.remove('active');
      if(items.length !== 0) {
        for (let i = 0; i < items.length; i++) {
          items[i].removeAttribute('style');
        }
      }

      this.runningAnimation = false;
    }
    else {
      this.$el.classList.add('no-slider');
      this.$el.classList.remove('slider');

      this.runningAnimation = true;

      // Init Slider
      if(this.$el.querySelector('.items .item')) {
        this.startFadeAnimation();
      }
    }
  }

  /**
   * Renders all contents
   * @param {String} local set true if local file should be loaded
   */
  renderRss(local) {
    if(this.getLink() !== '') {
      this.renderContent(local);
      this.renderSpeedSettings();
    }

    this.renderLayout(this.getSliderState());
    this.renderPaddings();
  }

  /**
   * Sets the padding to Items
   */
  renderPaddings() {
    if(this.$el.classList.contains('slider')) return;

    let items = this.$el.getElementsByClassName('item');
    if(items.length !== 0) {
      for(let i = 0; i < items.length; i++) {
        items[i].style.padding = this.style.padding + 'px';
      }
    }
  }

  /**
   * Has been called after all modules are rendered
   */
  render() {
    this.$el.classList.add('slider');
    this.renderRss(false);
  }

  stop() {
    clearTimeout(this.swipeTask);
    this.$el = null;
    this.xmlDOM = null;
  }
}
</script>
