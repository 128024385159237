<template>
  <div class="fd-tutorial">
    <div class="fd-tutorial-item-hint" :style="style">
      <div class="fd-tutorial-item-hint-text">
        <div class="fd-tutorial-item-hint-text-header">
          {{this.title}}
          <i class="cross" @click="stopTutorial"></i>
        </div>
        <div class="fd-tutorial-item-hint-text-content">{{this.content}}</div>
        <div class="fd-tutorial-item-hint-text-footer">
          <button v-if="step" @click="setNext(false)">{{this.$_('fd.tutorial.modal.back')}}</button>
          <button class="right" @click="setNext(true)">{{this.buttontext}}</button>
        </div>
      </div>
      <div v-if="this.left == '55px' && this.buttontext != this.$_('fd.tutorial.modal.end')" class="fd-tutorial-item-hint-arrow left"></div>
      <div v-if="this.left != '55px' && this.buttontext != this.$_('fd.tutorial.modal.end')" class="fd-tutorial-item-hint-arrow right"></div>
      <div v-if="this.buttontext == this.$_('fd.tutorial.modal.end')" class="fd-tutorial-item-hint-arrow left save"></div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'TutorialView',
	data() {
		return {
			title: false,
			content: false,
			step: 0,
			buttontext: this.$_('fd.tutorial.modal.next'),
			left: '55px',
			top: '0px',
		};
	},
  computed: {
    style() {
			return {
        top: this.top,
				left: this.left,
				}
    }
  },
	mounted() {
		this.title = this.$_('fd.tutorial.settings.title');
		this.content = this.$_('fd.tutorial.settings.content');

		this.$root.$on('stopTutorial', _ => {
			this.step = -1;
			this.setNext(true);
		});
	},
	methods: {
		setNext(next) {
			let step = '';

			if (next) {
				this.step++;
			} else {
				this.step--;
			}

			switch (this.step) {
				case 0:
					step = 'settings';
					this.top = '0px';
					break;
				case 1:
					step = 'modules';
					this.top = '290px';
					this.left = '55px';
					break;
				case 2:
					step = 'undo';
					this.left = (window.innerWidth - 290) + 'px';
					this.top = '0px';
					break;
				case 3:
					step = 'toolbar';
					this.top = '93px';
					this.left = (window.innerWidth - 290) + 'px';
					break;
				case 4:
					step = 'help';
					this.buttontext = this.$_('fd.tutorial.modal.next');
					this.left = '55px';
					this.top = '480px';
					break;
				case 5:
					step = 'save';
					this.buttontext = this.$_('fd.tutorial.modal.end');
					this.top = '0px';
					break;
				case 6:
					this.$root.$emit('stopTutorial');
					return;
					break;
			}
			this.title = this.$_('fd.tutorial.' + step + '.title');
			this.content = this.$_('fd.tutorial.' + step + '.content');
		},
		stopTutorial() {
			this.$root.$emit('stopTutorial');
		}
	},
};
</script>

<style lang="scss">
.fd-tutorial {
	.fd-tutorial-item-hint {
		font-family: sans-serif, Arial, Verdana, 'Trebuchet MS';
		position: absolute;
		background-color: rgb(249, 200, 0);
		color: black;
		width: 250px;
		height: fit-content;
		justify-content: center;
		align-items: center;
		font-size: 11px;
		font-weight: bold;
		border-radius: 3px;
		box-shadow: 2px 2px 15px;
		margin: auto auto;
	}
	.fd-tutorial-item-hint-text {
		.fd-tutorial-item-hint-text-header {
			line-height: 30px;
		}
		line-height: 15px;
		vertical-align: center;
		width: 100%;
		padding: 0 5px 0 5px;
		&.fd-tutorial-item-hint-text-title {
			width: 10px;
		}
		.cross {
			color: white;
			float: right;
			padding: 8px 15px 0px 0px;
			cursor: pointer;
		}
	}
	.fd-tutorial-item-hint-arrow {
		&.left {
			left: -9px;
			border-right: 10px solid rgb(249, 200, 0);
		}
		&.right {
			left: 249px;
			border-left: 10px solid rgb(249, 200, 0);
		}
		&.save {
			top: 10px !important;
		}
		border-radius: 2px;
		top: 45px;
		position: absolute;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
	}
	.fd-tutorial-item-hint-text-footer {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		button {
			padding: 5px 10px;
			border: 1px solid #000;
			border-radius: 3px;
			cursor: pointer;
			margin: 10px 0 5px 0px;
			background-color: rgb(249, 200, 0);
			outline:0;
			&.right {
				float: right;
				margin-right: 10px;
			}
		}

		button:hover {
			background-color: rgb(214, 214, 214);
		}
	}
}
</style>

<i18n lang="de_DE">

	fd.tutorial.modal.head:  Willkommen beim FoxDesigner
	fd.tutorial.modal.body:  Sind Sie interessiert an einem Tutorial?
	fd.tutorial.modal.run:   Start
	fd.tutorial.modal.later: später
	fd.tutorial.modal.never: Nie
	fd.tutorial.modal.next: weiter
	fd.tutorial.modal.back: zurück
	fd.tutorial.modal.end: Ende

	fd.tutorial.settings.title:   Einstellungen
	fd.tutorial.settings.content: Hier öffnen Sie die Einstellungen, in denen Sie unter anderem das Hintergrundbild einrichten oder ein Gitternetz aktivieren können
	fd.tutorial.modules.title:    Module
	fd.tutorial.modules.content:  Hier finden Sie diverse Module, die Sie mit einem einfachen Klick dem Slide hinzufügen können
	fd.tutorial.undo.title:       Zurück
	fd.tutorial.undo.content:     Hier können Sie in Ihrem Verlauf vor und zurück springen
	fd.tutorial.toolbar.title:    ToolBar
	fd.tutorial.toolbar.content:  Sie finden hier auch diverse andere Buttons, die Ihnen die Arbeit erleichtern. Bei manchen müssen Sie mehr als ein Modul ausgewählt haben!
	fd.tutorial.help.title:       Hilfe
	fd.tutorial.help.content:     Hier finden Sie einige nützliche Tastenkombinationen, die Ihnen die Arbeit erleichtern
	fd.tutorial.save.title:       Speichern
	fd.tutorial.save.content:     Speichern Sie Ihre Arbeit, bevor Sie den FoxDesigner beenden!

</i18n>
