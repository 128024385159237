const xmlns = 'http://www.w3.org/2000/svg';

export function createSVG() {
	const svg = document.createElementNS(xmlns, 'svg');
	svg.setAttributeNS(null, 'width', '0');
	svg.setAttributeNS(null, 'height', '0');
	return svg;
}

export function createSVGElement(tag, attributes) {
	const element = document.createElementNS(xmlns, tag);
	for(const key in attributes) {
		element.setAttributeNS(null, key, attributes[key]);
	}
	return element;
}

export function createURL(svg) {
	const serializer = new XMLSerializer();
	const svgString = serializer.serializeToString(svg);

	return URL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' }));
}

export function generateBlur(deviation) {
	deviation = Math.round(deviation);

	if(document.querySelector('#fd-blur-' + deviation)) {
		return 'url(#fd-blur-' + deviation + ')';
	}

	const svg = createSVG();

	const filter = createSVGElement('filter', {
		id: 'fd-blur-' + deviation,
		x: '-5%',
		y: '-5%',
		width: '110%',
		height: '110%',
	});

	filter.appendChild(createSVGElement('feGaussianBlur', {
		in: 'SourceGraphic',
		stdDeviation: deviation,
	}));

	svg.appendChild(filter);

	document.body.appendChild(svg);

	return 'url(#fd-blur-' + deviation + ')';
}

export function generateDropShadow(x, y, r, color, show) {
	if (show === 0) {
		return '';
	}

	x = Math.round(x);
	y = Math.round(y);
	r = Math.round(r);

	// color = '#000000';

	const selector = `fd-shadow-${x}-${y}-${r}-${color}`
		.replace(/[ \)#]/g, '')
		.replace(/[,\(]/g, '-')
		.replace(/\./g, '_');

	if(document.querySelector('#' + selector)) {
		return `url(#${selector})`;
	}

	const svg = createSVG();

	const filter = createSVGElement('filter', {
		id: selector,
		x: '-20%',
		y: '-20%',
		width: '140%',
		height: '140%',
	});

	filter.appendChild(createSVGElement('feGaussianBlur', {
		in: 'SourceAlpha',
		stdDeviation: r,
	}));

	filter.appendChild(createSVGElement('feOffset', {
		dx: x,
		dy: y,
		result: 'offsetblur',
	}));

	filter.appendChild(createSVGElement('feFlood', {
		'flood-color': color
	}));

	filter.appendChild(createSVGElement('feComposite', {
		in2: 'offsetblur',
		operator: 'in'
	}));

	const feMerge = createSVGElement('feMerge', {});
	filter.appendChild(feMerge);

	feMerge.appendChild(createSVGElement('feMergeNode', {}));

	feMerge.appendChild(createSVGElement('feMergeNode', {
		in: 'SourceGraphic'
	}));

	svg.appendChild(filter);

	document.body.appendChild(svg);

	return `url(#${selector})`;
}
