import Grid from './Grid';

export default {
  createGrid: function(options) {
    return new Grid(options);
  },

  defaultGridList: [{
    rows: 2,
    cols: 3,
    space: 3,
    ratio: 16/9,
    elements: [
      {x:0, y:0, w:1, h:1},
      {x:1, y:0, w:1, h:1},
      {x:2, y:0, w:1, h:1},
      {x:0, y:1, w:1, h:1},
      {x:1, y:1, w:1, h:1},
      {x:2, y:1, w:1, h:1}
    ]
  }, {
    rows: 2,
    cols: 3,
    space: 3,
    ratio: 16/9,
    elements: [
      {x:0, y:0, w:1, h:1},
      {x:1, y:0, w:2, h:2},
      {x:0, y:1, w:1, h:1}
    ]
  }, {
    rows: 2,
    cols: 3,
    space: 3,
    ratio: 16/9,
    elements: [
      {x:0, y:0, w:2, h:2},
      {x:2, y:0, w:1, h:1},
      {x:2, y:1, w:1, h:1}
    ]
  }, {
    rows: 2,
    cols: 3,
    space: 3,
    ratio: 16/9,
    elements: [
      {x:0, y:0, w:1, h:1},
      {x:1, y:0, w:2, h:1},
      {x:0, y:1, w:2, h:1},
      {x:2, y:1, w:1, h:1}
    ]
  }]
}
