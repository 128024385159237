<script>
import CloudsModule from './CloudsModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'CloudsModule',
	icon: 'cloud',
	resize: 'all',
};

export const MetaDescriptionView = {
	name: 'CloudsMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'CloudsModule',
		x: 'center',
		y: 'top',
		width: 1800,
		height: 500,
		style: {
			opacity: 80,
			advanced: null,
		},
		props: {
			cropped: true,
			amountClouds: 10,
			cloudSize: 30,
			cloudSpeed: 2,
			perspectiveLevel: 1,
		},
	};
}

export const ConfigView = {
	name: 'CloudsModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [
				{
					type: 'Boolean',
					label: this.$_('fd.cloudsmodule.settings.cropped'),
					value: this.module.props.cropped,
					events: {
						input: this.setCropped,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.cloudsmodule.settings.amountClouds'),
					value: this.module.props.amountClouds,
					min: 1,
					max: 20,
					events: {
						input: this.setAmountClouds,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.cloudsmodule.settings.cloudSize'),
					value: this.module.props.cloudSize,
					min: 5,
					max: 100,
					events: {
						input: this.setCloudSize,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.cloudsmodule.settings.cloudSpeed'),
					value: this.module.props.cloudSpeed,
					min: -3,
					max: 3,
					events: {
						input: this.setCloudSpeed,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.cloudsmodule.settings.perspectiveLevel'),
					value: this.module.props.perspectiveLevel,
					min: 0,
					max: 10,
					events: {
						input: this.setPerspectiveLevel,
					},
				},
			];
		},
	},
	methods: {
		setCropped(cropped) {
			this.commitChange('props', { cropped });
		},
		setAmountClouds(amountClouds) {
			this.commitChange('props', { amountClouds });
		},
		setCloudSize(cloudSize) {
			this.commitChange('props', { cloudSize });
		},
		setCloudSpeed(cloudSpeed) {
			this.commitChange('props', { cloudSpeed });
		},
		setPerspectiveLevel(perspectiveLevel) {
			this.commitChange('props', { perspectiveLevel });
		},
	},
};

export default {
	extends: CloudsModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.cloudsmodule.title: Wolkenband
fd.cloudsmodule.description: >
	Das Wolkenband-Modul zeigt ein Band aus mehreren vorbeiziehenden Wolken.
fd.cloudsmodule.add: Wolkenband hinzufügen

fd.cloudsmodule.settings.cropped: Beschnitten
fd.cloudsmodule.settings.amountClouds: Anzahl Wolken
fd.cloudsmodule.settings.cloudSize: Wolkengröße
fd.cloudsmodule.settings.cloudSpeed: Geschwindigkeit
fd.cloudsmodule.settings.perspectiveLevel: Perspektive
</i18n>
