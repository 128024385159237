<template>
	<div class="fd-group-module">
		<modules-view :modules="modules"></modules-view>
	</div>
</template>

<script>
/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'GroupModule',
	props: ['module'],
	normalize,
	// https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
	beforeCreate() {
		this.$options.components.ModulesView = require('../components/Modules.vue').default;
	},
	computed: {
		modules() {
			return this.$store.state.data.modules
				.filter(
					m => this.module.props.childs.includes(m.mid)
				)
				// Subtract the GroupModule position
				.map(m => {
					let offsetX = 0;
					let offsetY = 0;

					if(this.module.style.border) {
						offsetX += this.module.style.border.left.px;
						offsetY += this.module.style.border.top.px;
					}

					if(this.module.style.padding) {
						offsetX += this.module.style.padding;
						offsetY += this.module.style.padding;
					}

					return {
						...m,
						x: m.x - this.module.x - offsetX,
						y: m.y - this.module.y - offsetY,
					}
				});
		}
	}
}
</script>
