<template>
	<modal-window-view class="fd-variable-chooser"
		:id="id"
		:size="500"
		:title="$_('fd.variablechooser.title')"
		:buttons="buttons"
	>
		<div class="search">
			<input type="text"
				:placeholder="$_('fd.variablechooser.search')"
				v-model="search"
			>
		</div>
		<div class="var-chooser">
			<div class="variable"
				v-for="(value, variable) in variables"
				:key="variable"
				v-if="
					(!filter || filter(variable))
					&&
					(!search || includesIgnoreCase(variable, search))
				"
			>
				<input type="radio" name="varchooser"
					:id="'var-' + variable"
					:value="variable"
					v-model="select"
				>
				<label :for="'var-' + variable">
					<span class="key">{{variable}}</span>
					<span class="value">{{JSON.stringify(value)}}</span>
				</label>
			</div>
		</div>
	</modal-window-view>
</template>

<script>
import ModalWindowView from './ModalWindow.vue';

export default {
	name: 'VariableChooserModalView',
	props: ['id', 'preselect', 'filter', 'onChoose'],
	components: { ModalWindowView },
	data() {
		return {
			select: this.preselect,
			search: '',
			buttons: [{
				label: this.$_('fd.variablechooser.actions.choose'),
				css: 'primary',
				click: () => this.chooseVariable()
			}, {
				label: this.$_('fd.variablechooser.actions.cancel')
			}]
		}
	},
	computed: {
		variables() {
			return this.$store.state.shared.variables;
		}
	},
	async mounted() {
		if(!this.select) {
			this.select = Object.keys(this.variables)[0];
		}

		await this.$nextTick();

		const selected = this.$el.querySelector('input[name=varchooser]:checked');
		selected && selected.parentNode.scrollIntoView();
	},
	methods: {
		chooseVariable() {
			return this.onChoose(this.select);
		},
		includesIgnoreCase: (a, b) => a.toLowerCase().includes(b.toLowerCase()),
	}
}
</script>

<style lang="scss">
.fd-variable-chooser {
	.body {
		flex-direction: column;

		.search {
			display: flex;
			margin-bottom: 5px;

			input {
				flex: 1;

				font-size: 14px;
				padding: 6px 7px;
				outline: none;
				border: 1px solid #bbb;
				border-radius: 4px;
			}
		}

		.var-chooser {
			flex: 1;
			max-height: 400px;
			overflow-y: auto;

			.variable {
				input {
					display: none;
				}

				input:checked + label {
					color: #fff;
					background: #888;
				}

				label {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					border-radius: 3px;
					padding: 5px;

					&:hover {
						background: #eee;
					}

					span.value {
						font-family: Consolas, "Currier New", monospace;
						font-size: smaller;
					}
				}
			}
		}
	}
}
</style>

<i18n lang="de_DE">
fd.variablechooser.title: Variable auswählen
fd.variablechooser.search: Suchen (nach z. B. 'Daily')
fd.variablechooser.actions.choose: Wählen
fd.variablechooser.actions.cancel: Abbrechen
</i18n>
