<template>
  <div class="fd-animation-list-item-view" :key="'animation_' + item.name">
    <div class="row">{{ item.index }}</div>
    <div class="image">
      <i v-bind:class="item.icon"
				@click="openAnimation()">
			</i>
    </div>

    <div class="slider">
      <span
        class="move" :style="style"
        v-bind:id="item.index"
        v-bind:title="'delay: ' + item.delay + 's' + ' | ' + 'duration: ' + item.duration + 's'"
				@mousedown="getElement"
        v-movable
        @move:start="moveStartSlider"
        @move="moveSlider"
				@move:end="moveEndSlider"
      ></span>
    </div>
  </div>
</template>

<script>
import * as ANIMATIONS_EXTRA from '../animations/_ANIMATIONS_EXTRA.js';
import ANIMATIONS from '../animations';

export default {
	name: 'AnimationListItemView',
	props: ['item'],
	data: () => ({
		element: null,
		duration: null,
		delay: null,
		startDelay: null,
		startDuration: null,
		lastMoveDuration: 0
	}),
	computed: {
		style() {
			return {
				width: `${this.duration}px`,
				transform: `translateX(${this.delay}px)`,
			};
		},
	},
	mounted() {
		if(typeof this.item.icon == 'undefined') {
			this.item.icon = ANIMATIONS[this.item.name].icon;
		}
		let sliderWidth = document.getElementsByClassName('slider')[0].offsetWidth - 10;
		this.delay = (this.item.delay / 60) * sliderWidth;
		this.duration = this.item.duration * 10;
	},
	methods: {
		getElement(evt) {
			if (evt.offsetX < 3) this.element = 1; // span.move::before -> duration
			else if (evt.offsetX > document.getElementById(this.item.index).offsetWidth - 3) this.element = 2; // span.move::after -> duration
			else this.element = 3; // span.move -> delay
		},
		moveStartSlider(evt) {
			this.startDelay = this.delay;
			this.startDuration = this.duration;
		},
		moveSlider(evt) {

			this.$emit('move', evt);
			if (!evt.returnValue) {
				return;
			}

			let { detail: { moveX }, } = evt;
			let sliderWidth = evt.target.parentNode.offsetWidth - 10;

			if (this.element == 3) {

				if (this.item.delay >= 0 && this.item.delay <= 60) {
					let move = moveX + this.startDelay;

					this.item.delay = ((move / sliderWidth) * 60);

					if (this.item.delay < 0) {
						this.item.delay = 0;
					}
					if (this.item.delay > 60) {
						this.item.delay = 60;
					}

					this.delay = (this.item.delay / 60) * sliderWidth;

					this.$store.state.data.modules.forEach(module => {
						module.animation.forEach(animation => {

							if (animation.index == this.item.index) {

								this.item.delay = Math.round(this.item.delay);
								animation.delay = this.item.delay;
								if (animation.delay < 0) animation.delay = 0;
								if (animation.delay > 60) animation.delay = 60;

								this.$store.commit('dirty');

							}

						});
					});
				}

			} else if (this.element == 2) {

				if (this.item.duration >= 1 && this.item.duration <= 10) {
					let after = moveX + this.startDuration;

					this.item.duration = after / 10;

					if (this.item.duration < 1) {
						this.item.duration = 1;
					}
					if (this.item.duration > 10) {
						this.item.duration = 10;
					}

					this.duration = this.item.duration * 10;

					this.$store.state.data.modules.forEach(module => {
						module.animation.forEach(animation => {

							if (animation.index == this.item.index) {

								this.item.duration = Math.round(this.item.duration);
								animation.duration = this.item.duration;
								if (animation.duration < 1) animation.duration = 1;
								if (animation.duration > 10) animation.duration = 10;

								this.$store.commit('dirty');

							}

						});
					});
				}

			} else if (this.element == 1) {

				if (this.item.duration >= 1 && this.item.duration <= 10) {
					let before = -moveX + this.startDuration;
					let difference = before - this.lastMoveDuration;

					// prevent duration for getting longer when delay is 0 and dragged over it
					if (this.item.delay == 0 && difference > 0) {
						return;
					}

					if (before >= 0) {
						if (before >= 10 && before <= 100) {
							this.item.duration = before / 10;
						}
					}

					if (before >= 10 && before <= 100) {
						if (this.duration > 10 && this.duration < 100) {
							let move = moveX + this.startDelay;
							this.item.delay = ((move / sliderWidth) * 60);
						}
					}

					if (this.item.duration < 1) {
						this.item.duration = 1;
					}
					if (this.item.duration > 10) {
						this.item.duration = 10;
					}
					if (this.item.delay < 0) {
						this.item.delay = 0;
					}
					if (this.item.delay > 60) {
						this.item.delay = 60;
					}

					this.duration = this.item.duration * 10;
					this.delay = (this.item.delay / 60) * sliderWidth;
					this.lastMoveDuration = before;

					this.$store.state.data.modules.forEach(module => {
						module.animation.forEach(animation => {

							if (animation.index == this.item.index) {

								this.item.duration = Math.round(this.item.duration);
								animation.duration = this.item.duration;
								if (animation.duration < 1) animation.duration = 1;
								if (animation.duration > 10) animation.duration = 10;

								this.item.delay = Math.round(this.item.delay);
								animation.delay = this.item.delay;
								if (animation.delay < 0) animation.delay = 0;
								if (animation.delay > 60) animation.delay = 60;

								this.$store.commit('dirty');

							}

						});
					});
				}

			}

		},
		moveEndSlider() {
			this.$store.state.data.modules.forEach(module => {
				module.animation.forEach(animation => {

					if (animation.index == this.item.index) {

						this.$root.$emit('init' + animation.index, animation.index);

					}

				});
			});
		},
		openAnimation() {
			let index = 0;
			this.$store.state.data.modules.forEach(module => {
				module.animation.forEach(animation => {

					if (animation.index == this.item.index) {

						this.$store.commit('activeModules', [module.mid]);
						this.$store.commit('activeAnimation', animation.index);
						this.$store.commit('activeAnimationIndex', index);
						this.$store.commit('highlightedModules', []);
						this.$store.dispatch('subNav/components', ANIMATIONS_EXTRA[animation.name]);

						index++;
					}

				});
			});
		}
	},
};
</script>

<style lang="scss">
.fd-animation-list-item-view {
	display: flex;
	margin: 0;
	padding: 5px 0;
	.row {
		width: 23px;
		text-align: center;
	}
	.image {
		flex: none;
		width: 20px;
		border-right: 1px solid #fff;
		i {
		cursor: pointer;
		}
	}
	.slider {
		position: relative;
		flex: 1;
		.move {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: #fff;
			cursor: ew-resize;
		}
		.move::after {
			content: '';
			position: absolute;
			top: 0;
			left: auto;
			right: -1px;
			height: 100%;
			width: 3px;
			cursor: col-resize;
		}
		.move::before {
			content: '';
			position: absolute;
			top: 0;
			left: -1px;
			height: 100%;
			width: 3px;
			cursor: col-resize;
		}
	}
}
</style>