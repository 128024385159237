const state = {
	advanced: false,
	snap: true,
	padding: 0,
	gridlines: false,
	autosave: true,
	tutorial: true,
}

function init(config) {
	const savedState = {};

	Object.keys(localStorage)
		.filter(key => ~key.indexOf('settings.'))
		.forEach(key => {
			savedState[key.replace('settings.', '')] = JSON.parse(localStorage[key]);
		});

	return {
		...state,
		...savedState
	};
}

export async function create(config) {
	return {
		namespaced: true,

		state: init(config),

		mutations: {
			mutation(state, mutation) {
				for(const key in mutation) {
					state[key] = mutation[key];
					localStorage.setItem('settings.' + key, JSON.stringify(mutation[key]));
				}
			}
		}
	}
}
