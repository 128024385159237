<template>
	<div class="fd-placeholder-module"></div>
</template>

<script>
export default {
	name: 'PlaceholderModule',
	normalize: () => {},
}
</script>
