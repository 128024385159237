<template>
	<div>
		<img
			v-if="imageData && imageData.src"
			:src="imageData.src">
		<canvas
			v-else
			ref="canvas"
			class="fd-draw-module"
			:class="{'ready': isRendered}"
			:width="width"
			:height="height"
		></canvas>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import { compute as getColor } from '../styles/Color.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'DrawModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			ctx: null
		}
	},
	computed: {
		width() {
			if(this.module.props.image) {
				return this.module.props.canvas.width;
			}

			return this.module.width;
		},
		height() {
			if(this.module.props.image) {
				return this.module.props.canvas.height;
			}

			return this.module.height;
		},
		isRendered() {
			if(!this.ctx) return false;

			this.draw(this.ctx);
			return true;
		},
		imageData() {
			if (this.isRendered && (this.module.props.image || this.$store.state.mode === 'show')) {
				return {
					src: this.$refs.canvas.toDataURL()
				};
			}
			return null;
		}
	},
	mounted() {
		this.ctx = this.$refs.canvas.getContext('2d');
	},
	methods: {
		draw(ctx) {
			ctx.fillStyle = getColor(this.module.style.color).color;
			ctx.strokeStyle = getColor(this.module.style.color).color;

			ctx.lineCap = 'round';
			ctx.lineJoin = 'round';
			ctx.lineWidth = this.module.props.lineWidth || 10;

			ctx.clearRect(
				0,
				0,
				this.width,
				this.height,
			);

			this.module.props.drawingElements.forEach(el => {
				try {
					this[el.shape](el, ctx, this.module.props.mode);
				}

				// FF 19 does not support ctx.ellipse
				catch (err) {
					console.error(err);
				}
			});
		},

		rect(el, ctx, mode) {
			ctx[mode + 'Rect'](el.x, el.y, el.w, el.h);
		},

		circle(el, ctx, mode) {
			ctx.beginPath();
			ctx.ellipse(
				el.x,
				el.y,
				Math.abs(el.rx),
				Math.abs(el.ry),
				0, 0, 2*Math.PI, true
			);
			ctx[mode]();
		},

		stroke(el, ctx, mode) {
			ctx.beginPath();

			el.points.forEach((point, i) => {
				ctx[i === 0 ? 'moveTo' : 'lineTo'](point.x, point.y);
			});

			ctx[el.points.length < 3 ? 'stroke' : mode]();
		}
	}
}
</script>

<style lang="scss">
.fd-draw-module {
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
}
</style>