<template>
	<div class="fd-group">
		<component
			v-for="(item, i) of config.values"
			:key="item.type + '_' + i"
			:is="item.type + 'View'"
			:config="item"
			:value="item.value"
			v-on="item.events"
		></component>
	</div>
</template>

<script>
import * as INPUTS from '../inputs';

export default {
	name: 'GroupInput',
	props: ['value', 'config'],
	components: { ...INPUTS },
}
</script>

<style lang="scss">
.fd-group {
	width: 100%;

	display: flex;

	& > * {
		margin-left: 5px;

		&:first-child {
			margin-left: 0;
		}
	}

	.fd-button {
		text-overflow: initial;

		i {
			margin: 0;
		}
	}

	.fd-number-input {
		text-align: center;
	}
}
</style>
