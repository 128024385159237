<template>
	<div class="fd-sub-nav-view"
		:style="style"
		:class="$store.state.subNav.components.map(c => c.name)"
	>

		<div class="control-bar">
			<button class="smaller" @click="toggleFixed"><i class="pushpin"></i></button>
			<button class="bigger" @click="closeNav"><i class="cross3"></i></button>
		</div>

		<div class="scroller" ref="scroller" :style="scrollerStyle" @click="hideColorPicker">
			<component
				v-for="component of $store.state.subNav.components"
				:key="component.name || component"
				:is="component"
			></component>
		</div>

		<div class="resizer"
			v-movable
			@move:start="moveStart"
			@move="move"
			@move:end="moveEnd"
		></div>

	</div>
</template>

<script>
export default {
	name: 'SubNavView',
	data() {
		return {
			scrollBarWidth: 0,
			startSubNavWidth: 0,
		}
	},
	computed: {
		subNavWidth() {
			let width = this.$store.state.subNav.width;

			if(
				this.$store.state.subNav.components.some(
					component => component.name === 'TemplatesView'
				)
			) {
				width *= 2;
			}

			return width;
		},
		style() {
			const translateX = this.$store.state.subNav.components.length || this.$store.state.subNav.fixed
				? 0
				: 'calc(-100% - 2px)';
			return {
				transform: `translateX(${translateX})`,
				width: this.subNavWidth + 'px'
			}
		},
		scrollerStyle() {
			if(this.$store.state.subNav.colorPicker.visible) {
				return {
					width: '100%',
					overflow: 'hidden'
				}
			}

			return {
				width: `calc(100% + ${this.scrollBarWidth}px)`
			}
		}
	},
	mounted() {
		this.$store.subscribe(async (mutation, state) => {
			if(mutation.type !== 'subNav/components') return;

			this.hideColorPicker();

			if(!mutation.payload) return;

			await this.$nextTick();
			this.scrollBarWidth = this.$refs.scroller.offsetWidth - this.$refs.scroller.clientWidth;
		});

		this.$store.subscribe(async (mutation, state) => {
			if(mutation.type !== 'subNav/resize') return;

			await this.$nextTick();
			this.scrollBarWidth = this.$refs.scroller.offsetWidth - this.$refs.scroller.clientWidth;
		});
	},
	methods: {
		toggleFixed() {
			this.$store.commit('subNav/fixed', !this.$store.state.subNav.fixed);
		},
		closeNav() {
			this.$store.commit('subNav/fixed', false);
			this.$store.dispatch('subNav/components', false);
		},
		hideColorPicker() {
			this.$store.commit('subNav/colorPicker', {
				visible: false,
				offset: 0,
				color: [0, 0, 0, 1]
			});
		},

		moveStart() {
			this.$el.style.userSelect = 'none';
			this.startSubNavWidth = this.$store.state.subNav.width;
		},
		move({ detail: { moveX } }) {
			if(this.startSubNavWidth + moveX < 200) {
				moveX = 200 - this.startSubNavWidth;
			}
			if(this.startSubNavWidth + moveX > 800) {
				moveX = 800 - this.startSubNavWidth;
			}
			this.$store.commit('subNav/width', this.startSubNavWidth + moveX);
			this.$store.commit('subNav/resize');
		},
		moveEnd() {
			this.$el.style.userSelect = '';
		}
	}
}
</script>

<style lang="scss">
.fd-sub-nav-view {
	&, & * {
		box-sizing: border-box;
	}

	color: #fff;
	background: rgba(0, 0, 0, .9);
	border-left: 1px solid #333;
	transition: transform 0.3s;

	.control-bar,
	.scroller {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.control-bar {
		z-index: 30;

		display: flex;
		justify-content: flex-end;

		button {
			color: #fff;
			cursor: pointer;
			padding: 5px;

			background: none;
			border: none;
			outline: none;

			&.smaller {
				font-size: 10px;
				padding: 4px 5px 6px;
			}
			&.bigger {
				font-size: 16px;
			}
		}
	}

	.scroller {
		z-index: 10;
		height: 100%;
		padding: 7px;

		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;

		display: flex;
		flex-direction: column;
	}

	.resizer {
		position: absolute;
		top: 0;
		right: -2px;
		height: 100%;
		z-index: 20;

		width: 4px;
		cursor: ew-resize;
	}
}
</style>
