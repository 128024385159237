<template>
	<div class="fd-watermark-module" :style="style">
		<sup>&reg;</sup>
	</div>
</template>

<script>
import '../../scss/fonts/opensans.scss';
import '../../scss/fonts/agency.scss';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	props.factor = props.factor || 24;
}

export default {
	name: 'WatermarkModule',
	props: ['module'],
	normalize,
	computed: {
		style() {
			return {
				fontSize: this.module.props.factor / 2 + 'px'
			}
		}
	}
}
</script>

<style lang="scss">
.fd-watermark-module-root {
	z-index: 9999 !important;
}
.fd-watermark-module {
	font-family: OpenSans, sans-serif;
	font-size: 0.5em;
	text-shadow: 0 0 10px rgba(0,0,0,0.8);
	text-align: center;
	color: white;

	&:before {
		content: 'solarfox';
		position: relative;
		top: 0.5em;
		font-family: Agency;
		font-weight: bold;
		font-size: 3em;
	}
}
</style>
