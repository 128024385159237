<template>
	<div class="fd-clouds-module">
		<div class="clouds" :class="cloudDirectionPaused ? 'all-inside' : null" :style="{
			overflow: cropped ? 'hidden' : 'visible',
		}">
			<div class="cloud-rail" v-for="k in amountClouds" :key="k" :class="'cloud-rail-' + k" :style="cloudRailStyle(k-1)">
				<div class="cloud" :class="'cloud-' + k" :style="cloudStyle(k-1)">
					<div v-for="n in 20" :key="n" class="cloud-part" :class="'cloud-part-' + n" :style="cloudPartStyle(n)">
						<div class="inner" :style="cloudPartInnerStyle()"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'CloudsModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			cloudDirectionPaused: false,
		}
	},
	computed: {
		cropped() {
			return this.module.props.cropped;
		},
		amountClouds() {
			return this.module.props.amountClouds || 1;
		},
		cloudSize() {
			return this.module.props.cloudSize;
		},
		cloudSpeed() {
			this.cloudDirectionPaused = this.module.props.cloudSpeed === 0;
			return Math.abs(this.module.props.cloudSpeed) || 0.01;
		},
		cloudDirection() {
			return this.module.props.cloudSpeed > -1 ? 'normal' : 'reverse';
		},
		perspectiveLevel() {
			return this.module.props.perspectiveLevel / 5 || 0;
		},
	},
	methods: {
		rand: function (range, noFloor) {
			if (!range) return Math.random();
			return noFloor ? Math.random() * range : Math.floor(Math.random() * range);
		},
		perspectiveValue: function (n) {
			return 1 + this.perspectiveLevel * n / this.amountClouds;
		},
		cloudRailStyle: function (n) {
			var duration = (this.perspectiveValue(n)) * (this.rand(120/this.cloudSpeed) + 480/this.cloudSpeed);
			var delay = this.rand(-1 * duration);
			return {
				animationDelay: delay + 's',
				animationDuration: duration + 's',
				animationDirection: this.cloudDirection,
				animationPlayState: this.cloudDirectionPaused ? 'paused' : null,
				zIndex: this.amountClouds - n,
			};
		},
		cloudStyle: function (n) {
			return {
				width: this.cloudSize + 'vh',
				height: this.cloudSize + 'vh',
				top: (this.rand(100/this.amountClouds) + (100/this.amountClouds) * n) + '%',
				transform: `translate3d(-50%, -50%, 0) scale(${ 1 / Math.pow(this.perspectiveValue(n), 2) })`,
			};
		},
		cloudPartStyle: function (n) {
			const rand = Math.random();
			return {
				left: this.rand(10) + 5 * n + '%',
				top: Math.round(rand * 30 + 35) + '%',
				transform:
					'translate3d(-50%, -50%, 0) ' +
					`scale3d(1, ${ 1 + this.perspectiveLevel * 0.4 * (0.1 - rand) }, 1) ` +
					`rotate3d(0, 0, 1, ${ this.rand(360) } deg)`,
			};
		},
		cloudPartInnerStyle: function () {
			return {
				animationDelay: this.rand(-180) + 's',
				animationDuration: this.rand(60) + 120 + 's',
				animationPlayState: this.$root.fastEnough && this.cloudSize > 10 ? null : 'paused',
			};
		},
	},
};
</script>

<style lang="scss">
@keyframes fd_clouds_module_part_anim {
  0% {
    transform: scale3d(0.6, 1, 1) translate3d(-70%, -30%, 0) rotate3d(0, 0, 1, 0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale3d(1.2, 1, 1) translate3d(-30%, -70%, 0) rotate3d(0, 0, 1, -360deg);
    opacity: 0;
  }
}
@keyframes fd_clouds_module_cloud_rail_anim {
  from {
    transform: translate3d(-100%, 0, 0);
	opacity: 0;
  }
  10% {
	opacity: 1;
  }
  90% {
	opacity: 1;
  }
  to {
    transform: translate3d(100%, 0, 0);
	opacity: 0;
  }
}

.fd-clouds-module {
	position: relative;

	.abs-h-v-centered {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.pointer-events-none {
		pointer-events: none;
	}

	.clouds {
		@extend .pointer-events-none;
		position: relative;
		width: 100%;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);

		.cloud-rail {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			will-change: transform;
			animation: fd_clouds_module_cloud_rail_anim 120s linear infinite;
		}

		&.all-inside .cloud-rail {
			left: 25%;
			width: 50%;
		}
	}

	.cloud {
		@extend .abs-h-v-centered;
		width: 100%;
		height: 200%;

		.cloud-part {
			@extend .abs-h-v-centered;
			width: 75%;
			height: 75%;

			.inner {
				@extend .abs-h-v-centered;
				width: 100%;
				height: 100%;
				background: url('../../img/cloud/cloud1.png');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;

				will-change: transform, opacity;
				animation: fd_clouds_module_part_anim 120s cubic-bezier(.1,.4,.9,.6) infinite alternate;
			}

			&:nth-child(even) .inner {
				background-image: url('../../img/cloud/cloud2.png');;
			}
		}
	}
}
</style>
