<template>
	<window-view
		class="fd-text-editor-view"
		v-show="this.$store.getters.activeModules[0].props.useVariables"
		:id="id"
		icon="pencil"
		:title="$_('fd.texteditor.title')"
		:width="600"
		:height="400"
	>
		<div class="content" :style="style" @click="editorClicked">
			<div :id="editorId" v-html="processedValue"></div>
		</div>

		<div class="footer">
			<button class="fd-button" @click="insertVar">{{$_('fd.texteditor.insertvar')}}</button>
			<button class="fd-button" @click="insertCalc">{{$_('fd.texteditor.insertcalc')}}</button>
			<!--button class="fd-button" @click="insertCon">
				Insert Condition
			</button-->
		</div>
	</window-view>
</template>

<script>
import WindowView from './Window.vue';
import * as CKE from '../../util/CKEditor.js';
import VariableChooser from './VariableChooser.vue';
import NoVariablesModalView from '../Modal/NoVariablesModal.vue';
import CalcChooser from './CalcChooser.vue';
import { _containsVariables } from '../../util/helper.js';

export default {
	name: 'TextEditorView',
	props: ['id', 'value', 'onChange'],
	components: { WindowView },
	data: () => ({
		dark: false,
	}),
	computed: {
		style() {
			return {
				background: `hsla(0, 0%, ${this.dark ? 0 : 100}%, .8)`,
			};
		},
		processedValue() {
			return this.preProcess(this.value);
		},
		variables() {
			return this.$store.state.shared.variables;
		},
		useVariables() {
			return this.$store.getters.activeModules[0].props.useVariables;
		},
		editorId() {
			return 'editor' + this.$store.getters.activeModules[0].mid;
		},
	},
	mounted() {
		this.initEditor();

		this.$root.$on('updateEditor', _ => this.initEditor());
	},
	methods: {
		initEditor() {
			for (name in CKEDITOR.instances) {
				CKEDITOR.instances[name].destroy(true);
			}

			let contain = _containsVariables(this.$store.getters.activeModules[0].props.text);

			if (contain) {
				this.$store.getters.activeModules[0].props.useVariables = true;
				this.$root.$emit('abortRemoveVariable');
			}
			// Create an instance of ckeditor
			if (this.$store.getters.activeModules[0].props.useVariables) {
				if (document.getElementsByClassName('fd-window-view')[0].style.display == 'none')
					document.getElementsByClassName('fd-window-view')[0].style.display = 'flex';
				this.$options.editor = CKEDITOR.replace(
					document.getElementById(
						'editor' + this.$store.getters.activeModules[0].mid
					),
					{
						language: this.$_('fd.language').split('_')[0],

						// Insert the right fonts
						font_names: this.$store.state.shared.fonts
							.map(font => `${font.name}/${font.value}`)
							.sort()
							.join(';'),

						// Fix height in css
						height: '',
						extraAllowedContent: 'span(*)',

						// My default config
						...CKE.CONFIG,
					}
				);

				// Add an extra button to the toolbar to toggle the background between
				// black and white
				CKE.addToolbarButton(
					this.$options.editor,
					{
						label: this.$_('fd.texteditor.toggle.backgroundcolor'),
						icon: 'toggle-backgroundcolor',
					},
					() => (this.dark = !this.dark)
				);
				this.$root.$emit(
					'updateText' + this.$store.getters.activeModules[0].mid
				);

				let content = this.preProcess(
					this.$store.getters.activeModules[0].props.text
				);

				this.$options.editor.setData(content);
			} else {
				if (document.getElementById('cke_fd-text-module' + this.id))
					document.getElementById('cke_fd-text-module' + this.id).remove();
				document.getElementsByClassName('fd-window-view')[0].style.display = 'none';
				if(this.$store.state.type == 'logo') {
					// Use px font_size for logopositioning
					CKE.CONFIG.format_tags = 'p';
					CKE.CONFIG.fontSize_sizes = [
						12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46,
						48, 50, 52, 55, 60, 65, 72, 80, 90, 100, 110, 120, 130, 140, 150, 160,
						170, 180, 190, 200, 220, 240, 260
					].map(size =>
						`${size}px`
					).join(';')
				}

				var div = document.getElementById('fd-text-module' + this.id);
				div.setAttribute('contenteditable', true);
				CKE.CONFIG.extraPlugins = CKE.CONFIG.extraPlugins.replace(
					'customVariable,customCalculation',
					''
				);
				CKE.CONFIG.extraPlugins = CKE.CONFIG.extraPlugins.replace('', '');
				this.$options.editor = CKEDITOR.inline(div, {
					language: this.$_('fd.language').split('_')[0],
					colorButton_enableMore: true,

					// Insert the right fonts
					font_names: this.$store.state.shared.fonts
						.map(font => `${font.name}/${font.value}`)
						.sort()
						.join(';'),

					// Fix height in css
					height: '',

					// My default config
					...CKE.CONFIG,
				});
				var div = document.getElementById(
					this.$store.getters.activeModules[0].mid
				);
			}

			// Register a change listener to update state
			this.$options.editor.on('change', e => {
				this.onChange(this.postProcess(e.editor.getData()));
			});
		},
		destroyed() {
			if (this.$options.editor && this.$options.editor.status == 'ready') {
				this.$options.editor.destroy();
			}

			this.$options.editor = null;
		},
		preProcess(txt) {
			const prepare = {};
			let match, regex;

			// Surround calcs with span.calc
			// ´calculation´{optional_options}
			regex = /(´|&acute;)(.*?)(´|&acute;)(\{([.,]?[.,]?[\d]?)\})?/g;
			while ((match = regex.exec(txt)) !== null) {
				const [needle, _1, calc, _2, _3, options] = match;

				prepare[needle] =
					'<span class="calc">' +
					// Replace # with special char sequenz to prevent var replace
					// within calc
					calc.replace(/#/g, '&~;') +
					'|' +
					(options || ',2') +
					'</span>';
			}

			// Surround vars with span.var
			regex = /#[\w,]+?#/g;
			while ((match = regex.exec(txt)) !== null) {
				if (!this.variables[match[0]]) continue;

				prepare[match[0]] = `<span class="var">${match[0]}</span>`;
			}

			for (const key in prepare) {
				txt = txt.split(key).join(prepare[key]);
			}

			// Replace special char sequenz back to #
			return txt.replace(/&~;/g, '#');
		},
		postProcess(txt) {
			const cleanup = [];
			let match, regex;

			// Remove span.var
			regex = /<span class="var">(.*?)<\/span>/g;
			while ((match = regex.exec(txt)) !== null) {
				cleanup.push({
					needle: match[0],
					replace: match[1],
				});
			}

			// Remove span.calc
			regex = /<span class="calc">(.*?)<\/span>/g;
			while ((match = regex.exec(txt)) !== null) {
				const [calc, options] = match[1].split('|');
				cleanup.push({
					needle: match[0],
					replace: `´${calc}´{${options}}`,
				});
			}

			cleanup.forEach(entry => {
				txt = txt.replace(entry.needle, entry.replace);
			});

			return txt;
		},
		editorClicked(evt) {
			if (!evt.target.classList.contains('cke_widget_element')) return;

			if (evt.target.classList.contains('var')) {
				this.editVar(evt.target);
			}

			if (evt.target.classList.contains('calc')) {
				this.editCalc(evt.target);
			}
		},
		insertVar() {
			if (!this.variables || Object.keys(this.variables).length < 1) {
				this.$store.commit('modal/create', {
					component: NoVariablesModalView,
				});
				return;
			}

			this.$store.commit('window/create', {
				id: this.id + ':vc',
				component: VariableChooser,
				props: {
					onChoose: variable => {
						if (!variable) return false;

						const el = this.$options.editor.document.createElement('span');
						el.addClass('var');

						el.setText(variable);

						this.$options.editor.insertElement(el);
						this.$options.editor.widgets.initOn(el, 'customVariable');
					},
				},
			});
		},
		editVar(element) {
			this.$store.commit('window/create', {
				id: this.id + ':vc',
				component: VariableChooser,
				props: {
					preselect: element.innerHTML,
					onChoose: variable => {
						if (!variable) return false;

						element.innerHTML = variable;
						this.$options.editor.fire('change');
					},
				},
			});
		},
		insertCalc() {
			this.$store.commit('window/create', {
				id: this.id + ':cc',
				component: CalcChooser,
				props: {
					onChoose: calc => {
						if (!calc) return false;

						const el = this.$options.editor.document.createElement('span');
						el.addClass('calc');

						el.setText(calc);

						this.$options.editor.insertElement(el);
						this.$options.editor.widgets.initOn(el, 'customCalculation');
					},
				},
			});
		},
		editCalc(element) {
			this.$store.commit('window/create', {
				id: this.id + ':cc',
				component: CalcChooser,
				props: {
					value: element.innerHTML,
					onChoose: calc => {
						if (!calc) return false;

						element.innerHTML = calc;
						this.$options.editor.fire('change');
					},
				},
			});
		},
	},
};
</script>

<style lang="scss">
@import '../../../scss/TextModuleInnerStyle.scss';

.fd-text-editor-view {
	.body {
		display: flex;
		flex-direction: column;

		.content {
			flex: 1;
			position: relative;
		}

		.footer {
			display: flex;
			height: 35px;

			& > * {
				flex: 1;
			}

			button {
				border-right: 1px solid #bcbcbc;
				border-radius: 0;
				background-color: #f8f8f8;
				border-top: 1px solid #c8c8c8;

				&:last-child {
					border-right: 1px solid #f8f8f8;
				}
			}
			button:hover {
				border-left: 1px solid #c8c8c8;
				border-bottom: 1px solid #c8c8c8;
				background-color: #e5e5e5;

				&:last-child {
					border-right: 1px solid #c8c8c8;
				}
			}
		}
	}
}

.fd-text-editor-view .cke {
	// Set height of ckeditor to 100%
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	font-size: 24px;
	border: none;

	box-sizing: border-box;

	.cke_inner {
		height: 100%;

		display: flex;
		flex-direction: column;

		.cke_contents {
			flex: 1;
			overflow-y: auto;

			.cke_wysiwyg_div {
				padding: 10px;
				height: initial;
			}
		}
	}

	// Set background of ckeditor content area to transparent
	.cke_inner,
	.cke_wysiwyg_div {
		background: transparent;
	}

	// Remove source code label
	.cke_button__source_label {
		display: none;
	}

	// Adjust size of font combo box
	.cke_combo__font .cke_combo_text {
		width: 100px;
	}

	// Apply styles from text module
	.cke_inner .cke_contents {
		@extend .fd-text-module-inner-style;
	}

	.cke_button__toggle-backgroundcolor_icon {
		font-family: 'icomoon';

		&:before {
			content: '\E92D';
		}
	}

	// Remove add var button
	.cke_button__customvariable {
		display: none;
	}

	// Remove add calc button
	.cke_button__customcalculation {
		display: none;
	}

	// Remove ugly border from drag handler
	.cke_widget_drag_handler {
		border: none !important;
	}

	// Style variable
	span.var,
	span.calc {
		background: #9e90d1;
		font-size: 12px;
		font-family: Consolas, 'Courier New', monospace;
		color: white;
		padding: 5px !important;
		border-radius: 3px;
		vertical-align: middle;
		cursor: pointer;
	}

	span.calc {
		background: #ff9a1f;
	}
}

// Adjust size of font combo panel
.cke_combopanel__fontsize {
	width: 250px !important;
}
</style>


<i18n lang="de_DE">
fd.texteditor.title: Text bearbeiten
fd.texteditor.insertvar: Variable einfügen
fd.texteditor.insertcalc: Berechnung einfügen
fd.texteditor.toggle.backgroundcolor: Hintergrundfarbe umschalten
</i18n>
