export const KEY = 'blur';

export const CONFIG = {
	type: 'Number',
	min: 0,
	max: 30
}

export function normalize(value) {
	return value || 0;
}

export function compute(value) {
	value = value || 0;
	return {
		webkitFilter: `blur(${value}px)`,
		mozFilter: `blur(${value}px)`,
		filter: `blur(${value}px)`
	}
}
