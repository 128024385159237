<template>
	<div class="fd-pane-view" v-show="isActive">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PaneView',
	props: ['label'],
	data: () => ({
		isActive: false
	})
}
</script>
