export const KEY = 'fontFamily';

export function getConfig($store, $_) {
	const groups = [];

	$store.state.shared.fonts.forEach(font => {
		let group = groups.find(group => group.label == font.type);
		if(!group) {
			group = {
				label: font.type,
				childs: [],
			}
			groups.push(group);
		}

		group.childs.push({
			label: font.name,
			value: font.value,
		});
	});

	groups.forEach(group => {
		group.label = $_('fd.font.type.' + group.label);
		group.childs.sort((a, b) => a.label.localeCompare(b.label));
	});

	return {
		type: 'Select',
		grouped: true,
		values: groups,
	}
}

export function normalize(value) {
	return value || 'sans-serif';
}

export function compute(value) {
	return {
		fontFamily: normalize(value)
	}
}
