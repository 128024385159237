<script>
import ChartModule from './ChartModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import {
	_deepCopy,
	_equals,
	_last,
} from '../util/helper.js';
import { compute as getColor } from '../styles/Color.js';
import { getConfig as getFontFamilyConfig } from '../styles/FontFamily.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'ChartModule',
	icon: 'chart',
	datasource: true
}

export const MetaDescriptionView = {
	name: 'ChartMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	const datasources = $store.state.shared.datasources;
	const datasource = datasources.length
		? {
				id: datasources[0].id,
				color: {
					r: 255,
					g: 204,
					b: 0,
					a: 70,
				},
				fillOpacity: 0.5,
				showInverse: false,
			}
		: null;

	return {
		animation: [],
		locked: false,
		class: 'ChartModule',
		x: 'center',
		y: 'center',
		width: 800,
		height: 500,
		style: {
			backgroundColor: {
				r: 255,
				g: 255,
				b: 255,
				a: 100,
			},
			padding: 30,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			datasources: datasource ? [datasource] : [],
			subdata: 'daily',
			type: 'area',
			starttime: 4,
			endtime: 24,
			stacked: true,
			ignorefirstzeros: true,
			font: '"PF Din Text", Arial, Helvetica, sans-serif',
			fontsize: 16,
			labelColor: {
				r: 0,
				g: 0,
				b: 0,
				a: 100,
			},
			gridColor: {
				r: 160,
				g: 160,
				b: 160,
				a: 30,
			},
			xAxisTickColor: {
				r: 160,
				g: 160,
				b: 160,
				a: 30,
			},
			unit: 'default'
		},
	};
}

export const ConfigView = {
	name: 'ChartModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		datasources() {
			return this.$store.state.shared.datasources;
		},
		timeFormatter() {
			return new Intl.DateTimeFormat((this.$store.state.timeformat === 2) ? 'en-US' : 'de-DE', { hour: 'numeric', minute: 'numeric' });
		},
		config() {
			const datasourceSettings = [];

			const datasources = this.datasources.map(d => ({
				label: d.name,
				value: d.id,
			}));

			this.module.props.datasources.forEach((datasource, index) => {
				const n = this.module.props.datasources.length > 1
					? ' ' + (index + 1)
					: '';

				datasourceSettings.push(...[{
					type: 'Select',
					label: this.$_('fd.chartmodule.settings.datasource') + n,
					value: datasource.id,
					values: datasources,
					events: {
						input: this.setDatasource(index)
					}
				}, {
					type: 'Color',
					label: this.$_('fd.chartmodule.settings.chartcolor'),
					value: datasource.color,
					events: {
						input: this.setChartColor(index)
					}
				}, {
					type: 'Number',
					label: this.$_('fd.chartmodule.settings.fillopacity'),
					value: datasource.fillOpacity * 100,
					events: {
						input: this.setFillOpacity(index)
					}
				}, {
					type: 'Boolean',
					label: this.$_('fd.chartmodule.settings.showinverse'),
					value: datasource.showInverse,
					events: {
						input: this.setShowInverse(index)
					}
				}]);
			});

		let timeArray = [];
		for (let i = 0; i <= 24; i++) {
			const date = new Date();
			date.setHours(i, 0, 0, 0);

			timeArray.push({
				label: this.timeFormatter.format(date) + ' ' + ((i === 0) ? this.$_('fd.chartmodule.settings.today') : '') + ((i === 24) ? this.$_('fd.chartmodule.settings.tomorrow') : ''),
				value: i
			})
		}

			return [ ...datasourceSettings, {
				type: 'Button',
				label: this.$_('fd.chartmodule.settings.add.datasource'),
				events: {
					click: this.addDatasource
				}
			}, {
				type: 'Button',
				label: this.$_('fd.chartmodule.settings.remove.datasource'),
				visible: this.module.props.datasources.length > 1,
				events: {
					click: this.removeDatasource
				}
			}, {
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.subdata'),
				value: this.module.props.subdata,
				values: [{
					label:  this.$_('fd.chartmodule.settings.subdata.raw'),
					value: 'raw'
				}, {
					label:  this.$_('fd.chartmodule.settings.subdata.daily'),
					value: 'daily'
				}, {
					label:  this.$_('fd.chartmodule.settings.subdata.monthly'),
					value: 'monthly'
				}, {
					label:  this.$_('fd.chartmodule.settings.subdata.yearly'),
					value: 'yearly'
				}],
				events: {
					input: this.setSubdata
				}
			}, {
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.type'),
				value: this.module.props.type,
				values: [{
					label:  this.$_('fd.chartmodule.settings.type.area'),
					value: 'area'
				}, {
					label:  this.$_('fd.chartmodule.settings.type.areaspline'),
					value: 'areaspline'
				}, {
					label:  this.$_('fd.chartmodule.settings.type.line'),
					value: 'line'
				}, {
					label:  this.$_('fd.chartmodule.settings.type.spline'),
					value: 'spline'
				}, {
					label:  this.$_('fd.chartmodule.settings.type.column'),
					value: 'column'
				}],
				events: {
					input: this.setChartType
				}
			}, {
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.starttime'),
				value:this.module.props.starttime,
				values: timeArray,
				visible: this.module.props.subdata === 'raw',
				events: {
					input: this.setStarttime
				}
			}, {
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.endtime'),
				value: this.module.props.endtime,
				values: timeArray,
				visible: this.module.props.subdata === 'raw',
				events: {
					input: this.setEndtime
				}
			}, {
				type: 'Boolean',
				label: this.$_('fd.chartmodule.settings.stacked'),
				value: this.module.props.stacked,
				events: {
					input: this.setStacked
				}
			}, {
				type: 'Boolean',
				label: this.$_('fd.chartmodule.settings.ignorefirstzeros'),
				value: this.module.props.ignorefirstzeros,
				events: {
					input: this.setFirstZerosIgnored
				}
			}, {
				...getFontFamilyConfig(this.$store, this.$_),
				label: this.$_('fd.chartmodule.settings.font'),
				value: this.module.props.font,
				events: {
					input: this.setChartFont
				}
			}, {
				type: 'Number',
				label: this.$_('fd.chartmodule.settings.fontsize'),
				value: this.module.props.fontsize,
				events: {
					input: this.setFontSize
				}
			}, {
				type: 'Color',
				label: this.$_('fd.chartmodule.settings.labelcolor'),
				value: this.module.props.labelColor,
				events: {
					input: this.setLabelColor
				}
			}, {
				type: 'Color',
				label: this.$_('fd.chartmodule.settings.gridcolor'),
				value: this.module.props.gridColor,
				events: {
					input: this.setGridColor
				}
			}, {
				type: 'Color',
				label: this.$_('fd.chartmodule.settings.xaxistickcolor'),
				value: this.module.props.xAxisTickColor,
				events: {
					input: this.setXAxisTickColor
				}
			}, {
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.unit'),
				value: this.module.props.unit,
				values: [{
					label: this.$_('fd.chartmodule.settings.unit.default'),
					value: 'default'
				}, {
					label: this.$_('fd.chartmodule.settings.unit.blank'),
					value: 'blank'
				}, {
					label: this.$_('fd.chartmodule.settings.unit.percentage'),
					value: 'percentage'
				}, {
					label: this.$_('fd.chartmodule.settings.unit.cubicmeter'),
					value: 'cubicmeter'
				}, {
					label: this.$_('fd.chartmodule.settings.unit.kilogram'),
					value: 'kilogram'
				}, {
					label: this.$_('fd.chartmodule.settings.unit.ton'),
					value: 'ton'
				}],
				events: {
					input: this.setChartUnit
				}
			}];
		}
	},
	methods: {
		setDatasource(index) {
			return (datasourceId) => {
				this.commitChange('props', {
					[`datasources[${index}].id`]: datasourceId
				});
			}
		},
		setChartColor(index) {
			return (color) => {
				this.commitChange('props', {
					[`datasources[${index}].color`]: color
				});
			}
		},
		setFillOpacity(index) {
			return (fillOpacity) => {
				this.commitChange('props', {
					[`datasources[${index}].fillOpacity`]: fillOpacity / 100
				});
			}
		},
		setShowInverse(index) {
			return (showInverse) => {
				this.commitChange('props', {
					[`datasources[${index}].showInverse`]: showInverse
				});
			}
		},
		addDatasource() {
			const datasource = {
				id: this.datasources[0].id,
				color: {
					r: 255,
					g: 204,
					b: 0,
					a: 70
				},
				fillOpacity: 0.5,
				showInverse: false
			};

			this.commitChange('props', {
				datasources: [
					...this.module.props.datasources,
					datasource
				]
			});
		},
		removeDatasource() {
			this.commitChange('props', {
				datasources: this.module.props.datasources.slice(0, -1)
			});
		},
		setSubdata(subdata) {
			this.commitChange('props', { subdata });
		},
		setChartType(type) {
			this.commitChange('props', { type });
		},
		setStarttime(starttime) {
			this.commitChange('props', { starttime });
		},
		setEndtime(endtime) {
			this.commitChange('props', { endtime });
		},
		setStacked(stacked) {
			this.commitChange('props', { stacked });
		},
		setFirstZerosIgnored(ignorefirstzeros) {
			this.commitChange('props', { ignorefirstzeros });
		},
		setChartFont(font) {
			this.commitChange('props', { font });
		},
		setFontSize(fontsize) {
			this.commitChange('props', { fontsize });
		},
		setLabelColor(color) {
			this.commitChange('props', { labelColor: color });
		},
		setGridColor(color) {
			this.commitChange('props', { gridColor: color });
		},
		setXAxisTickColor(color) {
			this.commitChange('props', { xAxisTickColor: color });
		},
		setChartUnit(unit) {
			this.commitChange('props', { unit });
		},
	}
}

export default {
	extends: ChartModule,

	computed: {
		datasourcesWatcher() {
			return _deepCopy(this.module.props.datasources);
		},
		updateDataWatcher() {
			return this.module.props.subdata
				+ this.module.props.ignorefirstzeros;
		},
		updateSubdataWatcher() {
			return this.module.props.subdata;
		},
		updateStarttimeWatcher() {
			return this.module.props.starttime;
		},
		updateEndtimeWatcher() {
			return this.module.props.endtime;
		},
		updateChartTypeWatcher() {
			return this.module.props.type;
		},
		updatePlotOptionsWatcher() {
			return this.module.props.stacked;
		},
		updateFontWatcher() {
			return this.module.props.font;
		},
		updateFontSizeWatcher() {
			return this.module.props.fontsize;
		},
		updateLabelColorWatcher() {
			return this.module.props.labelColor;
		},
		updateGridColorWatcher() {
			return this.module.props.gridColor;
		},
		updateXAxisTickColorWatcher() {
			return this.module.props.xAxisTickColor;
		},
		updateUnitWatcher() {
			return this.module.props.unit;
		},
		reflowWatcher() {
			return this.module.width + this.module.height;
		}
	},
	watch: {
		datasourcesWatcher(datasources, old) {
			if(datasources.length != old.length) {
				// Datasource was added
				if(datasources.length > old.length) {
					const i = datasources.length - 1;
					this.$options.chart.addSeries({
						color: getColor(datasources[i].color).color,
						fillOpacity: datasources[i].fillOpacity,
						marker: {
							enabled: false
						},
						data: this.toHighchartsDataFormat(this.datasources[i])
					});
				}
				// Datasource was removed
				else {
					_last(this.$options.chart.series).remove();
				}
				this.reflowChart();
				return;
			}

			datasources.forEach((datasource, i) => {
				// Id or showInverse was changed
				if(
					datasource.id !== old[i].id
					||
					datasource.showInverse !== old[i].showInverse
				) {
					this.$options.chart.series[i].update({
						data: this.toHighchartsDataFormat(this.datasources[i])
					});
				}

				// Color was changed
				if(!_equals(datasource.color, old[i].color)) {
					this.$options.chart.series[i].update({
						color: getColor(datasource.color).color
					});
				}

				// FillOpacity was changed
				if(datasource.fillOpacity !== old[i].fillOpacity) {
					this.$options.chart.series[i].update({
						fillOpacity: datasource.fillOpacity
					});
				}
			});
		},
		updateDataWatcher() {
			for(let i = 0; i < this.$options.chart.series.length; i++) {
				this.$options.chart.series[i].update({
					data: this.toHighchartsDataFormat(this.datasources[i])
				});
			}
			this.reflowChart();
		},
		updateSubdataWatcher() {
			this.$options.chart.update({
				xAxis: {
					min: this.getChartPeriod()[0],
					max: this.getChartPeriod()[1],
					labels: this.getXAxisLabels(this.$store.state.timeformat, this.module.props, this.timeFormatter)
				},
			});
		},
		updateStarttimeWatcher() {
			this.$options.chart.update({
				xAxis: {
					min: this.getChartPeriod()[0],
				},
			});
		},
		updateEndtimeWatcher() {
			this.$options.chart.update({
				xAxis: {
					max: this.getChartPeriod()[1],
				},
			});
		},
		updateChartTypeWatcher() {
			this.$options.chart.update({
				chart: {
					type: this.module.props.type
				}
			});
		},
		updatePlotOptionsWatcher(stacked) {
			this.$options.chart.update({
				plotOptions: {
					area: {
						stacking: stacked ? 'normal' : null
					},
					areaspline: {
						stacking: stacked ? 'normal' : null
					},
					line: {
						stacking: stacked ? 'normal' : null
					},
					spline: {
						stacking: stacked ? 'normal' : null
					},
					column: {
						stacking: stacked ? 'normal' : null
					}
				}
			});
		},
		updateFontWatcher(font) {
			const opt = {
				labels: {
					style: {
						fontFamily: font
					}
				}
			};
			this.$options.chart.xAxis[0].update(opt);
			this.$options.chart.yAxis[0].update(opt);
		},
		updateFontSizeWatcher(fontsize) {
			const opt = {
				labels: {
					style: {
						fontSize: fontsize + 'px'
					}
				}
			};
			this.$options.chart.xAxis[0].update(opt);
			this.$options.chart.yAxis[0].update(opt);
		},
		updateLabelColorWatcher(color) {
			const opt = {
				labels: {
					style: {
						color: getColor(color).color
					}
				}
			};
			this.$options.chart.xAxis[0].update(opt);
			this.$options.chart.yAxis[0].update(opt);
		},
		updateGridColorWatcher(color) {
			this.$options.chart.yAxis[0].update({
				gridLineColor: getColor(color).color
			});
		},
		updateXAxisTickColorWatcher(color) {
			this.$options.chart.xAxis[0].update({
				tickColor: getColor(color).color
			});
		},
		updateUnitWatcher(unit) {
			this.$options.chart.yAxis[0].update();
		},
		reflowWatcher() {
			this.$options.chart.reflow();
		}
	},
	methods: {
		reflowChart() {
			this.$options.chart.update({
				chart: {
					type: this.module.props.type
				}
			});
		}
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.chartmodule.title: Chart
fd.chartmodule.description: >
	Hier können Sie eine Ihrer Datenquellen als Chart einfügen.
fd.chartmodule.add: Chart hinzufügen

fd.chartmodule.settings.datasource.choose: '- bitte wählen -'
fd.chartmodule.settings.datasource: Datenquelle
fd.chartmodule.settings.add.datasource: Datenquelle hinzufügen
fd.chartmodule.settings.remove.datasource: Datenquelle entfernen

fd.chartmodule.settings.subdata: Subdaten
fd.chartmodule.settings.subdata.raw: 15 min Werte
fd.chartmodule.settings.subdata.daily: täglich
fd.chartmodule.settings.subdata.monthly: monatlich
fd.chartmodule.settings.subdata.yearly: jährlich

fd.chartmodule.settings.starttime: Startzeit
fd.chartmodule.settings.endtime: Endzeit
fd.chartmodule.settings.today: Heute
fd.chartmodule.settings.tomorrow: Morgen

fd.chartmodule.settings.type: Charttyp
fd.chartmodule.settings.type.area: Area
fd.chartmodule.settings.type.areaspline: Areaspline
fd.chartmodule.settings.type.line: Line
fd.chartmodule.settings.type.spline: Spline
fd.chartmodule.settings.type.column: Column

fd.chartmodule.settings.unit: Einheit
fd.chartmodule.settings.unit.blank: Ohne Einheit
fd.chartmodule.settings.unit.default: Standard
fd.chartmodule.settings.unit.cubicmeter: Kubikmeter (Wasser)
fd.chartmodule.settings.unit.percentage: Prozent (%)
fd.chartmodule.settings.unit.kilogram: Kilogramm (CO²)
fd.chartmodule.settings.unit.ton: Tonne (CO²)

fd.chartmodule.settings.font: Schriftart
fd.chartmodule.settings.fontsize: Schriftgröße
fd.chartmodule.settings.chartcolor: Chartfarbe
fd.chartmodule.settings.labelcolor: Labelfarbe
fd.chartmodule.settings.gridcolor: Linienfarbe (Grid)
fd.chartmodule.settings.showinverse: invertieren
fd.chartmodule.settings.xaxistickcolor: Tick-Farbe X-Achse
fd.chartmodule.settings.stacked: Stapeln
fd.chartmodule.settings.fillopacity: Fülltransparenz
fd.chartmodule.settings.ignorefirstzeros: Nullwerte ignorieren
</i18n>
