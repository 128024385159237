/**
 * EXISTS_FILTER
 * Filters all falsy values like "", undefined or e. g. NaN
 */
export const EXISTS_FILTER = Boolean;

/**
 * UNIQUE_FILTER
 * Filters for unique values
 */
export const UNIQUE_FILTER = (val, i, self) => self.indexOf(val) === i;
