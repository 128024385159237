<template>
  <div class="fd-yield-line-module">
    <div class="line" :style="lineStyle"></div>
    <div v-if="!resizing" class="chunks">
      <div v-for="(chunk, index) in chunks" :key="index" class="chunk" :style="chunk"></div>
    </div>
  </div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import { compute as getColor } from '../styles/Color.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'EnergyFlowModule',
	extends: BaseModule,
	normalize,

	data() {
		return {
			resizing: false,
			// speedPxPerSecond: 200,
			chunksDistancePx: 100,
		};
	},
	computed: {
		datasources() {
			const datasources = this.$store.state.shared.datasources;
			if (!datasources || !datasources.length) return;

			return this.module.props.datasources
				.map(datasource => {
					let data = datasources.find(d => d.id == datasource.id);
					if (typeof data != 'undefined') {
						return data;
					} else {
						this.module.props.datasources[0].id = datasources[0].id;
						return datasources[0];
					}
				})
				.filter(Boolean);
		},
		currentValue() {
			if (!this.datasources.length) return 0;

			let current_yield = 0;

			this.datasources.forEach(datasource => {
				current_yield += Object.values(datasource.data.raw).pop() || 0;
			});

			return current_yield;
		},
		dailyIncomeValue() {
			if (!this.datasources.length) return 0;

			let current_day = 0;

			this.datasources.forEach(datasource => {
				current_day += Object.values(datasource.data.daily).pop() || 0;
			});

			return current_day;
		},
		powered() {
			if (this.module.props.manualControl) {
				return this.module.props.manualAnimation;
			}

			switch (this.module.props.countStyle) {
				case 'current':
					return this.currentValue > 0;
				case 'dailyincome':
					return this.dailyIncomeValue > 0;
			}

			return true;
		},
		lineStyle() {
			return {
				backgroundColor: getColor(this.module.props.lineColor).color,
				height: this.module.props.lineWidth + 'px',
			};
		},
		animationDurationSeconds() {
			// TODO: maybe change from css animation to js controlled movement to make chunk movement speed actually constant
			return (
				Math.ceil((this.module.width / this.module.props.speed) * 1000) / 1000
			);
			// return (
			// 	Math.ceil(
			// 		(this.amountChunks / (this.module.width / this.speedPxPerSecond)) *
			// 			1000
			// 	) / 1000
			// );
		},
		chunkStyle() {
			return {
				backgroundColor: getColor(this.module.props.chunkColor).color,
				width: this.module.props.chunkSize + 'px',
				height: this.module.props.chunkSize + 'px',
				animationDuration: this.animationDurationSeconds + 's',
				animationPlayState: this.powered ? 'running' : 'paused',
			};
		},
		amountChunks() {
			return Math.ceil(this.module.width / this.chunksDistancePx);
		},
		chunks() {
			return new Array(this.amountChunks).fill(1).map((chunk, index, arr) => ({
				...this.chunkStyle,
				animationDelay:
					Math.round(
						-1 *
							this.animationDurationSeconds *
							((index + 1) / arr.length) *
							100
					) /
						100 +
					's',
			}));
		},
	},
};
</script>

<style lang="scss">
.fd-yield-line-module {
	width: 300px;
	height: 20px;
	position: relative;

	.line,
	.chunk {
		position: absolute;
	}

	.line {
		width: 100%;
		height: 2px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		background-color: black;
	}

	.chunk {
		width: 10px;
		height: 10px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		background-color: red;
		border-radius: 50%;

		animation: fd-yield-line-chunk-movement 4s linear infinite;

		&:nth-of-type(1) {
			animation-delay: -2s;
		}
		&:nth-of-type(2) {
			animation-delay: -1.333s;
		}
		&:nth-of-type(3) {
			animation-delay: -0.666s;
		}
		&:nth-of-type(4) {
			animation-delay: 0s;
		}
	}
}

@keyframes fd-yield-line-chunk-movement {
	0% {
		left: 0%;
		opacity: 0;
		transform: translate3d(0, -50%, 0);
	}
	5% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		left: 100%;
		opacity: 0;
		transform: translate3d(-100%, -50%, 0);
	}
}
</style>
