<script>
import hotkeys from 'hotkeys-js';

export default {
	name: 'ShortcutHandlerView',
	render: _ => null,
	computed: {
		activeModules() {
			return this.$store.getters.activeModules;
		},
	},
	created() {
		hotkeys.filter = event => {
			const target = event.target || event.srcElement;

			return (
				target.classList.contains('fd-context-focus-checker') ||
				(!['INPUT', 'SELECT', 'TEXTAREA'].includes(target.tagName) &&
					!target.isContentEditable)
			);
		};

		hotkeys('enter', () => this.$store.dispatch('editModule'));

		hotkeys('ctrl+c', () => this.$store.dispatch('copy'));
		hotkeys('ctrl+v', () => {
			this.$store.dispatch('paste');
			setTimeout(() => {
				this.$root.$emit('initModuleSnapping');
				this.$root.$emit('showAnimatedModules');
			}, 50);
		});
		hotkeys('ctrl+a', () => this.$store.dispatch('markAll') && false);
		hotkeys('ctrl+s', () => this.$root.$emit('save') && false);
		hotkeys('ctrl+z', () => this.$root.$emit('historyBack'));
		hotkeys('ctrl+g', () => {
			if (
				this.activeModules.length > 1 &&
				this.activeModules.every(m => m.class != 'GroupModule')
			) {
				this.$store.dispatch('group');
			} else if (
				this.activeModules.length == 1 &&
				this.activeModules[0].class == 'GroupModule'
			) {
				this.$store.dispatch('ungroup');
			}

			return false;
		});

		hotkeys('del', () => this.$store.dispatch('deleteModules'));

		hotkeys('up', () => {
			this.$store.commit('changeModules', {
				type: 'offset',
				modules: this.$store.state.activeModules,
				values: { y: -1 },
			});
		});
		hotkeys('down', () => {
			this.$store.commit('changeModules', {
				type: 'offset',
				modules: this.$store.state.activeModules,
				values: { y: 1 },
			});
		});
		hotkeys('left', () => {
			this.$store.commit('changeModules', {
				type: 'offset',
				modules: this.$store.state.activeModules,
				values: { x: -1 },
			});
		});
		hotkeys('right', () => {
			this.$store.commit('changeModules', {
				type: 'offset',
				modules: this.$store.state.activeModules,
				values: { x: 1 },
			});
		});
		hotkeys('esc', () => {
			if (this.$store.state.contextmenu.entrys.length) {
				this.$store.commit('contextmenu/close');
				return;
			}

			if (
				this.$store.state.subNav.components.length &&
				!this.$store.state.subNav.fixed
			) {
				this.$store.dispatch('subNav/components', null);
				return;
			}

			this.$store.commit('highlightedModules', null);
			this.$store.commit('activeModules', null);
		});

		hotkeys('shift+c', () => {
			this.$root.$emit('stop');
			this.$root.$emit('showAnimatedModules');
		});
		hotkeys('shift+left', () => this.$store.dispatch('alignLeft'));
		hotkeys('shift+h', () => {
			if (!hotkeys.isPressed('s')) {
				this.$store.dispatch('alignCenterHorizontal');
				return;
			}

			if (this.$store.state.activeModules.length > 2) {
				this.$store.dispatch('spreadHorizontal');
			}
		});
		hotkeys('shift+right', () => this.$store.dispatch('alignRight'));
		hotkeys('shift+up', () => this.$store.dispatch('alignTop'));
		hotkeys('shift+v', () => {
			if (!hotkeys.isPressed('s')) {
				this.$store.dispatch('alignCenterVertical');
				return;
			}

			if (this.$store.state.activeModules.length > 2) {
				this.$store.dispatch('spreadVertical');
			}
		});
		hotkeys('shift+down', () => this.$store.dispatch('alignBottom'));
	},
};
</script>
