<template>
	<div class="fd-background" :class="classes" :style="[style, styleTransformOrigin]"></div>
</template>

<script>
import { generateBlur } from '../util/SVGGen.js';

export default {
	name: 'BackgroundView',
	data() {
		return {
			animationIterations: 0,
			styleTransformOrigin: { transformOrigin: '0 0' },
		}
	},
	computed: {
		classes() {
			return (
				this.$store.state.data.background.animation
					? 'background-animation'
					: ''
			);
		},
		style() {
			const style = {};

			if(this.$store.state.data.background.blur) {
				style.filter = generateBlur(this.$store.state.data.background.blur);
			}

			if(this.$store.state.data.background.image) {
				style.backgroundImage = `url('${
					this.$store.state.data.background.image.path
				}')`;
			}

			return style;
		}
	},
	mounted() {
		// This is because ff 19 does not add filter with vue
		this.$el.style.filter = this.style.filter;

		this.setRandomTransformOrigin();
		this.$el.addEventListener('animationiteration', () => {
			this.animationIterations++;

			if (this.animationIterations % 2 === 0) {
				this.$el.style.animationPlayState = 'paused';

				setTimeout(() => {
					this.setRandomTransformOrigin();
					
					setTimeout(() => {
						this.$el.style.animationPlayState = 'running';
					}, 1);
				}, 1);
			}
		});
	},
	methods: {
		setRandomTransformOrigin() {
			this.styleTransformOrigin = { transformOrigin:  Math.round(Math.random() * 100) + '% ' + Math.round(Math.random() * 100) + '%' };
		}
	},
}
</script>

<style>
.fd-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 10;

	background-size: cover;
	background-position: center;
}

.fd-background.background-animation {
	animation: background-animation 30s ease-in-out infinite alternate;
}

@keyframes background-animation {
	0% {
		transform: scale(1.0) translateZ(0);
	}
	100% {
		transform: scale(1.2) translateZ(0);
	}
}
</style>
