<template>
	<div class="fd-modal-view" @mousedown.self="closePassive">
		<div class="window" :style="style">

			<div class="head">
				<i v-if="icon" :class="icon"></i>
				<div>{{title}}</div>
				<slot name="header"></slot>
				<button class="close"
					v-if="!dialog"
					@click="closePassive"
				>
					<i v-if="this.$store.state.type != 'image'" class="cross"></i>
				</button>
			</div>

			<div class="body" :style="bodyStyle">
				<slot></slot>
			</div>

			<div class="footer">
				<slot name="footer"></slot>
				<button class="fd-button"
					v-for="button of buttons"
					:key="button.label"
					:class="button.css || []"
					:disabled="button.disabled"
					@click="handleButtonClick(button.click || (_ => _))"
				>
					<i v-if="button.icon" :class="button.icon"></i>
					{{button.label}}
				</button>
			</div>

		</div>
	</div>
</template>

<script>
import '../../../scss/fonts/opensans.scss';

export default {
	name: 'ModalView',
	props: {
		dialog: Boolean,
		size: {
			type: Number,
			default: 600
		},
		cancelFn: {
			type: Function,
			default: () => {}
		},
		icon: String,
		title: String,
		buttons: {
			type: Array,
			default: () => [{
				label: 'Ok',
				css: 'primary',
				click: () => {}
			}]
		}
	},
	computed: {
		style() {
			if(this.$store.state.type == 'image') {
				return {
					width: '100%',
					height: '100%'
				}
			} else {
				return {
					width: `${this.size}px`
				}
			}
		},
		bodyStyle() {
			if(this.$store.state.type == 'image') {
				return {
					'max-height': '80vh'
				}
			}
		}
	},
	methods: {
		close() {
			if(this.$store.deleteFile) {
				this.$store.deleteFile = false;
				this.$store.commit('modal/close');
				this.$root.$emit('openFileChooser');
			} else {
				if(this.$store.state.type != 'image') {
					this.$store.commit('modal/close');
				}
			}
		},
		closePassive() {
			if(this.dialog) return;
			this.cancelFn() !== false
			&& this.close();
		},
		handleButtonClick(callback) {
				callback() !== false
				&& this.cancelFn() !== false
				&& this.close();
		}
	}
}
</script>

<style lang="scss">
$yellow: #fec107;

.fd-modal-view {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: rgba(0, 0, 0, 0.5);

	font-family: OpenSans, sans-serif;

	display: flex;
	justify-content: center;
	align-items: center;

	& > .window {
		background: white;
		border-radius: 6px;
		overflow: hidden;
		box-shadow: 0 0 10px rgba(0,0,0,0.5);

		& > .head {
			background: $yellow;
			color: #fff;
    		font-size: 0.9em;
			text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

			display: flex;
			align-items: center;

			& > :not(.close):not(i) {
				flex: 1;
				padding: 6px 0 6px 16px;
			}

			& > i {
				padding: 6px 0 6px 16px;
			}

			& > .close {
				margin: 0;
				height: 31px;
				flex: 0 0 30px;
				background: none;
				border: none;
				outline: none;
				color: white;
    			font-size: 0.8em;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

				cursor: pointer;
			}
		}

		& > .body {
			padding: 15px 15px 0;
			font-size: 14px;
			display: flex;
			justify-content: center;
			text-align: justify;

			max-height: 60vh;
    		overflow-y: auto;
		}

		& > .footer {
			padding: 15px;
			text-align: center;
			display: flex;
			justify-content: flex-end;

			button {
				flex: 0 1 200px;
				margin-left: 10px;

				color: white;
				font-size: 0.8em;
				text-transform: uppercase;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

				&.primary {
					background: $yellow;
				}

				&[disabled="disabled"] {
					cursor: not-allowed;
				}

				i {
					margin-right: 5px;
				}
			}
		}
	}
}
</style>
