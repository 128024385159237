<template>
	<div class="fd-editor-extra">

		<shortcut-handler-view />

		<component
			v-for="window of $store.state.window.windows"
			:key="window.id"
			:is="window.component"
			:id="window.id"
			v-bind="window.props"
		></component>

		<color-picker-view
			v-if="$store.state.subNav.colorPicker.visible"
		></color-picker-view>

		<context-menu-view
			v-if="$store.state.contextmenu.entrys.length"
		></context-menu-view>

		<component
			v-if="$store.state.modal.component"
			:is="$store.state.modal.component"
			v-bind="$store.state.modal.props"
		></component>

	</div>
</template>

<script>
import ColorPickerView from './ColorPicker.vue';
import ContextMenuView from './ContextMenu.vue';
import ShortcutHandlerView from './ShortcutHandler.vue';

export default {
	name: 'EditorExtraView',
	components: {
		ColorPickerView,
		ContextMenuView,
		ShortcutHandlerView,
	}
}
</script>

<style lang="scss">
.fd-editor-extra {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}
}
</style>
