<template>
	<div class="fd-fly-animation" :style="style">
		<slot></slot>
	</div>
</template>

<script>
import { Tween, easing } from 'mo-js';

export default {
	name: 'Fly',
	icon: 'airplane2',
	props: ['animation', 'rect'],
	data() {
		return {
			translateX: 0,
			translateY: 0,
			scaleX: 1,
			scaleY: 1,
      classList: []
		}
	},
	computed: {
		style() {
			return {
				transform: `translate3d(${this.translateX}px, ${this.translateY}px, 0px) scale3d(${this.scaleX}, ${this.scaleY}, 1)`
			}
		},
		refDist() {
			let r = this.rect.r;
			switch(this.animation.reference) {
				case 't': r +=   0; break;
				case 'b': r += 180; break;
				case 'l': r += 270; break;
				case 'r': r +=  90; break;
			}

			const hyp = (a, alpha) => a / Math.cos(alpha * Math.PI / 180);
			switch(Math.floor(r % 360 / 45)) {
				case 7: return hyp(this.getTopDist(),    -r);
				case 0: return hyp(this.getTopDist(),     r);
				case 1: return hyp(this.getRightDist(),  -r +  90);
				case 2: return hyp(this.getRightDist(),   r -  90);
				case 3: return hyp(this.getBottomDist(), -r + 180);
				case 4: return hyp(this.getBottomDist(),  r - 180);
				case 5: return hyp(this.getLeftDist(),   -r + 270);
				case 6: return hyp(this.getLeftDist(),    r - 270);
			}
		}
	},
	mounted() {

		if(typeof this.animation.reference == 'undefined') {
			this.animation.reference = 'r';
		}
		if(typeof this.animation.direction == 'undefined') {
			this.animation.direction = 'in';
		}
		if(typeof this.animation.mode == 'undefined') {
			this.animation.mode = 'easing';
		}
		if(typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if(typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
		}

		initFly(this);

		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('init' + this.animation.index, index => { 
			if(index == this.animation.index) {
				initFly(this);
			}
		});
		this.$root.$on('update', _ => this.update());
		this.$root.$on('showAnimatedModules', _ => {
			this.translateX = 0;
			this.translateY = 0;
		 });
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				this.$options.animation.run();
			} else {
				this.$options.animation.setProp('delay', 0);
				this.$options.animation.run();
				this.$options.animation.setProp('delay', this.animation.delay * 1000);
			}
		},

		stop() {
			this.$options.animation.stop();
		},
		
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
			this.$root.$on('init' + this.animation.index, index => { 
				if(index == this.animation.index) {
					initFly(this);
				}
			});
		},

		// Get distance to the top side
		getTopDist() {
			return this.rect.y + this.rect.height;
		},

		// Get distance to the left side
		getLeftDist() {
			return this.rect.x + this.rect.width;
		},

		// Get distance to the right side
		getRightDist() {
			return this.$store.state.screensize.width - this.rect.x;
		},

		// Get distance to the bottom side
		getBottomDist() {
			return this.$store.state.screensize.height - this.rect.y;
		},

		init_in_t() {
			const translateY = this.translateY = -this.refDist;

			return (progress) => {
				const bounce = this.animation.mode !== 'linear' ? easing.bounce.out(progress) : progress;
				this.translateY = translateY * ( 1 - bounce);
			}
		},
		init_in_b() {
			const translateY = this.translateY = this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? --k * k * k * k * ((s + 1) * k + s) + 1 : k;
				this.translateY = translateY * ( 1 - fly );
			}
		},
		init_in_l() {
			const translateX = this.translateX = -this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? --k * k * k * k * ((s + 1) * k + s) + 1 : k;
				this.translateX = translateX * ( 1 - fly );
			}
		},
		init_in_r() {
			const translateX = this.translateX = this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? --k * k * k * k * ((s + 1) * k + s) + 1 : k;
				this.translateX = translateX * ( 1 - fly );
			}
		},

		init_out_t() {
			const translateCurve = easing.path('M0,100 L25,100 L60,-10 L100,0'),
						// squashCurve = easing.path('M0,100.004963 C0,100.004963 25,147.596355 25,100.004961 C25,70.7741867 32.2461944,85.3230873 58.484375,94.8579105 C68.9280825,98.6531013 83.2611815,99.9999999 100,100');
						squashCurve = easing.path('M0,100 C0,100 25,148 25,100 C25,71 32,85 58,95 C69,99 83,100 100,100');

			const translateY = -this.refDist;

			return (progress) => {
				const translate = this.animation.mode !== 'linear' ? translateCurve(progress) : progress,
					squash = this.animation.mode !== 'linear' ? squashCurve(progress) : progress,
					scaleX = this.animation.mode !== 'linear' ? 1 - 2 * squash : 1,
					scaleY = this.animation.mode !== 'linear' ? 1 + 2 * squash : 1;

				this.translateY = translateY * translate;
				this.scaleX = scaleX;
				this.scaleY = scaleY;
			}
		},
		init_out_b() {
			const translateY = this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? k * k * k * k * k * ((s + 1) * k - s) : k;
				this.translateY = translateY * fly;
			};
		},
		init_out_l() {
			const translateX = -this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? k * k * k * k * k * ((s + 1) * k - s) : k;
				this.translateX = translateX * fly;
			};
		},
		init_out_r() {
			const translateX = this.refDist;

			return (k) => {
				const s = 1.2;
				const fly = this.animation.mode !== 'linear' ? k * k * k * k * k * ((s + 1) * k - s) : k;
				this.translateX = translateX * fly;
			};
		},
	}
}

function initFly(self) {

	self.translateX = 0;
	self.translateY = 0;
	
	let initFn = `init_${self.animation.direction}_${self.animation.reference}`;

	if(!self[initFn]) {
		initFn = `init_${self.animation.direction}_t`;
	}
	
	self.$options.animation = new Tween({
		duration: self.animation.duration * 1000,
		delay: self.animation.delay * 1000,
		onUpdate: self[initFn]() || (() => {})
	});
}
</script>
