<template>
	<div class="fd-conetx-menu-view" :style="style">

		<template
			v-for="entry in entrys"
		>
			<div class="entry"
				v-if="entry.type == 'entry'"
				:key="entry.label"
				@mousedown="entry.click"
				v-show="entry.visible !== false"
			>
				<i class="icon" :class="entry.icon"></i>
				<div class="label">{{entry.label}}</div>
			</div>

			<div class="separator"
				v-else-if="entry.type == 'separator'"
				:key="entry.key"
				v-show="entry.visible !== false"
			></div>

			<div class="submenu"
				v-else
				:key="entry.label"
				v-show="entry.visible !== false"
			>
				<i class="icon" :class="entry.icon"></i>
				<div class="label">{{entry.label}}</div>
				<i class="icon arrow-right3"></i>
				<div class="subentrys">
					<template
						v-for="entry in entry.entrys"
					>
						<div class="entry"
							v-if="entry.type == 'entry'"
							:key="entry.label"
							@mousedown="entry.click"
							v-show="entry.visible !== false"
						>
							<i class="icon" :class="entry.icon"></i>
							<div class="label">{{entry.label}}</div>
						</div>

						<div class="separator"
							v-else-if="entry.type == 'separator'"
							:key="entry.key"
							v-show="entry.visible !== false"
						></div>
					</template>
				</div>
			</div>
		</template>


		<input type="text" class="fd-context-focus-checker" ref="focusChecker"
			@blur="handleFocusLost"
		>

	</div>
</template>

<script>
import '../../scss/fonts/opensans.scss';

export default {
	name: 'ContextMenuView',
	data: () => ({
		ignoreFocusLost: false
	}),
	computed: {
		position() {
			return this.$store.state.contextmenu.createPoint;
		},
		entrys() {
			return this.$store.state.contextmenu.entrys;
		},
		style() {
			return {
				transform: `translate(${this.position.x + 1}px, ${this.position.y < 800 ? this.position.y + 1 : this.$store.state.contextmenu.entrys.length > 3 ? this.position.y - 164 : this.position.y - 90}px)`
			}
		}
	},
	mounted() {
		this.$refs.focusChecker.focus();
	},
	methods: {
		handleFocusLost() {
			this.$store.commit('contextmenu/close');
		}
	}
}
</script>

<style lang="scss">
.fd-conetx-menu-view,
.fd-conetx-menu-view .subentrys {
	position: absolute;
	top: 0;
	left: 0;

	font-family: OpenSans, sans-serif;

	background: rgba(0,0,0,.8);
	box-shadow: 0 0 20px rgba(0,0,0,.8);
	font-size: 12px;

	display: flex;
	flex-direction: column;
}

.fd-conetx-menu-view .submenu {
	.subentrys {
		top: 0;
		left: 100%;

		display: none;
	}

	&:hover .subentrys {
		display: flex;
	}
}

.fd-conetx-menu-view .entry,
.fd-conetx-menu-view .submenu {
	color: #fff;
	padding: 7px 26px 7px 10px;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background: #444;
	}

	.icon {
		width: 18px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-right: 7px;
	}

	.label {
		display: inline-block;
	}
}

.fd-conetx-menu-view .submenu {
	position: relative;

	i.arrow-right3 {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		font-size: 9px;
		margin-right: 4px;
	}
}

.fd-conetx-menu-view .separator {
	height: 1px;
	margin: 3px 6px;
   background: #777;
}

.fd-context-focus-checker {
	position: absolute;
	opacity: 0;
	top: -9999px;
	pointer-events: none;
}
</style>
