<template>
	<select class="fd-select-input" v-model="model">

		<template v-if="config.grouped">
			<optgroup
				v-for="group of config.values"
				:key="group.label"
				:label="group.label"
			>
				<option
					v-for="option of group.childs"
					:key="option.label"
					:value="option.value"
				>{{option.label}}</option>
			</optgroup>
		</template>

		<template v-else>
			<option
				v-for="option of config.values"
				:key="option.label"
				:value="option.value"
			>{{option.label}}</option>
		</template>

	</select>
</template>

<script>
export default {
	name: 'SelectInput',
	props: ['value', 'config'],
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>

<style>
.fd-select-input {
	width: 100%;

	color: #222;

	font-size: 11px;
	padding: 4px 7px;
	outline: none;
	border: 0;
	border-radius: 2px;
}
</style>
