import { EXISTS_FILTER, UNIQUE_FILTER } from './util/Filter.js';

export async function loadFonts(fonts) {
	initFontStyleSheets(await fonts);
}

function initFontStyleSheets(fonts) {
	fonts
		.map(font => font.source)
		.filter(EXISTS_FILTER)
		.filter(UNIQUE_FILTER)
		.forEach(sheetPath => loadSheet(sheetPath));
}

function loadSheet(sheetPath) {
	const linkEl = document.createElement('link');
	linkEl.rel = 'stylesheet';
	linkEl.type = 'text/css';
	linkEl.href = sheetPath;
	if (!document.head.innerHTML.includes(sheetPath)) {
		document.head.appendChild(linkEl);
	}
}
