const state = {
	width: 1920,
	height: 1080,
}

function init(config) {
	return {
		...state,
		...config.screensize
	}
}

export async function create(config) {
	return {
		namespaced: true,

		state: init(config),

		// mutations: {}
	}
}
