<template>
	<modal-view class="fd-unlocked-template-modal-view"
		:size="550"
		icon="warning"
		:title="$_('fd.unlocked.template.modal.title')"
		:dialog="false"
		:buttons="[{
			label: $_('fd.unlocked.template.modal.actions.ok'),
			css: 'primary'
		}]"
	>
		<p v-html="$_('fd.unlocked.template.modal.content', { shop })"></p>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';

export default {
	name: 'UnlockedTemplateModalView',
	props: ['template'],
	components: { ModalView },
	data: () => ({
		shop: `
			<a href="https://shop.solar-fox.de/" target="blank">
				https://shop.solar-fox.de/
			</a>
		`
	})
}
</script>

<style lang="scss">
.fd-unlocked-template-modal-view {
	.body {
		flex-direction: column;
	}
}
</style>

<i18n lang="de_DE">
fd.unlocked.template.modal.title: Template noch nicht freigeschaltet!
fd.unlocked.template.modal.content: >
	Sie haben ein Template ausgewählt, welches noch nicht freigeschaltet ist.
	Sie können dieses Template mit einem Zusatzpaket freischalten. Dieses finden
	Sie in unserem Online-Shop unter #{shop}
fd.unlocked.template.modal.actions.ok: Ok
</i18n>
