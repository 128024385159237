<template>
	<div class="fd-list-input">
		<text-view class="search" v-if="config.search" v-model.lazy="search" />
		<div class="scrollview" :class="{noSpace: !config.label}">
			<component
				class="list-item"
				v-for="item of config.values"
				:key="item.key"
				:is="config.itemView"
				:item="item"
				:selected="selected.includes(item.value)"
				v-show="!config.search || compare(item.search, search) || search == ''"
				@click="$emit('input', item.value)"
				v-on="item.events || {}"
			/>
		</div>
	</div>
</template>

<script>
import TextView from './Text.vue';
export default {
	name: 'ListInput',
	props: ['value', 'config'],
	components: { TextView },
	data: _ => ({ search: '', mounted: false }),
	computed: {
		selected() {
			if(this.value instanceof Array) {
				return this.value;
			}
			return [this.value]
		},
		ready() {
			return this.config.values && this.config.values.length && this.mounted;
		},
		configSearchWatcher() {
			return this.config.search;
		}
	},
	watch: {
		async ready() {
			await this.$nextTick();
			const selected = this.$el.querySelector('[selected]');
			if(selected) selected.scrollIntoView();
		},
		search(search) {
			this.$emit('search', search);
		},
		configSearchWatcher(search) {
			this.search = search;
		}
	},
	mounted() {
		if(this.config.search && this.config.search !== true) {
			this.search = this.config.search;
		}
		this.mounted = true;
	},
	methods: {
		compare(a, b) {
			if(this.config.compare && this.config.compare instanceof Function) {
				return this.config.compare(a, b);
			}
			return a.toLowerCase().includes(b.trim().toLowerCase());
		}
	}
}
</script>

<style lang="scss">
.fd-config-item.List {
	flex-direction: column;

	& > * {
		width: 100% !important;
	}
}
.fd-list-input {
	width: 100%;

	.search {
		margin: 4px 0 2px;
	}

	.scrollview {
		margin-top: 4px;
		max-height: 310px;
		overflow-y: auto;

		&.noSpace {
			margin-top: -6px;
		}
	}
}
</style>
