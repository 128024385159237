<template>
	<div class="fd-file-uploading-view">
		<div class="background"></div>
		<div class="thumb" :class="file.visible" :style="style"></div>
		<div class="marker">
			<i class="upload10"></i>
		</div>
		<div class="upload-bar">
			<div class="progress" :style="progressStyle"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileUploadingView',
	props: ['file'],
	computed: {
		style() {
			return {
				backgroundImage: `url('${this.file.data}')`
			}
		},
		progressStyle() {
			return {
				width: `${this.file.progress}%`
			}
		}
	}
}
</script>

<style lang="scss">
.fd-file-uploading-view {
	display: inline-block;
	min-width: 50px;

	position: relative;

	border: 3px solid transparent;

	&:after {
		content: '';
		display: block;
		padding-bottom: 80%;
	}

	.background {
		z-index: 10;

		background: url('../../../img/colorpicker_colorbg.png');
	}

	.background, .thumb {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.thumb {
		z-index: 20;

		background-size: cover;
		background-position: center;

		filter: #{"grayscale()"};

		box-shadow: 0 0 5px rgba(0,0,0,.3);

		&.normal {
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	.marker {
		position: absolute;
		right: 5px;
		bottom: 2px;
		z-index: 40;

		color: #4affff;
		font-size: 23px;
		text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	}

	.upload-bar {
		position: absolute;
		top: 50%;
		left: 5px;
		right: 5px;
		height: 8px;
		margin-top: -6px;
		z-index: 50;

		border: 2px solid #4affff;

		.progress {
			position: absolute;
			top: 0;
			left: 0;
			width: 0%;
			height: 100%;
			background: #4affff;
		}
	}
}
</style>
