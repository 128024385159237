import { _evalDefault } from '../util/helper.js';

export const KEY = 'color';

export const CONFIG = {
	type: 'Color'
}

export function normalize(value) {
	return {
		r: 0,
		g: 0,
		b: 0,
		a: 100,
		..._evalDefault(value, {})
	};
}

export function compute(value) {
	value = normalize(value);

	return {
		color: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a / 100})`
	}
}
