<script>
import CounterModule from './CounterModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'CounterModule',
	icon: 'seven-segment-7',
}

export const MetaDescriptionView = {
	name: 'CounterMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'CounterModule',
		x: 'center',
		y: 'center',
		width: 700,
		height: 140,
		style: {
			fontFamily: 'Consolas, "Courier New", monospace',
			fontSize: 90,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			variable: '#CurrentPowerRaw#',
			divider: 1000,
			interval: 2,
		},
	}
}

export const ConfigView = {
	name: 'CounterModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		variables() {
			return this.$store.state.shared.variables;
		},
		config() {
			return [
				{
					type: 'Select',
					label: this.$_('fd.countermodule.settings.variable'),
					value: this.module.props.variable,
					values: Object.keys(this.variables)
						.filter(key => key.includes('Raw'))
						.map(key => ({
							label: key.replace('Raw', ''),
							value: key
						})),
					events: {
						input: this.setVariable
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.countermodule.settings.divider'),
					value: this.module.props.divider,
					events: {
						input: this.setDivider
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.countermodule.settings.interval'),
					value: this.module.props.interval,
					events: {
						input: this.setInterval
					}
				}
			];
		}
	},
	methods: {
		setVariable(variable) {
			this.commitChange('props', { variable });
		},
		setDivider(divider) {
			this.commitChange('props', { divider });
		},
		setInterval(interval) {
			this.commitChange('props', { interval });
		},
	}
}

export default {
	extends: CounterModule,

	mounted() {
		this.start();
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.countermodule.title: Zähler
fd.countermodule.description: >
	Das Zähler-Module zeigt einen Stromzähler.
fd.countermodule.add: Zähler hinzufügen

fd.countermodule.settings.variable: Variable
fd.countermodule.settings.divider: Teiler
fd.countermodule.settings.interval: Interval
</i18n>
