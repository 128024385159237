<script>
export default {
	name: 'TextInput',
	props: ['value', 'config'],
	render(h) {
		return h('input', {
			class: 'fd-text-input',
			attrs: {
				type: 'text'
			},
			domProps: {
				value: this.value
			},
			on: {
				input: this.emit
			}
		});
	},
	methods: {
		emit(e) {
			this.$emit('input', e.target.value)
		}
	}
}
</script>

<style lang="scss">
.fd-text-input, .fd-textarea-input {
	width: 100%;

	background: hsla(0, 0%, 100%, .2);
	color: #fff;
	font-size: 11px;
	padding: 4px 7px;
	outline: none;
	border: 0;
	border-radius: 2px;
}
</style>
