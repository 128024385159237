<template>
	<div class="fd-infobar-view">

		<div class="fd-module-info" :class="{hidden: activeModules.length < 1}">
			<div class="fd-input-group">
				<label for="fd-info-x">x:</label>
				<number-input
					id="fd-info-x"
					:value="x"
					@input="update('x', $event)"
					:readonly="!movable"
				></number-input>
			</div>
			<div class="fd-input-group">
				<label for="fd-info-y">y:</label>
				<number-input
					id="fd-info-y"
					:value="y"
					@input="update('y', $event)"
					:readonly="!movable"
				></number-input>
			</div>
			<div class="fd-input-group">
				<label for="fd-info-w"><i class="arrow-resize3"></i></label>
				<number-input
					id="fd-info-w"
					:value="width"
					@input="update('width', $event)"
					:readonly="resizeMode !== 'all' && resizeMode !== 'width'"
				></number-input>
			</div>
			<div class="fd-input-group">
				<label for="fd-info-h"><i class="arrow-resize4"></i></label>
				<number-input
					id="fd-info-h"
					:value="height"
					@input="update('height', $event)"
					:readonly="resizeMode !== 'all' && resizeMode !== 'width'"
				></number-input>
			</div>
			<div class="fd-input-group" :class="{hidden: activeModules.length > 1}">
				<label for="fd-info-r">°</label>
				<number-input
					id="fd-info-r"
					:value="r"
					:config="{step: 0.1}"
					@input="updateR"
					:readonly="!rotatable"
				></number-input>
			</div>
			<div class="fd-input-group" :class="{hidden: activeModules.length > 1}">
				<label for="fd-info-z"><i class="stack3"></i></label>
				<number-input
					id="fd-info-z"
					:value="z || 1"
					readonly
				></number-input>
			</div>
		</div>

		<div class="fd-mouse-info">
			<i class="cursor"></i>
			<div class="fd-value-group">
				<span class="fd-label">x:</span>
				<span class="fd-value">{{mx}}</span>
			</div>
			<div class="fd-value-group">
				<span class="fd-label">y:</span>
				<span class="fd-value">{{my}}</span>
			</div>
		</div>

		<div class="fd-mouse-info" v-if="$root.debug">
			<div class="fd-value-group">
				<span class="fd-label">fps:</span>
				<span class="fd-value">{{fps}}</span>
			</div>
		</div>
	</div>
</template>

<script>
import '../../scss/fonts/icomoon.scss';
import { EXISTS_FILTER } from '../util/Filter.js';
import { _getMax, _getMin, _getPosition } from '../util/helper.js';
import NumberInput from '../inputs/Number.vue';
import * as MODULES from '../modules/_MODULES_EXTRA.js';

export default {
	name: 'InfobarView',
	components: { NumberInput },
	computed: {
		activeModules() {
			return this.$store.getters.activeModules;
		},
		resizeMode() {
			if(this.activeModules.length !== 1) return 'none';

			const module = this.activeModules[0];
			let resize = MODULES[module.class].meta.resize;

			if(resize instanceof Function) {
				resize = resize(module.props);
			}

			if(![
				'all',
				'none',
				'width',
				'height',
			].includes(resize) && typeof resize !== 'number') {
				resize = null;
			}

			return resize || 'all';
		},
		rotatable() {
			if(this.activeModules.length !== 1) return 'false';

			const module = this.activeModules[0];
			return MODULES[module.class].meta.rotate !== false;
		},
		movable() {
			return this.activeModules.every(module =>
				MODULES[module.class].meta.move !== false
			);
		},
		x() {
			if(this.activeModules.length < 1) return 0;

			const x = _getMin(this.activeModules.map(m =>
				_getPosition(m, this.$store.state.screensize).x
			));
			return Math.round((x) * 100) / 100;
		},
		y() {
			if(this.activeModules.length < 1) return 0;

			const y = _getMin(this.activeModules.map(m =>
				_getPosition(m, this.$store.state.screensize).y
			));
			return Math.round((y) * 100) / 100;
		},
		width() {
			if(this.activeModules.length < 1) return 0;

			const w = _getMax(this.activeModules.map(m =>
				_getPosition(m, this.$store.state.screensize).x + m.width
			));
			return Math.round((w - this.x) * 100) / 100;
		},
		height() {
			if(this.activeModules.length < 1) return 0;

			const h = _getMax(this.activeModules.map(m =>
				_getPosition(m, this.$store.state.screensize).y + m.height
			));
			return Math.round((h - this.y) * 100) / 100;
		},
		r() {
			if(
				this.activeModules.length !== 1
				||
				!this.activeModules[0].r
			) return 0;

			let r = this.activeModules[0].r * 0.3;
			if(r > 270) r -= 360;
			if(r < -90) r += 360;
			return Math.round(r * 10) / 10;
		},
		z() {
			return this.activeModules.length == 1 ? this.activeModules[0].z : '';
		},
		mx() {
			return Math.round(this.$root.mouseX);
		},
		my() {
			return Math.round(this.$root.mouseY);
		},
		fps() {
			return this.$root.fps;
		}
	},
	methods: {
		update(val, e) {
			this.$store.commit('changeModules', {
				type: 'offset',
				modules: this.activeModules.map(m => m.mid),
				values: {
					[val]: e - this[val]
				}
			});
		},
		updateR(e) {
			this.$store.commit('changeModules', {
				type: 'set',
				modules: this.activeModules.map(m => m.mid),
				values: {
					r: (e % 360) / 0.3
				}
			});
		}
	}
}
</script>

<style lang="scss">
.fd-infobar-view {
	*:not(i) {
		font-family: monospace;
		font-size: 12px;
	}

	display: flex;

	color: #fff;
	background: #000;
	padding: 1px 20px;

	.fd-module-info,
	.fd-mouse-info {
		display: flex;
		align-items: flex-end;
	}

	.fd-mouse-info {
		margin-left: 80px;
	}

	.fd-input-group {
		& + .fd-input-group {
			margin-left: 10px;
		}

		input {
			color: #fff;
			background: none;
			border: none;
			border-radius: 0;
			padding: 0;
			margin: 0;
			outline: none;

			width: 80px;

			border-bottom: 1px dashed transparent;
			&:hover:not([readonly]) {
				border-bottom: 1px dashed white;
			}
		}
	}

	.fd-value-group {
		& + .fd-value-group {
			margin-left: 10px;
		}

		.fd-value {
			display: inline-block;
			width: 50px;
		}
	}

	.hidden {
		opacity: 0;
		pointer-events: none;
	}
}
</style>
