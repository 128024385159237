<template>
	<div class="fd-basic-config-view fd-layer-view">
		<h2 class="head">
			<span class="icon">
				<i class="clapboard"></i>
			</span>
			<span class="separator"></span>
			<span class="title">{{$_('fd.animations.headline')}}</span>
		</h2>

		<animation-list-view v-if="!this.display" :config="animations" :key="'settings_animationList_1'" />

		<div class="body">
			<config-item-view
				v-for="(item, i) of config"
				:key="'settings_' + item.type + '_' + i"
				:item="item"
			/>
		</div>
	</div>
</template>

<script>
import ANIMATIONS from '../animations';
import BasicConfigView from './BasicConfig.vue';
import ConfigItemView from './ConfigItem.vue';
import AnimationModalView from './Modal/AnimationModal.vue';
import AnimationListView from './AnimationList.vue';

export default {
	name: 'AnimationConfigView',
	extends: BasicConfigView,
	components: { ConfigItemView, AnimationListView },
	data: () => ({
		display: true,
	}),
	filters: {},
	computed: {
		animations() {
			let animations = [];
			this.$store.state.data.modules.forEach(module => {
				if (typeof module.animation != 'undefined') {
					if (module.animation.length) {
						this.display = false;
						module.animation.forEach(animation => {
							animations.push({
								index: animation.index,
								name: animation.name,
								icon: ANIMATIONS[animation.name].icon,
								delay: animation.delay,
								duration: animation.duration,
							});
						});
					}
				}
			});
			return animations;
		},
		config() {
			return [
				{
					type: 'Info',
					text: this.$_('fd.animations.noanimations'),
					visible: this.display,
				},
				{
					type: 'Button',
					label: this.$_('fd.animations.editor'),
					events: {
						click: this.animationEditor,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.animations.settings.play.all'),
					events: {
						click: this.startAll,
					},
				},
			];
		},
	},
	mounted() {
		this.$store.state.data.modules.forEach(module => {
			if (typeof module.animation != 'undefined') {
				if (module.animation.length) this.display = false;
			}
		});
	},
	methods: {
		animationEditor() {
			this.$store.dispatch('subNav/components', null);
			this.$store.commit('modal/create', {
				component: AnimationModalView,
			});
		},
		startAll() {
			this.$root.$emit('startAllAnimations');
		},
	},
};
</script>

<style lang="scss">
.fd-sub-nav-view.fd-sub-nav-view.AnimationConfigView {
	width: 658px !important;
}
</style>


<i18n lang="de_DE">
fd.animations.headline: Animationen
fd.animations.noanimations: Erstellen Sie eine Animation über das jeweilige Modul.
fd.animations.editor: Animationseditor
fd.animations.settings.play.all: Alle Abspielen
</i18n>