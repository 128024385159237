<template>
  <div class="fd-image-module" :style="style">
    <div class="fd-image-preloader" ref="image-preloader"></div>
  </div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import CONFIG from '../Config.js';

import { loadImage } from '../Preloader.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if (props.url) {
		props.image = {
			path: props.url,
		};
	}

	if (props.image) {
		props.images = [props.image];
	}

	if (!props.slide) {
		props.slide = {
			running: false,
			duration: 2,
			delay: 1000,
		};
	}
}

export default {
	name: 'ImageModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			currentImageIndex: 0,
			runningTask: -1,
		};
	},
	computed: {
		style() {
			if (!this.module.props.images.length) return {};

			if(!this.module.props.images[this.currentImageIndex]) {
				this.currentImageIndex = 0;
			}

			const style = {
				backgroundImage: `url('${
					this.module.props.images[this.currentImageIndex].path
				}')`,
				backgroundSize: this.module.props.size,
			};

			if (this.module.logo && this.$store.state.mode == 'edit') {
				style.opacity = 0.5;
			}

			if (this.module.props.slide.running) {
				style.transition = `background-image ${
					this.module.props.slide.duration
				}s`;
			}

			return style;
		},
		runningWatcher() {
			return this.module.props.slide.running;
		}
	},
	watch: {
		runningWatcher(running) {
			this.stop();
			if(running) this.start();
			else this.currentImageIndex = 0;
		}
	},
	mounted() {
		this.module.props.images.forEach(image => {
			loadImage(image.path, this.$refs['image-preloader']).catch(() => {});
		});
	},
	methods: {
		start() {
			if (this.module.props.slide.running) {
				this.runningTask = setTimeout(
					_ => this.nextImage(),
					this.module.props.slide.delay +
						this.module.props.slide.duration * 1000 +
						200
				);
			}
		},

		stop() {
			clearTimeout(this.runningTask);
		},

		nextImage() {
			if (this.module.props.images.length < 1) return;

			const nextImageIndex =
				(this.currentImageIndex + 1) % this.module.props.images.length;

			if (!this.module.props.images[nextImageIndex]) {
				this.module.props.images.splice(nextImageIndex, 1);
				this.nextImage();
			}

			loadImage(
				this.module.props.images[nextImageIndex].path,
				this.$refs['image-preloader']
			)
				.then(() => {
					clearTimeout(this.runningTask);
					setTimeout(() => {
						this.currentImageIndex = nextImageIndex;

						if (this.module.props.slide.running) {
							this.runningTask = setTimeout(
								_ => this.nextImage(),
								this.module.props.slide.delay +
									this.module.props.slide.duration * 1000 +
									200
							);
						}
					}, 100);
				})
				.catch(() => {
					this.module.props.images.splice(nextImageIndex, 1);
					this.nextImage();
				});
		},
	},
};
</script>

<style>

.fd-image-module {
	background-repeat: no-repeat;
	background-position: center center;
}

.fd-image-preloader {
	position: absolute;
	top: -9999px;
	left: -9999px;
	width: 1px;
	height: 1px;
}
</style>
