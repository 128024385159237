<template>
  <div class="fd-rotate2-animation" :class="classList" :style="style">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'Rotate2',
  icon: 'loop3',
	props: ['animation'],
	data() {
		return {
      styleTrigger: 0,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
        styleTrigger: this.styleTrigger,
        animationDuration: this.animation.duration + 's',
        animationIterationCount: this.animation.count || 'infinite'
			}
    }
  },
	mounted() {
    if(typeof this.animation.count == 'undefined') {
      this.animation.count = 99;
    }
    if(typeof this.animation.duration == 'undefined') {
      this.animation.duration = 1;
    }
    if(typeof this.animation.delay == 'undefined') {
      this.animation.delay = 0;
    }
		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
      if(delay) {
        delay = this.animation.delay;
      } else {
        delay = 0;
      }
      this.styleTrigger = 1;
      this.$options.timeout = setTimeout(_ => {
        this.classList.push('rotation');
      }, delay * 1000);
		},

		stop() {
      this.styleTrigger = 0;
      clearTimeout(this.$options.timeout);
			this.classList = [];
    },
    
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
    },
  }
}
</script>

<style>
.fd-rotate2-animation.rotation {
  animation-name: rotation;
  animation-timing-function: linear;
}

@keyframes rotation {
  from {
    transform: rotate3d(0, 0, 1, 0deg) translate3d(50%, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg) translate3d(50%, 0, 0) rotate3d(0, 0, 1, -360deg);
  }
}
</style>
