<template>
	<div class="fd-basic-config-view">
		<h2 class="head">
			<span class="icon"><i :class="meta.icon"></i></span>
			<span class="separator" v-if="meta.icon"></span>
			<span class="title">{{$_('fd.' + meta.name + '.title')}}</span>
		</h2>
		<div class="body">
			<config-item-view
				v-for="(item, i) of config"
				:key="meta.name + '_' + item.type + '_' + i"
				:item="item"
			/>
		</div>
	</div>
</template>

<script>
import ConfigItemView from './ConfigItem.vue';

export default {
	name: 'SimpleConfigView',
	components: { ConfigItemView }
}
</script>
