<template>
  <div
    class="fd-modules"
    :class="{selecting: startPoint}"
    @contextmenu.prevent="contextmenu"
    v-movable
    @move:start="moveStart"
    @move="move"
    @move:end="moveEnd"
  >
    <module-view v-for="module of filteredModules" :key="module.mid" :module="module"></module-view>

    <div class="selector" :style="selectorStyle"></div>
    <div id="selectorX" class="selector nearest" :style="selectorX"></div>
    <div id="selectorY" class="selector nearest" :style="selectorY"></div>
  </div>
</template>

<script>
import ModulesView from './Modules.vue';
import ModuleView from './ModuleExtra.vue';
import ProInformationWindow from '../components/Window/ProInformationWindow.vue';
import NoDatasourceWindow from '../components/Window/NoDatasourceWindow.vue';
import {
	_rotate,
	_exists,
	_deepCopy
} from '../util/helper.js';

export default {
	extends: ModulesView,
	components: { ModuleView },
	data: () => ({
		point1: null,
		point2: null,
		pointX1: null,
		pointX2: null,
		pointY1: null,
		pointY2: null,
		offset: null,
	}),
	computed: {
		startPoint() {
			if (!this.point1 || !this.point2) return null;
			return {
				x: Math.min(this.point1.x, this.point2.x),
				y: Math.min(this.point1.y, this.point2.y),
			};
		},
		endPoint() {
			if (!this.point1 || !this.point2) return null;
			return {
				x: Math.max(this.point1.x, this.point2.x),
				y: Math.max(this.point1.y, this.point2.y),
			};
		},
		selectorStyle() {
			if (!this.startPoint || !this.endPoint) {
				return {
					opacity: 0,
					pointerEvents: 'none',
				};
			}

			return {
				transform: `translate(${this.startPoint.x}px, ${this.startPoint.y}px)`,
				width: `${this.endPoint.x - this.startPoint.x}px`,
				height: `${this.endPoint.y - this.startPoint.y}px`,
			};
		},
		// X
		startX() {
			if (!this.pointX1 || !this.pointX2) return null;
			return {
				x: Math.min(this.pointX1.x, this.pointX2.x),
				y: Math.min(this.pointX1.y, this.pointX2.y),
			};
		},
		endX() {
			if (!this.pointX1 || !this.pointX2) return null;
			return {
				x: Math.max(this.pointX1.x, this.pointX2.x),
				y: Math.max(this.pointX1.y, this.pointX2.y),
			};
		},
		selectorX() {
			if (!this.startX || !this.endX) {
				return {
					opacity: 0,
					pointerEvents: 'none',
				};
			}

			return {
				transform: `translate(${this.startX.x}px, ${this.startX.y}px)`,
				width: `${this.endX.x - this.startX.x}px`,
				height: `${this.endX.y - this.startX.y}px`,
			};
		},
		//Y
		startY() {
			if (!this.pointY1 || !this.pointY2) return null;
			return {
				x: Math.min(this.pointY1.x, this.pointY2.x),
				y: Math.min(this.pointY1.y, this.pointY2.y),
			};
		},
		endY() {
			if (!this.pointY1 || !this.pointY2) return null;
			return {
				x: Math.max(this.pointY1.x, this.pointY2.x),
				y: Math.max(this.pointY1.y, this.pointY2.y),
			};
		},
		selectorY() {
			if (!this.startY || !this.endY) {
				return {
					opacity: 0,
					pointerEvents: 'none',
				};
			}

			return {
				transform: `translate(${this.startY.x}px, ${this.startY.y}px)`,
				width: `${this.endY.x - this.startY.x}px`,
				height: `${this.endY.y - this.startY.y}px`,
			};
		},
	},
	mounted() {
		this.$root.$on('initModuleSnapping', _ => {
			this.filteredModules.forEach(module => {
				this.$root.$on('setPoints' + module.mid, nearest => {
					this.pointX1 = {
						x: nearest.X.x,
						y: 0,
					};
					this.pointX2 = {
						x: nearest.X.x,
						y: 1080,
					};
					this.pointY1 = {
						x: 0,
						y: nearest.Y.y,
					};
					this.pointY2 = {
						x: 1920,
						y: nearest.Y.y,
					};
				});
			});
		});

		this.$root.$on('moveEnd', _ => {
			this.pointX1 = null;
			this.pointX2 = null;
			this.pointY1 = null;
			this.pointY2 = null;
		});
		this.$root.$on('resizeEnd', _ => {
			this.pointX1 = null;
			this.pointX2 = null;
			this.pointY1 = null;
			this.pointY2 = null;
		});
		this.$root.$on('proInformation', _ => {
			this.$store.commit('window/create', {
				id: 'proInformation',
				component: ProInformationWindow,
				});
		});
		this.$root.$on('noDatasource', _ => {
			this.$store.commit('window/create', {
				id: 'noDatasource',
				component: NoDatasourceWindow,
				});
		});
		this.$root.$emit('initModuleSnapping');
	},
	methods: {
		contextmenu(evt) {
			const x = _exists(evt.pageX) ? evt.pageX : evt.touches[0].pageX;
			const y = _exists(evt.pageY) ? evt.pageY : evt.touches[0].pageY;
			this.$store.commit('contextmenu/open', {
				createPoint: { x, y },
				entrys: [
					{
						type: 'entry',
						label: this.$_('fd.context.save'),
						icon: 'checkmark3',
						click: () => this.$root.$emit('save'),
					},
					{
						type: 'entry',
						label: this.$_('fd.context.history.back'),
						icon: 'undo2',
						click: () => {
							this.$root.$emit('historyBack');
						},
					},
					{
						type: 'entry',
						label: this.$_('fd.context.fullscreen'),
						icon: this.$store.state.fullscreen ? 'shrink7' : 'enlarge7',
						click: () => this.$root.$emit('fullscreen'),
					},
				],
			});
		},
		deselectAll() {
			this.$store.commit('activeModules', null);
			this.$store.commit('highlightedModules', null);
			this.$store.dispatch('subNav/components', null);
		},
		moveStart({ detail: evt }) {
			this.deselectAll();
			const { x, y } = evt.target.getBoundingClientRect();
			this.offset = { x, y };
		},
		move({ detail: evt }) {
			this.point1 = {
				x: (evt.startX - this.offset.x) / this.$store.state.intern.scale,
				y: (evt.startY - this.offset.y) / this.$store.state.intern.scale,
			};
			(this.point2 = {
				x: (evt.currentX - this.offset.x) / this.$store.state.intern.scale,
				y: (evt.currentY - this.offset.y) / this.$store.state.intern.scale,
			}),
				this.highlightModules(this.startPoint, this.endPoint);
		},
		async moveEnd({ detail: evt }) {
			if (!this.point1 || !this.point2) return;
			await this.selectModules(this.startPoint, this.endPoint);
			this.point1 = null;
			this.point2 = null;
		},
		async highlightModules(startPoint, endPoint) {
			this.$store.commit(
				'highlightedModules',
				await this.$store
					.dispatch('getModulesInRect', {
						x: startPoint.x,
						y: startPoint.y,
						x2: endPoint.x,
						y2: endPoint.y,
					})
					.then(modules => modules.map(m => m.mid))
			);
		},
		async selectModules(startPoint, endPoint) {
			this.$store.commit('highlightedModules', []);
			this.$store.commit(
				'activeModules',
				await this.$store
					.dispatch('getModulesInRect', {
						x: startPoint.x,
						y: startPoint.y,
						x2: endPoint.x,
						y2: endPoint.y,
					})
					.then(modules => modules.map(m => m.mid))
			);
		},
	},
};
</script>

<style lang="scss">
.fd-modules {
	&.selecting *:not(.selector) {
		pointer-events: none;
	}

	.selector {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(135, 163, 191, 0.3);
		border: 2px dashed #39f;
		z-index: 999999999;
		&.nearest {
			border: 1px dashed #f00 !important;
		}
		&.middle {
			border: 1px dashed #fafb3d !important;
		}
	}

}
</style>

<i18n lang="de_DE">
fd.context.save: Speichern
fd.context.history.back: Rückgängig
fd.context.fullscreen: Vollbild
fd.context.align: Zentrieren
fd.context.toolsettingsbutton: Modul ändern
</i18n>
