<template>
	<div class="fd-weather-module">
		<template v-if="weather[module.props.time]">
			<div v-if="module.props.type == 'icon'" class="icon" :class="icon" :style="icon_size"></div>
			<div v-if="module.props.type == 'text'" class="text">{{text}}</div>
			<div v-if="/^temperature/.test(module.props.type)" class="text">{{minTemp}} / {{highTemp}}</div>
		</template>
	</div>
</template>

<script>
import '../../scss/fonts/icomoon.scss';
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if(!props.factor) {
		props.factor = 24;
	}
}

export default {
	name: 'WeatherModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			weather: {}
		}
	},
	computed: {
		icon() {
			if(!this.weather[this.module.props.time]) return '';

			return {
				'001.png': 'sun',
				'002.png': 'weather-cloud-sun',
				'003.png': 'cloud2',
				'004.png': 'weather-snow',
				'005.png': 'weather-lightning',
				'006.png': 'weather-snow',
				'007.png': 'weather-windy',
				'008.png': 'weather-windy',
				'009.png': 'weather-rain',
				'010.png': 'weather-rain',
				'011.png': 'weather-rain',
				'012.png': 'weather-rain',
				'013.png': 'weather-snow',
				'014.png': 'weather-snow',
				'015.png': 'weather-snow',
				'016.png': '',
				'017.png': 'weather-snow',
				'018.png': 'weather-snow'
			}[this.weather[this.module.props.time].icon];
		},
		icon_size() {
			if(this.module.props.resize_icon) {
				if(this.module.height < this.module.width) return 'font-size: ' + this.module.height + 'px';
				return 'font-size: ' + this.module.width + 'px';
			}

			return false;
		},
		text() {
			if(!this.weather[this.module.props.time]) return '';

			return this.weather[this.module.props.time].condition;
		},
		units() {
			const isUnit = {
				'°C': 'C',
				'°F': 'F'
			}[this.weather[this.module.props.time].unit];

			const toUnit = this.module.props.type.substr(-1);

			return {isUnit, toUnit};
		},
		minTemp() {
			if(!this.weather[this.module.props.time]) return '';

			const {isUnit, toUnit} = this.units;
			return this.convert(this.weather[this.module.props.time].low, isUnit, toUnit);
		},
		highTemp() {
			if(!this.weather[this.module.props.time]) return '';

			const {isUnit, toUnit} = this.units;
			return this.convert(this.weather[this.module.props.time].high, isUnit, toUnit);
		},
	},
	async mounted() {
		this.weather = await this.$store.dispatch('getWeather', [
			this.module.props.lat,
			this.module.props.lng,
			this.module.props.asl,
			'Europe/Berlin',
		]);
	},
	methods: {
		convert(temp, is, to) {
			temp = parseInt(temp);

			const suffix = {
				'C': '°C',
				'F': '°F',
				'K': 'K'
			}[to];

			if(is == to)
				return `${temp} ${suffix}`;

			if(is == 'C')
				temp += 273;

			if(is == 'F')
				temp = (temp + 459.67)/1.8;

			if(to == 'C')
				temp -= 273;

			if(to == 'F')
				temp = temp * 1.8 - 459.67;

			temp = parseInt(temp);

			return `${temp} ${suffix}`;
		}
	}
}
</script>

<style lang="scss">
.fd-weather-module {
	display: flex !important;
	align-items: center;
	justify-content: center;

	.icon {
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}
	.text {
		width: 100%;
	}
}
</style>
