import CONFIG from '../../Config.js';
import * as MODULES from '../../modules/_MODULES.js';
import * as STYLES from '../../styles';
import { _getPosition } from '../../util/helper.js';

const state = {
	title: CONFIG.DEFAULTS.TITLE,
	background: {
		image: {
			path: CONFIG.BASEPATH + '/img/bg/standard.jpg',
		},
		blur: 0,
		animation: false,
	},
	logo: false,
	modules: [
		{
			mid: '_m:1',
			class: 'WatermarkModule',
			locked: false,
			x: 'right',
			y: 'bottom',
			width: 160,
			height: 80,
			style: {},
		},
		{
			animation: [],
			mid: '_m:2',
			locked: false,
			x: 'center',
			y: 'center',
			width: 400,
			height: 400,
			r: 0,
			z: 2,
			style: {
				backgroundColor: { r: 0, g: 0, b: 0, a: 0 },
				border: {
					px: 0,
					style: 'solid',
					color: {
						r: 0,
						g: 0,
						b: 0,
						a: 0
					}
				},
				shadow: {
					x: 0,
					y: 0,
					width: 0,
					color: {
						r: 0,
						g: 0,
						b: 0,
						a: 0
					}
				},
				opacity: 100,
				advanced: {}
			},
			props: {
				images: [{
						path: CONFIG.BASEPATH + '/img/FoxDesigner-Icon.png',
				}],
				size: 'contain',
				slide: {
					running: false,
					duration: 2,
					delay: 1000
				}
			},
			class: 'ImageModule',
		},
	],
	preload: [CONFIG.BASEPATH + '/img/FoxDesigner-Icon.png'],
	version: CONFIG.VERSION,
};

async function init(config) {
	config.data = await config.data;

	if (!config.data) return state;

	// Fix empty array
	if (config.data instanceof Array && !config.data.length) {
		return state;
	}

	// Handle data as array
	if (!config.data.version && config.data.length) {
		config.data = {
			title: CONFIG.DEFAULTS.TITLE,
			background: config.data[0],
			modules: config.data[1],
			preload: config.data[2] || [],
			version: config.data[3] || '0.0.0',
		};
	}

	// Handle old data.data
	if (!config.data.modules && config.data.data) {
		config.data.modules = config.data.data;
		config.data.data = null;
		delete config.data.data;
	}

	if (config.data.logo) {
		// remove old logo_modules cause they might have changed
		for (const [key, module] of Object.entries(config.data.modules)) {
			if (module.logo && typeof module.zIndex !== "undefined") {
				// remove always first item in array because splice resizes arrays automatically
				config.data.modules.splice(0, 1);
			}
		}
		// when logo_modules are available load them back in
		if (typeof config.shared.logo_modules !== "undefined" && config.shared.logo_modules.length > 0) {
			config.shared.logo_modules.forEach(logo_module => {
				config.data.modules.splice(0, 0, logo_module);
			});
		} else {
			config.data.logo = false;
		}
	}

	if (!config.data.modules) {
		config.data.modules = [];
	}

	// Fix animation className
	let index = 1;
	config.data.modules.forEach(module => {
		module.animation = module.animation || [];
		module.animation.forEach(animation => {
			animation.index = index++;
			animation.name = animation.className || animation.class || animation.name;
		});
	});

	// Fix no background config
	if (config.data && !config.data.background) {
		config.data.background = {
			image: null,
			blur: 0,
			animation: false,
		};
	}

	// Fix background image is not an object but a string
	if (
		config.data.background.image &&
		!config.data.background.image.path &&
		typeof config.data.background.image === 'string'
	) {
		config.data.background.image = {
			path: config.data.background.image.replace(/^url\(|\)$/g, ''),
		};
	}

	// Fix no preload config
	if (config.data && !config.data.preload) {
		config.data.preload = [];
	}

	// Handle old versions
	if (config.data.version != CONFIG.VERSION) {
		// Fix Version differences here
	}

	// Handle deprecated mids
	let handle_mids = false;
	for (let j = 0; j < config.data.modules.length; j++) {
		if ((typeof config.data.modules[j].logo === "undefined" && !config.data.modules[j].logo) && isNaN(config.data.modules[j].mid.replace('_m:', ''))) {
			handle_mids = true;
			break;
		}
	}

	if (handle_mids) {
		let group_childs = [];
		for (let i = 0; i < config.data.modules.length; i++) {
			if (isNaN(config.data.modules[i].mid.replace('_m:', '')) || (typeof config.data.modules[i].isGroup !== "undefined" && config.data.modules[i].isGroup)) {
				group_childs[config.data.modules[i].mid] = generateModuleId(config.data.modules);
				config.data.modules[i].mid = generateModuleId(config.data.modules);
			}
		}

		config.data.modules.forEach(module => {
			if (module.class == 'GroupModule') {
				for (let i = 0; i < module.props.childs.length; i++) {
					if (typeof group_childs[module.props.childs[i]] !== "undefined") {
						module.props.childs[i] = group_childs[module.props.childs[i]];
					}
				}
			}
		});
	}

	// Fix duplicate mid
	if (config.data.modules) {
		// Get all mids
		const mids = config.data.modules.map(m => m.mid);

		// Check for duplicate
		for (let i = mids.length; i > 0; i--) {
			const mid = mids[i - 1];
			if (mids.slice(0, i - 1).includes(mid)) {
				config.data.modules[i - 1].mid = mid + i.toString();
			}
		}
	}

	return config.data || state;
}

function normalize(config) {
	config.modules.forEach(normalizeModule);
	return config;
}

function normalizeModule(module) {
	if(!module.props) module.props = {};
	if(!module.style) module.style = {};

	if(DEBUG && !MODULES[module.class].normalize) {
		throw new Error(
			`Add a normalize function to your ${module.class}.vue file!`
		);
	}

	// Normalize props
	MODULES[module.class].normalize(module.props);

	// Normalize styles
	for(const style in module.style) {
		module.style[style] = STYLES[style].normalize(module.style[style]);
	}
}

export async function create(config) {
	return {
		namespaced: true,

		state: await init(config).then(normalize),

		mutations: {
			replace(state, newState) {
				for (const key in newState) {
					state[key] = newState[key];
				}
			},

			module(state, template) {
				state.modules.push(template);
			},
		},

		actions: {
			replace({ commit }, data) {
				return init({ data }).then(newState => commit('replace', newState));
			},
			module({ state, commit, rootState }, template) {
				const module = {
					mid: generateModuleId(state.modules),
					z: generateZ(state.modules),
					x: 0,
					y: 0,
					r: 0,
					width: 200,
					height: 150,
					...template,
				};
				normalizeModule(module);

				commit('module', {
					...module,
					..._getPosition(module, rootState.screensize)
				});
				commit('dirty', null, { root: true });

				return module.mid;
			}
		},
	};
}

function generateModuleId(modules) {
	let lastIndex = 0;
	modules.forEach(module => {
		const index = parseMID(module.mid);
		if (index > lastIndex) {
			lastIndex = index;
		}
	});
	return '_m:' + (lastIndex + 1).toString();
}

function parseMID(mid) {
	return parseInt(mid.replace('_m:', ''));
}

function generateZ(modules) {
	let lastZ = 0;
	modules.forEach(module => {
		if (module.z > lastZ) {
			lastZ = module.z;
		}
	});
	return lastZ + 1;
}
