const basepath = '';

export default {
  VERSION: VERSION,
  BASEPATH: basepath,
  DEFAULTS: {
    TITLE: 'Foxdesigner',
    BACKGROUND: {
      IMAGE: {
        path: basepath + '/img/bg/standard.jpg'
      }
    }
  }
}
