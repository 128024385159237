import * as intern from './intern.js';
import * as screensize from './screensize.js';
import * as user from './user.js';
import * as settings from './settings.js';
import * as shared from './shared.js';
import * as data from './data.js';
import * as editor from './editor.js';
import * as subNav from './subNav.js';
import * as modal from './modal.js';
import * as window from './window.js';
import * as contextmenu from './contextmenu.js';

export const rendererModules = {
	intern,
	screensize,
	settings,
	shared,
	data,
}

export const editorModules = {
	...rendererModules,
	user,
	editor,
	subNav,
	modal,
	window,
	contextmenu,
}
