<template>
	<modal-view class="fd-animation-modal-view"
		:size="1300"
		:title="$_('fd.animation.modal.title')"
		:animation="true"
		icon="clapboard"
		:buttons="buttons"
	>

		<table>
			<thead>
				<tr>
					<th
						v-for="(item, i) of columns"
						:key="'columns_title' + i"
					>{{ item }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(module, i) of modules"
					:key="'module_' + module.meta.name + '_' + i"
				>
					<td>
						{{ module.module.z }}
					</td>
					<td>
						<component class="name" :is="module.description" :module="module.module"></component>
					</td>
					<td>
						{{ $_('fd.animation.' + module.animation.name + '.desc') }}
					</td>
					<td>
						{{ module.animation.delay + 's' }}
					</td>
					<td>
						{{ module.animation.duration + 's' }}
					</td>

					<td>
						<animation-list-view class="animation-list"
							:config="[module.animation]"
							:key="'settings_animationList_1'"
						/>
					</td>
				</tr>
				<tr>
					<td colspan="5"></td>
					<td>
						<canvas id="x-axis" width="602" height="19"/>
					</td>
				</tr>
			</tbody>
		</table>

	</modal-view>
</template>

<script>
import * as MODULES from '../../modules/_MODULES_EXTRA';
import ModalView from './Modal.vue';
import DescriptionView from '../Description.vue';
import AnimationListView from '../AnimationList.vue';
import AnimationConfigView from '../AnimationConfig.vue';

export default {
	name: 'AnimationModalView',
	components: { ModalView, AnimationListView },
	data() {
		return {
			buttons: [{
				label: this.$_('fd.animation.modal.actions.save'),
				css: 'primary',
				click: this.closeAnimationModal
			}, {
				label: this.$_('fd.animation.modal.actions.cancel'),
				click: this.closeAnimationModal
			}]
		}
	},
	mounted() {
		if(document.getElementById('x-axis') !== 'null') {
			let ctx = document.getElementById('x-axis').getContext("2d");
			ctx.fillRect(3,4, 600,2); // line 0 -> 230
			ctx.fillRect(3,0, 2,10); // left border
			ctx.fillRect(602,0, -2,10); // right border
			for(let i = 3; i < 550; i+=99,5) { // middle stripes
				ctx.fillRect(i,1, 1.5,8);
			}
			ctx.fillText("0s", 0,19);
			ctx.fillText("10s", 98,19);
			ctx.fillText("20s", 198,19);
			ctx.fillText("30s", 298,19);
			ctx.fillText("40s", 398,19);
			ctx.fillText("50s", 498,19);
			ctx.fillText("60s", 586,19);
		}
	},
	computed: {
		columns() {
			let columns = [];

			return columns = [
				this.$_('fd.animation.modal.column.module.id'), 
				this.$_('fd.animation.modal.column.module.name'),
				this.$_('fd.animation.modal.column.animation.name'),
				this.$_('fd.animation.modal.column.animation.delay'),
				this.$_('fd.animation.modal.column.animation.duration'),
				this.$_('fd.animation.modal.column.animation.list')
			];
		},
		modules() {
			let modules = [];

			this.$store.state.data.modules.forEach(module => {
				if(typeof module.animation != 'undefined') {
					if (module.animation.length > 0) {
						module.animation.forEach( animation => {
	
							modules.push({
								module: module,
								meta: MODULES[module.class].meta,
								description: MODULES[module.class].description || DescriptionView,
								animation: animation
							});
	
						});
					}
				}
			});

			return modules;
		}
	},
	methods: {
		closeAnimationModal() {
			this.$store.commit('window/close');
			this.$store.dispatch('subNav/components', AnimationConfigView);
		}
	}
}

</script>

<style lang="scss">

.fd-animation-modal-view {

	table {
		text-align: center;
		border-spacing: 3em 0;
		thead {
			padding: 0 0 0 0;
		}
		tbody {
		.fd-animation-list-input {
				.fd-animation-list-item-view {
					.slider {
						width: 600px;
						background-color: #f0f0f0;
						.move {
							background-color: #fcbf07;
						}
						.move::after {
							background-color: white;
						}
						.move::before {
							background-color: white;
						}
					}
				}
			}
		}
		th, td {
			canvas {
				margin-left: 41px;
			}
		}
		td:last-child {
			width: 268px;
		}
		th:last-child {
			width: 268px;
		}
	}
}

</style>

<i18n lang="de_DE">
fd.animation.modal.title: Animationseditor
fd.animation.modal.column.module.id: M-ID
fd.animation.modal.column.module.name: Modul
fd.animation.modal.column.animation.id: A-ID
fd.animation.modal.column.animation.name: Animation
fd.animation.modal.column.animation.delay: Verzögerung
fd.animation.modal.column.animation.duration: Dauer
fd.animation.modal.column.animation.list: Zeitablaufdiagramm
fd.animation.modal.actions.save: Ok
fd.animation.modal.actions.cancel: Abbrechen
</i18n>