<template>
	<div class="fd-info">{{config.text}}</div>
</template>

<script>
export default {
	name: 'InfoView',
	props: ['config']
}
</script>

<style lang="scss">
.fd-config-item.Info {
	.label {
		display: none;
	}
	.input {
		flex: 0 0 100% !important;
	}
}

.fd-info {
	width: 100%;
	font-size: 12px;
}
</style>
