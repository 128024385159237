<template>
  <div class="fd-wiggle-animation" :class="classList" :style="style">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'Wiggle',
  icon: 'fan',
	props: ['animation'],
	data() {
		return {
      triggerStyle: 0,
      classList: ['animated']
		}
  },
  computed: {
    style() {
			return {
        triggerStyle: this.triggerStyle,
        animationDuration: this.animation.duration + 's',
        animationIterationCount: this.animation.count || 99
			}
    }
  },
	mounted() {
    if(typeof this.animation.count == 'undefined') {
      this.animation.count = 99;
    }
    if(typeof this.animation.duration == 'undefined') {
      this.animation.duration = 1;
    }
    if(typeof this.animation.delay == 'undefined') {
      this.animation.delay = 0;
    }
		this.$root.$on('start', _ => this.start(true));
		this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('update', _ => this.update());
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
      if(delay) {
        delay = this.animation.delay;
      } else {
        delay = 0;
      }
      this.triggerStyle = 1;
      this.$options.timeout = setTimeout(_ => {
        this.classList.push(this.animationClass());
      }, delay * 1000);
		},

		stop() {
      this.triggerStyle = 0;
      clearTimeout(this.$options.timeout);
			this.classList = [];
    },
    
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
    },
    
    animationClass() {
      return 'wiggle';
    },
  }
}
</script>

<style>
.fd-wiggle-animation.wiggle {
  animation-name: wiggle;
}

@keyframes wiggle {
  0% {
    transform: rotate3d(0, 0, 1, -4deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 4deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, -4deg);
  }
}
</style>
