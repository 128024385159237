<template>
	<div class="fd-sun-module">
		<div class="sun" :style="sunStyle"></div>
		<div class="ray-box" :style="rayBoxStyle" v-if="module.props.length > 0">
			<div v-for="(style, n) in rayStyles" :key="n" class="ray" :class="'ray' + n" :style="style">
				<div class="inner" :style="[rayInnerStyle, style.inner]"></div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import { compute as getColor } from '../styles/Color.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'SunModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			rayCount: 9,
			rayStyles: [],
		};
	},
	created: function () {
		for (let i = 0; i < this.rayCount; i++) {
			this.rayStyles.push({
				transform: `rotate3d(0, 0, 1, ${360 / this.rayCount * i}deg)
					scale3d(${Math.random() * 0.4 + 0.2}, 0.7, 1)`,
				inner: {
					animationDelay: (Math.random() * -30) + 's',
					animationDuration: (Math.random() * 10 + 25) + 's',
				}
			});
		}
	},
	computed: {
		color() {
			return getColor(this.module.props.color).color;
		},
		gradientColor() {
			const color = { ...this.module.props.color };
			color.a *= 0.2;
			return getColor(color).color;
		},
		lightGradientColor() {
			const color = { ...this.module.props.color };
			color.a *= 0.1;
			return getColor(color).color;
		},
		sunStyle() {
			return {
				background: this.color,
				background: `radial-gradient(${this.color} 5%, ${this.lightGradientColor}, ${this.lightGradientColor}, transparent 60%, transparent)`,
			};
		},
		rayBoxStyle() {
			return {
				width: this.module.props.length * 10 + '%',
				height: this.module.props.length * 10 + '%',
				opacity: this.module.props.opacity / 100,
				animationDuration:
					this.module.props.rotationSpeed !== 0
						? 600 / Math.abs(this.$root.tooSlow ? 1 : this.module.props.rotationSpeed) + 's'
						: '0s',
				animationName:
					this.module.props.rotationSpeed > 0
						? 'fd_sun_module_ray_rotation_anim'
						: 'fd_sun_module_ray_rotation_anim_back',
				willChange:
					this.module.props.rotationSpeed === 0
						? null : 'transform',
			};
		},
		rayInnerStyle () {
			return {
				backgroundImage: `linear-gradient(to bottom right,
					transparent 27%,
					${this.color} 35%,
					transparent 45%,
					transparent
				)`,
				animationName: this.module.props.rotationSpeed === 0 && this.$root.fastEnough ? 'fd_sun_module_ray_flow_anim' : 'fd_sun_module_ray_flow_only_opacity_anim',
				animationPlayState: this.$root.tooSlow ? 'paused' : null
			};
		},
	},
};
</script>

<style lang="scss">
@keyframes fd_sun_module_ray_rotation_anim {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -360deg);
	}
}
@keyframes fd_sun_module_ray_rotation_anim_back {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, 360deg);
	}
}
@keyframes fd_sun_module_ray_flow_anim {
	0% {
		opacity: 0.5;
		background-position: 50% 50%;
	}
	50% {
		opacity: 1;
		background-position: 70% 70%;
	}
	100% {
		opacity: 0.5;
		background-position: 50% 50%;
	}
}
@keyframes fd_sun_module_ray_flow_only_opacity_anim {
	0% {
		opacity: 0.1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.1;
	}
}

.fd-sun-module {
	position: relative;

	.abs-h-v-centered {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.pointer-events-none {
		pointer-events: none;
	}

	.sun {
		@extend .abs-h-v-centered;
		@extend .pointer-events-none;
		width: 500%;
		height: 500%;
		z-index: 20;

		border-radius: 50%;
		background: radial-gradient(
			#fff 5%,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2),
			transparent 60%,
			transparent
		);
	}

	.ray-box {
		@extend .abs-h-v-centered;
		@extend .pointer-events-none;
		z-index: 10;
		width: 2000%;
		height: 2000%;
		opacity: 0.25;
		overflow: hidden;
		border-radius: 50%;

		animation: fd_sun_module_ray_rotation_anim 240s linear infinite;
	}

	.ray {
		position: absolute;
		left: 50%;
		top: 50%;

		width: 100%;
		height: 100%;
		transform-origin: left top;

		.inner {
			display: block;
			content: '';
			width: 100%;
			height: 100%;
			transform: rotate3d(0, 0, 1, 45deg);
			transform-origin: left top;
			will-change: opacity, background-position;
			animation: fd_sun_module_ray_flow_anim 30s ease infinite;

			background: linear-gradient(to bottom right,
				transparent 27%,
				#fff 35%,
				transparent 45%,
				transparent
			);
			background-size: 200% 200%;
			background-position: 50% 50%;
			opacity: 1;
		}

		$rays: 9;
		@for $i from 0 to $rays {
			&.ray#{$i} {
				transform: rotate3d(0, 0, 1, 0deg + 360 / $rays * $i)
					scale3d(random() * 0.4 + 0.2, 0.7, 1);

				.inner {
					animation-delay: random(30) * -1s;
					animation-duration: random(10) * 1s + 25s;
				}
			}
		}
	}
}
</style>
