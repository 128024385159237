<template>
	<div class="fd-color-picker-view" :style="style">

		<div class="mousePicker">
			<div class="comparer">
				<div class="old" :style="{color: arrayToRGBA(initialColor)}"></div>
				<div class="new" :style="{color: arrayToRGBA(color)}"></div>
			</div>
			<div class="mouseHSV">
				<canvas ref="hCanvas" width="221" height="221" :data-draw="hDrawState"></canvas>
				<canvas ref="svCanvas" width="221" height="221" :data-draw="svDrawState"></canvas>
				<div class="hSelector" :style="hSelectorStyle" ref="hSelector"></div>
				<div class="svSelector" :style="svSelectorStyle" ref="svSelector"></div>
			</div>
			<div class="mouseAlpha" :style="mouseAlphaStyle">
				<div class="background" :style="mouseAlphaBackgroundStyle"></div>
				<div class="aSelector" :style="aSelectorStyle" ref="aSelector"></div>
			</div>
		</div>

		<div class="keyboardPicker">
			<div class="config-item hex">
				<label for="colorpicker-hex">HEX:</label>
				<div class="input">
					<text-input id="colorpicker-hex" v-model="hexInput"/>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="config-item h">
						<label for="colorpicker-h">H:</label>
						<div class="input">
							<number-input id="colorpicker-h" :value="hInput" @input="setH"/>
						</div>
					</div>
					<div class="config-item s">
						<label for="colorpicker-s">S:</label>
						<div class="input">
							<number-input id="colorpicker-s" :config="{min: 0, max: 100}" :value="sInput" @input="setS"/>
						</div>
					</div>
					<div class="config-item v">
						<label for="colorpicker-v">V:</label>
						<div class="input">
							<number-input id="colorpicker-v" :config="{min: 0, max: 100}" :value="vInput" @input="setV"/>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="config-item r">
						<label for="colorpicker-r">R:</label>
						<div class="input">
							<number-input id="colorpicker-r" :config="{min: 0, max: 255}" :value="rInput" @input="setR"/>
						</div>
					</div>
					<div class="config-item g">
						<label for="colorpicker-g">G:</label>
						<div class="input">
							<number-input id="colorpicker-g" :config="{min: 0, max: 255}" :value="gInput" @input="setG"/>
						</div>
					</div>
					<div class="config-item b">
						<label for="colorpicker-b">B:</label>
						<div class="input">
							<number-input id="colorpicker-b" :config="{min: 0, max: 255}" :value="bInput" @input="setB"/>
						</div>
					</div>
				</div>
			</div>
			<div class="config-item alpha">
				<label for="colorpicker-alpha">Alpha:</label>
				<div class="input">
					<number-input id="colorpicker-alpha" :config="{min: 0, max: 100}" :value="alphaInput" @input="setAlpha"/>
				</div>
			</div>
			<div class="color-grid">
				<div
					class="color"
					v-for="color of colors"
					:key="color"
					@click="setColor(parseColor(color))"
				>
					<div :style="{background: color}"></div>
				</div>
			</div>
		</div>

		<div class="footer">
			<button-input @click="ok" :config="{label: 'OK'}"/>
			<button-input @click="cancel" :config="{label: $_('fd.colorpicker.cancel')}"/>
		</div>

	</div>
</template>

<script>
import TextInput from '../inputs/Text.vue';
import NumberInput from '../inputs/Number.vue';
import ButtonInput from '../inputs/Button.vue';
import { _parseColor, _arrayToRGBA, _rgbToHSV, _hsvToRGB } from '../util/Color.js';
import {
	_createImage,
	_setDonut,
	_setTriangle,
	_drawImage,
} from '../util/Image.js';
import Movable from '../util/Movable.js';

const sqrt3 = Math.sqrt(3);

export default {
	name: 'ColorPickerView',
	components: { TextInput, NumberInput, ButtonInput },
	data() {
		return {
			initialColor: this.$store.state.subNav.colorPicker.color,
			hContext: null,
			svContext: null,
			hexInput: this.arrayToHEX(this.$store.state.subNav.colorPicker.color),
			hexInputFlag: false,

			colors: [
				'#FFFFFF',
				'rgba(0, 0, 0, 0)',
				'#FF0000', '#FF4000', '#FF8000', '#FFBF00', '#FFFF00', '#BFFF00', '#80FF00', '#40FF00',
				'#BFBFBF', '#808080',
				'#00FF00', '#00FF40', '#00FF80', '#00FFBF', '#00FFFF', '#00BFFF', '#0080FF', '#0040FF',
				'#404040', '#000000',
				'#0000FF', '#4000FF', '#8000FF', '#BF00FF', '#FF00FF', '#FF00BF', '#FF0080', '#FF0040',
			]
		}
	},
	watch: {
		offset() {
			this.initialColor = this.$store.state.subNav.colorPicker.color;
		},
		hexInput(input) {
			this.hexInputFlag = true;
			this.setColor(this.parseColor(input));
		},
		color(color) {
			if(this.hexInputFlag) {
				this.hexInputFlag = false;
				return;
			}
			this.hexInput = this.arrayToHEX(color);
		}
	},
	computed: {
		color() {
			return this.$store.state.subNav.colorPicker.color;
		},
		offset() {
			return this.$store.state.subNav.colorPicker.offset;
		},
		style() {
			const maxOffset = this.$root.$el.offsetHeight - 310 - 5;
			let offset = this.offset - 140;
			if(offset > maxOffset) offset = maxOffset;
			if(offset < 5) offset = 5;
			return {
				left: this.$store.state.subNav.width + 46 + 'px',
				top: offset + 'px'
			}
		},
		hSelectorStyle() {
			return {
				transform: `rotate(${this.hsv[0]}deg)`
			};
		},
		svSelectorStyle() {
			const x = 110 + 85 * (2 * this.hsv[2] - this.hsv[1] * this.hsv[2] - 1) * sqrt3 / 2;
			const y = 110 + 85 * (1 - 3 * this.hsv[1] * this.hsv[2]) / 2;

			return {
				transform: `translate(${x}px, ${y}px)`
			}
		},
		mouseAlphaStyle() {
			return {
				color: this.arrayToRGBA([
					this.color[0],
					this.color[1],
					this.color[2],
					1
				])
			};
		},
		mouseAlphaBackgroundStyle() {
			const currentColor = this.mouseAlphaStyle.color;
			return {
				background: `linear-gradient(to right, rgba(0,0,0,0) 0%, ${currentColor} 100%)`
			}
		},
		aSelectorStyle() {
			return {
				left: `calc(${this.color[3] * 100}% - 3px)`
			};
		},
		hsv() {
			return _rgbToHSV(this.color);
		},
		hDrawState() {
			return this.hContext && this.drawH(this.hContext);
		},
		svDrawState() {
			return this.svContext && this.drawSV(this.svContext);
		},
		hInput() {
			return Math.round(this.hsv[0]);
		},
		sInput() {
			return Math.round(this.hsv[1] * 100);
		},
		vInput() {
			return Math.round(this.hsv[2] * 100);
		},
		rInput() {
			return this.color[0];
		},
		gInput() {
			return this.color[1];
		},
		bInput() {
			return this.color[2];
		},
		alphaInput() {
			return Math.round(this.color[3] * 100);
		},
	},
	mounted() {
		this.hContext = this.$refs.hCanvas.getContext('2d');
		this.svContext = this.$refs.svCanvas.getContext('2d');

		const hSelector = Movable(this.$refs.hSelector);
		let center = null;
		hSelector.addEventListener('move:start', _ => {
			const rect = hSelector.parentElement.getBoundingClientRect();
			center = {
				x: rect.x + rect.width / 2,
				y: rect.y + rect.height / 2,
			};
			hSelector.classList.add('active');
			hSelector.parentElement.classList.add('active');
		});
		hSelector.addEventListener('move', ({detail: evt}) => {
			const [x, y] = [
				evt.currentX - center.x,
				evt.currentY - center.y,
			];
			const phi = (Math.atan2(y, x) * 180 / Math.PI + 450) % 360;
			const color = _hsvToRGB([
				phi,
				this.hsv[1],
				this.hsv[2],
				this.hsv[3]
			]);
			this.$store.commit('subNav/colorPicker', {
				visible: true,
				offset: this.offset,
				color
			});
		});
		hSelector.addEventListener('move:end', _ => {
			hSelector.classList.remove('active');
			hSelector.parentElement.classList.remove('active');
		});

		const svSelector = Movable(this.$refs.svSelector);
		svSelector.addEventListener('move:start', _ => {
			const rect = hSelector.parentElement.getBoundingClientRect();
			center = {
				x: rect.x + rect.width / 2,
				y: rect.y + rect.height / 2,
			};
			svSelector.classList.add('active');
			svSelector.parentElement.classList.add('active');
		});
		svSelector.addEventListener('move', ({detail: evt}) => {
			const [x, y] = [
				(evt.currentX - center.x) / 85,
				(evt.currentY - center.y) / 85,
			];
			const color = _hsvToRGB([
				/* hue */ this.hsv[0],
				/* sat */ Math.min(1, Math.max(0, (1 - 2 * y) / (sqrt3 * x - y + 2))),
				/* val */ Math.min(1, Math.max(0, (sqrt3 * x - y + 2) / 3)),
				1
			]);
			this.$store.commit('subNav/colorPicker', {
				visible: true,
				offset: this.offset,
				color
			});
		});
		svSelector.addEventListener('move:end', _ => {
			svSelector.classList.remove('active');
			svSelector.parentElement.classList.remove('active');
		});

		const aSelector = Movable(this.$refs.aSelector);
		let width = null;
		let alpha = null;
		aSelector.addEventListener('move:start', _ => {
			width = aSelector.parentElement.offsetWidth;
			alpha = this.color[3];
			aSelector.classList.add('active');
			aSelector.parentElement.classList.add('active');
		});
		aSelector.addEventListener('move', ({detail: evt}) => {
			const delta = evt.moveX / width;
			this.$store.commit('subNav/colorPicker', {
				visible: true,
				offset: this.offset,
				color: [
					this.color[0],
					this.color[1],
					this.color[2],
					Math.round(Math.min(1, Math.max(0, alpha + delta)) * 100) / 100,
				]
			});
		});
		aSelector.addEventListener('move:end', _ => {
			aSelector.classList.remove('active');
			aSelector.parentElement.classList.remove('active');
		});
	},
	methods: {
		drawH(ctx) {
			const size = 220;
			const image = _createImage(ctx, size + 1, size + 1);

			let cx, cy;
			_setDonut(
				image,
				cx = size / 2,
				cy = size / 2,
				size / 2, // outer radius
				size / 2 - 25, // inner radius
				(x, y) => {
					x -= cx;
					y -= cy;

					const phi = (Math.atan2(y, x) * 180 / Math.PI + 450) % 360;
					return _hsvToRGB([
						phi,
						this.hsv[1],
						this.hsv[2],
						]);
				}
			);

			_drawImage(ctx, image);
		},
		drawSV(ctx) {
			const size = 220;
			const image = _createImage(ctx, size + 1, size + 1);

			let cx, cy, radius;
			_setTriangle(
				image,
				cx = size / 2,
				cy = size / 2,
				radius = size / 2 - 25,
				(x, y) => {
					x = (x - cx) / radius;
					y = (y - cy) / radius;

					return _hsvToRGB([
						/* hue */ this.hsv[0],
						/* sat */ (1 - 2 * y) / (sqrt3 * x - y + 2),
						/* val */ (sqrt3 * x - y + 2) / 3,
						1
					]);
				}
			);

			_drawImage(ctx, image);
		},
		arrayToRGBA: _arrayToRGBA,
		arrayToHEX(color) {
			return (
				'#' +
				(color[0]).toString(16).padStart(2, '0') +
				(color[1]).toString(16).padStart(2, '0') +
				(color[2]).toString(16).padStart(2, '0') +
				(color[3] == 1 ? '' : Math.round(color[3] * 255).toString(16).padStart(2, '0'))
			).toUpperCase();
		},
		parseColor:_parseColor,
		setColor(color) {
			this.$store.commit('subNav/colorPicker', {
				visible: true,
				offset: this.offset,
				color
			});
		},
		setH(h) {
			this.setColor(_hsvToRGB([
				(parseInt(h) + 360) % 360,
				this.hsv[1],
				this.hsv[2],
				this.hsv[3],
			]));
		},
		setS(s) {
			this.setColor(_hsvToRGB([
				this.hsv[0],
				parseInt(s) / 100,
				this.hsv[2],
				this.hsv[3],
			]));
		},
		setV(v) {
			this.setColor(_hsvToRGB([
				this.hsv[0],
				this.hsv[1],
				parseInt(v) / 100,
				this.hsv[3],
			]));
		},
		setR(r) {
			this.setColor([
				parseInt(r),
				this.color[1],
				this.color[2],
				this.color[3],
			]);
		},
		setG(g) {
			this.setColor([
				this.color[0],
				parseInt(g),
				this.color[2],
				this.color[3],
			]);
		},
		setB(b) {
			this.setColor([
				this.color[0],
				this.color[1],
				parseInt(b),
				this.color[3],
			]);
		},
		setAlpha(a) {
			this.setColor([
				this.color[0],
				this.color[1],
				this.color[2],
				parseInt(a) / 100,
			]);
		},
		ok() {
			this.$store.commit('subNav/colorPicker', {
				visible: false,
				offset: 0,
				color: [0, 0, 0, 1]
			});
		},
		async cancel() {
			this.setColor(this.initialColor);

			await this.$nextTick();
			this.ok();
		}
	}
}
</script>

<style lang="scss">
.fd-color-picker-view {
	position: absolute;
	top: 0;
	width: 482px;
	background: black;
	border: 1px solid #fff;

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		"mouse keyboard"
		"mouse footer";

	.mousePicker {
		grid-area: mouse;
		padding: 10px;

		.comparer {
			width: 100px;
			height: 20px;
			border: 1px solid #fff;
			border-radius: 5px;
			overflow: hidden;

			background: url('../../img/colorpicker_colorbg.png');

			display: flex;
			div {
				flex: 1;
				background: currentColor;
				transform: skewX(-25deg) scaleX(1.1);
			}

			.old {
				transform-origin: right center;
			}

			.new {
				transform-origin: left center;
				border-left: 1px solid #fff;
			}
		}

		.hSelector,
		.svSelector,
		.aSelector {
			position: absolute;
			border: 1px solid white;
			border-radius: 4px;
			filter: drop-shadow(0 0 2px black);

			cursor: move;
			cursor: grab;

			&.active {
				cursor: grabbing;
			}
		}

		.mouseHSV {
			width: 100%;
			padding-bottom: 100%;
			margin-top: 10px;
			position: relative;

			&.active {
				cursor: grabbing;
			}

			canvas {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.hSelector {
				top: 0;
				left: calc(50% - 3px);
				width: 4px;
				height: 24px;

				transform-origin: 3px 110.5px;
			}

			.svSelector {
				top: -3.5px;
				left: -3.5px;
				width: 6px;
				height: 6px;
			}
		}

		.mouseAlpha {
			position: relative;
			margin-top: 10px;
			height: 20px;
			border: 1px solid #fff;
			border-radius: 5px;

			background: url('../../img/colorpicker_colorbg.png');

			.background {
				width: 100%;
				height: 100%;
				border-radius: 5px;

				background: linear-gradient(to right, rgba(0,0,0,0) 0%, currentColor 100%);
			}

			&.active {
				cursor: grabbing;
			}

			.aSelector {
				top: -2px;
				left: -3px;
				width: 4px;
				height: 22px;
			}
		}
	}

	.keyboardPicker {
		grid-area: keyboard;

		padding: 10px;

		.config-item {
			display: flex;
			align-items: center;

			label {
				font-family: OpenSans, sans-serif;
				font-size: 11px;
				color: #fff;
				flex: 0;
				margin-right: 10px;
			}

			.input {
				flex: 1;
			}

			* {
				box-sizing: border-box;
			}
		}

		.config-item.hex,
		.config-item.alpha {
			width: 50%;
		}

		.config-item.alpha {
			margin-left: 50%;
		}

		.row {
			margin: 7px -10px;

			display: flex;

			.col {
				flex: 1;
				margin: 0 10px;

				label {
					flex-basis: 12px;
				}

				.config-item {
					margin: 3px 0;
				}
			}
		}

		.color-grid {
			margin-top: 25px;

			display: grid;
			grid-template-columns: repeat(10, 1fr);
			grid-gap: 4px;

			.color {
				border: 1px solid #fff;
				background: url('../../img/colorpicker_colorbg.png');
				cursor: pointer;

				div {
					padding-bottom: 100%;
				}
			}
		}
	}

	.footer {
		grid-area: footer;

		display: flex;
		justify-content: flex-end;
		padding: 10px 0;

		button {
			width: auto;
			margin-right: 10px;
		}
	}
}
</style>

<i18n lang="de_DE">
fd.colorpicker.cancel: Abbrechen
</i18n>
