<script>
export default {
	name: 'TextInput',
	props: ['value', 'config'],
	data() {
		return {
			textareaValue: this.value
		}
	},
	render(h) {
		return h('textarea', {
			class: 'fd-textarea-input',
			domProps: {
				value: this.textareaValue
			},
			on: {
				input: e => {
					this.textareaValue = e.target.value;
					this.emit(e);
				}
			}
		});
	},
	methods: {
		emit(e) {
			this.$emit('input', e.target.value)
		}
	}
}
</script>

<style lang="scss">
.fd-config-item.Textarea {
	flex-direction: column;

	& > * {
		width: 100% !important;
	}
}
.fd-textarea-input {
	min-width: 100%;
	min-height: 80px;
}
</style>
