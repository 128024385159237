export const KEY = 'fontStyle';

export const CONFIG = {
	type: 'ButtonGroup',
	mode: 'check',
	values: [
		{
			label: 'k',
			color: 'italic',
			key: 'italic',
		},
		{
			label: 'f',
			color: 'bold',
			key: 'bold',
		},
		{
			label: 'u',
			color: 'underline',
			key: 'underline',
		}
	]
}

export function normalize(value) {
	value = value || {};
	return {
		italic: false,
		bold: false,
		underline: false,
		...value
	};
}

export function compute(value) {
	value = normalize(value);

	return {
		fontStyle: value.italic ? 'italic' : 'normal',
		fontWeight: value.bold ? 'bold' : 'normal',
		textDecoration: value.underline ? 'underline' : 'initial',
	}
}
