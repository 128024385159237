export function _getNearestEdges(storeState, evt, callback) {
	_getModuleEdgesAndTarget(storeState, evt, modules => {
		if (modules.targetModule.edges) {
			let X = {
					distance: 1920,
					x: null,
					middle: false,
				},
				Y = {
					distance: 1080,
					y: null,
					middle: false,
				};
			modules.modules.forEach(module => {
				modules.targetModule.edges.forEach(targetEdges => {
					_getNearestXAndY(module.edges, targetEdges, nearest => {
						if (typeof modules.targetModule.moveDirection != 'undefined' && modules.targetModule.moveDirection.includes('l')) {
							if (Math.abs(nearest.x - targetEdges.x) < Math.abs(X.distance)) {
								X.x = nearest.x;
								X.distance = nearest.x - targetEdges.x;
								X.middle = nearest.middleX;
							}
						} else {
							if (Math.abs(targetEdges.x - nearest.x) < Math.abs(X.distance)) {
								X.x = nearest.x;
								X.distance = targetEdges.x - nearest.x;
								X.middle = nearest.middleX;
							}
						}

						if (typeof modules.targetModule.moveDirection != 'undefined' && modules.targetModule.moveDirection.includes('t')) {
							if (Math.abs(nearest.y - targetEdges.y) < Math.abs(Y.distance)) {
								Y.y = nearest.y;
								Y.distance = nearest.y - targetEdges.y;
								Y.middle = nearest.middleY;
							}
						} else {
							if (Math.abs(targetEdges.y - nearest.y) < Math.abs(Y.distance)) {
								Y.y = nearest.y;
								Y.distance = targetEdges.y - nearest.y;
								Y.middle = nearest.middleY;
							}
						}
					});
				});
			});
			callback({
				X,
				Y,
			});
		}
	});
}

function _getModuleEdgesAndTarget(storeState, evt, callback) {
	const target = evt.target;
	let {
		detail: { moveX, moveY },
	} = evt;

	const lastMoveX = module.lastMoveX ? module.lastMoveX : 0;
	const lastMoveY = module.lastMoveY ? module.lastMoveY : 0;
	const tmpmoveX = moveX;
	const tmpmoveY = moveY;
	moveX -= lastMoveX;
	moveY -= lastMoveY;

	module.lastMoveX = tmpmoveX;
	module.lastMoveY = tmpmoveY;

	let targetModule = new Object(),
		modules = new Array();
	storeState.data.modules.forEach(module => {
		if (module.class != 'WatermarkModule' && !module.isGroup && !module.logo) {
			if (module.mid == target.parentNode.id) {
				if (evt.direction) {
					switch (evt.direction) {
						case 'b':
							module.edges = [
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
							break;
						case 't':
							module.edges = [
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
							];
							break;
						case 'r':
							module.edges = [
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
							break;
						case 'br':
							module.edges = [
								// // bottom left
								// {
								// 	x: module.x,
								// 	y: module.y + module.height,
								// },
								// // top right
								// {
								// 	x: module.x + module.width,
								// 	y: module.y,
								// },
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
							break;
						case 'tr':
							module.edges = [
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// // top left
								// {
								// 	x: module.x,
								// 	y: module.y,
								// },
								// // bottom right
								// {
								// 	x: module.x + module.width,
								// 	y: module.y + module.height,
								// },
							];
							break;
						case 'l':
							module.edges = [
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
							];
							break;
						case 'bl':
							module.edges = [
								// // top left
								// {
								// 	x: module.x,
								// 	y: module.y,
								// },
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// // bottom right
								// {
								// 	x: module.x + module.width,
								// 	y: module.y + module.height,
								// },
							];
							break;
						case 'tl':
							module.edges = [
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// // bottom left
								// {
								// 	x: module.x,
								// 	y: module.y + module.height,
								// },
								// // top right
								// {
								// 	x: module.x + module.width,
								// 	y: module.y,
								// },
							];
							break;
					}
				} else {
					if (moveX == 0) {
						if (moveY > 0) {
							module.moveDirection = 'b';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						} else if (moveY != 0) {
							module.moveDirection = 't';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						}
					} else if (moveX > 0) {
						if (moveY == 0) {
							module.moveDirection = 'r';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						} else if (moveY > 0) {
							module.moveDirection = 'br';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						} else {
							module.moveDirection = 'tr';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						}
					} else {
						if (moveY == 0) {
							module.moveDirection = 'l';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						} else if (moveY > 0) {
							module.moveDirection = 'bl';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						} else {
							module.moveDirection = 'tl';
							module.edges = [
								// middle
								{
									x: module.x + ( module.width / 2 ),
									y: module.y + ( module.height / 2 ),
								},
								// top left
								{
									x: module.x,
									y: module.y,
								},
								// bottom left
								{
									x: module.x,
									y: module.y + module.height,
								},
								// top right
								{
									x: module.x + module.width,
									y: module.y,
								},
								// bottom right
								{
									x: module.x + module.width,
									y: module.y + module.height,
								},
							];
						}
					}
				}

				targetModule = module;
			} else {
				module.edges = [
					// middle
					{
						x: module.x + ( module.width / 2 ),
						y: module.y + ( module.height / 2 ),
					},
					// top left
					{
						x: module.x,
						y: module.y,
					},
					// top right
					{
						x: module.x + module.width,
						y: module.y,
					},
					// bottom left
					{
						x: module.x,
						y: module.y + module.height,
					},
					// bottom right
					{
						x: module.x + module.width,
						y: module.y + module.height,
					},
				];

				modules.push(module);
			}
		}
	});

	let windowEdges = new Object();
	// Add Displayedges
	windowEdges.edges = [
		// middle
		{
			x: 960,
			y: 540,
		},
		// top left
		{
			x: 0 + storeState.user.padding,
			y: 0 + storeState.user.padding,
		},
		// top right
		{
			x: 1920 - storeState.user.padding,
			y: 0 + storeState.user.padding,
		},
		// bottom left
		{
			x: 0 + storeState.user.padding,
			y: 1080 - storeState.user.padding,
		},
		// bottom right
		{
			x: 1920 - storeState.user.padding,
			y: 1080 - storeState.user.padding,
		},
	];
	modules.push(windowEdges);

	if (storeState.dashboard >= 0)
		modules = _getDashboardEdges(storeState, modules);

	return callback({
		targetModule: targetModule,
		modules: modules,
	});
}

function _getNearestXAndY(module, targetModule, callback) {
	let X = {
			distance: 1920,
			x: null,
			middle: false,
		},
		Y = {
			distance: 1080,
			y: null,
			middle: false,
		};

	for (let i = 0; i <= 4; i++) {
		if (Math.abs(targetModule.x - module[i].x) < Math.abs(X.distance)) {
			X.x = module[i].x;
			X.distance = targetModule.x - module[i].x;
			if(i == 0) {
				X.middle = true;
			} else {
				X.middle = false;
			}
		}

		if (Math.abs(targetModule.y - module[i].y) < Math.abs(Y.distance)) {
			Y.y = module[i].y;
			Y.distance = targetModule.y - module[i].y;
			if(i == 0) {
				Y.middle = true;
			} else {
				Y.middle = false;
			}
		}
	}

	callback({
		x: X.x,
		y: Y.y,
		middleX: X.middle,
		middleY: Y.middle,
	});
}

function _getDashboardEdges(storeState, modules) {
	let dashboardEdges = new Array();
	switch (storeState.dashboard) {
		case 0:
			dashboardEdges = [
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 58,
					},
					// top right
					{
						x: 620,
						y: 58,
					},
					// bottom left
					{
						x: 57,
						y: 511,
					},
					// bottom right
					{
						x: 620,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 678,
						y: 58,
					},
					// top right
					{
						x: 1241,
						y: 58,
					},
					// bottom left
					{
						x: 678,
						y: 511,
					},
					// bottom right
					{
						x: 1241,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 1299,
						y: 58,
					},
					// top right
					{
						x: 1862,
						y: 58,
					},
					// bottom left
					{
						x: 1299,
						y: 511,
					},
					// bottom right
					{
						x: 1862,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 569,
					},
					// top right
					{
						x: 620,
						y: 569,
					},
					// bottom left
					{
						x: 57,
						y: 1022,
					},
					// bottom right
					{
						x: 620,
						y: 1022,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 678,
						y: 569,
					},
					// top right
					{
						x: 1241,
						y: 569,
					},
					// bottom left
					{
						x: 678,
						y: 1022,
					},
					// bottom right
					{
						x: 1241,
						y: 1022,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 1299,
						y: 569,
					},
					// top right
					{
						x: 1862,
						y: 569,
					},
					// bottom left
					{
						x: 1299,
						y: 1022,
					},
					// bottom right
					{
						x: 1862,
						y: 1022,
					},
				],
			];
			break;
		case 1:
			dashboardEdges = [
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 58,
					},
					// top right
					{
						x: 620,
						y: 58,
					},
					// bottom left
					{
						x: 57,
						y: 511,
					},
					// bottom right
					{
						x: 620,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 678,
						y: 58,
					},
					// top right
					{
						x: 1862,
						y: 58,
					},
					// bottom left
					{
						x: 678,
						y: 1022,
					},
					// bottom right
					{
						x: 1862,
						y: 1022,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 569,
					},
					// top right
					{
						x: 620,
						y: 569,
					},
					// bottom left
					{
						x: 57,
						y: 1022,
					},
					// bottom right
					{
						x: 620,
						y: 1022,
					},
				],
			];
			break;
		case 2:
			dashboardEdges = [
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 58,
					},
					// top right
					{
						x: 1241,
						y: 58,
					},
					// bottom left
					{
						x: 57,
						y: 1022,
					},
					// bottom right
					{
						x: 1241,
						y: 1022,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 1299,
						y: 58,
					},
					// top right
					{
						x: 1862,
						y: 58,
					},
					// bottom left
					{
						x: 1299,
						y: 511,
					},
					// bottom right
					{
						x: 1862,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 1299,
						y: 569,
					},
					// top right
					{
						x: 1862,
						y: 569,
					},
					// bottom left
					{
						x: 1299,
						y: 1022,
					},
					// bottom right
					{
						x: 1862,
						y: 1022,
					},
				],
			];
			break;
		case 3:
			dashboardEdges = [
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 58,
					},
					// top right
					{
						x: 620,
						y: 58,
					},
					// bottom left
					{
						x: 57,
						y: 511,
					},
					// bottom right
					{
						x: 620,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 678,
						y: 58,
					},
					// top right
					{
						x: 1862,
						y: 58,
					},
					// bottom left
					{
						x: 678,
						y: 511,
					},
					// bottom right
					{
						x: 1862,
						y: 511,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 57,
						y: 569,
					},
					// top right
					{
						x: 1241,
						y: 569,
					},
					// bottom left
					{
						x: 57,
						y: 1022,
					},
					// bottom right
					{
						x: 1241,
						y: 1022,
					},
				],
				[
					// middle
					{
						x: null,
						y: null,
					},
					// top left
					{
						x: 1299,
						y: 569,
					},
					// top right
					{
						x: 1862,
						y: 569,
					},
					// bottom left
					{
						x: 1299,
						y: 1022,
					},
					// bottom right
					{
						x: 1862,
						y: 1022,
					},
				],
			];
			break;
	}
	dashboardEdges.forEach(edge => {
		modules.push({
			edges: edge,
		});
	});
	return modules;
}
