export function _transform(save, override) {
	save.transform = Object.assign({}, save.transform || {}, override);

	const transform = [];

	for(const key in save.transform) {
		transform.push(key + '(' + save.transform[key] + ')');
	}

	return transform.join(' ');
}


/**
 * Edit values and place it in a Template
 *
 * e.g.
 *   var obj = {
 *     template: "Meine #{xyz} Farben sind: #{colors}",
 *     xyz:    "favorit",
 *     colors: "red, green and blue"
 *   }
 *
 *   var output = execTemplate(obj, function(val) {
 *     return toGerman(val);
 *   }, "template");
 *
 *   output => Meine lieblings Farben sind rot, grün und blau
 *
 * @param  {Object}   obj      The Object, must contain the tpl key
 * @param  {String}   tpl      The template key in obj
 * @param  {Function} edit     To edit the values
 * @return {String}            Returns the edited values includet in the
 *                             template
 */
export function _execTemplate(obj, edit = val => val, tpl = 'tpl') {
	return obj[tpl].replace(/#{(.*?)}/g, (a, x) => {
		return edit(obj[x]);
	});
}

export function _darker(rgba) {
	return {
		r: parseInt(rgba.r * 0.7),
		g: parseInt(rgba.g * 0.7),
		b: parseInt(rgba.b * 0.7),
		a: rgba.a
	}
}

/**
 * Build a DOMElement from a template string
 * @param   {String}     template The template to base on
 * @returns {DomElement}
 */
export function _elementFromTemplate(template) {
	const wrapper = document.createElement('template');
	wrapper.innerHTML = template;

	return wrapper.content.firstElementChild || wrapper.content.firstChild;
}

export function _calc(calc, sign = ',', decimals = 2, nsep = '') {
	decimals = parseInt(decimals);

	// prepare for calc
	calc = calc.replace(/\s/g, '');
	calc = calc.replace(/,/g, '.');

	// calc
	calc = averageCalc(calc);

	// format output
	calc = Math.round(calc * Math.pow(10, decimals)) / Math.pow(10, decimals);

	let calcs = calc.toString().replace('.', sign);
	let ta = calcs.split(sign);
	let calcn = ta[0], calcm = ta[1] || '0'.repeat(decimals);
	let calcna = [];
	for(var i = calcn.length - 1; i >= 0; i -= 3) {
		calcna.unshift(
			(calcn[i - 2] || '') + '' +
			(calcn[i - 1] || '') + '' +
			(calcn[i])
		);
	}
	return calcna.join(nsep) + (decimals > 0 ? sign : '') + calcm;
}

function averageCalc(calc) {
	let m;
	let regex;

	regex = /[\(]{1}([^\(]*?)[\)]{1}/;
	while((m = regex.exec(calc)) !== null) {
		calc = calc.replace(m[0], averageCalc(m[1]));
	}

	regex = /[.\d]+[\*\/]{1}[.\d]+/;
	while((m = regex.exec(calc)) !== null) {
		calc = calc.replace(m[0], simpleCalc(m[0]));
	}

	regex = /[.\d]+[%]{1}[.\d]+/;
	while((m = regex.exec(calc)) !== null) {
		calc = calc.replace(m[0], simpleCalc(m[0]));
	}

	regex = /[.\d]+[+-]{1}[.\d]+/;
	while((m = regex.exec(calc)) !== null) {
		calc = calc.replace(m[0], simpleCalc(m[0]));
	}

	return calc;
}

function simpleCalc(calc) {
	if(calc.indexOf('+') !== -1) {
		calc = calc.split('+');
		return parseFloat(calc[0]) + parseFloat(calc[1]);
	}
	if(calc.indexOf('-') !== -1) {
		calc = calc.split('-');
		return parseFloat(calc[0]) - parseFloat(calc[1]);
	}
	if(calc.indexOf('*') !== -1) {
		calc = calc.split('*');
		return parseFloat(calc[0]) * parseFloat(calc[1]);
	}
	if(calc.indexOf('/') !== -1) {
		calc = calc.split('/');
		return parseFloat(calc[0]) / parseFloat(calc[1]);
	}
	if(calc.indexOf('%') !== -1) {
		calc = calc.split('/');
		return parseFloat(calc[0]) / parseFloat(calc[1]);
	}
	return calc;
}

/**
 * Decodes HTML content
 * @param  {String} html Content which will get decoded
 * @return {String}      decoded HTML content
 */
export function _decodeHtml(html) {
	let txt = document.createElement('textarea');
	txt.innerHTML = html;
	return (txt.value);
}

/**
 * Check value on min and max
 * @param  {int}    value the value
 * @param  {Number} min   min value
 * @param  {Number} max   max value
 * @return {int}          the checked value
 */
export function _validateMinMax(value, min = 0, max = 100) {
	if(!value && value !== 0)
		return min;

	value = parseFloat(value);
	if(value < min) return min;
	if(value > max) return max;
	return value;
}

/**
 * Squeeze text (transforms 'foooooobar' to 'fooo…bar' for a limit of 8)
 * @param   {String}  text   The string to squeeze
 * @param   {Number}  limit  The absolute limit
 * @returns {String}         The squeezed text
 */
export function _squeezeText(text, limit = 16) {
	if(!text || text.length <= limit) return text;

	const half = --limit >> 1;
	return text.substr(0, limit - half) + '…' + text.substr(-half);
}

export function _getMax(array, evalFn = a => a) {
	let max = 0;
	array.forEach(item => {
		const compare = evalFn(item);
		if(compare > max) {
			max = compare;
		}
	});
	return max;
}

export function _getMin(array, evalFn = a => a) {
	let min = Infinity;
	array.forEach(item => {
		const compare = evalFn(item);
		if(compare < min) {
			min = compare;
		}
	});
	return min;
}

export function _getAvg(array, evalFn = a => a) {
	let sum = 0;
	array.forEach(item => {
		const compare = evalFn(item);
		sum += compare;
	});
	return sum / array.length;
}

export function Defer() {
	const defer = {};
	defer.promise = new Promise((resolve, reject) => {
		defer.resolve = resolve;
		defer.reject = reject;
	});
	return defer;
}

export function _loadScript(path) {
	const defer = Defer();

	// Adding the script tag to the head
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = path;

	// Then bind the event to the callback function.
	// There are several events for cross browser compatibility.
	script.onreadystatechange = script.onload = defer.resolve;

	// Fire the loading
	document.head.appendChild(script);

	return defer.promise;
}

export function _getLocation(uri) {
	const l = document.createElement('a');
	l.href = uri;
	return l;
}


export function _getPosition(module, screensize) {
	let x = module.x;
	let y = module.y;

	switch(x) {
		case 'left':
			x = 0;
			break;

		case 'center':
			x = (screensize.width - module.width) / 2;
			break;

		case 'right':
			x = screensize.width - module.width;
			break;
	}

	switch(y) {
		case 'top':
			y = 0;
			break;

		case 'center':
			y = (screensize.height - module.height) / 2;
			break;

		case 'bottom':
			y = screensize.height - module.height;
			break;
	}

	return { x, y };
}

export function _last(array) {
	return array[array.length - 1];
}

export function _pipe(v, ...fns) {
	return fns.reduce((v, fn) => fn(v), v);
}


export function _loadImage(path) {
	const defer = Defer();

	const img = document.createElement('img');
	img.onload = () => defer.resolve(img);
	img.src = path;

	return defer.promise;
}

export function _exists(value) {
	return value !== null && value !== undefined;
}

export function _evalDefault(value, defaultVal) {
	return value !== null && value !== undefined ? value : defaultVal;
}

export function _isArray(arr) {
	return Array.isArray(arr);
}

export function _isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]'
}

export function _deepCopy(obj) {
	let copy = _isArray(obj) ? [] : {};
	for(let key in obj) {
		copy[key] = _isObject(obj[key]) || _isArray(obj[key])
			? _deepCopy(obj[key])
			: obj[key];
	}
	return copy;
}

export function _rotate(deg, x, y, width, height) {
	while(deg < 0) {
		deg += 360;
	}

	deg %= 360;

	if(deg > 45 && deg < 135 || deg > 225 && deg < 315) {
		x += (width - height) / 2;
		y += (height - width) / 2;
		[width, height] = [height, width];
	}

	return {
		x,
		y,
		width,
		height
	}
}

export function _initFullScreen(el, listener) {
	const browsers = [
		{
			element: 'fullscreenElement',
			request: 'requestFullScreen',
			event: 'fullscreenchange',
		},
		{
			element: 'webkitFullscreenElement',
			request: 'webkitRequestFullScreen',
			event: 'webkitfullscreenchange',
		},
		{
			element: 'mozFullScreenElement',
			request: 'mozRequestFullScreen',
			event: 'mozfullscreenchange',
		},
		{
			element: 'msFullscreenElement',
			request: 'msRequestFullScreen',
			event: 'msfullscreenchange',
		},
	];

	for(let i = 0; i < browsers.length; i++) {
		const b = browsers[i];
		if(!el.ownerDocument[b.element] && el[b.request]) {
			el.addEventListener(b.event, _ => {
				listener({ isFullScreen: !!el.ownerDocument[b.element] });
			});
		}
	}
}

export function _toggleFullScreen(el) {
	const browsers = [
		{
			element: 'fullscreenElement',
			request: 'requestFullScreen',
			exit: 'exitFullscreen',
		},
		{
			element: 'webkitFullscreenElement',
			request: 'webkitRequestFullScreen',
			exit: 'msExitFullscreen',
		},
		{
			element: 'mozFullScreenElement',
			request: 'mozRequestFullScreen',
			exit: 'mozCancelFullScreen',
		},
		{
			element: 'msFullscreenElement',
			request: 'msRequestFullScreen',
			exit: 'webkitExitFullscreen',
		},
	];

	for (let i = 0; i < browsers.length; i++) {
		const b = browsers[i];
		if(!el.ownerDocument[b.element] && el[b.request]) {
			el[b.request]();
		}
		else if(el.ownerDocument[b.element] && el.ownerDocument[b.exit]) {
			el.ownerDocument[b.exit]();
		}
	}
}

export function _setValue({ obj, key, val }) {
	// TODO: Implement without eval
	eval(`obj.${key} = val`);
}

export function _equals(a, b) {
	if(
		Object.prototype.toString.call(a)
		!==
		Object.prototype.toString.call(b)
	) return false;

	if(_isArray(a)) {
		if(a.length !== b.length) return false;

		return a.every((_, i) => _equals(a[i], b[i]));
	}

	if(_isObject(a)) {
		for (const key in a) {
			if(a.hasOwnProperty(key)) {
				if(!b.hasOwnProperty(key)) return false;
				if(!_equals(a[key], b[key])) return false;
			}
		}

		return true;
	}

	return a == b;
}


export function _containsVariables(text) {

	const prepare = {};
	let match,
		regex;
	// Surround calcs with span.calc
	// ´calculation´{optional_options}
	regex = /(´|&acute;)(.*?)(´|&acute;)(\{([.,]?[.,]?[\d]?)\})?/g;
	while ((match = regex.exec(text)) !== null) {
		const [needle, _1, calc, _2, _3, options] = match;

		prepare[needle] =
			'<span class="calc">' +
			// Replace # with special char sequenz to prevent var replace
			// within calc
			calc.replace(/#/g, '&~;') +
			'|' +
			(options || ',2') +
			'</span>';
	}

	// Surround vars with span.var
	regex = /#[\w,]+?#/g;
	while ((match = regex.exec(text)) !== null) {
		prepare[match[0]] = `<span class="var">${match[0]}</span>`;
	}
	for (const key in prepare) {
		text = text.split(key).join(prepare[key]);
	}

	text = text.replace(/&~;/g, '#');

	let ifregex = /\{if\|(.*?)\}/gi;
	match = ifregex.exec(text);
	if (match !== null) prepare[match[0]] = match[0];

	if (Object.keys(prepare).length) {
		return true;
	} else {
		return false;
	}
}
