<template>
	<div class="fd-basic-config-view fd-templates-view">
		<h2 class="head">
			<span class="icon">
				<i class="puzzle3"></i>
			</span>
			<span class="separator"></span>
			<span class="title">{{$_('fd.templates.headline')}}</span>
		</h2>
		<div class="body">
			<div class="col">
				<config-item-view
					:item="{
						type: 'Info',
						text: this.$_('fd.templates.placeholder.hint'),
				}"
				/>
				<config-item-view
					:item="{
					type: 'Button',
					label: $_('fd.templates.create.placeholder'),
					events: {
						click: this.createPlaceholder
					}
				}"
				/>

				<div class="scroll-container">
					<div class="scroll-view">
						<group-view :label="$_('fd.templates.group.my.templates')">
							<config-item-view
								v-for="(item, i) of myTemplatesConfig"
								:key="'mytemplates_' + item.type + '_' + i"
								:item="item"
							/>
						</group-view>

						<group-view :label="$_('fd.templates.group.premium.templates')">
							<config-item-view :item="premiumTemplatesList" />
						</group-view>

						<group-view :label="$_('fd.templates.group.free.templates')">
							<config-item-view :item="freeTemplatesList" />
						</group-view>
					</div>
				</div>
			</div>

			<div class="col">
				<config-item-view
					v-for="(item, i) of previewConfig"
					:key="'preview_' + item.type + '_' + i"
					:item="item"
				/>
				<div class="scroll-container">
					<div class="scroll-view preview-list" v-if="currentGroup">
						<config-item-view
							v-for="template of currentGroup.templates"
							:key="template.id"
							:item="{
								type: 'Preview',
								name: template.name,
								thumb: template.thumb,
								badge: template.premium
									? (template.premium.unlocked ? 'checkmark3' : 'star')
									: null
							}"
							:class="{selected: template.id === currentTemplate.id}"
							@click.native="_ => selectTemplate(template)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ConfigItemView from './ConfigItem.vue';
import GroupView from './Group.vue';
import ComplexListItem from '../inputs/ComplexListItem.vue';
import DeleteTemplateModalView from './Modal/DeleteTemplateModal.vue';
import UnlockedTemplateModalView from './Modal/UnlockedTemplateModal.vue';
import { UNIQUE_FILTER } from '../util/Filter.js';
import { _deepCopy, _elementFromTemplate, _loadImage } from '../util/helper.js';

import {
	getTemplate as getPlaceholderTemplate,
	ConfigView as PlaceholderConfigView,
} from '../modules/PlaceholderModuleExtra.vue';

const tempStore = {
	privateTemplateName: '',
};

export default {
	name: 'TemplatesView',
	components: {
		ConfigItemView,
		GroupView,
	},
	data: () => ({
		privateTemplateName: tempStore.privateTemplateName,
		currentGroup: null,
		currentTemplate: null,
		saving: false,
		missingName: false,
	}),
	watch: {
		privateTemplateName(val) {
			tempStore.privateTemplateName = val;
		},
	},
	mounted() {
		for (const key in this.templates.premium) {
			const group = this.templates.premium[key];
			if (group.unlocked) {
				this.selectGroup(group);
				return;
			}
		}
		for (const key in this.templates.free) {
			this.selectGroup(this.templates.free[key]);
			return;
		}
	},
	computed: {
		templates() {
			const templates = this.$store.state.shared.templates;

			const groups = {
				private: [],
				premium: templates
					.filter(template => template.premium)
					.map(template => template.group)
					.filter(UNIQUE_FILTER)
					.reduce((groups, group) => {
						groups[group] = {
							id: group,
							unlocked: true,
							label: this.$_('fd.templates.group.' + group),
							templates: [],
						};
						return groups;
					}, {}),
				free: templates
					.filter(template => !template.premium && template.group !== 'private')
					.map(template => template.group)
					.filter(UNIQUE_FILTER)
					.reduce((groups, group) => {
						groups[group] = {
							id: group,
							label: this.$_('fd.templates.group.' + group),
							templates: [],
						};
						return groups;
					}, {}),
			};

			templates.forEach(template => {
				if (template.group === 'private') {
					groups.private.push(template);
				} else if (template.premium) {
					const group = groups.premium[template.group];
					if (!template.premium.unlocked) {
						group.unlocked = false;
					}
					group.templates.push(template);
				} else {
					const group = groups.free[template.group];
					group.templates.push(template);
				}
			});

			return groups;
		},
		currentPrivateTemplate() {
			return this.templates.private.find(
				template => template.name === this.privateTemplateName
			);
		},
		myTemplatesConfig() {
			return [
				{
					type: 'Info',
					text: this.$_('fd.templates.missingname'),
					visible: this.missingName,
				},
				{
					type: 'List',
					label: this.$_('fd.templates.saved'),
					search: true,
					itemView: ComplexListItem,
					values: this.templates.private.map(template => ({
						key: template.id,
						icon: 'circle-small',
						label: template.name,
						search: template.name,
						value: template,
						actions: [
							{
								icon: 'bin',
								click: _ => this.deletePrivateTemplate(template),
							},
						],
					})),
					events: {
						input: this.selectPrivateTemplate,
					},
				},
				{
					type: 'Text',
					label: this.$_('fd.templates.name'),
					value: this.privateTemplateName,
					events: {
						input: val => (this.privateTemplateName = val),
					},
				},
				{
					type: 'Button',
					label: this.$_(
						'fd.templates.' + (this.currentPrivateTemplate ? 'save' : 'create')
					),
					icon: this.saving ? 'spinner' : null,
					color: this.currentPrivateTemplate ? 'danger' : 'default',
					events: {
						click: this.savePrivateTemplate,
					},
				},
			];
		},
		freeTemplatesList() {
			return {
				type: 'List',
				itemView: ComplexListItem,
				value: this.currentGroup && this.currentGroup.id,
				values: Object.keys(this.templates.free).map(key => {
					const group = this.templates.free[key];
					return {
						key: key,
						icon: 'circle-small',
						label: group.label,
						value: group.id,
					};
				}),
				events: {
					input: id =>
						this.selectGroup(
							Object.values(this.templates.free).find(g => g.id == id)
						),
				},
			};
		},
		premiumTemplatesList() {
			return {
				type: 'List',
				itemView: ComplexListItem,
				value: this.currentGroup && this.currentGroup.id,
				values: Object.keys(this.templates.premium).map(key => {
					const group = this.templates.premium[key];
					return {
						key: key,
						icon: group.unlocked ? 'checkmark3' : 'star',
						label: group.label,
						value: group.id,
					};
				}),
				events: {
					input: id =>
						this.selectGroup(
							Object.values(this.templates.premium).find(g => g.id == id)
						),
				},
			};
		},
		previewConfig() {
			if (!this.currentTemplate) return [];

			return [
				{
					type: 'Preview',
					label: this.$_('fd.templates.preview.config.preview'),
					name: this.currentTemplate.name,
					thumb: this.currentTemplate.thumb,
					badge: this.currentTemplate.premium
						? this.currentTemplate.premium.unlocked
							? 'checkmark3'
							: 'star'
						: null,
				},
				{
					type: 'Button',
					label: this.$_('fd.templates.preview.config.accept'),
					events: {
						click: this.acceptCurrentTemplate,
					},
				},
			];
		},
	},
	methods: {
		async createPlaceholder() {
			if (this.$store.state.type != 'light') {
				const template = getPlaceholderTemplate();
				const mid = await this.$store.dispatch('data/module', template);
				this.$store.commit('activeModules', [mid]);
				this.$store.commit('highlightedModules', []);
				this.$store.dispatch('subNav/components', PlaceholderConfigView);
				this.$root.$emit('initModuleSnapping');
			} else {
				this.$root.$emit('proInformation');
			}
		},
		selectPrivateTemplate(template) {
			this.currentTemplate = template;
		},
		stopSaving() {
			setTimeout(_ => {
				this.saving = false;
			}, 300);
		},
		async savePrivateTemplate() {
			if (this.$store.state.type != 'light') {
				this.saving = true;

				if (this.privateTemplateName == '') {
					this.missingName = true;
					this.stopSaving();
					return;
				}
				this.missingName = false;

				const template = this.currentPrivateTemplate
					? {
							...this.currentPrivateTemplate,
							data: {},
							thumb: await this.createThumb(),
					  }
					: {
							name: this.privateTemplateName,
							premium: false,
							group: 'private',
							data: {},
							thumb: await this.createThumb(),
					  };

				template.data = _deepCopy(this.$store.state.data);

				if (this.currentPrivateTemplate) {
					this.$store
						.dispatch('editTemplate', [template.id, template])
						.then(_ => this.stopSaving());

					const templates = [].concat(this.$store.state.shared.templates);
					const index = templates.findIndex(t => t.name === template.name);
					templates.splice(index, 1, template);

					this.$store.commit('shared/mutation', { templates });
					return;
				}

				template.id = await this.$store.dispatch('createTemplate', [template]);
				this.stopSaving();

				this.$store.commit('shared/mutation', {
					templates: [...this.$store.state.shared.templates, template],
				});
			} else {
				this.$root.$emit('proInformation');
			}
		},
		deletePrivateTemplate(template) {
			this.$store.commit('modal/create', {
				component: DeleteTemplateModalView,
				props: { template },
			});
		},
		selectGroup(group) {
			this.currentGroup = group;
			this.selectTemplate(this.currentGroup.templates[0]);
		},
		selectTemplate(template) {
			this.currentTemplate = template;
		},
		acceptCurrentTemplate() {
			if (this.$store.state.type != 'light') {
				if (
					this.currentTemplate.premium &&
					!this.currentTemplate.premium.unlocked
				) {
					this.$store.commit('modal/create', {
						component: UnlockedTemplateModalView,
						props: { template: this.currentTemplate },
					});
					return;
				}

				this.$store.dispatch(
					'data/replace',
					_deepCopy(this.currentTemplate.data)
				);
				this.$store.commit('dirty');
				this.privateTemplateName = '';
			} else {
				this.$root.$emit('proInformation');
			}
		},

		async createThumb() {
			const thumb = _elementFromTemplate('<canvas></canvas');
			thumb.width = this.$store.state.screensize.width;
			thumb.height = this.$store.state.screensize.height;

			const ctx = thumb.getContext('2d');
			ctx.lineCap = 'round';
			ctx.lineJoin = 'round';
			ctx.lineWidth = '20';

			// Background image
			if (this.$store.state.settings.showBackground) {
				const img = await _loadImage(
					this.$store.state.data.background.image.path
				);
				ctx.drawImage(
					img,
					0,
					0,
					img.width,
					img.height,
					0,
					0,
					thumb.width,
					thumb.height
				);
			} else {
				ctx.rect(0, 0, thumb.width, thumb.height);
				ctx.fill();
				ctx.fillStyle = '#FFFFFF';
				ctx.strokeStyle = '#FFFFFF';
			}

			const moduleDraw = {
				TextModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				ImageModule: async m => {
					const img = await _loadImage(m.props.images[0].path);
					ctx.drawImage(
						img,
						0,
						0,
						img.width,
						img.height,
						m.x,
						m.y,
						m.width,
						m.height
					);
				},
				DrawModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				StyleModule: async m => {
					const img = await _loadImage(m.props.url);

					ctx.drawImage(
						img,
						0,
						0,
						img.width,
						img.height,
						m.x,
						m.y,
						m.width,
						m.height
					);
				},
				WeatherModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				ShapeModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				FeedModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width / 2.6 - 20 - 40, m.y + 10 + 25);

					ctx.moveTo(m.x + 10 + m.width / 2.6 - 20, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width / 1.2 - 20 - 40, m.y + 10 + 25);

					ctx.moveTo(m.x + 10 + m.width / 1.2 - 20, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 25);
					ctx.stroke();
				},
				ChartModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				PieChartModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				MeterModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				VideoModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.font = m.width * 0.4 + 'px icomoon';
					ctx.fillText(
						String.fromCharCode('0xe961'),
						m.x + m.width / 2 - (m.width * 0.4) / 2,
						m.y + m.height / 2 + m.width * 0.18
					);
				},
				CounterModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				iFrameModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				ClockModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				SunModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				CloudModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				CloudsModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				EnergyFlowModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				PlaceholderModule: m => {
					ctx.strokeRect(m.x + 10, m.y + 10, m.width - 20, m.height - 20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
			};

			for (const m of this.$store.state.data.modules) {
				if (!moduleDraw.hasOwnProperty(m.class)) continue;

				await moduleDraw[m.class](m);
			}

			return thumb.toDataURL('image/jpeg', 0.7);
		},
	},
};
</script>

<style lang="scss">
.fd-sub-nav-view.TemplatesView {
	.scroller {
		overflow: hidden;
	}
}
.fd-templates-view {
	flex: 1;
	margin: 0;

	display: flex;
	flex-direction: column;

	& > .body {
		flex: 1;
		display: flex;

		.col {
			flex: 1;
			padding: 0 7px;

			display: flex;
			flex-direction: column;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&:not(:last-child) {
				border-right: 1px solid #333;
			}
		}

		.scroll-container {
			margin: 6px 0;
			flex: 1;
			position: relative;
		}

		.scroll-view {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
		}

		.preview-list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 6px;
			align-content: start;
			align-items: start;

			.Preview {
				padding: 0;
				border: none;
				user-select: none;
				cursor: pointer;

				&.selected {
					border: 2px solid #ddd;
				}
			}
		}
	}
}
</style>

<i18n lang="de_DE">
fd.templates.headline: Templates
fd.templates.create.placeholder: Platzhalterelement erstellen
fd.templates.placeholder.hint: Bitte beachten Sie, dass nur Elemente, die in einer Ebene über dem Platzhalterelement liegen, auf das Platzhalterelement angewendet werden können.

fd.templates.group.my.templates: Eigene Templates
fd.templates.group.premium.templates: Premium-Templates (kostenpflichtig)
fd.templates.group.free.templates: Freie Templates

fd.templates.saved: Suche
fd.templates.name: Templatename
fd.templates.create: Template erstellen
fd.templates.save: Template überschreiben
fd.templates.missingname: Bitte geben Sie einen Templatenamen an!

fd.templates.preview.config.preview: Vorschau
fd.templates.preview.config.accept: übernehmen
</i18n>
