<script>
import GroupModule from './GroupModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import { EXISTS_FILTER } from '../util/Filter.js';
import ComplexListItemView from '../inputs/ComplexListItem.vue';
import * as MODULES from './_MODULES_EXTRA.js';
import {
	_last,
	_deepCopy,
	_isArray,
	_isObject,
} from '../util/helper.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'GroupModule',
	icon: 'make-group',
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate() {
	return {
		animation: [],
		locked: false,
		class: 'GroupModule',
		style: {
			backgroundColor: { r: 0, g: 0, b: 0, a: 0 },
			border: null,
			shadow: null,
			padding: 0,
			opacity: null,
			advanced: null,
		},
		props: {
			childs: []
		},
	}
}

export const ConfigView = {
	name: 'GroupModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		childModules() {
			return this.module.props.childs
				.map(mid =>
					this.$store.state.data.modules.find(m => m.mid == mid)
				)
				.filter(EXISTS_FILTER);
		},
		config() {
			return [{
				type: 'Button',
				label: this.$_('fd.groupmodule.settings.split'),
				events: {
					click: this.ungroup,
				}
			}, {
				type: 'List',
				label: this.$_('fd.groupmodule.settings.list'),
				itemView: ComplexListItemView,
				value: null,
				values: this.childModules.map(m => ({
					label: m.class,
					value: m.mid,
					key: m.mid,
					icon: MODULES[m.class].meta.icon,
					events: {
						mouseover: _ => this.highlight(m.mid),
						mouseleave: _ => this.highlight(),
					},
					actions: [{
						icon: 'cross3',
						click: () => {
							this.commitChange('props', {
								childs: this.module.props.childs.filter(mid => mid != m.mid)
							});
							this.$store.commit('activeModules', [m.mid]);
							this.$store.commit('highlightedModules', []);
							this.$store.dispatch('subNav/components', MODULES[m.class].ConfigView);
						}
					}]
				})),
				events: {
					input: mid => this.setActiveModule(mid)
				}
			}];
		}
	},
	methods: {
		highlight(mid) {
			this.$store.commit('highlightedModules',
				mid
					? {
						modules: [mid],
						forced: true
					}
					: null
			);
		},
		setActiveModule(mid) {
			const module = this.childModules.find(m => m.mid == mid);
			this.$store.commit('activeModules', {
				modules: [module.mid],
				forced: true
			});
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', MODULES[module.class].ConfigView);
		},
		ungroup() {
			this.$store.dispatch('ungroup');
		}
	}
}

export default {
	extends: GroupModule,
	beforeCreate() {
		this.$options.components.ModulesView = require('../components/ModulesExtra.vue').default;
	},
	computed: {
		moduleMoveWatcher() {
			return {
				x: this.module.x,
				y: this.module.y,
			};
		},
		moduleResizeWatcher() {
			return {
				width: this.module.width,
				height: this.module.height,
			};
		},
		borderWatcher() {
			return this.module.style.border;
		},
		paddingWatcher() {
			return this.module.style.padding;
		}
	},
	watch: {
		moduleMoveWatcher(newPos, oldPos) {
			if(!this.$store.state.setHist) {
				this.$store.commit('changeModules', {
					type: 'offset',
					modules: this.module.props.childs,
					values: {
						x: newPos.x - oldPos.x,
						y: newPos.y - oldPos.y,
					}
				});
			}
		},
		moduleResizeWatcher(newSize, oldSize) {
			if(!this.$store.state.setHist) {
				this.$store.commit('changeModules', {
					type: 'offset',
					modules: this.module.props.childs,
					values: {
						width: newSize.width - oldSize.width,
						height: newSize.height - oldSize.height
					}
				});
			}
		},
		borderWatcher(newValue, oldValue) {
			if(!this.$store.state.setHist) {
				this.$store.commit('changeModules', {
					type: 'offset',
					modules: this.module.props.childs,
					values: {
						x: newValue.left.px - oldValue.left.px,
						y: newValue.top.px - oldValue.top.px
					}
				});
			}
		},
		paddingWatcher(newValue, oldValue) {
			if(!this.$store.state.setHist) {
				this.$store.commit('changeModules', {
					type: 'offset',
					modules: this.module.props.childs,
					values: {
						x: newValue - oldValue,
						y: newValue - oldValue
					}
				});
			}
		}
	},
	mounted() {
		this.$store.subscribe((mutation, state) => {
			// If this module will be deleted, then delete childs
			if(
				mutation.type === 'deleteModules'
				&&
				mutation.payload.some(mid => mid === this.module.mid)
			) {
				this.$store.commit('deleteModules', this.module.props.childs);
			}

			// If some child will be highlighted/active, remove from the list
			if(
				(
					mutation.type === 'highlightedModules'
					||
					mutation.type === 'activeModules'
				)
				&&
				(
					_isObject(mutation.payload)
					||
					(
						_isArray(mutation.payload)
						&&
						mutation.payload.some(mid =>
							this.module.props.childs.includes(mid)
						)
					)
				)
			) {
				if(mutation.payload.forced) return;

				this.module.props.childs.forEach(mid => {
					const i = mutation.payload.indexOf(mid);

					if(i < 0) return;

					mutation.payload.splice(i, 1);
				})
			}
		});

		this.$store.subscribeAction((action, state) => {
			if(action.type !== 'duplicateModules') return;

			const payload = action.payload || state.activeModules;

			if(payload.every(mid => mid != this.module.mid)) return;

			// Cancel this duplication by filtering payload
			const index = payload.indexOf(this.module.mid);
			payload.splice(index, 1);

			// Call own duplication
			this.duplicate();
		});
	},
	methods: {
		async duplicate() {
			// Duplicate all childs first
			const childs = await this.$store.dispatch(
				'duplicateModules',
				this.module.props.childs
			);

			// Then make new group
			const groupModule = await this.$store.dispatch('group', childs);

			// Waiting for engine set default styles
			await this.$nextTick();

			// Set style
			this.$store.commit('changeModules', {
				type: 'style',
				modules: [groupModule],
				values: _deepCopy(this.module.style)
			});

			this.$store.commit('activeModules', [
				...this.$store.state.activeModules,
				groupModule
			]);
			this.$store.commit('highlightedModules', []);
		}
	},

	// extra
	meta,
	getTemplate,
	ConfigView,
}
</script>

<style lang="scss">
.fd-group-module .fd-module:not([data-active]) {
  pointer-events: none;
}
.fd-group-module .fd-decorator {
	pointer-events: none !important;

	& > * {
		display: none !important;
	}
}
</style>

<i18n lang="de_DE">
fd.groupmodule.title: Gruppe
fd.groupmodule.settings.split: Gruppe auflösen
fd.groupmodule.settings.list: Gruppe
</i18n>
