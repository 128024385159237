const state = {
	id: null,
	type: 'pro',
	subType: null,

	// required
	el: null,
	mode: null,
	web_public: false,
	timeformat: 1,

	location: {
		country: '',
		city: '',
		lat: '',
		lng: ''
	}
}

function throwMissingRequired(state) {
	for(const key of ['el', 'mode']) {
		if(!state[key]) {
			throw new Error(`Missing required field '${key}' in state!`);
		}
	}
}

function handleRootEl(state) {
	if(typeof state.el == 'string') {
		state.el = document.querySelector(state.el);
	}

	state.el.classList.add('fd-instance');
}

function init(config) {
	throwMissingRequired(config);
	handleRootEl(config);

	const _config = {};
	for(const key in state) {
		if(!config[key]) continue;
		_config[key] = config[key];
	}

	return {
		...state,
		..._config
	};
}

function handleActions(actions) {
	const handeledActions = {};
	for(const key in actions) {
		handeledActions[key] = (_, args) => actions[key](...args);
	}
	return handeledActions;
}

export async function create(config) {
	return {
		state: init(config),

		actions: handleActions(config.actions)
	}
}
