var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fd-basic-config-view fd-templates-view"},[_c('h2',{staticClass:"head"},[_vm._m(0),_vm._v(" "),_c('span',{staticClass:"separator"}),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$_('fd.templates.headline')))])]),_vm._v(" "),_c('div',{staticClass:"body"},[_c('div',{staticClass:"col"},[_c('config-item-view',{attrs:{"item":{
					type: 'Info',
					text: this.$_('fd.templates.placeholder.hint'),
			}}}),_vm._v(" "),_c('config-item-view',{attrs:{"item":{
				type: 'Button',
				label: _vm.$_('fd.templates.create.placeholder'),
				events: {
					click: this.createPlaceholder
				}
			}}}),_vm._v(" "),_c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"scroll-view"},[_c('group-view',{attrs:{"label":_vm.$_('fd.templates.group.my.templates')}},_vm._l((_vm.myTemplatesConfig),function(item,i){return _c('config-item-view',{key:'mytemplates_' + item.type + '_' + i,attrs:{"item":item}})})),_vm._v(" "),_c('group-view',{attrs:{"label":_vm.$_('fd.templates.group.premium.templates')}},[_c('config-item-view',{attrs:{"item":_vm.premiumTemplatesList}})],1),_vm._v(" "),_c('group-view',{attrs:{"label":_vm.$_('fd.templates.group.free.templates')}},[_c('config-item-view',{attrs:{"item":_vm.freeTemplatesList}})],1)],1)])],1),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._l((_vm.previewConfig),function(item,i){return _c('config-item-view',{key:'preview_' + item.type + '_' + i,attrs:{"item":item}})}),_vm._v(" "),_c('div',{staticClass:"scroll-container"},[(_vm.currentGroup)?_c('div',{staticClass:"scroll-view preview-list"},_vm._l((_vm.currentGroup.templates),function(template){return _c('config-item-view',{key:template.id,class:{selected: template.id === _vm.currentTemplate.id},attrs:{"item":{
							type: 'Preview',
							name: template.name,
							thumb: template.thumb,
							badge: template.premium
								? (template.premium.unlocked ? 'checkmark3' : 'star')
								: null
						}},nativeOn:{"click":function($event){return (function (_) { return _vm.selectTemplate(template); })($event)}}})})):_vm._e()])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"puzzle3"})])}]

export { render, staticRenderFns }