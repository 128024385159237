<template>
	<div class="fd-style-module">
		<img class="background" :src="module.props.url" />
		<div class="wrapper" :style="wrapperStyle">
			<component class="fd-module-inner"
				v-if="module.props.child"
				:is="module.props.child.class"
				:module="module.props.child"
				:class="childClass"
			></component>
		</div>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import CONFIG from '../Config.js';
import * as MODULES from '../modules/_MODULES.js';
import { _loadImage } from '../util/helper.js';

export const IMAGES = [
	{
		label: 'Post it',
		value: CONFIG.BASEPATH + '/img/style/Post-it.png',
		size: [16,17,16,19],
		restriction: 'TextModule'
	}, {
		label: 'Polaroid',
		value: CONFIG.BASEPATH + '/img/style/Polaroid.png',
		size: [8,12,25,10],
		restriction: 'ImageModule'
	}, {
		label: 'High squared',
		value: CONFIG.BASEPATH + '/img/style/Paper-01.png',
		size: [15,11,14,11],
		restriction: 'TextModule'
	}, {
		label: 'High blank',
		value: CONFIG.BASEPATH + '/img/style/Paper-02.png',
		size: [21,11,18,11],
		restriction: 'TextModule'
	}, {
		label: 'Post it 2',
		value: CONFIG.BASEPATH + '/img/style/Paper-03.png',
		size: [27,14,14,12],
		restriction: 'TextModule'
	}, {
		label: 'Square lined',
		value: CONFIG.BASEPATH + '/img/style/Paper-04.png',
		size: [18,20,22,21],
		restriction: 'TextModule'
	}, {
		label: 'Square lined (small)',
		value: CONFIG.BASEPATH + '/img/style/Paper-04-small.png',
		size: [11,9,10,12],
		restriction: 'TextModule'
	}, {
		label: 'Square blank',
		value: CONFIG.BASEPATH + '/img/style/Paper-05.png',
		size: [18,17,23,18],
		restriction: 'TextModule'
	}, {
		label: 'Square blank (small)',
		value: CONFIG.BASEPATH + '/img/style/Paper-05-small.png',
		size: [12,10,10,13],
		restriction: 'TextModule'
	}, {
		label: 'Crosswise blank',
		value: CONFIG.BASEPATH + '/img/style/Paper-06.png',
		size: [38,14,21,16],
		restriction: 'TextModule'
	}
];

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if(props.child === true) {
		props.child = false;
	}
}

export default {
	name: 'StyleModule',
	extends: BaseModule,
	normalize,
	components: { ...MODULES },
	computed: {
		childClass() {
			if(!this.module.props.child) return null;
			if(typeof this.module.props.child.class == 'undefined') return null;
			const shortName = this.module.props.child.class.replace('Module', '').toLowerCase();
			return `fd-${shortName}-module`;
		},
		image() {
			return IMAGES.find(image => this.module.props.url == image.value);
		},
		wrapperStyle() {
			return {
				top:    this.image.size[0] + '%',
				right:  this.image.size[1] + '%',
				bottom: this.image.size[2] + '%',
				left:   this.image.size[3] + '%',
			}
		}
	}
}
</script>

<style lang="scss">
.fd-style-module-root {
	height: auto !important;
}

.fd-style-module {
	.background {
		width: 100%;
		display: block;
	}

	.wrapper {
		position: absolute;
	}
}
</style>
