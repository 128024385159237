<script>
import PieChartModule from './PieChartModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import { getConfig as getFontFamilyConfig } from '../styles/FontFamily.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'PieChartModule',
	icon: 'pie-chart2',
	datasource: true
}

export const MetaDescriptionView = {
	name: 'PieChartMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
}

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store, $_) {
	return {
		animation: [],
		locked: false,
		class: 'PieChartModule',
		x: 'center',
		y: 'center',
		width: 800,
		height: 500,
		style: {
			backgroundColor: {
				r: 255, g: 255, b: 255, a: 100
			},
			padding: 30,
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			datasource: null,
			type: 0,
			consumptiondirectDesc: $_('fd.piechartmodule.settings.desc.consumptiondirect'),
			consumptiondirectColor: {
				r: 255, g: 192, b: 1, a: 100
			},
			batteryoutDesc: $_('fd.piechartmodule.settings.desc.batteryout'),
			batteryoutColor: {
				r: 51, g: 102, b: 1, a: 100
			},
			gridinDesc: $_('fd.piechartmodule.settings.desc.gridin'),
			gridinColor: {
				r: 89, g: 89, b: 89, a: 100
			},
			batteryinDesc: $_('fd.piechartmodule.settings.desc.batteryin'),
			batteryinColor: {
				r: 120, g: 182, b: 0, a: 100
			},
			gridoutDesc: $_('fd.piechartmodule.settings.desc.gridout'),
			gridoutColor: {
				r: 191, g: 191, b: 191, a: 100
			},
			enableLabels: true,
			innersize: 0,
			font: '"PF Din Text", Arial, Helvetica, sans-serif',
			fontsize: 16
		},
	}
}

export const ConfigView = {
	name: 'PieChartModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		datasources() {
			return this.$store.state.shared.datasources;
		},
		config() {
			const datasources = [
				{
					label: this.$_('fd.chartmodule.settings.datasource.choose'),
					value: 0
				},
				...this.datasources
					.filter(d => d.type == 0)
					.map(d => ({
						label: d.name,
						value: d.id,
					}))
			];

			return [{
				type: 'Select',
				label: this.$_('fd.chartmodule.settings.datasource'),
				value: this.module.props.datasource,
				values: datasources,
				events: {
					input: this.setDatasource
				}
			}, {
				type: 'Select',
				label: this.$_('fd.piechartmodule.settings.type'),
				value: this.module.props.type,
				values: [{
					label: this.$_('fd.piechartmodule.settings.type.autarky'),
					value: 0
				}, {
					label: this.$_('fd.piechartmodule.settings.type.selfconsumption'),
					value: 1
				}],
				events: {
					input: this.setType
				}
			}, {
				type: 'Text',
				label: this.$_('fd.piechartmodule.settings.desc.consumptiondirect'),
				value: this.module.props.consumptiondirectDesc,
				events: {
					input: this.setConsumptiondirectDesc
				}
			}, {
				type: 'Color',
				label: this.$_('fd.piechartmodule.settings.color'),
				value: this.module.props.consumptiondirectColor,
				events: {
					input: this.setConsumptiondirectColor
				}
			}, {
				type: 'Text',
				label: this.$_('fd.piechartmodule.settings.desc.batteryout'),
				value: this.module.props.batteryoutDesc,
				events: {
					input: this.setBatteryoutDesc
				}
			}, {
				type: 'Color',
				label: this.$_('fd.piechartmodule.settings.color'),
				value: this.module.props.batteryoutColor,
				events: {
					input: this.setBatteryoutColor
				}
			}, {
				type: 'Text',
				label: this.$_('fd.piechartmodule.settings.desc.gridin'),
				value: this.module.props.gridinDesc,
				events: {
					input: this.setGridinDesc
				}
			}, {
				type: 'Color',
				label: this.$_('fd.piechartmodule.settings.color'),
				value: this.module.props.gridinColor,
				events: {
					input: this.setGridinColor
				}
			}, {
				type: 'Text',
				label: this.$_('fd.piechartmodule.settings.desc.batteryin'),
				value: this.module.props.batteryinDesc,
				events: {
					input: this.setBatteryinDesc
				}
			}, {
				type: 'Color',
				label: this.$_('fd.piechartmodule.settings.color'),
				value: this.module.props.batteryinColor,
				events: {
					input: this.setBatteryinColor
				}
			}, {
				type: 'Text',
				label: this.$_('fd.piechartmodule.settings.desc.gridout'),
				value: this.module.props.gridoutDesc,
				events: {
					input: this.setGridoutDesc
				}
			}, {
				type: 'Color',
				label: this.$_('fd.piechartmodule.settings.color'),
				value: this.module.props.gridoutColor,
				events: {
					input: this.setGridoutColor
				}
			}, {
				type: 'Boolean',
				label: this.$_('fd.piechartmodule.settings.enablelabels'),
				value: this.module.props.enableLabels,
				events: {
					input: this.setEnableLabels
				}
			}, {
				type: 'Number',
				label: this.$_('fd.piechartmodule.settings.innersize'),
				value: this.module.props.innersize,
				events: {
					input: this.setInnerSize
				}
			}, {
				...getFontFamilyConfig(this.$store, this.$_),
				label: this.$_('fd.chartmodule.settings.font'),
				value: this.module.props.font,
				events: {
					input: this.setChartFont
				}
			}, {
				type: 'Number',
				label: this.$_('fd.chartmodule.settings.fontsize'),
				value: this.module.props.fontsize,
				events: {
					input: this.setFontSize
				}
			}];
		}
	},
	methods: {
		setDatasource(datasource) {
			this.commitChange('props', { datasource });
		},
		setType(type) {
			this.commitChange('props', { type });
		},
		setConsumptiondirectDesc(consumptiondirectDesc) {
			this.commitChange('props', { consumptiondirectDesc });
		},
		setConsumptiondirectColor(consumptiondirectColor) {
			this.commitChange('props', { consumptiondirectColor });
		},
		setBatteryoutDesc(batteryoutDesc) {
			this.commitChange('props', { batteryoutDesc });
		},
		setBatteryoutColor(batteryoutColor) {
			this.commitChange('props', { batteryoutColor });
		},
		setGridinDesc(gridinDesc) {
			this.commitChange('props', { gridinDesc });
		},
		setGridinColor(gridinColor) {
			this.commitChange('props', { gridinColor });
		},
		setBatteryinDesc(batteryinDesc) {
			this.commitChange('props', { batteryinDesc });
		},
		setBatteryinColor(batteryinColor) {
			this.commitChange('props', { batteryinColor });
		},
		setGridoutDesc(gridoutDesc) {
			this.commitChange('props', { gridoutDesc });
		},
		setGridoutColor(gridoutColor) {
			this.commitChange('props', { gridoutColor });
		},
		setEnableLabels(enableLabels) {
			this.commitChange('props', { enableLabels });
		},
		setInnerSize(innersize) {
			this.commitChange('props', { innersize });
		},
		setChartFont(font) {
			this.commitChange('props', { font });
		},
		setFontSize(fontsize) {
			this.commitChange('props', { fontsize });
		},
	}
}

export default {
	extends: PieChartModule,

	computed: {
		updateDataWatcher() {
			return this.highchartsData;
		},
		updateEnableLabelsWatcher() {
			return this.module.props.enableLabels;
		},
		updateInnerSizeWatcher() {
			return this.module.props.innersize;
		},
		updateChartFontWatcher() {
			return this.module.props.font;
		},
		updateFontSizeWatcher() {
			return this.module.props.fontsize;
		},
		reflowWatcher() {
			return this.module.width + this.module.height;
		}
	},
	watch: {
		updateDataWatcher() {
			if(!this.$options.chart) return;

			this.$options.chart.series[0].update({
				data: this.highchartsData
			});
		},
		updateEnableLabelsWatcher(enable) {
			this.$options.chart.update({
				plotOptions: { pie: { dataLabels: {
					enabled: enable
				}}}
			});
		},
		updateInnerSizeWatcher(innersize) {
			this.$options.chart.update({
				plotOptions: { pie: {
					innerSize: innersize + '%'
				}}
			});
		},
		updateChartFontWatcher(font) {
			this.$options.chart.update({
				plotOptions: { pie: { dataLabels: {
					style: {
						fontFamily: font
					}
				}}}
			});
		},
		updateFontSizeWatcher(fontsize) {
			this.$options.chart.update({
				plotOptions: { pie: { dataLabels: {
					style: {
						fontSize: fontsize + 'px'
					}
				}}}
			});
		},
		reflowWatcher() {
			this.$options.chart.reflow();
		}
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
}
</script>

<i18n lang="de_DE">
fd.piechartmodule.title: Pie-Chart
fd.piechartmodule.description: >
	Hier können Sie eine Ihren Autarkiegrad oder Ihren Eigenverbrauchsanteil von
	einer Ihrer Datenquellen als Pie-Chart einfügen.
fd.piechartmodule.add: Pie-Chart hinzufügen

fd.piechartmodule.settings.type: Typ
fd.piechartmodule.settings.type.autarky: Autarkiegrad
fd.piechartmodule.settings.type.selfconsumption: Eigenverbrauchsanteil

fd.piechartmodule.settings.desc.consumptiondirect: Direktverbrauch
fd.piechartmodule.settings.desc.batteryout: Batteriebezug
fd.piechartmodule.settings.desc.gridin: Netzbezug
fd.piechartmodule.settings.desc.batteryin: Batterieladung
fd.piechartmodule.settings.desc.gridout: Netzeinspeisung
fd.piechartmodule.settings.color: Farbe

fd.piechartmodule.settings.enablelabels: Labels
fd.piechartmodule.settings.innersize: Innere Größe
</i18n>
