function init() {
	return {
		willBe: 'alive',
		timestamp: 0,
		scale: 1,
	}
}

export async function create() {
	return {
		namespaced: true,

		state: init(),

		mutations: {
			destroy(state) {
				state.willBe = 'destroyed';
			},
			updateTimestamp(state) {
				state.timestamp = Date.now() / 1000 | 0;
			},
			scale(state, scale) {
				state.scale = scale;
			}
		},

		actions: {
			startTimestampInterval({ commit, state }) {
				clearInterval(this.timestampInterval);

				this.timestampInterval = setInterval(_ => {
					if(state.willBe == 'destroyed') {
						clearInterval(this.timestampInterval);
						return;
					}

					commit('updateTimestamp');
				}, 1000);
			},

			stopTimestampInterval() {
				clearInterval(this.timestampInterval);
			}
		}
	}
}
