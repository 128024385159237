<template>
	<div class="fd-feed-module">
		<div :class="{marqee: items.length}" :style="marqeeStyle">

			<template v-if="!items.length">
				Loading ...
			</template>

			<template v-else>
				<template v-for="(item, i) of items">

					<template v-if="typeof item == 'string'">
						<span :key="i"> {{item}}</span>
					</template>

					<template v-else-if="module.props.onlyTitles || !item.desc">
						<strong :key="i"> +++  {{item.title}}</strong>
					</template>

					<template v-else>
						<strong :key="i"> +++  {{item.title}}: </strong>{{item.desc}}
					</template>

				</template>
			</template>

		</div>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import CONFIG from '../Config.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if(!props.text) {
		props.text = '';
	}
}

export default {
	name: 'FeedModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			running: false,
			marqeeWidth: 0,
			items: [],
		}
	},
	computed: {
		marqeeStyle() {
			const style = {};

			if(this.running && this.items.length) {
				style.animationDuration = this.marqeeWidth / (this.module.props.speed * 2) + 's';
				style.paddingLeft = '100%';
			}

			return style;
		},
		loadItemsWatcher() {
			return this.module.props.uri || this.module.props.text;
		}
	},
	watch: {
		loadItemsWatcher() {
			this.loadItems();
		}
	},
	mounted() {
		this.loadItems();
	},
	methods: {
		loadItems() {
			(
				!this.module.props.uri
					? Promise.resolve([this.module.props.text || 'N/A'])
					: getFeedContent(this.module.props.uri).then(parseXMLToItems)
			)
				.then(items => this.items = items)
				.then(_ => this.calcMarquee())
				.catch(console.error);
		},
		async calcMarquee() {
			await this.$nextTick();
			this.marqeeWidth = this.$el.firstChild.offsetWidth
		},
		start() {
			this.running = true;
		},
		stop() {
			this.running = false;
		}
	}
}

function getFeedContent(uri) {
	if(!uri)
		return Promise.reject('No URI!');

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', CONFIG.BASEPATH + '/lib/lib.rss.php' + '?t=' + new Date().getTime());
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.addEventListener('load', (e) => {
			if(xhr.responseXML && xhr.responseXML.documentElement)
				return resolve(xhr.responseXML.documentElement);

			if(DOMParser) {
				try {
					let xmlFeed = new DOMParser().parseFromString(xhr.responseText, "text/xml");
					let parsererror = xmlFeed.querySelector('parsererror').textContent;

					console.warn('Error while parsing the Feed, the Parser alerts:', parsererror.substr(0, parsererror.indexOf('\n')));

					resolve(xmlFeed.documentElement);
				}
				catch(e) {
					reject(e);
				}
			}
		});
		xhr.addEventListener('error', (e) => {
			reject(e);
		});
		xhr.send('url='+encodeURIComponent(uri));
	});
}

function parseXMLToItems(xml) {
	const type = {
		rss: {
			items: 'channel',
			item: 'item',
			title: 'title',
			desc: 'description'
		},

		feed: {
			items: null,
			item: 'entry',
			title: 'title',
			desc: 'summary'
		}
	}[xml.tagName];

	const items = [];
	if(type) (xml.querySelector(type.items) || xml).querySelectorAll(type.item).forEach(node => {
			const titleNode = node.querySelector(type.title);
			const descNode = node.querySelector(type.desc);

			items.push({
				title: titleNode ? titleNode.innerHTML.trim() : null,
				desc: descNode ? descNode.innerHTML.trim() : null
			});
		});

	return items;
}
</script>

<style lang="scss">
@keyframes marquee {
	0% { transform: translateX(0); }
	100% { transform: translateX(-100%); }
}

.fd-feed-module-root {
	height: auto !important;
}

.fd-feed-module {
	display: block !important;
	overflow: hidden;

	.marqee {
		position: relative;
		white-space: nowrap;
		float: left;
		animation: marquee 0s linear infinite;
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
</style>
