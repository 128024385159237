<template>
	<div class="fd-meter-module">
		<!-- default meter -->
		<div class="fd-meter-module-default" :class="classes" :style="style" v-show="tachoDesign == 'default'">

			<div v-show="tachoStyle == 'current'">
				<div v-for="(value, key) of getCurrentSkala" :key="key">
					<div v-bind:class="`meter-scala meter-scala-${key}`" v-bind:style="`color:${fontColor};`">{{ value }}kW</div>
				</div>
			</div>

			<div v-show="tachoStyle == 'dailyincome'">
				<div v-for="(value, key) of getDailyincomeSkala" :key="key">
					<div v-bind:class="`meter-scala meter-scala-${key}`" v-bind:style="`color:${fontColor};`">{{ value }}kWh</div>
				</div>
			</div>

			<div v-show="tachoStyle == 'dailyset'">
				<div class="meter-scala meter-scala-0" v-bind:style="`color:${fontColor};`">0%</div>
				<div class="meter-scala meter-scala-1" v-bind:style="`color:${fontColor};`">50%</div>
				<div class="meter-scala meter-scala-2" v-bind:style="`color:${fontColor};`">100%</div>
			</div>

			<svg class="tacho-bg" viewBox="0 0 500 250" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<radialGradient spreadMethod="pad" :id="gradientId">
						<stop offset="0.85" :stop-color="color"/>
						<stop offset="1" :stop-color="darkerColor"/>
					</radialGradient>
				</defs>
				<g>
					<ellipse rx="248" ry="248" cx="250" cy="250" fill="rgb(224, 223, 221)"/>
					<ellipse rx="235" ry="235" cx="250" cy="250" :fill="'url(#' + gradientId + ')'"/>
					<ellipse rx="140" ry="140" cx="250" cy="250" fill="rgb(224, 223, 221)"/>
					<ellipse rx="125" ry="125" cx="250" cy="250" fill="#fff"/>
					<ellipse rx="100" ry="100" cx="250" cy="250" fill="#fff" stroke="rgb(186, 186, 186)" stroke-width="15" stroke-dasharray="2,18"/>
				</g>
			</svg>

			<svg class="tacho-fg" viewBox="0 0 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<filter id="gaussian">
						<feGaussianBlur in="Source" stdDeviation="3" result="blur"></feGaussianBlur>
					</filter>
				</defs>
				<g style="transformOrigin: 250px 250px; transition: all 0.5s;" :style="needleStyleDefault">
					<path d="m250,249l0,-200l15,200l-15,0z" fill="rgb(186, 186, 186)"/>
					<path d="m250,249l0,-200l-15,200l15,0z" fill="rgb(238, 238, 238)"/>
					<ellipse rx="30" ry="30" cx="250" cy="250" fill="rgb(150, 150, 150)" filter="url(#gaussian)"/>
					<ellipse rx="30" ry="30" cx="250" cy="250" fill="rgb(150, 150, 150)"/>
				</g>
			</svg>

		</div>
		<!-- analogous meter -->
		<div class="fd-meter-module-ov" :class="classes" :style="style" v-show="tachoDesign == 'ov'">

			<div v-show="tachoStyle == 'current'">
				<div v-for="(value, key) of getCurrentSkala" :key="key">
					<div v-bind:class="`meter-scala meter-scala-${key}`" v-bind:style="`color:${fontColor};`">{{ value }}</div>
				</div>
				<h1 class="meter-scala meter-kw" v-bind:style="{color: fontColor, fontSize: unitSize}">kW</h1>
			</div>

			<div v-show="tachoStyle == 'dailyincome'">
				<div v-for="(value, key) of getDailyincomeSkala" :key="key">
					<div v-bind:class="`meter-scala meter-scala-${key}`" v-bind:style="`color:${fontColor};`">{{ value }}</div>
				</div>
				<h1 class="meter-scala meter-kwh" v-bind:style="{color: fontColor, fontSize: unitSize}">kWh</h1>
			</div>

			<div v-show="tachoStyle == 'dailyset'">
				<div class="meter-scala meter-scala-0" v-bind:style="`color:${fontColor};`">0</div>
				<div class="meter-scala meter-scala-1" v-bind:style="`color:${fontColor};`">25</div>
				<div class="meter-scala meter-scala-2" v-bind:style="`color:${fontColor};`">50</div>
				<div class="meter-scala meter-scala-3" v-bind:style="`color:${fontColor};`">75</div>
				<div class="meter-scala meter-scala-4" v-bind:style="`color:${fontColor};`">100</div>
				<h1 class="meter-scala meter-percent" v-bind:style="{color: fontColor, fontSize: unitSize}">%</h1>
			</div>

			<svg class="tacho-fg" viewBox="0 125 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<filter id="gaussian">
						<feGaussianBlur in="Source" stdDeviation="3" result="blur"></feGaussianBlur>
					</filter>
				</defs>
				<g style="transformOrigin: 250px 250px; transition: all 0.5s;" :style="needleStyleOV">
					<path d="m250,249l0,-85l12,85l-15,0z" :fill="darkerColor"/>
					<path d="m250,249l0,-85l-12,85l15,0z" :fill="color"/>
					<ellipse rx="18" ry="18" cx="250" cy="251" fill="rgb(150, 150, 150)" filter="url(#gaussian)"/>
					<ellipse rx="18" ry="18" cx="250" cy="251" fill="rgb(73, 71, 77)"/>
				</g>
			</svg>

		</div>

	</div>
</template>

<script>
import { _darker } from '../util/helper.js';
import BaseModule from './BaseModule.vue';
import { compute as getColor } from '../styles/Color.js';
import StyleModuleVue from './StyleModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'MeterModule',
	extends: BaseModule,
	normalize,
	data() {
		return {
			date: new Date(),
			gradientId: 'gradient-' + Math.random().toString(16).substr(2),
			needleOffsetDefault: 0,
			needleOffsetOV: 30,
			kwhpkwp: 890,
			sollMonth: [2, 6, 9, 11, 11, 13, 13, 12, 10, 6, 4, 3]
		}
	},
	beforeMount() {
		if (typeof this.module.props.tachoDesign === 'undefined') this.module.props.tachoDesign = 'default';
		if (typeof this.module.props.tachoStyle === 'undefined') this.module.props.tachoStyle = 'dailyset';
		if (typeof this.module.props.fontColor === 'undefined') this.module.props.fontColor = {default: {r: 255, g: 255, b: 255, a: 50}, ov: {r: 0, g: 0, b: 0, a: 100}};
	},
	computed: {
		datasources() {
			const datasources = this.$store.state.shared.datasources;
			if(!datasources || !datasources.length) return;

			return this.module.props.datasources.map(datasource => {
				let data = datasources.find(d => d.id == datasource.id);
				if(typeof data != 'undefined') {
					return data;
				} else {
					this.module.props.datasources[0].id = datasources[0].id;
					return datasources[0];
				}
			}).filter(Boolean);
		},
		classes() {
			return [this.module.props.skala ? 'show-skala' : 'hide-skala'];
		},
		style() {
			return {
				fontSize: this.module.width / 500 * 24 + 'px'
			}
		},
		unitSize() {
			return this.module.width / 500 * 40 + 'px';
		},
		tachoDesign() {
			return this.module.props.tachoDesign;
		},
		tachoStyle() {
			return this.module.props.tachoStyle;
		},
		color() {
			return getColor(this.module.props.color).color;
		},
		darkerColor() {
			return getColor(_darker(this.module.props.color)).color;
		},
		fontColor() {
			let fontColor;

			if (this.module.props.tachoDesign == 'default') {
				fontColor = this.module.props.fontColor.default;
			} else if (this.module.props.tachoDesign == 'ov') {
				fontColor = this.module.props.fontColor.ov;
			}

			return getColor(fontColor).color;
		},
		needleStyleDefault() {
			let value = 0;

			if (this.module.props.tachoStyle == 'current') {
				value = this.currentValue;
			} else if (this.module.props.tachoStyle == 'dailyincome') {
				value = this.dailyIncomeValue;
			} else if (this.module.props.tachoStyle == 'dailyset') {
				value = this.value;
			}
			return {
				transform: `rotate(${180 * value - 90 + this.needleOffsetDefault}deg)`
			}
		},
		needleStyleOV() {
			let value = 0;

			if (this.module.props.tachoStyle == 'current') {
				value = this.currentValue;
			} else if (this.module.props.tachoStyle == 'dailyincome') {
				value = this.dailyIncomeValue;
			} else if (this.module.props.tachoStyle == 'dailyset') {
				value = this.value;
			}

			return {
				transform: `rotate(${244 * value - 90 - this.needleOffsetOV}deg)`
			}
		},
		value() {
			if(!this.datasources.length) return 0;

			let target = 0;
			let actual = 0;

			this.datasources.forEach(datasource => {
				target += datasource.wp || 0;
				actual += Object.values(datasource.data.raw).pop() || 0;
			});

			return target && actual/target;
		},
		getCurrentSkala() {
			if (!this.datasources.length) return 0;

			let kwp = 0;
			let current_day_data = 0;
			let current_yields = [];

			this.datasources.forEach(datasource => {
				kwp += (datasource.wp / 1000) || 0;
			});

			let j = 0;
			let current_yields_steps = Math.ceil((kwp / 4));
			if (this.tachoDesign == 'default') {

				for (let i = 0; i < 5; i+=2) {
					current_yields[j] = current_yields_steps * i;
					j++;
				}
			} else if (this.tachoDesign == 'ov') {


				for (let i = 0; i < 5; i++) {
					current_yields[i] = current_yields_steps * j;
					j++;
				}
			}

			return current_yields;
		},
		currentValue() {
			if (!this.datasources.length) return 0;

			let kwp = 0;
			let current_yield = 0;
			let current_day = 0;

			this.datasources.forEach(datasource => {
				kwp += (datasource.wp / 1000) || 1;
				current_yield += Object.values(datasource.data.raw).pop() || 0;
				current_day += Object.values(datasource.data.daily).pop() || 0;
			});

			let currentSkala = this.getCurrentSkala;
			let kwh_this_year = this.calc_kwh_this_year(kwp);
			let kwh_per_month = this.calc_kwh_this_month(kwh_this_year);
			let expected_value_this_month = kwh_per_month[this.date.getMonth()] || 1;
			let current_yield_percentage = (1 / (currentSkala[currentSkala.length - 1] * 1000)) * current_yield;

			if (current_yield_percentage > 1) {
				current_yield_percentage = 1;
			}

			return current_yield_percentage;
		},
		getDailyincomeSkala() {
			if (!this.datasources.length) return 0;

			let kwp = 0;
			let current_day = 0;
			let current_day_yields = [];

			this.datasources.forEach(datasource => {
				kwp += (datasource.wp / 1000) || 0;
				current_day += Object.values(datasource.data.daily).pop() || 0
			});

			let kwh_this_year = this.calc_kwh_this_year(kwp);
			let kwh_per_month = this.calc_kwh_this_month(kwh_this_year);
			let expected_value_this_month = kwh_per_month[this.date.getMonth()];
			let expected_yield = (expected_value_this_month / (this.getDaysInMonth((this.date.getMonth()+1), (this.date.getYear()+1900))));

			current_day /= 1000;
			if (current_day > (expected_yield * 0.9)) {
				expected_yield = (current_day / 90) * 100;
			}

			let j = 0;
				let current_day_steps = Math.ceil((expected_yield / 4));
			if (this.tachoDesign == 'default') {

				for (let i = 0; i < 5; i+=2) {
					current_day_yields[j] = current_day_steps * i;
					j++;
				}
			} else if (this.tachoDesign == 'ov') {

				for (let i = 0; i < 5; i++) {
					current_day_yields[i] = current_day_steps * j;
					j++;
				}
			}

			return current_day_yields;
		},
		dailyIncomeValue() {
			if (!this.datasources.length) return 0;

			let kwp = 0;
			let current_day = 0;
			let current_day_percentage = 0;

			this.datasources.forEach(datasource => {
				kwp += (datasource.wp / 1000) || 1;
				current_day += Object.values(datasource.data.daily).pop() || 0;
			});

			let dailySkala = this.getDailyincomeSkala;
			let kwh_this_year = this.calc_kwh_this_year(kwp);
			let kwh_per_month = this.calc_kwh_this_month(kwh_this_year);
			let expected_value_this_month = kwh_per_month[this.date.getMonth()];
			let expected_yield = (expected_value_this_month / (this.getDaysInMonth((this.date.getMonth()+1), (this.date.getYear()+1900))));

			current_day /= 1000;
			if (current_day > (expected_yield * 0.9)) {
				current_day_percentage = 0.9;
			} else {
				current_day_percentage = (1 / dailySkala[dailySkala.length - 1]) * current_day;
			}

			return current_day_percentage;
		}
	},
	methods: {
		start() {
			let direction = 1;

			const drift = () => {
				if (this.module.props.tachoDesign == 'default') {
					const driftDeg = 0.3;

					this.needleOffsetDefault += driftDeg * direction;
					direction *= -1;
				} else if (this.module.props.tachoDesign == 'ov') {
					const driftDeg = 1;

					this.needleOffsetOV += driftDeg * direction;
					direction *= -1;
				}
			}

			this.$options.interval = setInterval(() => drift(), 200);
		},
		stop() {
			clearInterval(this.$options.interval);
			if (this.module.props.tachoDesign == 'default') {
				this.needleOffsetDefault = 0;
			} else if (this.module.props.tachoDesign == 'ov') {
				this.needleOffsetOV = 31;
			}
		},
		calc_kwh_this_month(kwh_this_year) {
			let kwh_per_month = [];

			this.datasources.forEach(datasource => {
				if (typeof datasource.soll_month !== 'undefined') {
					datasource.soll_month.forEach((soll, index) => {
						kwh_per_month[index] = (kwh_this_year / 100) * soll;
					});
				} else {
					this.sollMonth.forEach((soll, index) => {
						kwh_per_month[index] = (kwh_this_year / 100) * soll;
					});
				}
			});

			return kwh_per_month;
		},
		calc_kwh_this_year(kwp) {
			let kwhpkwp = 0;

			this.datasources.forEach(datasource => {
				if (typeof datasource.kwhpkwp !== 'undefined') {
					kwhpkwp = datasource.kwhpkwp * kwp;
				} else {
					kwhpkwp = this.kwhpkwp * kwp;
				}
			});

			return kwhpkwp;
		},
		getDaysInMonth(month, year) {
			return new Date(year, month, 0).getDate();
		},
	}
}
</script>

<style lang="scss">
.fd-meter-module-default {
	.tacho-bg, .tacho-fg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.tacho-fg {
		height: 200%;
		pointer-events: none;
		z-index: 15;
	}

	&.hide-skala .meter-scala {
		display: none;
	}

	.meter-scala {
		position: absolute;
		z-index: 10;
		font-family: "OpenSans", Arial, sans-serif;
	}

	.meter-scala-0 {
		bottom: 0;
		left: 0;
		padding-bottom: 3%;
		width: 25%;
		text-align: center;
	}

	.meter-scala-1 {
		top: 0;
		left: 50%;
		width: 26%;
		margin-left: -13%;
		text-align: center;
		padding: 10% 0;
	}

	.meter-scala-2 {
		bottom: 0;
		right: 0;
		padding-bottom: 3%;
		width: 25%;
		text-align: center;
	}
}

.fd-meter-module-ov {
	background: url('../../img/tacho/tacho.png') no-repeat center center;
	background-size: contain;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.tacho-fg {
		width: 100%;
		height: 200%;
		pointer-events: none;
		position: absolute;
		z-index: 15 !important;
	}

	&.hide-skala .meter-scala {
		display: none;
	}

	.meter-scala {
		position: absolute;
		z-index: 10;
		font-family: "OpenSans", Arial, sans-serif;
	}

	.meter-scala-0 {
		bottom: 0;
		left: 25%;
		width: 25%;
		padding: 14% 0;
		text-align: center;
	}

	.meter-scala-1 {
		top: 0;
		left: 26%;
		width: 25%;
		padding: 15% 0;
		text-align: center;
	}

	.meter-scala-2 {
		top: 0;
		left: 50%;
		width: 26%;
		margin-left: -13%;
		text-align: center;
		padding: 8% 0;
	}

	.meter-scala-3 {
		top: 0;
		left: 0;
		width: 123%;
		padding: 15% 0;
		text-align: center;
	}

	.meter-scala-4 {
		bottom: 0;
		right: 0;
		padding: 14% 0;
		width: 79%;
		text-align: center;
	}
	.meter-kw {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 44%;
		padding-top: 29%;
	}

	.meter-kwh {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 42%;
		padding-top: 29%;
	}

	.meter-percent {
		position: absolute;
		font-family: "OpenSans", Arial, sans-serif;
		font-weight: inherit;
		padding: 0% 46%;
		padding-top: 29%;
	}
}
</style>
