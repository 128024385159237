import { _elementFromTemplate } from './util/helper.js';

export function getLoadingEl() {
  const $store = _global.get('store');
  let loadingEl = $store.state.el.querySelector('#preloader');

  if(!loadingEl) {
    loadingEl = _elementFromTemplate(`
      <div id="preloader">
        <i class="spinner"></i>
      </div>
    `);
    document.body.appendChild(loadingEl);
  }

  return loadingEl;
}

/**
 * Load an image into the browsers cache
 * @param  {String}  path  The path
 */
export function loadImage(path, loadingEl) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve();
    };
    image.onerror = image.onabort = () => {
      reject();
    };
    image.src = path;

    if(!loadingEl.querySelector(`img[src="${path}"]`))
      loadingEl.appendChild(image);
  });
}

/**
 * Load a script
 * @param  {String}  path  The path
 */
export function loadScript(path, loadingEl) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = path;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = script.onload = () => {
      resolve();
    };

    script.onerror = script.onabort = (e) => {
      reject(e);
    };

    // Fire the loading
    document.head.appendChild(script);
  });
}

const loaders = {loadImage, loadScript};

export function load(data, type, loadingEl) {
  if(!loadingEl) {
    loadingEl = getLoadingEl();
  }

  return Promise.all(data.map(
    d => loaders['load' + (type || d.type)](d.path, loadingEl)
  ));
}
