function init() {
	return {
		windows: []
	}
}


export async function create() {
	return {
		namespaced: true,

		state: init(),

		mutations: {
			create(state, { id, component, props }) {
				state.windows.push({
					id,
					component,
					props,
				});
			},
			close(state, id) {
				const index = state.windows.findIndex(window => window.id === id);

				if(index < 0) return;

				state.windows.splice(index, 1);
			}
		}
	}
}
