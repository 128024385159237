<template>
  <div class="fd-custom-animation" :style="style">
		<slot></slot>
	</div>
</template>

<script>
import { Tween, easing } from 'mo-js';
import { _transform } from '../util/helper.js';

export default {
  name: 'Custom',
  icon: 'star',
	props: ['animation'],
  data() {
    return {
      style: {}
    }
  },
  computed: {
    variables() {
      return this.$store.state.shared.variables;
    },
    rawStyle() {
      return '{' + this.animation.progress.split('\n')
        .map(l => l.replace(';', ''))
        .join(',') + '}';
    },
    path() {
      return easing.path(this.animation.path)
    }
  },
	async mounted() {
		if(typeof this.animation.duration == 'undefined') {
			this.animation.duration = 1;
		}
		if(typeof this.animation.delay == 'undefined') {
			this.animation.delay = 0;
    }
		if(typeof this.animation.repeat == 'undefined') {
			this.animation.repeat = 0;
		}
		if(typeof this.animation.path == 'undefined') {
			this.animation.path = 'M0,0 L10,100 L20,100 L30,0 L50,0 L60,100 L70,100 L80,0 L80,0';
    }
		if(typeof this.animation.progress == 'undefined') {
			this.animation.progress = 'scaleX: $progress';
    }
    
    initCustom(this);

		this.$root.$on('start', _ => this.start(true));
    this.$root.$on('stop', _ => this.stop());
		this.$root.$on('start' + this.animation.index, index => { 
			if(index == this.animation.index) {
				this.start(false);
			}
		});
		this.$root.$on('init' + this.animation.index, index => { 
			if(index == this.animation.index) {
				initCustom(this);
			}
		});
		this.$root.$on('update', _ => this.update());

    this.progress(0);
	},
	beforeDestroy() {
		this.stop();
	},
	methods: {
		start(delay) {
			if (delay) {
				this.$options.animation.run();
			} else {
				this.$options.animation.setProp('delay', 0);
				this.$options.animation.run();
				this.$options.animation.setProp('delay', this.animation.delay * 1000);
			}
		},

		stop() {
			this.$options.animation.stop();
    },
		
		update() {
			this.$root.$on('start' + this.animation.index, index => { 
				if(index == this.animation.index) {
					this.start(false) 
				}
			});
			this.$root.$on('init' + this.animation.index, index => { 
				if(index == this.animation.index) {
					initCustom(this);
				}
			});
		},

    progress($progress) {
      const $vars = this.variables;
      const $ratio = 1;
      const style = (function(_style) {
        let style = {};
        try {
          eval('style = ' + _style);
        }
        catch(e) {}
        return style;
      })(this.rawStyle);

      this.style = this.parseStyle(style);
    },

    parseStyle(_style) {
      const transform = {};
      const style = {};
      const transformFunctions = [
        'translateX',
        'translateY',
        'translateZ',
        'scale',
        'scaleX',
        'scaleY',
        'scaleZ',
        'rotate',
        'skewX',
        'skewY'
      ];

      let isTransform = false;

      for(const key in _style) {
        if(transformFunctions.includes(key)) {
          transform[key] = _style[key];
          isTransform = true;
        }

        else
          style[key] = _style[key];
      }

      if(isTransform)
        style.transform = _transform({}, transform);

      return style;
    }
  }
}

function initCustom(self) {
  const progress = (progress) => {
    self.progress(self.path(progress));
  }

  self.$options.animation = new Tween({
    duration: self.animation.duration * 1000,
    delay: self.animation.delay * 1000,
    onUpdate: progress
  });
}
</script>
