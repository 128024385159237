<template>
  <div class="fd-main-nav-view">
    <main-nav-item-view
      class="primary"
      @click="save"
      :icon="icon"
      :icon2="icon2"
      :active="active"
      name="save"
      :hint="getHint('save')"
      @mouseover="mouseOver('save')"
      @mouseleave="mouseOut()"
    />
    <main-nav-item-view
      icon="menu7"
      @click="settings"
      :active="active"
      name="settings"
      :hint="getHint('settings')"
      @mouseover="mouseOver('settings')"
      @mouseleave="mouseOut()"
    />
    <main-nav-item-view
      icon="stack3"
      @click="layers"
      :active="active"
      name="layers"
      :hint="getHint('layers')"
      @mouseover="mouseOver('layers')"
      @mouseleave="mouseOut()"
    />
    <main-nav-item-view
      v-if="this.$store.state.type != 'logo'"
      icon="clapboard"
      @click="animations"
      :active="active"
      name="animations"
      :hint="getHint('animations')"
      @mouseover="mouseOver('animations')"
      @mouseleave="mouseOut()"
    />
    <!-- <main-nav-item-view
    	v-if="this.$store.state.type != 'logo'"
      icon="puzzle3"
      @click="templates"
      :active="active"
      name="templates"
      :hint="getHint('templates')"
      @mouseover="mouseOver('templates')"
      @mouseleave="mouseOut()"
    /> -->

    <div class="separator"></div>

    <main-nav-item-view
      v-for="moduleNavItem of moduleNav"
      :key="moduleNavItem.name"
      :type="$store.state.type"
      :icon="moduleNavItem.icon"
      :active="active"
      :name="moduleNavItem.name"
      :hint="moduleNavItem.hint"
      @click="showModuleDescription(moduleNavItem.description)"
      @contextmenu.prevent="addModule(moduleNavItem.name)"
      @mouseover="mouseOver(moduleNavItem.name)"
      @mouseleave="mouseOut()"
    ></main-nav-item-view>

    <div class="separator"></div>

    <main-nav-item-view
      icon="question3"
      @click="help"
      :active="active"
      name="help"
      :hint="getHint('help')"
      @mouseover="mouseOver('help')"
      @mouseleave="mouseOut()"
    />
  </div>
</template>

<script>
import MainNavItemView from './MainNavItem.vue';
import * as MODULES from '../modules/_MODULES_EXTRA.js';
import SettingsView from './Settings.vue';
import LayerView from './Layer.vue';
import TemplatesView from './Templates.vue';
import HelpView from './Help.vue';
import AnimationConfigView from './AnimationConfig.vue';
import FileChooser from '../components/Modal/FileChooser.vue';

import {
	_last,
	_elementFromTemplate,
	_loadImage
} from '../util/helper.js';

export default {
	name: 'MainNavView',
	components: { MainNavItemView },
	data() {
		return {
			saving: false,
			active: false,
			moduleNav: [
				{
					...MODULES.TextModule.meta,
					description: MODULES.TextModule.MetaDescriptionView,
					hint: this.$_('fd.TextModule.add'),
				},
				{
					...MODULES.ImageModule.meta,
					description: MODULES.ImageModule.MetaDescriptionView,
					hint: this.$_('fd.ImageModule.add'),
				},
				{
					...MODULES.DrawModule.meta,
					description: MODULES.DrawModule.MetaDescriptionView,
					hint: this.$_('fd.DrawModule.add'),
				},
				{
					...MODULES.StyleModule.meta,
					description: MODULES.StyleModule.MetaDescriptionView,
					hint: this.$_('fd.StyleModule.add'),
				},
				{
					...MODULES.WeatherModule.meta,
					description: MODULES.WeatherModule.MetaDescriptionView,
					hint: this.$_('fd.WeatherModule.add'),
				},
				{
					...MODULES.ShapeModule.meta,
					description: MODULES.ShapeModule.MetaDescriptionView,
					hint: this.$_('fd.ShapeModule.add'),
				},
				{
					...MODULES.FeedModule.meta,
					description: MODULES.FeedModule.MetaDescriptionView,
					hint: this.$_('fd.FeedModule.add'),
				},
				{
					name: 'charts',
					icon: 'chart',
					description: [
						MODULES.ChartModule.MetaDescriptionView,
						MODULES.PieChartModule.MetaDescriptionView,
						MODULES.MeterModule.MetaDescriptionView,
					],
					hint: this.$_('fd.chartmodule.add'),
				},
				{
					name: 'more',
					icon: 'menu',
					description: [
						MODULES.VideoModule.MetaDescriptionView,
						MODULES.CounterModule.MetaDescriptionView,
						MODULES.iFrameModule.MetaDescriptionView,
						MODULES.ClockModule.MetaDescriptionView,
						MODULES.SunModule.MetaDescriptionView,
						MODULES.CloudModule.MetaDescriptionView,
						MODULES.CloudsModule.MetaDescriptionView,
						MODULES.EnergyFlowModule.MetaDescriptionView,
					],
					hint: this.$_('fd.moremodules.add'),
				},
			],
		};
	},
	computed: {
		icon() {
			if (this.saving) {
				return 'spinner';
			}

			return 'checkmark3';
		},
		icon2() {
			if (this.$store.state.dirty && !this.saving) {
				return 'notification';
			}

			return '';
		},
	},
	mounted() {
		this.$root.$on('save', _ => this.save());

		if (this.$store.state.type == 'image') {
			this.loadImageModule();
			this.$root.$on('openFileChooser', () => {
				this.loadImageModule();
			});
		} else {
			if (this.$store.state.user.autosave) {
				this.$store.state.interval = setInterval(() => {
					this.save();
				}, 3 * 60 * 1000);
			}
		}
	},
	methods: {
		async save() {
			this.saving = true;

			let state = null;

			setTimeout(() => {
				if (state === 'saved') this.saving = false;
				state = 'minTimeSpinnerShownDone';
			}, 500);

			/* Show thumb for testing
			const thumb = await this.createThumb();
			const w = window.open('');
			w.document.write(`
				<iframe
					width="1920px"
					height="1080px"
					frameBorder="0"
					src="${thumb}"
				></iframe>
			`);
			//*/

			// remove logo_modules in data.modules to prevent saving logo_modules in data.json
			if (this.$store.state.data.logo) {
				for (const [key, module] of Object.entries(
					this.$store.state.data.modules
				)) {
					if (module.logo && typeof module.zIndex !== 'undefined') {
						await this.$store.state.data.modules.splice(0, 1);
					}
				}
			}

			await this.$store.dispatch('saveData', [
				this.$store.state.data,
			]);

			// add logo_modules again to display them
			if (this.$store.state.data.logo) {
				this.$store.state.shared.logo_modules.forEach(logo_module => {
					this.$store.state.data.modules.splice(0, 0, logo_module);
				});
			}

			if (state == 'minTimeSpinnerShownDone') {
				this.saving = false;
			}

			this.$store.commit('clean');
			state = 'saved';
		},
		settings() {
			this.$store.commit('activeModules', []);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', SettingsView);
		},
		layers() {
			this.$store.commit('activeModules', []);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', LayerView);
		},
		async loadImageModule() {
			const template = MODULES['ImageModule'].getTemplate(this.$store, this.$_);
			const mid = await this.$store.dispatch('data/module', template);
			this.$store.commit('activeModules', [mid]);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch(
				'subNav/components',
				MODULES['ImageModule'].ConfigView
			);
		},
		animations() {
			this.$store.commit('activeModules', []);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', AnimationConfigView);
		},
		templates() {
			this.$store.dispatch('subNav/components', TemplatesView);
		},
		mouseOver(name) {
			this.active = name;
		},
		mouseOut() {
			this.active = false;
		},
		getHint(hint) {
			switch (hint) {
				case 'save':
					return this.$_('fd.context.save');
					break;
				case 'settings':
					return this.$_('fd.settings.headline');
					break;
				case 'layers':
					return this.$_('fd.layer.headline');
					break;
				case 'animations':
					return this.$_('fd.animations.headline');
					break;
				case 'templates':
					return this.$_('fd.templates.headline');
					break;
				case 'help':
					return this.$_('fd.helpview.title');
					break;
			}
		},

		showModuleDescription(description) {
			this.$store.dispatch('subNav/components', description);
		},
		async addModule(moduleClass) {
			let add = false;

			this.$store.dispatch('subNav/components', null);

			if (moduleClass != 'charts' && moduleClass != 'more') {
				if (this.$store.state.type == 'light') {
					if (MODULES[moduleClass].meta.type == 'light') {
						add = true;
					} else {
						this.$root.$emit('proInformation');
					}
				} else {
					add = true;
				}
				if (add) {
					const template = MODULES[moduleClass].getTemplate(
						this.$store,
						this.$_
					);
					const mid = await this.$store.dispatch('data/module', template);
					this.$store.commit('activeModules', [mid]);
					this.$store.commit('highlightedModules', []);
					if (moduleClass != 'TextModule') {
						this.$store.dispatch(
							'subNav/components',
							MODULES[moduleClass].ConfigView
						);
					}
					this.$store.commit('setHistory', 'Add Module');
					this.$root.$emit('initModuleSnapping');
				}
			}
		},

		help() {
			this.$store.commit('activeModules', []);
			this.$store.commit('highlightedModules', []);
			this.$store.dispatch('subNav/components', HelpView);
		},

		async createThumb() {
			if (this.$store.state.type == 'logo') {
				return;
			}

			const thumb = _elementFromTemplate('<canvas></canvas');
			thumb.width = this.$store.state.screensize.width;
			thumb.height = this.$store.state.screensize.height;

			const ctx = thumb.getContext('2d');
			ctx.lineCap = 'round';
			ctx.lineJoin = 'round';
			ctx.lineWidth = '20';

			// Background image
			if (this.$store.state.settings.showBackground) {
				const img = await _loadImage(
					this.$store.state.data.background.image.path
				);
				ctx.drawImage(
					img,
					0,
					0,
					img.width,
					img.height,
					0,
					0,
					thumb.width,
					thumb.height
				);
			} else {
				ctx.rect(0, 0, thumb.width, thumb.height);
				ctx.fill();
				ctx.fillStyle = '#FFFFFF';
				ctx.strokeStyle = '#FFFFFF';
			}

			const moduleDraw = {
				TextModule: m => {
					// ctx.strokeRect(m.x+10, m.y+10, m.width-20, m.height-20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 40);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 40);
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 80);
					ctx.lineTo(m.x + 10 + m.width - 20 - 80, m.y + 10 + 80);
					ctx.stroke();
				},
				ImageModule: async m => {
					if (typeof m.props.images[0] != 'undefined') {
						const img = await _loadImage(m.props.images[0].path);
						ctx.drawImage(
							img,
							0,
							0,
							img.width,
							img.height,
							m.x,
							m.y,
							m.width,
							m.height
						);
					}
				},
				FeedModule: m => {
					// ctx.strokeRect(m.x+10, m.y+10, m.width-20, m.height-20);

					ctx.beginPath();
					ctx.moveTo(m.x + 10 + 40, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width / 2.6 - 20 - 40, m.y + 10 + 25);

					ctx.moveTo(m.x + 10 + m.width / 2.6 - 20, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width / 1.2 - 20 - 40, m.y + 10 + 25);

					ctx.moveTo(m.x + 10 + m.width / 1.2 - 20, m.y + 10 + 25);
					ctx.lineTo(m.x + 10 + m.width - 20 - 40, m.y + 10 + 25);
					ctx.stroke();
				},
				VideoModule: m => {
					// ctx.strokeRect(m.x+10, m.y+10, m.width-20, m.height-20);
					ctx.font = m.width * 0.4 + 'px icomoon';
					ctx.fillText(
						String.fromCharCode('0xe961'),
						m.x + m.width / 2 - (m.width * 0.4) / 2,
						m.y + m.height / 2 + m.width * 0.18
					);
				},
			};

			for (const m of this.$store.state.data.modules) {
				if (!moduleDraw.hasOwnProperty(m.class)) continue;

				await moduleDraw[m.class](m);
			}

			try {
				return thumb.toDataURL('image/jpeg', 0.7);
			} catch (err) {
				return;
			}
		},
	},
};
</script>

<style lang="scss">
.fd-main-nav-view {
	background: black;

	display: flex;
	flex-direction: column;

	.separator {
		height: 1px;
		margin: 8px 7px 3px;
		background: #333;
	}
}
</style>

<i18n lang="de_DE">

fd.moremodules.add: Weitere Module hinzufügen

</i18n>
