import {
	normalize as normalizeColor,
	compute as computeColor,
} from './Color.js';

export const KEY = 'border';

export const CONFIG = {
	type: 'Border'
}

export function normalizeBorder(value) {
	return {
		px: value.px || 0,
		style: value.style || 'solid',
		color: normalizeColor(value.color || {})
	}
}

export function normalize(value) {
	value = value || {};

	return {
		top: normalizeBorder(value.top || {}),
		left: normalizeBorder(value.left || {}),
		right: normalizeBorder(value.right || {}),
		bottom: normalizeBorder(value.bottom || {}),
		show: (typeof value.show !== undefined) ? value.show : 0,
	};
}

export function watchHook(newValue, oldValue) {
	return {
		type: 'offset',
		values: {
			x: oldValue.left.px
				- newValue.left.px,
			y: oldValue.top.px
				- newValue.top.px,
			width: 0
				- oldValue.left.px - oldValue.right.px
				+ newValue.left.px + newValue.right.px,
			height: 0
				- oldValue.top.px - oldValue.bottom.px
				+ newValue.top.px + newValue.bottom.px,
		}
	}
}

function getBorder(value) {
	return `${value.px}px ${value.style} ${computeColor(value.color).color}`;
}

export function compute(value) {
	value = normalize(value);
	return {
		borderTop: getBorder(value.top),
		borderRight: getBorder(value.right),
		borderBottom: getBorder(value.bottom),
		borderLeft: getBorder(value.left),
		show: value.show,
	}
}
