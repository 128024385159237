import _CONFIG from '../Config.js';

window.CKEDITOR_BASEPATH = '../../dist/ckeditor/';

require('ckeditor');

// Create a variable widget to create and edit variables
CKEDITOR.plugins.add('customVariable', {
	requires: 'widget',

	init(editor) {
		editor.widgets.add('customVariable', {
			button: 'customVariable',
			template: '<span class="var">#SomeVariable#</span>',
			allowedContent: 'span(!var)',
			requiredContent: 'span(var)',
			upcast: function(element) {
				return element.name == 'span' && element.hasClass('var');
			}
		});
	}
});

// Create a calc widget to create and edit calculations
CKEDITOR.plugins.add('customCalculation', {
	requires: 'widget',

	init(editor) {
		editor.widgets.add('customCalculation', {
			button: 'customCalculation',
			template: '<span class="calc">1+1|.,2</span>',
			allowedContent: 'span(!calc)',
			requiredContent: 'span(calc)',
			upcast: function(element) {
				return element.name == 'span' && element.hasClass('calc');
			}
		});
	}
});

export const CONFIG = {
	forcePasteAsPlainText: true,
	startupFocus: 'end',

	extraPlugins: [
		'divarea',
		'colorbutton',
		'colordialog',
		'font',
		'justify',
		'customVariable',
		'customCalculation',
	].join(','),

	removeButtons: [
		'Subscript',
		'Superscript',
	].join(','),

	removePlugins: [
		'pastetext',
		'pastefromword',
		'wsc',
		'scayt',

		'uploadimage',
		'uploadwidget',

		'link',
		'image',
		'table',
		'tabletools',
		'tableselection',
		'horizontalrule',
		'maximize',
		'blockquote',
		'stylescombo',
		'about',

		// Remove bottom bar
		'resize',
		'elementspath',
	].join(','),

	toolbarGroups: [
		{ name: 'mode' },
		{ name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
		{ name: 'paragraph', groups: ['list', 'indent', 'align'] },
		{ name: 'styles' },
		{ name: 'insert' },
		{ name: 'colors' },
		{ name: 'extra' },
	],

	fontSize_sizes: [
		12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46,
		48, 50, 52, 55, 60, 65, 72, 80, 90, 100, 110, 120, 130, 140, 150, 160,
		170, 180, 190, 200, 220, 240, 260
	].map(size =>
		`${size}/${Math.round(1 / 24 * size * 100) / 100}em`
	).join(';')
}

export function addToolbarButton(editor, options, onClick) {
	const command = options.command || `custom-button-command-${Math.random()}`;

	editor.addCommand(command, {
		exec: onClick
	});

	editor.ui.addButton(command.toUpperCase(), {
		command: command,
		toolbar: 'extra',
		...options
	});
}
