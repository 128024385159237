<script>
import EnergyFlowModule from './EnergyFlowModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'EnergyFlowModule',
	// icon: 'arrow-resize7',
	// icon: 'battery-charging',
	// icon: 'film2',
	// icon: 'power-cord',
	// icon: 'transmission',
	icon: 'tree7',
	resize: 'width',
};

export const MetaDescriptionView = {
	name: 'EnergyFlowMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'EnergyFlowModule',
		x: 'center',
		y: 'center',
		width: 300,
		height: 20,
		style: {
			shadow: null,
			opacity: null,
		},
		props: {
			datasources: [],
			countStyle: 'current',
			manualControl: false,
			manualAnimation: false,
			lineColor: {
				r: 255,
				g: 255,
				b: 255,
				a: 100,
			},
			lineWidth: 2,
			chunkColor: {
				r: 255,
				g: 255,
				b: 0,
				a: 95,
			},
			chunkSize: 10,
			speed: 100,
		},
	};
}

export const ConfigView = {
	name: 'EnergyFlowModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		datasources() {
			return this.$store.state.shared.datasources;
		},
		config() {
			const datasourceSettings = [];

			const datasources = this.datasources.map(d => ({
				label: d.name,
				value: d.id,
			}));

			this.module.props.datasources.forEach((datasource, index) => {
				const n =
					this.module.props.datasources.length > 1 ? ' ' + (index + 1) : '';

				datasourceSettings.push({
					type: 'Select',
					label: this.$_('fd.chartmodule.settings.datasource') + n,
					value: datasource.id,
					values: datasources,
					events: {
						input: this.setDatasource(index),
					},
				});
			});

			return [
				...datasourceSettings,
				{
					type: 'Button',
					label: this.$_('fd.chartmodule.settings.add.datasource'),
					events: {
						click: this.addDatasource,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.chartmodule.settings.remove.datasource'),
					visible: datasourceSettings.length > 0,
					events: {
						click: this.removeDatasource,
					},
				},
				{
					type: 'Select',
					label: this.$_('fd.energyflowmodule.settings.countStyle'),
					visible: datasourceSettings.length > 0,
					value: this.module.props.countStyle,
					values: [
						{
							label: this.$_('fd.energyflowmodule.settings.countStyle.current'),
							value: 'current',
						},
						{
							label: this.$_(
								'fd.energyflowmodule.settings.countStyle.dailyincome'
							),
							value: 'dailyincome',
						},
					],
					events: {
						input: this.setCountStyle,
					},
				},
				{
					type: 'Boolean',
					label: this.$_('fd.energyflowmodule.settings.manualControl'),
					value: this.module.props.manualControl,
					events: {
						input: this.setManualControl,
					},
				},
				{
					type: 'Boolean',
					label: this.$_('fd.energyflowmodule.settings.manualAnimation'),
					visible: this.module.props.manualControl,
					value: this.module.props.manualAnimation,
					events: {
						input: this.setManualAnimation,
					},
				},
				{
					type: 'Color',
					label: this.$_('fd.energyflowmodule.settings.linecolor'),
					value: this.module.props.lineColor,
					events: {
						input: this.setLineColor,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.energyflowmodule.settings.linewidth'),
					value: this.module.props.lineWidth,
					min: 0,
					max: 30,
					events: {
						input: this.setLineWidth,
					},
				},
				{
					type: 'Color',
					label: this.$_('fd.energyflowmodule.settings.chunkcolor'),
					value: this.module.props.chunkColor,
					events: {
						input: this.setChunkColor,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.energyflowmodule.settings.chunksize'),
					value: this.module.props.chunkSize,
					min: 1,
					max: 80,
					events: {
						input: this.setChunkSize,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.energyflowmodule.settings.speed'),
					value: this.module.props.speed,
					min: 0,
					max: 200,
					events: {
						input: this.setSpeed,
					},
				},
			];
		},
	},
	methods: {
		setDatasource(index) {
			return datasourceId => {
				this.commitChange('props', {
					[`datasources[${index}].id`]: datasourceId,
				});
			};
		},
		addDatasource() {
			const datasource = {
				id: this.datasources[0].id,
			};

			this.commitChange('props', {
				datasources: [...this.module.props.datasources, datasource],
			});
		},
		removeDatasource() {
			this.commitChange('props', {
				datasources: this.module.props.datasources.slice(0, -1),
			});
		},
		setCountStyle(countStyle) {
			this.commitChange('props', { countStyle });
		},
		setManualControl(manualControl) {
			this.commitChange('props', { manualControl });
		},
		setManualAnimation(manualAnimation) {
			this.commitChange('props', { manualAnimation });
		},
		setLineColor(lineColor) {
			this.commitChange('props', { lineColor });
		},
		setLineWidth(lineWidth) {
			this.commitChange('props', { lineWidth });
		},
		setChunkColor(chunkColor) {
			this.commitChange('props', { chunkColor });
		},
		setChunkSize(chunkSize) {
			this.commitChange('props', { chunkSize });
		},
		setSpeed(speed) {
			this.commitChange('props', { speed });
		},
	},
};

export default {
	extends: EnergyFlowModule,

	mounted() {
		/** Disable chunks while resize to avoid unevenly spaced chunks */
		this.$root.$on('resizeStart', () => {
			this.resizing = true;
		});
		this.$root.$on('resizeEnd', () => {
			this.resizing = false;
		});
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.energyflowmodule.title: Energiefluss
fd.energyflowmodule.description: >
	Das Ertragslinie-Modul generiert eine animierte Linie anhand von Ertrags-/Verbrauchsdaten.
fd.energyflowmodule.add: Linie hinzufügen

fd.energyflowmodule.settings.manualControl: manuelle Steuerung
fd.energyflowmodule.settings.manualAnimation: Animation aktiv

fd.energyflowmodule.settings.countStyle: Style
fd.energyflowmodule.settings.countStyle.current: Aktuelle Leistung
fd.energyflowmodule.settings.countStyle.dailyincome: Tagesertrag

fd.energyflowmodule.settings.linecolor: Linienfarbe
fd.energyflowmodule.settings.linewidth: Linienstärke
fd.energyflowmodule.settings.chunkcolor: Paketfarbe
fd.energyflowmodule.settings.chunksize: Paketgröße
fd.energyflowmodule.settings.speed: Geschwindigkeit
</i18n>
