<template>
  <modal-window-view
    class="fd-nodatasource-window"
    :id="id"
    :size="550"
    :title="$_('fd.window.nodatasource.header')"
    :buttons="buttons"
  >
    <div class="nodatasource-window">
      <div>
        <div class="head">
          {{$_('fd.window.nodatasource.body')}}
        </div>
        <div class="body">
          <button
            class="fd-button primary"
            @click="closeWindow"
          >{{$_('fd.window.nodatasource.ok')}}</button>
        </div>
        <div class="footer"></div>
      </div>
    </div>
  </modal-window-view>
</template>

<script>
import ModalWindowView from './ModalWindow.vue';

export default {
	name: 'NoDatasourceWindow',
	components: { ModalWindowView },
	props: ['id'],
	data() {
		return {
			buttons: [],
		};
	},
	methods: {
		closeWindow() {
			this.$store.commit('window/close', this.id);
		},
	},
};
</script>

<style lang="scss">
$yellow: #fec107;

.fd-nodatasource-window {
	input,
	select {
		display: block;
		padding: 6px 12px;
		font-size: 14px;
		color: #555;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		width: 100%;
		outline: none;
		margin: 0;
		box-sizing: border-box;
	}

	.window > .body {
		flex-direction: column;

		.nodatasource-window {
			flex: 1;

			.head {
				margin: 10px 25px;
				display: block;
			}

			.body {
				float: right;

				input,
				select {
					font-family: 'Courier New', Consolas, monospace;
				}

				button {
					width: 200px;
					&.primary {
						background: $yellow;
					}
					color: white;
					font-size: 0.9em;
					text-transform: uppercase;
					text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					margin: 10px 10px;
					padding: 10px 55px 10px 55px;
				}
			}
		}
	}
}
</style>

<i18n lang="de_DE">

fd.window.nodatasource.header: Keine Datenquelle zugewiesen!
fd.window.nodatasource.body: Bitte weisen Sie eine Datenquelle zu, um dieses Modul verwenden zu können!
fd.window.nodatasource.ok: OK

</i18n>
