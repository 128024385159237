<template>
  <video class="fd-video-module" :src="src" loop muted></video>
</template>

<script>
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
  name: 'VideoModule',
  extends: BaseModule,
	normalize,
  computed: {
    src() {
      if(this.module.props.usb) {
        return this.$store.state.settings.usbvideopath + this.module.props.usb;
      }
      return this.module.props.uri;
    }
  },
  methods: {
    start() {
      this.play();
    },

    stop() {
      if(this.$el) this.$el.pause();
    },

    play() {
      if(!this.$el) return;

      if(this.$el.readyState == 4) {
        this.$el.oncanplay = null;
        return this.$el.play();
      }

      this.$el.oncanplay = _ => {
        if(this.$el.readyState == 4) {
          this.play();
        }
      };
    }
  }
}
</script>

<style>
.fd-video-module-root .fd-module-wrapper {
  background: black;
}
.fd-video-module {
  object-fit: fill;
}
</style>
