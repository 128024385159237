export const KEY = 'margin';

export const CONFIG = {
	type: 'Number',
	min: 0,
	max: 200
}

export function normalize(value) {
	return value || 0;
}

export function compute(value) {
	return {
		margin: normalize(value) + 'px'
	}
}
