<script>
import SunModule from './SunModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'SunModule',
	icon: 'sun2',
	resize: 1,
};

export const MetaDescriptionView = {
	name: 'SunMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'SunModule',
		x: 'center',
		y: 'center',
		width: 150,
		height: 150,
		style: {
			opacity: null,
			advanced: null,
		},
		props: {
			color: { r: 255, g: 255, b: 255, a: 100 },
			length: 100,
			opacity: 25,
			rotationSpeed: 2,
		},
	};
}

export const ConfigView = {
	name: 'SunModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	computed: {
		config() {
			return [
				{
					type: 'Color',
					label: this.$_('fd.sunmodule.settings.color'),
					value: this.module.props.color,
					events: {
						input: this.setColor,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.sunmodule.settings.length'),
					value: this.module.props.length,
					min: 0,
					max: 200,
					events: {
						input: this.setLength,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.sunmodule.settings.opacity'),
					value: this.module.props.opacity,
					min: 0,
					max: 100,
					events: {
						input: this.setOpacity,
					},
				},
				{
					type: 'Number',
					label: this.$_('fd.sunmodule.settings.rotationSpeed'),
					value: this.module.props.rotationSpeed,
					min: -10,
					max: 10,
					events: {
						input: this.setRotationSpeed,
					},
				},
			];
		},
	},
	methods: {
		setColor(color) {
			this.commitChange('props', { color });
		},
		setLength(length) {
			this.commitChange('props', { length });
		},
		setOpacity(opacity) {
			this.commitChange('props', { opacity });
		},
		setRotationSpeed(rotationSpeed) {
			this.commitChange('props', { rotationSpeed });
		},
	},
};

export default {
	extends: SunModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.sunmodule.title: Sonne
fd.sunmodule.description: >
	Das Sonnen-Modul zeigt eine strahlende Sonne.
fd.sunmodule.add: Sonne hinzufügen

fd.sunmodule.settings.color: Farbe
fd.sunmodule.settings.length: Strahlenlänge
fd.sunmodule.settings.opacity: Strahlenintensität
fd.sunmodule.settings.rotationSpeed: Strahlenrotation
</i18n>
