<template>
	<div class="fd-basic-config-view fd-layer-view">
		<h2 class="head">
			<span class="icon">
				<i class="clapboard"></i>
			</span>
			<span class="separator"></span>
			<span class="title">{{$_('fd.animations.headline')}}</span>
		</h2>
		<div class="body">
			<config-item-view
				v-for="(item, i) of config"
				:key="'settings_' + item.type + '_' + i"
				:item="item"
			/>
		</div>
	</div>
</template>

<script>
import * as ANIMATIONS from '../animations/_ANIMATIONS.js';
import * as ANIMATIONSEXTRA from '../animations/_ANIMATIONS_EXTRA.js';
import BasicConfigView from './BasicConfig.vue';
import ConfigItemView from './ConfigItem.vue';
import ComplexListItemView from '../inputs/ComplexListItem.vue';
import { _deepCopy } from '../util/helper.js';

export default {
	name: 'AnimationSettingsView',
	extends: BasicConfigView,
	components: { ConfigItemView },
	data: () => ({ list: [] }),
	filters: {},
	computed: {
		config() {
			return [
				{
					type: 'List',
					label: this.$_('fd.shapemodule.settings.shapelist'),
					search: true,
					itemView: ComplexListItemView,
					// value: this.module.animation,
					values: this.list,
					events: {
						input: this.setAnimation,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.animations.settings.play.all'),
					events: {
						click: () => this.$root.$emit('startAllAnimations'),
					},
				},
			];
		},
	},
	async mounted() {
		await this.$nextTick();
		await new Promise(r => setTimeout(r, 100));

		for (let key in ANIMATIONS) {
			this.list.push({
				label: this.$_('fd.animation.' + key + '.desc'),
				value: ANIMATIONS[key],
				key: key,
				search: key,
				icon: ANIMATIONS[key].icon,
				actions: [],
			});
		}
	},
	methods: {
		setAnimation(animation) {
			if (this.$store.state.type != 'light') {
				const animationName = animation.name;

				if (typeof this.module.animation == 'undefined') {
					this.module.animation = new Array();
				}

				let index = 1;
				this.$store.state.data.modules.forEach(module => {
					if (module.class != 'WatermarkModule') {
						if (typeof module.animation != 'undefined') {
							index += module.animation.length;
						}
					}
				});
				animation.index = index;
				this.module.animation.push(_deepCopy(animation));
				animation = this.module.animation;
				this.commitChange('animation', { animation });
				this.$store.commit('activeAnimation', index);
				this.$store.commit(
					'activeAnimationIndex',
					this.module.animation.length - 1
				);
				this.$store.dispatch(
					'subNav/components',
					ANIMATIONSEXTRA[animationName]
				);
				this.$store.commit('setHistory', 'animation-add');

				setTimeout(() => {
					this.$root.$emit('showAnimatedModules');
				}, 50);
			} else {
				this.$root.$emit('proInformation');
			}
		},
	},
};
</script>

<style lang="scss">
</style>


<i18n lang="de_DE">
fd.animations.headline: Animationen

fd.animation.bounce.desc: Hüpfen
fd.animation.custom.desc: Custom
fd.animation.fade.desc:   Ein-Ausblenden
fd.animation.fadein.desc: Einblenden
fd.animation.flash.desc:  Blinken
fd.animation.flip.desc:   Flip
fd.animation.floater.desc: Floater
fd.animation.fly.desc:    Fliegen
fd.animation.pulse.desc:  Pulsieren
fd.animation.pulse2.desc: Pulsieren2
fd.animation.roll.desc:   Rollen
fd.animation.rotate.desc: Rotieren
fd.animation.rotate2.desc: Rotieren2
fd.animation.tada.desc:   Tada
fd.animation.wiggle.desc: Wiggle

fd.animations.add:                             Animation
fd.animations.add.desc:                        Animation erstellen
fd.animations.edit:                            Animationen
fd.animations.editor:                          Animationseditor
fd.animations.editor.desc:                     Bewegen Sie die Balken an die gewünschte Position oder ändern Sie die Größe mit Hilfe der linken Maustaste.
fd.animations.noanimations:                    Erstellen Sie eine Animation über das jeweilige Modul.
fd.animations.settings.type:                   hinzufügen
fd.animations.settings.reference:              Referenz
fd.animations.settings.reference.top:          oben
fd.animations.settings.reference.bottom:       unten
fd.animations.settings.reference.left:         links
fd.animations.settings.reference.right:        rechts
fd.animations.settings.reference.topleft:      oben links
fd.animations.settings.reference.topright:     oben rechts
fd.animations.settings.reference.bottomleft:   unten links
fd.animations.settings.reference.bottomright:  unten rechts
fd.animations.settings.direction:              Richtung
fd.animations.settings.direction.in:           hinein
fd.animations.settings.direction.out:          hinaus
fd.animations.settings.mode:                   Modus
fd.animations.settings.mode.easing:            Dynamisch
fd.animations.settings.mode.linear:            Linear
fd.animations.settings.duration:               Dauer
fd.animations.settings.speed:                  Geschwindigkeit
fd.animations.settings.delay:                  Verzögerung
fd.animations.settings.play:                   Abspielen (ohne Verzögerung)
fd.animations.settings.stop:                   Abspielen stoppen
fd.animations.settings.play.all:               Alle Abspielen
fd.animations.settings.delete:                 Löschen
fd.animations.settings.count:                  Zähler


fd.animation.custom.settings.repeat:   Wiederholen
fd.animation.custom.settings.path:     Animations-Pfad
fd.animation.custom.settings.progress: Progress


</i18n>
