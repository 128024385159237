<template>
	<div class="fd-color-input">
		<text-input v-model="input"/>
		<div class="preview">
			<div class="color-picker-arrow" v-if="isPickerVisible"></div>
			<div :style="previewBackgroundStyle" @click.stop="toggleColorPicker"></div>
			<i class="palette" :style="previewForegroundStyle"></i>
		</div>
	</div>
</template>

<script>
import TextInput from './Text.vue';
import { _parseColor, _arrayToRGBA } from '../util/Color.js';

export default {
	name: 'ColorInput',
	props: ['value', 'config'],
	components: { TextInput },
	data() {
		return {
			id: 0,
			input: this.objectToRGBA(this.value),
		}
	},
	watch: {
		input() {
			this.$emit('input', this.arrayToObject(this.normalized));
		},
		colorPickerColor(pickedColor) {
			if(!pickedColor) return;

			this.input = this.arrayToRGBA(pickedColor);
		}
	},
	computed: {
		normalized() {
			return _parseColor(this.input);
		},
		previewForegroundStyle() {
			const alpha = this.normalized[3];
			const offset = 256 * (1 - alpha);
			const hue =
				(this.normalized[0] + offset) * 0.3 +
				(this.normalized[1] + offset) * 0.6 +
				(this.normalized[2] + offset) * 0.1;

			return {
				color: this.arrayToRGBA(hue < 128 ? [255, 255, 255] : [0, 0, 0])
			}
		},
		previewBackgroundStyle() {
			return {
				backgroundColor: this.arrayToRGBA(this.normalized)
			}
		},
		isPickerVisible() {
			return this.$store.state.subNav.colorPicker.visible
				&& this.$store.state.subNav.colorPicker.offset == this.id;
		},
		colorPickerColor() {
			return this.isPickerVisible
				&& this.$store.state.subNav.colorPicker.color;
		}
	},
	methods: {
		objectToRGBA(color) {
			if(color.a === 100) {
				return `rgb(${color.r}, ${color.g}, ${color.b})`;
			}
			return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a / 100})`;
		},
		rgbaToArray(color) {
			return color
				.split('(')[1]
				.split(')')[0]
				.split(',')
				.map(parseFloat)

				// Sometimes color is rgb not rgba, make sure [3] has always a correct
				// value
				.concat([1]);
		},
		arrayToObject(color) {
			return {
				r: color[0],
				g: color[1],
				b: color[2],
				a: Math.round(color[3] * 100),
			}
		},
		arrayToRGBA: _arrayToRGBA,
		toggleColorPicker() {
			this.id = this.$el.getBoundingClientRect().top;
			this.$store.commit('subNav/colorPicker', {
				visible: !this.isPickerVisible,
				offset: this.id,
				color: this.normalized
			});
		}
	}
}
</script>

<style lang="scss">
.fd-color-input {
	display: flex;
	flex: 1;

	.preview {
		position: relative;
		width: 30px;
		margin-left: 3px;
		border: 1px solid #333;
		border-radius: 3px;
		cursor: pointer;

		background-image: url('../img/colorpicker_colorbg.png');

		& > div, & > i {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		& > i {
			top: 1px;
			left: 2px;
			pointer-events: none;
		}

		.color-picker-arrow {
			position: absolute;
			left: initial;
			right: -10px;
			border-style: solid;
			border-width: 10px 10px 10px 0;
			border-color: transparent #fff;
			z-index: 10;
			pointer-events: none;
		}
	}
}
</style>
