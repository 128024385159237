<template>
	<div class="fd-tooltip-view">
		<div class="tooltip-wrapper">
			<div class="tooltip">{{text}}</div>
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'TooltipView',
	props: ['text']
}
</script>

<style lang="scss">
.fd-tooltip-view {
	display: flex;
	position: relative;

	.tooltip-wrapper {
		display: none;
		pointer-events: none;
	}

	&:hover .tooltip-wrapper {
		display: initial;
	}

	.tooltip-wrapper {
		position: absolute;
		bottom: 100%;
		left: 50%;
		width: 200px;
		z-index: 10;

		.tooltip {
			display: inline-block;
			position: relative;
			left: 0;
			bottom: 8px;
			transform: translateX(-50%);
			width: auto;
			max-width: 150px;
			height: auto;

			color: #000;
			background: #fff;
			padding: 5px 7px;
			border: 2px solid #fec107;
			border-radius: 6px;
    	box-shadow: inset 0 0 2px rgba(0,0,0,.5);

			&:after {
				content: '';
				position: absolute;
				left: calc(50% - 10px);
				bottom: -10px;

				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;

				border-top: 10px solid #fec107;
			}
		}
	}
}
</style>
