<template>
	<div class="fd-basic-config-view">
		<h2 class="head">
			<span class="icon"><i :class="icon"></i></span>
			<span class="separator"></span>
			<span class="title">{{this.headline}}</span>
		</h2>
		<div class="body">
			<config-item-view
				v-for="(item, i) of config"
				:key="'settings_' + item.type + '_' + i"
				:item="item"
			/>
		</div>
	</div>
</template>

<script>
import BasicConfigView from './BasicConfig.vue';
import ConfigItemView from './ConfigItem.vue';
import ExportModalView from './Modal/ExportModal.vue';
import SystemFileChooser from '../util/SystemFileChooser.js';
import GridFactory from '../util/grids/GridFactory';
import Grid from '../util/grids/Grid';
import { Defer } from '../util/helper.js';

export default {
	name: 'SettingsView',
	extends: BasicConfigView,
	components: { ConfigItemView },
	computed: {
		icon() {
			return 'wrench';
		},
		headline() {
			return this.$_('fd.settings.headline');
		},
		config() {
			let gridValues = [
						{
							label: this.$_('fd.settings.dashboard.nogrid'),
							value: -1
						}
			]
			for (let i = 1; i <= 4; i++) {
				const object = {
					label: this.$_('fd.settings.dashboard.grid') + ' ' + i,
					value: -1 + i
				};
				gridValues.push(object)
			}

			return [
				{
					type: 'Text',
					label: this.$_('fd.settings.title'),
					value: this.$store.state.data.title,
					visible: this.$store.state.type != 'logo',
					events: {
						input: this.setSlideTitle
					}
				},
				{
					type: 'File',
					label: this.$_('fd.settings.background'),
					fileTypeKey: 'image',
					allowed: /^image/,
					visible: this.$store.state.type != 'logo',
					value: [this.$store.state.data.background.image],
					events: {
						input: images => this.setBackground(images[0])
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.settings.blur'),
					visible: this.$store.state.type != 'logo',
					value: this.$store.state.data.background.blur,
					events: {
						input: this.setBlur
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.logo'),
					visible: this.$store.state.type != 'logo' && this.$store.state.shared.logo_modules.length,
					value: this.$store.state.data.logo,
					events: {
						input: this.setLogo
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.advanced'),
					visible: this.$store.state.type != 'logo',
					value: this.$store.state.user.advanced,
					events: {
						input: this.setAdvanced
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.animation'),
					visible: this.$store.state.user.advanced,
					value: this.$store.state.data.background.animation,
					events: {
						input: this.setAnimation
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.snap'),
					value: this.$store.state.user.snap,
					events: {
						input: this.setSnap
					}
				},
				{
					type: 'Number',
					label: this.$_('fd.settings.padding'),
					value: this.$store.state.user.padding,
					events: {
						input: this.setPadding
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.grid'),
					value: this.$store.state.user.gridlines,
					events: {
						input: this.setGrid
					}
				},
				{
					type: 'Boolean',
					label: this.$_('fd.settings.autosave'),
					value: this.$store.state.user.autosave,
					events: {
						input: this.setAutosave
					}
				},
				{
					type: 'Select',
					label: this.$_('fd.settings.dashboard'),
					value: this.$store.state.dashboard >= 0 ? this.$store.state.dashboard : -1,
					values: gridValues,
					events: {
						input: this.setDashboard
					}
				},
				{
					type: 'Button',
					label: this.$_('fd.settings.export'),
					visible: this.$store.state.type != 'light' && this.$store.state.type != 'logo',
					events: {
						click: this.export
					}
				},
				{
					type: 'Button',
					label: this.$_('fd.settings.import'),
					visible: this.$store.state.type != 'light' && this.$store.state.type != 'logo',
					events: {
						click: this.import
					}
				},
			];
		}
	},
	methods: {
		setSlideTitle(title) {
			this.$store.commit('setTitle', title)
		},
		setBackground(image) {
			this.$store.commit('changeBackground', { image });
		},
		setBlur(blur) {
			this.$store.commit('changeBackground', { blur });
		},
		setLogo(logo) {
			this.$store.commit('setLogo', { logo });
			if (logo) {
				this.$store.state.shared.logo_modules.forEach(logo_module => {
					this.$store.state.data.modules.splice(0, 0, logo_module);
				});
			} else {
				this.$store.state.shared.logo_modules.forEach(logo_module => {
					let index = this.$store.state.data.modules.indexOf(logo_module);
					if (index > -1) {
						this.$store.state.data.modules.splice(index, 1);
					} else {
						// delete existing logo(s) after reopening
						for (const [key, module] of Object.entries(this.$store.state.data.modules)) {
							if (module.logo && module.zIndex == logo_module.zIndex) {
								this.$store.state.data.modules.splice(0, 1);
							}
						}
					}
				});
			}
		},
		setAdvanced(advanced) {
			this.$store.commit('user/mutation', { advanced });
		},
		setAnimation(animation) {
			this.$store.commit('changeBackground', { animation });
		},
		setSnap(snap) {
			this.$store.commit('user/mutation', { snap });
		},
		setPadding(padding) {
			this.$store.commit('user/mutation', { padding });
		},
		setGrid(gridlines) {
			this.$store.commit('user/mutation', { gridlines });
		},
		setAutosave(autosave) {
			this.$store.commit('user/mutation', { autosave });

			if(this.$store.state.interval) {
				clearInterval(this.$store.state.interval);
			}

			if(autosave) {
				if(this.$store.state.user.autosave) {
					this.$store.state.interval = setInterval(() => {
						this.$root.$emit('save');
					}, 3*60*1000);
				}
			}
		},
		setDashboard(dashboard) {
			this.$store.state.dashboard = dashboard;
			let canvas = document.getElementById('dashboard');

			if(dashboard < 0 || !GridFactory.defaultGridList[dashboard]) {
				return canvas.style.display = 'none';
			}

			canvas.style.display = 'block';
			canvas.width = this.$store.state.screensize.width;
			canvas.height = this.$store.state.screensize.height;

			const gridOptions = GridFactory.defaultGridList[dashboard];
			gridOptions.ratio = this.$store.state.screensize.width/this.$store.state.screensize.height;

			GridFactory.createGrid(gridOptions).draw(canvas);
		},
		export() {
			this.$store.commit('modal/create', {
				component: ExportModalView
			});
		},
		import() {
			// TODO: Make fancy modal
			SystemFileChooser
				.open({
					accept: /application\/json/
				})
				.then(resultList => {
					if(!resultList.length) return;

					const result = resultList[0];
					const defer = Defer();

					result.hooks.dataReady = () => defer.resolve(result.file.data);
					return defer.promise;
				})
				.then(base64 =>
					fetch(base64).then(res => res.text())
				)
				.then(json => {
					try {
						return JSON.parse(json);
					}
					catch (e) {}

					try {
						return JSON.parse(decodeURIComponent(json));
					}
					catch (e) {}

					return Promise.reject(new Error('Failed to parse as JSON: ' + json));
				})
				.then(newData =>
					this.$store.dispatch('data/replace', newData)
				)
				.catch(console.error);
		},
	}
}
</script>

<style lang="scss">

</style>

<i18n lang="de_DE">
fd.settings.headline: Einstellungen
fd.settings.title: Titel
fd.settings.background: Hintergrund
fd.settings.blur: Blur
fd.settings.logo: Logo
fd.settings.advanced: Fortgeschritten
fd.settings.animation: Hintergrund-Zoom
fd.settings.snap: Hilfslinien
fd.settings.padding: Innenabstand
fd.settings.grid: Gitternetz
fd.settings.autosave: Auto. speichern
fd.settings.dashboard: Dashboardgerüst
fd.settings.dashboard.nogrid: keines
fd.settings.dashboard.grid: Gerüst

fd.settings.export: Export
fd.settings.import: Import
</i18n>
