export const KEY = 'textAlign';

const options = ['left', 'center', 'right', 'justify'];

export const CONFIG = {
	type: 'ButtonGroup',
	mode: 'radio',
	values: [
		{
			icon: 'paragraph-left3',
			value: 0,
		},
		{
			icon: 'paragraph-center3',
			value: 1,
		},
		{
			icon: 'paragraph-right3',
			value: 2,
		},
		{
			icon: 'paragraph-justify3',
			value: 3,
		}
	]
}

export function normalize(value) {
	return value || 0;
}

export function compute(value) {
	return {
		textAlign: options[normalize(value)]
	}
}
