<script>
import StyleModule, { IMAGES } from './StyleModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import CONFIG from '../Config.js';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';
import { _deepCopy, _loadImage } from '../util/helper.js';
import * as MODULES from './_MODULES_EXTRA.js';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'StyleModule',
	icon: 'palette',
	resize: 'width',
};

export const MetaDescriptionView = {
	name: 'StyleMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({ meta }),
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	return {
		animation: [],
		locked: false,
		class: 'StyleModule',
		x: 'center',
		y: 'center',
		width: 300,
		height: 279.375,
		style: {
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			url: CONFIG.BASEPATH + '/img/style/Post-it.png',
			child: false,
		},
	};
}

export const description = {
	extends: DescriptionView,
	data() {
		const image = IMAGES.find(image => this.module.props.url == image.value);
		return {
			description: image && image.label,
		};
	},
};

export const ConfigView = {
	name: 'StyleModuleConfigView',
	extends: BasicConfigView,
	data: () => ({ meta }),
	mounted() {
		this.$root.$on('setStyleModuleUrl', url => this.setImage(url));
	},
	computed: {
		pathWatcher() {
			return this.module.props.url;
		},
		config() {
			return [
				{
					type: 'File',
					label: this.$_('fd.stylemodule.settings.style'),
					fileTypeKey: 'image',
					allowed: /^image/,
					style: true,
					visible: 'normal',
					multiselect: false,
					value: [{ path: this.module.props.url }],
					events: {
						input: this.setImage,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.stylemodule.settings.editchild'),
					visible: this.module.props.child,
					events: {
						click: this.editChild,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.stylemodule.settings.separatechild'),
					visible: this.module.props.child,
					events: {
						click: this.separateChild,
					},
				},
			];
		},
	},
	watch: {
		pathWatcher(url) {
			this.setImage(url);
		}
	},
	methods: {
		setImage(url) {
			this.commitChange('props', { url });
			_loadImage(url).then(img => {
				const ratio = img.width / img.height;
				const height = this.module.width / ratio;
				this.commitChange('set', { height });
			});
		},
		editChild() {
			const mid = this.module.mid;
			const child = _deepCopy(this.module.props.child);
			const configView = MODULES[this.module.props.child.class].ConfigView;
			this.$store.dispatch('subNav/components', {
				name: configView.name + '_StyleModuleChild',
				extends: configView,
				data: () => ({ child }),
				computed: {
					module() {
						return this.child;
					},
					layout() {
						return [];
					},
					style() {
						return [];
					},
					other() {
						return [];
					},
				},
				methods: {
					commitChange(type, values) {
						if (type !== 'props') return;

						child.props = {
							...child.props,
							...values,
						};

						this.child = child;

						this.$store.commit('changeModules', {
							type: 'props',
							modules: [mid],
							values: { child },
						});
					},
				},
			});
		},
		async separateChild() {
			const module = _deepCopy(this.module.props.child);
			this.commitChange('props', { child: false });
			const mid = await this.$store.dispatch('data/module', module);
			this.$store.commit('activeModules', [mid]);
			this.$store.dispatch(
				'subNav/components',
				MODULES[module.class].ConfigView
			);
		},
	},
};

export default {
	extends: StyleModule,

	data: () => ({ imageRatio: 1 }),
	computed: {
		updateImageRatioWatcher() {
			return this.module.props.url;
		},
		updateWidthWatcher() {
			return this.module.width;
		},
	},
	watch: {
		updateImageRatioWatcher(url) {
			_loadImage(url).then(img => {
				this.imageRatio = img.width / img.height;
			});
		},
		updateWidthWatcher(width) {
			const height = width / this.imageRatio;
			this.$store.commit('changeModules', {
				type: 'set',
				modules: [this.module.mid],
				values: { height },
			});
		},
	},
	mounted() {
		this.$options.unsubscribe = this.$store.subscribe(this.onMutation);
		this.$root.$emit('setStyleModuleUrl', this.module.props.url);
	},
	beforeDestroy() {
		this.$options.unsubscribe();
	},
	methods: {
		onMutation({ type, payload }, state) {
			if (payload === this.module.mid) return;

			if (type === 'moduleMoveStart') {
				this.someModuleMoveStart(payload);
			}
			if (type === 'moduleMove') {
				this.someModuleMove(payload);
			}
			if (type === 'moduleMoveEnd') {
				this.someModuleMoveEnd(payload);
			}
		},
		getModule(mid) {
			return this.$store.getters.modules[mid];
		},
		someModuleMoveStart(mid) {
			const { width, height } = this.getModule(mid);
			this.$options.module = { width, height };
		},
		someModuleMove(mid) {
			const m = this.getModule(mid);
			if (!m) return;
			if (typeof this.image != 'undefined' && m.class !== this.image.restriction) return;
			if (m.z <= this.module.z) return;

			const self = this.getModule(this.module.mid);
			const { width, height } = this.$options.module;

			this.$store.commit('highlightedModules', self.mid);

			let values = null;

			if (
				// check if module is between x and width
				m.x + width / 2 > self.x + 10 &&
				m.x + width / 2 < self.x + self.width - 10 &&
				// check if module is between y and height
				m.y + height / 2 > self.y + 10 &&
				m.y + height / 2 < self.y + self.height - 10
			) {
				const xOffset = (this.image.size[3] / 100) * self.width;
				const yOffset = (this.image.size[0] / 100) * self.height;
				const wOffset = (this.image.size[1] / 100) * self.width;
				const hOffset = (this.image.size[2] / 100) * self.height;
				values = {
					x: self.x + xOffset,
					y: self.y + yOffset,
					width: self.width - xOffset - wOffset,
					height: self.height - yOffset - hOffset,
				};
			}

			if (
				(m.x + width / 2 < self.x + 10 && m.x + width / 2 > self.x - 10) ||
				(m.x + width / 2 > self.x + self.width - 10 &&
					m.x + width / 2 < self.x + self.width + 10) ||
				(m.y + height / 2 < self.y + 10 && m.y + height / 2 > self.y - 10) ||
				(m.y + height / 2 > self.y + self.height - 10 &&
					m.y + height / 2 < self.y + self.height + 10)
			) {
				values = {
					width: this.$options.module.width,
					height: this.$options.module.height,
				};
			}

			if (!values) return;

			this.$store.commit('changeModules', {
				type: 'set',
				modules: [mid],
				values,
			});
		},
		someModuleMoveEnd(mid) {
			this.$store.commit('highlightedModules', []);

			const m = this.getModule(mid);
			if (!m) return;
			if (typeof this.image != 'undefined' && m.class !== this.image.restriction) return;
			if (m.z <= this.module.z) return;

			const module = this.getModule(this.module.mid);
			const { width, height } = this.$options.module;

			if (
				// check if module is between x and width
				m.x + width / 2 > module.x + 10 &&
				m.x + width / 2 < module.x + module.width - 10 &&
				// check if module is between y and height
				m.y + height / 2 > module.y + 10 &&
				m.y + height / 2 < module.y + module.height - 10 &&
				// check z index
				m.z > module.z
			) {
			} else return;

			const child = _deepCopy(m);
			delete child.mid;
			delete child.z;
			child.width = this.$options.module.width;
			child.height = this.$options.module.height;

			this.$store.commit('deleteModules', [m.mid]);

			this.$store.commit('changeModules', {
				type: 'props',
				modules: [this.module.mid],
				values: { child },
			});

			this.$store.commit('activeModules', [this.module.mid]);
			this.$store.dispatch('subNav/components', ConfigView);
		},
	},

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
};
</script>

<i18n lang="de_DE">
fd.stylemodule.title: Style
fd.stylemodule.description: >
	Mit diesem Element lassen sich Vorlagen und Elemente wie z.B. Notizzettel,
	Post-It, etc. ergänzen.
fd.stylemodule.add: Style hinzufügen

fd.stylemodule.settings.style: Style
fd.stylemodule.settings.editchild: Untermodul bearbeiten
fd.stylemodule.settings.separatechild: Untermodul trennen
</i18n>
