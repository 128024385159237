<script>
import PaneView from './Pane.vue';
import ModulesView from './ModulesExtra.vue';

export default {
	extends: PaneView,
	components: { ModulesView },
	data() {
		return {
			recalculate: 0,
		}
	},
	computed: {
		offset() {
			const {top, left} = this.$el.getBoundingClientRect();

			// Use getter to force recalculate on value change
			if(this.recalculate) this.recalculate = 0;

			return {top, left}
		}
	},
	watch: {
		scale() {
			clearTimeout(this.$options.scaleTimer);
			this.$options.scaleTimer = setTimeout(() => this.recalculate++, 150);
		}
	},
	async mounted() {
		document.addEventListener('mousemove', (evt) => {
			const x = (evt.clientX - this.offset.left) / this.scale;
			const y = (evt.clientY - this.offset.top) / this.scale;

			this.$root.mouseX = Math.min(Math.max(x, 0), this.$store.state.screensize.width);
			this.$root.mouseY = Math.min(Math.max(y, 0), this.$store.state.screensize.height);
		});

		let resizeTimer;
		window.addEventListener('resize', () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => this.recalculate++, 150);
		});
	}
}
</script>
