<template>
  <modal-window-view
    class="fd-variableleft-window"
		:id="id"
    :size="450"
    :title="$_('fd.textmodule.variableleft.head')"
    :buttons="buttons"
  >
    <div class="variableleft-window">
      <div>
        <div class="head">{{$_('fd.textmodule.variableleft.body')}}</div>
        <div class="body">
          <button class="fd-button primary" @click="removeVariables">{{$_('fd.textmodule.variableleft.delete')}}</button>
          <button
            class="fd-button"
            @click="abortRemoveVariable"
          >{{$_('fd.textmodule.variableleft.abort')}}</button>
        </div>
        <div class="footer"></div>
      </div>
    </div>
  </modal-window-view>
</template>

<script>
import ModalWindowView from './ModalWindow.vue';

export default {
	name: 'VariableLeftWindow',
	components: { ModalWindowView },
	props: ['id'],
	data() {
		return {
			buttons: [],
		};
	},
	methods: {
		removeVariables() {
			this.$root.$emit('removeVariables');
			this.$store.commit('window/close', this.id);
		},
		abortRemoveVariable() {
			this.$root.$emit('abortRemoveVariable');
			this.$store.commit('window/close', this.id);
		},
	},
};
</script>

<style lang="scss">
$yellow: #fec107;

.fd-variableleft-window {
	input,
	select {
		display: block;
		padding: 6px 12px;
		font-size: 14px;
		color: #555;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2px;
		width: 100%;
		outline: none;
		margin: 0;
		box-sizing: border-box;
	}

	.window > .body {
		flex-direction: column;

		.variableleft-window {
			flex: 1;

			.head {
				margin: 10px 25px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.body {
				display: flex;
				align-items: center;
				justify-content: center;

				input,
				select {
					font-family: 'Courier New', Consolas, monospace;
				}

				button {
					width: initial;
					&.primary {
						background: $yellow;
					}
					color: white;
					font-size: 0.9em;
					text-transform: uppercase;
					text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					margin: 10px 10px;
					padding: 10px 55px 10px 55px;
				}
			}
		}
	}
}
</style>