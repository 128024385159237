<template>
	<input
		type="text"
		class="fd-number-input"
		v-model="internalValue"
		@keydown.up="up"
		@keydown.down="down"
		:readonly="config.editable === false"
	>
</template>

<script>
export default {
	name: 'NumberInput',
	props: {
		value: {
			type: Number,
			default: 0,
		},
		config: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			internalValue: this.value
		}
	},
	watch: {
		value(newStringValue) {
			newStringValue = "" + newStringValue;
			const newFloatValue = parseFloat(newStringValue.replace(',', '.')) || 0;

			if(this.internalValue === newFloatValue) {
				return;
			}

			if(newFloatValue != ("" + this.internalValue).replace(',', '')) {
				this.internalValue = newFloatValue;
			}
		},
		internalValue(newStringValue) {
			newStringValue = "" + newStringValue;
			let newFloatValue = parseFloat(newStringValue.replace(',', '.')) || 0;

			if(this.internalValue === newFloatValue) {
				return;
			}

			if(newFloatValue != newStringValue.replace(',', '')) {
				this.internalValue = newFloatValue;
			}

			let checkFloatValue = newFloatValue;
			if(this.config.min || this.config.min === 0) {
				checkFloatValue = Math.max(this.config.min, checkFloatValue);
			}

			if(this.config.max) {
				checkFloatValue = Math.min(this.config.max, checkFloatValue);
			}

			if(checkFloatValue !== newFloatValue) {
				this.internalValue = checkFloatValue;
			}
			
			this.$emit('input', checkFloatValue);
		}
	},
	methods: {
		up() {
			this.internalValue = parseFloat(this.internalValue) + parseFloat(this.config.step || 1) + "";
		},
		down() {
			this.internalValue = parseFloat(this.internalValue) - parseFloat(this.config.step || 1) + "";
		},
	}
}
</script>

<style lang="scss">
.fd-number-input {
	width: 100%;

	background: hsla(0, 0%, 100%, .2);
	color: #fff;
	font-family: monospace;
	font-size: 10px;
	padding: 4px 7px;
	outline: none;
	border: 0;
	border-radius: 2px;

	&[readonly] {
		background: none;
		border: 1px solid hsla(0, 0%, 100%, .2);
	}
}
</style>
