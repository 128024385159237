<template functional>
  <button class="fd-main-nav-item-view" :class="data.staticClass" v-on="listeners"
	v-if="props.type != 'logo' || props.name == 'ImageModule' || props.name == 'TextModule'"
  >
    <i :class="props.icon"></i>
    <i class="state" :class="props.icon2"></i>
    <div v-if="props.active==props.name" class="fd-main-nav-item-hint">
      <span class="fd-main-nav-item-hint-text">{{props.hint}}</span>
      <div class="fd-main-nav-item-hint-arrow"></div>
    </div>
  </button>
</template>

<script>
import '../../scss/fonts/icomoon.scss';

export default {
	name: 'MainNavItemView',
	// computed: {
	// 	showItem() {
	// 		console.log(this)
	// 		console.log("jfgkdsnfgksdj")
	// 		return true;
	// 	}
	// }
};
</script>

<style lang="scss">
.fd-main-nav-item-view {
	position: relative;
	margin: 5px 5px 0;
	padding: 5px;

	background: #222;
	color: #fff;
	text-align: center;
	border-radius: 2px;

	outline: none;
	border: none;
	cursor: pointer;

	.fd-main-nav-item-hint {
		position: absolute;
		background-color: rgb(249, 200, 0);
		color: black;
		left: 55px;
		top: 0px;
		width: auto;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		font-weight: bold;
		border-radius: 3px;
		box-shadow: 2px 2px 15px;
		margin: 0 auto;
	}
	.fd-main-nav-item-hint-text {
		float: left;
		height: 24px;
		text-align: center;
		line-height: 24px;
		vertical-align: center;
		white-space: nowrap;
		overflow: hidden;
		padding: 0 5px 0 5px;
	}

	.fd-main-nav-item-hint-arrow {
		border-radius: 2px;
		left: -9px;
		top: 4px;
		position: absolute;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid rgb(249, 200, 0);
	}

	&:hover {
		background: #333;
	}

	&.primary {
		background: #46a046;
	}

	i {
		display: block;
		font-size: 20px;
	}

	i.state {
		position: absolute;
		bottom: 3px;
		right: 3px;
		font-size: 11px;
	}
}
</style>
