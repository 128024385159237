<template>
  <div class="fd-chart-module" :data-source="chart"></div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import Highcharts from 'highcharts';
import { compute as getColor } from '../styles/Color.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	// Fix change to multiple datasources support
	if (props.datasource && !props.datasources.length) {
		props.datasources.push({
			id: props.datasource,
			color: props.chartColor,
			fillOpacity: 0.5,
			showInverse: false,
		});
	}
}

export default {
	name: 'ChartModule',
	extends: BaseModule,
	normalize,
	data: () => ({ isMounted: false }),
	computed: {
		timeFormatter() {
			return new Intl.DateTimeFormat((this.$store.state.timeformat === 2) ? 'en-US' : 'de-DE', { hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Berlin' });
		},
		chart() {
			if(
				!this.isMounted
				||
				!this.datasources.length
				||
				this.$options.chart
			) return -1;

			this.$options.chart = Highcharts.chart(
				this.$el,
				Highcharts.merge(this.defaultChartOptions, this.chartOptions)
			);

			return this.$options.chart.index;
		},
		datasources() {
			const datasources = this.$store.state.shared.datasources;
			if (!datasources.length) return [];

			return this.module.props.datasources
				.map(settings => {
					let datasource = datasources.find(d => d.id == settings.id);
					if (!datasource) {
						this.module.props.datasources[0].id = datasources[0].id;
						datasource = datasources[0];
					}

					return {
						...settings,
						...datasource,
					};
				})
				.filter(Boolean);
		},
		defaultChartOptions() {
			const props = this.module.props;

			return {
				chart: {
					reflow: false,
					backgroundColor: 'rgba(0,0,0,0)',
				},
				title: {
					text: null,
				},
				xAxis: {
					min: this.getChartPeriod()[0],
					max: this.getChartPeriod()[1],
					tickColor: getColor(props.xAxisTickColor).color,
					labels: this.getXAxisLabels(this.$store.state.timeformat, props, this.timeFormatter)
				},
				yAxis: {
					title: {
						text: null,
					},
					gridLineColor: getColor(props.gridColor).color,
					labels: {
						style: {
							color: getColor(props.labelColor).color,
							fontSize: props.fontsize + 'px',
						},
						formatter: function() {
							const units = [
								{
									div: 1e3,
									ext: 'k',
								},
								{
									div: 1e6,
									ext: 'M',
								},
								{
									div: 1e9,
									ext: 'G',
								},
							];

							let div = 1;
							let ext = '';

							units.forEach(u => {
								if (this.axis.tickInterval / u.div >= 1) {
									div = u.div;
									ext = u.ext;
								}
							});

							switch(props.unit) {
								case 'blank':
									return `${this.value / div}`
									break;
								case 'cubicmeter':
									let cbm_gas = this.value / div;
									cbm_gas = cbm_gas * 0.116;
									return `${cbm_gas.toFixed(2)} m³`
									break;
								case 'kilogram':
									let kwh = this.value / div;
									return `${kwh * (670 / 1000)} ${ext}g`
									break;
								case 'ton':
									let ton = this.value / div;
									return `${ton * (670 / 1000000)} t`
									break;
								case 'percentage':
									return `${this.value / div} %`
									break;
								default:
									return `${this.value / div} ${ext}W${
										props.subdata != 'raw' ? 'h' : ''
									}`;
								break;
							}

						},
					},
				},
				legend: {
					enabled: false,
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					enabled: false,
				},
				plotOptions: {
					series: {
						animation: false,
						enableMouseTracking: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
					area: {
						stacking: props.stacked ? 'normal' : null,
					},
					areaspline: {
						stacking: props.stacked ? 'normal' : null,
					},
					line: {
						stacking: props.stacked ? 'normal' : null,
					},
					spline: {
						stacking: props.stacked ? 'normal' : null,
					},
					column: {
						stacking: props.stacked ? 'normal' : null,
						borderWidth: 0,
						pointPadding: 0.1,
						groupPadding: 0.1,
					},
				},
			};
		},
		chartOptions() {
			const series = this.datasources.map(datasource => {
				return {
					color: getColor(datasource.color).color,
					fillOpacity: datasource.fillOpacity,
					marker: {
						enabled: false,
					},
					data: this.toHighchartsDataFormat(datasource),
				};
			});

			return {
				chart: {
					type: this.module.props.type,
				},
				xAxis: {
					type: 'datetime',
				},
				series: series,
			};
		},
	},
	created() {
		if (typeof this.module.props.starttime === 'undefined') {
			this.$set(this.module.props, 'starttime', 4);
		}
		if (typeof this.module.props.endtime === 'undefined') {
			this.$set(this.module.props, 'endtime', 24);
		}
	},
	mounted() {
		Highcharts.setOptions({
			chart: {
				style: {
					fontFamily: this.module.props.font,
				},
			},
		});

		this.isMounted = true;
	},
	beforeDestroy() {
		if(this.$options.chart) {
			this.$options.chart.destroy();
		}
		this.$options.chart = null;
	},
	methods: {
		getChartPeriod() {
			const props = this.module.props;

			const series = this.datasources.map(datasource => {
				return {
					color: getColor(datasource.color).color,
					fillOpacity: datasource.fillOpacity,
					marker: {
						enabled: false,
					},
					data: this.toHighchartsDataFormat(datasource),
				};
			});

			var ts = Date.now();
			if (typeof series[0].data[0] !== 'undefined') {
				ts = series[0].data[0][0];
			}
			var date = new Date(new Date(ts).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
			switch(props.subdata) {
				case "raw":
					date.setHours(props.starttime,0,0,0);
					var start = date.getTime();
					date.setHours(props.endtime - 1,59,59,999);
					var end = date.getTime();
				break;

				case "daily":
					var start = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
					var end = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
				break;

				case "monthly":
					var start = new Date(date.getFullYear(), 0, 1).getTime();
					var end = new Date(date.getFullYear(), 12, 0).getTime();
				break;

				case "yearly":
					var start = new Date(date.getFullYear(), 0, 1).getTime();
					var end = new Date(new Date().getFullYear(), 12, 0).getTime();
				break;

			}
			return [start,end];
		},
		getXAxisLabels(timeformat, props, formatter) {
			const xAxisLabel = {
				autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
				autoRotationLimit: 120,
				style: {
					color: getColor(props.labelColor).color,
					fontSize: props.fontsize + 'px',
				},
				formatter: null,
			};

			if (props.subdata == 'raw') {
				xAxisLabel.formatter = function() {
					const date = new Date(this.value);

					return formatter.format(date);
				};
			}

			return xAxisLabel;
		},
		toHighchartsDataFormat(datasource) {
			if (!datasource.data) return [];

			const subdata = datasource.data[this.module.props.subdata];
			if (!subdata || subdata instanceof Array) return [];

			const data = [];

			const multiplicator = datasource.showInverse ? -1 : 1;
			for (const timestamp in subdata) {
				var value = parseFloat(subdata[timestamp]) * multiplicator;
				if(this.module.props.formula != "") {
					var tmp = this.module.props.formula;
					try {
						value = eval(tmp.replace(/[a-zA-Z]/g, value))
					} catch(e) {}
				}
				data.push([
					timestamp * 1000,
					value,
				]);
			}

			while (
				data.length &&
				data[0][1] == 0 &&
				this.module.props.ignorefirstzeros
			) {
				data.shift();
			}

			return data;
		},
	},
};
</script>

<style>
.fd-chart-module {
	width: calc(100% + 7px) !important;
	height: calc(100% + 14px) !important;
	margin: 0 0 0 -7px;
}
</style>
