<template>
	<modal-view class="fd-delete-template-modal-view"
		:size="550"
		icon="bin"
		:title="$_('fd.delete.template.modal.title')"
		:dialog="true"
		:buttons="buttons"
	>
		<p>
			<strong>{{$_('fd.delete.template.modal.attention')}}</strong>
			{{$_('fd.delete.template.modal.nohistoryback')}}
		</p>
		<p>{{$_('fd.delete.template.modal.content')}}</p>
		<p class="center strong">{{template.name}}</p>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';

export default {
	name: 'DeleteTemplateModalView',
	props: ['template'],
	components: { ModalView },
	data() {
		return {
			buttons: [{
				label: this.$_('fd.delete.template.modal.actions.delete'),
				css: 'attention',
				icon: 'bin',
				click: () => this.deleteTemplate()
			}, {
				label: this.$_('fd.delete.template.modal.actions.cancel'),
				css: 'primary'
			}]
		}
	},
	methods: {
		deleteTemplate() {
			this.$store.dispatch('deleteTemplate', [this.template.id]);

			const templates = [].concat(this.$store.state.shared.templates);
			const index = templates.findIndex(t => t.id === this.template.id);
			templates.splice(index, 1);

			this.$store.commit('shared/mutation', { templates });
		}
	}
}
</script>

<style lang="scss">
.fd-delete-template-modal-view {
	.body {
		flex-direction: column;
	}

	.center {
		text-align: center;
	}

	.strong {
		font-size: larger;
		font-weight: 600;
	}

	button.attention {
		background: red;
	}
}
</style>

<i18n lang="de_DE">
fd.delete.template.modal.title: Template löschen!
fd.delete.template.modal.attention: Achtung!
fd.delete.template.modal.nohistoryback: >
	Dieser Schritt kann nicht rückgängig gemacht werden.
fd.delete.template.modal.content: >
	Sie haben ein Template zum Löschen ausgewählt. Sind Sie sich sicher, dass
	Sie folgendes Template löschen möchten?
fd.delete.template.modal.actions.delete: Ja, löschen!
fd.delete.template.modal.actions.cancel: Abbrechen
</i18n>
