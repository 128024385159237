<script>
import WeatherModule from './WeatherModule.vue';
import BasicConfigView from '../components/BasicConfig.vue';
import ComplexListItemView from '../inputs/ComplexListItem.vue';
import * as Google from '../util/Google.js';
import DescriptionView from '../components/Description.vue';
import BasicFullDescriptionView from '../components/BasicFullDescription.vue';

/**
 * This object stores the meta information about a module
 */
export const meta = {
	name: 'WeatherModule',
	icon: 'weather-cloud-sun',
};

export const MetaDescriptionView = {
	name: 'WeatherMetaDescriptionView',
	extends: BasicFullDescriptionView,
	data: () => ({
		meta,
		city: '',
		search: '',
		ready: false,
		list: [],
	}),
	computed: {
		config() {
			return [{
				type: 'Info',
				text: this.$_('fd.weathermodule.description'),
			}, {
				type: 'List',
				label: this.$_('fd.weathermodule.settings.location'),
				search: this.city || true,
				compare: _ => true,
				itemView: ComplexListItemView,
				value: this.city,
				values: this.list,
				events: {
					search: this.setSearch,
					input: this.setLocation,
				},
			}, {
				type: 'Button',
				label: this.$_('fd.weathermodule.add'),
				events: {
					click: () => this.addModule(),
				},
			}, {
				type: 'Button',
				label: this.$_('fd.weathermodule.special.three_day_preview'),
				events: {
					click: () => this.add3DayPreview(),
				},
			}];
		},
	},
	watch: {
		async search(search) {
			if (!this.ready || !search || search.length < 3 || this.city === search) return;

			let res = await fetch('https://www.meteoblue.com/en/server/search/query3?query=' + search);

			res = await res.json();

			if (Array.isArray(res.results)) {
				this.list = [];
				res.results.forEach(result => {
					if (!res.results.includes(result.key)) {
						this.list.push({
							label: `${result.name}, ${result.admin1}`,
							key: `${result.name}, ${result.admin1}, ${result.id}`,
							value: {
								description: result.name,
								country: result.iso2,
								asl: result.asl,
								lat: result.lat,
								lng: result.lon
							},
						});
					}
				});
			}
		},
	},
	async mounted() {
		this.ready = true;
		let slideshow_location = {
			country: this.$store.state.location.country,
			city: this.$store.state.location.city,
			asl: this.$store.state.location.asl,
			lat: this.$store.state.location.lat,
			lng: this.$store.state.location.lng,
		};
		slideshow_location = JSON.stringify(slideshow_location);
		const location = JSON.parse(localStorage.location || slideshow_location || '{"city":"Frankfurt am Main, Deutschland","country":"DE","lat":50.11092209999999,"lng":8.682126700000026}');
		this.city = location.city || '';
	},
	methods: {
		setSearch(search) {
			this.search = search;
		},
		setLocation(location) {
			const props = {};
			props.city = location.description;
			props.country = location.country;
			props.asl = location.asl;
			props.lat = location.lat;
			props.lng = location.lng;
			this.city = props.city;
			this.list = [];
			this.handleLocation(props);
		},
		handleLocation(location) {
			localStorage.location = JSON.stringify(location);
		},
		async add3DayPreview() {
			const mid = await this.addModuleGroup(
				get3DayPreviewTemplate(this.$store),
			);
			await this.$nextTick();
			if (this.$store.state.type != 'light') {
				this.$store.dispatch('centerModule', mid);
			}
		},
	},
};

/**
 * This function is called on new module creation and should provide a complete
 * working module. The normalize function is called right after that
 */
export function getTemplate($store) {
	let slideshow_location = {
		country: $store.state.location.country,
		city: $store.state.location.city,
		asl: $store.state.location.asl,
		lat: $store.state.location.lat,
		lng: $store.state.location.lng,
	};
	slideshow_location = JSON.stringify(slideshow_location);
	const location = JSON.parse(localStorage.location || slideshow_location || '{"city":"Frankfurt am Main, Deutschland","country":"DE","lat":50.11092209999999,"lng":8.682126700000026,"asl":113}');

	const font = $store.state.shared.fonts.find(
		font => font.value.toLowerCase().includes('bellgothic'),
	);

	return {
		animation: [],
		locked: false,
		class: 'WeatherModule',
		x: 'center',
		y: 'center',
		width: 250,
		height: 200,
		style: {
			fontFamily: font && font.value,
			fontSize: 130,
			textAlign: 1,
			fontStyle: null,
			color: null,
			backgroundColor: { r: 0, g: 0, b: 0, a: 0 },
			border: null,
			shadow: null,
			opacity: null,
			advanced: null,
		},
		props: {
			city: '',
			country: '',
			lat: 50,
			lng: 9,
			asl: 113,
			...location,
			time: 0, // 0 to 5 days in the future
			type: 'icon', // icon, text, temperatureC, temperatureF, temperatureK
			resize_icon: false,
		},
	};
}

function get3DayPreviewTemplate($store) {
	let slideshow_location = {
		country: $store.state.location.country,
		city: $store.state.location.city,
		asl: $store.state.location.asl,
		lat: $store.state.location.lat,
		lng: $store.state.location.lng,
	};
	slideshow_location = JSON.stringify(slideshow_location);
	const weatherLocation = JSON.parse(localStorage.location || slideshow_location || '{"city":"Frankfurt am Main, Deutschland","country":"DE","lat":50.11092209999999,"lng":8.682126700000026,"asl":113}');

	const font = $store.state.shared.fonts.find(
		font => font.value.toLowerCase().includes('bellgothic'),
	);

	const shadowStyle = {
		shadow: {
			color: { r: 0, g: 0, b: 0, a: 50 },
			width: 10,
			x: 0,
			y: 0,
		},
	};

	const iconStyle = {
		...shadowStyle,
		color: { r: 255, g: 255, b: 255, a: 100 },
		fontSize: 170,
	};

	const textStyle = {
		...iconStyle,
		textAlign: 1,
		fontSize: 36,
		fontFamily: font && font.value,
	};

	return [
		// Headline
		{
			class: 'TextModule',
			x: 0,
			y: 0,
			width: 900,
			props: {
				autoresizing: true,
				text: `<p style='text-align:center'><span style='color:#FFFFFF'><span style='font-size:1.5em'><span style='font-family:${font && font.value || 'arial,helvetica,sans-serif'}'>${weatherLocation.city || ''}</span></span></span></p>`,
			},
			style: { ...shadowStyle },
		},

		// Weather Icons
		{
			class: 'WeatherModule',
			x: 0,
			y: 90,
			width: 300,
			height: 200,
			props: {
				...weatherLocation,
				time: 0,
				type: 'icon',
			},
			style: { ...iconStyle },
		},
		{
			class: 'WeatherModule',
			x: 300,
			y: 90,
			width: 300,
			height: 200,
			props: {
				...weatherLocation,
				time: 1,
				type: 'icon',
			},
			style: { ...iconStyle },
		},
		{
			class: 'WeatherModule',
			x: 600,
			y: 90,
			width: 300,
			height: 200,
			props: {
				...weatherLocation,
				time: 2,
				type: 'icon',
			},
			style: { ...iconStyle },
		},

		// Weather Temperature
		{
			class: 'WeatherModule',
			x: 0,
			y: 305,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 0,
				type: 'temperatureC',
			},
			style: { ...textStyle },
		},
		{
			class: 'WeatherModule',
			x: 300,
			y: 305,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 1,
				type: 'temperatureC',
			},
			style: { ...textStyle },
		},
		{
			class: 'WeatherModule',
			x: 600,
			y: 305,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 2,
				type: 'temperatureC',
			},
			style: { ...textStyle },
		},

		// Weather Text
		{
			class: 'WeatherModule',
			x: 0,
			y: 370,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 0,
				type: 'text',
			},
			style: { ...textStyle },
		},
		{
			class: 'WeatherModule',
			x: 300,
			y: 370,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 1,
				type: 'text',
			},
			style: { ...textStyle },
		},
		{
			class: 'WeatherModule',
			x: 600,
			y: 370,
			width: 300,
			height: 60,
			props: {
				...weatherLocation,
				time: 2,
				type: 'text',
			},
			style: { ...textStyle },
		},
	];
}

export const description = {
	extends: DescriptionView,
	data() {
		const type = {
			icon: this.$_('fd.weathermodule.settings.type.icon'),
			text: this.$_('fd.weathermodule.settings.type.text'),
			temperatureC: this.$_('fd.weathermodule.settings.type.temperature.c'),
			temperatureF: this.$_('fd.weathermodule.settings.type.temperature.f'),
			temperatureK: this.$_('fd.weathermodule.settings.type.temperature.k'),
		}[this.module.props.type];

		const time = [
			this.$_('fd.weathermodule.settings.time.today'),
			this.$_('fd.weathermodule.settings.time.plus1day'),
			this.$_('fd.weathermodule.settings.time.plus2day'),
			this.$_('fd.weathermodule.settings.time.plus3day'),
			this.$_('fd.weathermodule.settings.time.plus4day'),
			this.$_('fd.weathermodule.settings.time.plus5day'),
		][this.module.props.time];

		return {
			description: `${this.$_('fd.weathermodule.title')} ${time} (${type})`,
		};
	},
};

export const ConfigView = {
	name: 'WeatherModuleConfigView',
	extends: BasicConfigView,
	data: () => ({
		meta,
		search: '',
		ready: false,
		list: [],
	}),
	computed: {
		config() {
			return [{
				type: 'List',
				label: this.$_('fd.weathermodule.settings.location'),
				search: this.module.props.city || true,
				compare: _ => true,
				itemView: ComplexListItemView,
				value: this.module.props.city,
				values: this.list,
				events: {
					search: this.setSearch,
					input: this.setLocation,
				},
			}, {
				type: 'Select',
				label: this.$_('fd.weathermodule.settings.time'),
				value: this.module.props.time,
				values: [{
					label: this.$_('fd.weathermodule.settings.time.today'),
					value: 0,
				}, {
					label: this.$_('fd.weathermodule.settings.time.plus1day'),
					value: 1,
				}, {
					label: this.$_('fd.weathermodule.settings.time.plus2day'),
					value: 2,
				}, {
					label: this.$_('fd.weathermodule.settings.time.plus3day'),
					value: 3,
				}, {
					label: this.$_('fd.weathermodule.settings.time.plus4day'),
					value: 4,
				}, {
					label: this.$_('fd.weathermodule.settings.time.plus5day'),
					value: 5,
				}],
				events: {
					input: this.setTime,
				},
			}, {
				type: 'Select',
				label: this.$_('fd.weathermodule.settings.type'),
				value: this.module.props.type,
				values: [{
					label: this.$_('fd.weathermodule.settings.type.icon'),
					value: 'icon',
				}, {
					label: this.$_('fd.weathermodule.settings.type.text'),
					value: 'text',
				}, {
					label: this.$_('fd.weathermodule.settings.type.temperature.c'),
					value: 'temperatureC',
				}, {
					label: this.$_('fd.weathermodule.settings.type.temperature.f'),
					value: 'temperatureF',
				}, {
					label: this.$_('fd.weathermodule.settings.type.temperature.k'),
					value: 'temperatureK',
				}],
				events: {
					input: this.setType,
				},
			}, {
				type: 'Boolean',
				label: this.$_('fd.weathermodule.settings.resize_icon.text'),
				value: this.module.props.resize_icon,
				events: {
					input: this.setIconResize,
				},
			}];
		},
	},
	watch: MetaDescriptionView.watch,
	mounted: MetaDescriptionView.mounted,
	methods: {
		setSearch: MetaDescriptionView.methods.setSearch,
		setLocation: MetaDescriptionView.methods.setLocation,
		handleLocation(props) {
			this.commitChange('props', props);
		},
		setTime(time) {
			this.commitChange('props', { time });
		},
		setType(type) {
			this.commitChange('props', { type });
		},
		setIconResize(resize_icon) {
			this.commitChange('props', { resize_icon });
		},
	},
};

export default {
	extends: WeatherModule,

	// extra
	meta,
	MetaDescriptionView,
	getTemplate,
	description,
	ConfigView,
};
</script>

<i18n lang='de_DE'>
fd.weathermodule.title: Wetter
fd.weathermodule.description: >
	Hiermit ist es möglich das Wetter anzeigen zu lassen. Erstellen Sie simple
	oder komplexe Layouts mit Icons oder Text.
fd.weathermodule.add: Wetter hinzufügen

fd.weathermodule.settings.location: Ort
fd.weathermodule.settings.time: Zeit
fd.weathermodule.settings.time.today: heute
fd.weathermodule.settings.time.plus1day: morgen
fd.weathermodule.settings.time.plus2day: in 2 Tagen
fd.weathermodule.settings.time.plus3day: in 3 Tagen
fd.weathermodule.settings.time.plus4day: in 4 Tagen
fd.weathermodule.settings.time.plus5day: in 5 Tagen

fd.weathermodule.settings.type: Typ
fd.weathermodule.settings.type.icon: Icon
fd.weathermodule.settings.type.text: Text
fd.weathermodule.settings.type.temperature.c: Temperatur (°C)
fd.weathermodule.settings.type.temperature.f: Temperatur (°F)
fd.weathermodule.settings.type.temperature.k: Temperatur (K)

fd.weathermodule.settings.resize_icon.text: Icon-Größe anpassen

fd.weathermodule.special.three_day_preview: 3 Tagesvorhersage hinzufügen
</i18n>
