import { _evalDefault } from '../util/helper.js';

export const KEY = 'backgroundColor';

export const CONFIG = {
	type: 'Color'
}

export function normalize(value) {
	return {
		r: 255,
		g: 255,
		b: 255,
		a: 70,
		..._evalDefault(value, {})
	};
}

export function compute(value) {
	value = normalize(value);

	return {
		backgroundColor: `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a / 100})`
	}
}
