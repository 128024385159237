<script>
import CONFIG from '../Config.js';
import SimpleConfigView from './SimpleConfig.vue';

export default {
	name: 'HelpView',
	extends: SimpleConfigView,
	data: () => ({
		meta: {
			icon: 'question3',
			name: 'HelpView',
		},
	}),
	computed: {
		config() {
			return [
				{
					type: 'Raw',
					html: `
					<div class="version">
						<div class="label">Version:</div>
						<div class="version">${CONFIG.VERSION}</div>
					</div>
				`,
				},
				{
					type: 'Button',
					label: this.$_('fd.helpview.tutorial'),
					events: {
						click: this.startTutorial,
					},
				},
				{
					type: 'Button',
					label: this.$_('fd.helpview.manual'),
					events: {
						click: this.getLink,
					},
				},
				{
					type: 'Raw',
					html: `
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.enter.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.enter.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+c.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+c.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+v.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+v.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+a.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+a.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+s.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+s.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+z.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+z.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.ctrl+g.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.ctrl+g.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.del.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.del.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.up.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.up.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.down.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.down.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.left.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.left.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.right.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.right.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.esc.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.esc.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.shift+c.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+c.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.shift+left.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+left.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.shift+h.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+h.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_(
							'fd.helpview.hint.shift+right.shortcut'
						)}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+right.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_(
							'fd.helpview.hint.shift+up.shortcut'
						)}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+up.info')}</div>
					</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_('fd.helpview.hint.shift+v.shortcut')}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+v.info')}</div>
					<div class="hint">
						<div class="hint-shortcut">${this.$_(
							'fd.helpview.hint.shift+down.shortcut'
						)}</div>
						<div class="hint-info">${this.$_('fd.helpview.hint.shift+down.info')}</div>
					</div>
				`,
				},
			];
		},
	},
	methods: {
		startTutorial() {
			this.$store.dispatch('subNav/components', []);
			this.$root.$emit('stopTutorial');
			this.$root.$emit('startTutorial');
		},
		getLink() {
			if (this.$_('fd.language').split('_')[0] === 'de') {
				window.open('https://www.solar-fox.de/files/oneo/documents/misc/DE/Anleitung_Foxdesigner.pdf', '_blank');
			} else {
				window.open('https://www.solar-fox.de/files/oneo/documents/misc/EN/Manual_Foxdesigner.pdf', '_blank');
			}
		},
	},
};
</script>

<style lang="scss">
.fd-raw .version {
	.label {
		display: block;
	}

	.version {
		font-family: Consolas, 'Courier New', Courier, monospace;
		font-size: 16px;
		text-align: center;
	}
}

.hint {
	font-size: 12px !important;
	margin: 10px 0px;
	.hint-shortcut {
		width: fit-content;
		padding: 2px 4px;
		font-size: 90%;
		color: #fff;
		background-color: #333;
		border-radius: 3px;
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
		margin-bottom: 2px;
	}
	.hint-info {
		padding-left: 5px;
	}
}
</style>


<i18n lang="de_DE">
fd.helpview.title: Hilfe
fd.helpview.tutorial: Tutorial starten
fd.helpview.manual: Anleitung herunterladen

fd.helpview.hint.enter.shortcut: Enter
fd.helpview.hint.enter.info: Modul ändern
fd.helpview.hint.ctrl+c.shortcut: Strg + c
fd.helpview.hint.ctrl+c.info: Kopieren
fd.helpview.hint.ctrl+v.shortcut: Strg + v
fd.helpview.hint.ctrl+v.info: Einfügen
fd.helpview.hint.ctrl+a.shortcut: Strg + a
fd.helpview.hint.ctrl+a.info: Alles makieren
fd.helpview.hint.ctrl+s.shortcut: Strg + s
fd.helpview.hint.ctrl+s.info: Speichern
fd.helpview.hint.ctrl+z.shortcut: Strg + z
fd.helpview.hint.ctrl+z.info: Rückgängig
fd.helpview.hint.ctrl+g.shortcut: Strg + g
fd.helpview.hint.ctrl+g.info: Gruppieren/ Gruppe lösen
fd.helpview.hint.del.shortcut: Entf
fd.helpview.hint.del.info: Entfernen
fd.helpview.hint.up.shortcut: Pfeiltaste hoch
fd.helpview.hint.up.info: Module hoch schieben
fd.helpview.hint.down.shortcut: Pfeiltaste runter
fd.helpview.hint.down.info: Module runter schieben
fd.helpview.hint.left.shortcut: Pfeiltaste links
fd.helpview.hint.left.info: Module nach links schieben
fd.helpview.hint.right.shortcut: Pfeiltaste rechts
fd.helpview.hint.right.info: Module nach rechts schieben
fd.helpview.hint.esc.shortcut: Esc
fd.helpview.hint.esc.info: Löse alle Makierungen
fd.helpview.hint.shift+c.shortcut: Umschalt + c
fd.helpview.hint.shift+c.info: Alle Animationen anhalten und zurücksetzen
fd.helpview.hint.shift+left.shortcut: Umschalt + Pfeiltaste links
fd.helpview.hint.shift+left.info: Linksbündig ausrichten
fd.helpview.hint.shift+h.shortcut: Umschalt + h
fd.helpview.hint.shift+h.info: Horizontal ausrichten
fd.helpview.hint.shift+right.shortcut: Umschalt + Pfeiltaste rechts
fd.helpview.hint.shift+right.info: Rechtsbündig ausrichten
fd.helpview.hint.shift+up.shortcut: Umschalt + Pfeiltaste hoch
fd.helpview.hint.shift+up.info: Nach oben ausrichten
fd.helpview.hint.shift+v.shortcut: Umschalt + v
fd.helpview.hint.shift+v.info: Vertical ausrichten
fd.helpview.hint.shift+down.shortcut: Umschalt + Pfeiltaste runter
fd.helpview.hint.shift+down.info: Nach unten ausrichten

</i18n>
