<template>
	<div class="fd-modules">
		<module-view
			v-for="module of filteredModules"
			:key="module.mid"
			:module="module"
		></module-view>
	</div>
</template>

<script>
import ModuleView from './Module.vue';

export default {
	name: 'ModulesView',
	props: ['modules'],
	components: { ModuleView },
	computed: {
		filteredModules() {
			// BlackList all child modules, they are rendered in group module
			const blackList = [];
			this.modules
				.filter(module => module.class == 'GroupModule')
				.forEach(module => {
					blackList.push(...module.props.childs);
				});

			return this.modules.filter(
				m => !blackList.includes(m.mid)
			);
		}
	}
}
</script>

<style>
.fd-modules {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 20;
}
</style>

