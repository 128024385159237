<template>
	<div class="fd-cloud-module">
		<div class="cloud">
			<div v-for="n in 20" :key="n" class="cloud-part" :class="'cloud-part-' + n" :style="cloudPartStyle(n)">
				<div class="inner" :style="cloudPartInnerStyle()"></div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseModule from './BaseModule.vue';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {}

export default {
	name: 'CloudModule',
	extends: BaseModule,
	normalize,
	methods: {
		rand: function (range, noFloor) {
			if (!range) return Math.random();
			return noFloor ? Math.random() * range : Math.floor(Math.random() * range)
		},
		cloudPartStyle: function (n) {
			return {
				left: this.rand(10) + 5 * n + '%',
				top: this.rand(20) + 40 + '%',
				transform:
					'translate3d(-50%, -50%, 1) ' +
					'scale3d(' +
						(this.rand(0.75, true) + 0.25) +
					', 1, 1) ' +
					'rotate3d(0, 0, 1, ' + this.rand(360) + 'deg)'
			};
		},
		cloudPartInnerStyle: function () {
			return {
				animationDelay: this.rand(-180) + 's',
				animationDuration: this.rand(60) + 120 + 's',
				animationPlayState: this.$root.tooSlow ? 'paused' : null
			};
		},
	},
};
</script>

<style lang="scss">
@keyframes fd_cloud_module_part_anim {
  0% {
    transform: scale3d(0.6, 1, 1) translate3d(-70%, -30%, 0) rotate3d(0, 0, 1, 0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale3d(1.2, 1, 1) translate3d(-30%, -70%, 0) rotate3d(0, 0, 1, -360deg);
    opacity: 0;
  }
}

.fd-cloud-module {
	position: relative;

	.abs-h-v-centered {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
	.pointer-events-none {
		pointer-events: none;
	}

	.cloud {
		@extend .abs-h-v-centered;
		@extend .pointer-events-none;
		width: 100%;
		height: 200%;

		.cloud-part {
			@extend .abs-h-v-centered;
			width: 75%;
			height: 75%;

			.inner {
				@extend .abs-h-v-centered;
				width: 100%;
				height: 100%;
				background: url('../../img/cloud/cloud1.png');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;

				will-change: transform, opacity;
				animation: fd_cloud_module_part_anim 120s cubic-bezier(.1,.4,.9,.6) infinite;
			}

			&:nth-child(even) .inner {
				background-image: url('../../img/cloud/cloud2.png');;
			}
		}
	}
}
</style>
