function init() {
	return {
		component: false,
		props: {}
	}
}


export async function create() {
	return {
		namespaced: true,

		state: init(),

		mutations: {
			create(state, {component, props}) {
				state.component = component;
				state.props = props;
			},
			close(state) {
				state.component = false;
				state.props = {};
			}
		}
	}
}
