<template>
	<modal-view
		class="fd-file-chooser"
		:size="1000"
		:icon="options.fileTypeKey"
		:title="$_('fd.filechooser.title.' + options.fileTypeKey)"
		:buttons="buttons"
	>
		<tabbed-pane-view v-if="!options.style">
			<pane-view
				v-for="group of groupsWithSelectedFiles"
				:key="group.name"
				:class="group.name"
				:label="$_('fd.file.group.' + group.name) + (
					options.multiselect ? ` (${group.selected})` : ''
				)"
			>
				<div
					class="no-files-warning"
					v-if="groupedFiles[group.name].length === 0"
				>{{$_('fd.filechooser.nofiles')}}</div>

				<template v-if="group.name === 'private'">
					<file-uploading-view v-for="file of uploadedFiles" :key="file.id" :file="file" />
				</template>
				<file-view
					v-for="file of groupedFiles[group.name]"
					:key="file.id"
					:file="file"
					:selected="selectedFiles.includes(file.id)"
					:deletable="file.group === 'private'"
					:deleted="deletedFiles.includes(file.id)"
					@click="select(file.id)"
					@delete="markDelete(file.id)"
				/>

			</pane-view>
		</tabbed-pane-view>

		<tabbed-pane-view v-if="options.style">
			<pane-view :label="$_('fd.file.group.style')">
				<file-view
					v-for="file of styleFiles"
					:key="file.id"
					:file="file"
					:selected="selectedFiles == file.thumb"
					@click="select(file.thumb)"
				/>
			</pane-view>
		</tabbed-pane-view>

		<div slot="footer" class="fd-separator"></div>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';
import { UNIQUE_FILTER } from '../../util/Filter.js';
import TabbedPaneView, { PaneView } from '../ui/TabbedPane.vue';
import FileView from '../ui/File.vue';
import { EXISTS_FILTER } from '../../util/Filter.js';
import SystemFileChooser from '../../util/SystemFileChooser.js';
import FileUploadingView from '../ui/FileUploading.vue';
import { IMAGES } from '../../modules/StyleModule.vue';

export default {
	name: 'FileChooser',
	components: {
		ModalView,
		TabbedPaneView,
		PaneView,
		FileView,
		FileUploadingView,
	},
	props: ['options'],
	data() {
		return {
			selectedFiles: [],
			deletedFiles: [],
			uploadedFiles: [],
		};
	},
	computed: {
		allFiles() {
			return this.$store.state.shared.files;
		},
		allowedFiles() {
			return this.allFiles.filter(
				file =>
					typeof file != 'undefined' &&
					this.options.allowed.test(file.type) &&
					(this.options.visible === file.visible ||
						this.options.visible === 'every' ||
						file.visible === 'every')
			);
		},
		groups() {
			if(this.$store.state.type == 'image') {
				return ['private'];
			} else {
				return ['private', ...this.allowedFiles.map(file => file.group)].filter(
					UNIQUE_FILTER
				);
			}
		},
		groupedFiles() {
			const groupedFiles = {};

			// Build Layer 1
			this.groups.forEach(group => (groupedFiles[group] = []));

			// Build Layer 2
			this.allowedFiles.forEach(file => {
				if(typeof groupedFiles[file.group] != 'undefined')
					groupedFiles[file.group].unshift(file)
			});

			return groupedFiles;
		},
		styleFiles() {
			let styleFiles = [];
			IMAGES.forEach(image => {
				styleFiles.push({
					id: image.label,
					name: image.label,
					size: '',
					thumb: image.value,
					visible: 'every',
				});
			});
			return styleFiles;
		},
		groupsWithSelectedFiles() {
			return this.groups.map(group => {
				return {
					name: group,
					selected: this.groupedFiles[group].reduce((ctr, file) => {
						if (this.selectedFiles.includes(file.id)) {
							return ctr + 1;
						}
						return ctr;
					}, 0),
				};
			});
		},
		buttons() {
			let buttons = [];
			if (this.$store.state.type != 'image' ||
				(this.$store.state.type == 'image' && this.$store.state.subType == 'select')
			) {
				buttons.push({
					label:
						(this.options.multiselect
							? `(${this.selectedFiles.length}) `
							: '') + this.$_('fd.filechooser.actions.ok'),
					css: 'primary',
					disabled: this.selectedFiles < 1,
					click: () => this.ok(),
				});
			}
			if (this.$store.state.type != 'image') {
				buttons.push({
					label: this.$_('fd.filechooser.actions.cancel'),
				});
			}
			if (!this.options.style) {
				buttons.push({
					label: this.$_('fd.filechooser.actions.upload'),
					icon: 'upload',
					css: 'fd-upload-button',
					click: () => this.upload(),
				});
			}
			return buttons;
		},
	},
	mounted() {
		if (this.options.style) {
			this.selectedFiles = this.options.value[0].path;
		} else {
			this.selectedFiles = this.options.value.map(file => {
				file = this.allFiles.find(f => f.path === file.path);
				return file && file.id;
			});

			if (!this.options.multiselect && this.selectedFiles.length > 1) {
				this.selectedFiles = [this.selectedFiles.filter(EXISTS_FILTER)[0]];
			}
		}
	},
	methods: {
		select(fileId) {
			if (this.$store.state.type != 'image'||
				(this.$store.state.type == 'image' && this.$store.state.subType == 'select')
			) {
				if (this.options.style) {
					this.selectedFiles = fileId;
				} else {
					if (this.deletedFiles.includes(fileId)) return;

					if (this.options.multiselect) {
						if (this.selectedFiles.includes(fileId)) {
							const index = this.selectedFiles.indexOf(fileId);
							this.selectedFiles.splice(index, 1);
						} else {
							this.selectedFiles.push(fileId);
						}
					} else {
						this.selectedFiles = [fileId];
					}
				}
			}
		},
		markDelete(fileId) {
			const index = this.deletedFiles.indexOf(fileId);
			if (index < 0) {
				this.deletedFiles.push(fileId);
			} else {
				this.deletedFiles.splice(index, 1);
			}
		},
		upload() {
			SystemFileChooser.open({
				accept: this.options.allowed,
			})
				.then(resultList => {
					return resultList.map(result => {
						result.file.group = 'private';
						result.file.visible = 'every';
						result.file.progress = 1;
						this.uploadedFiles.push(result.file);

						return new Promise(resolve => {
							result.hooks.dataReady = () => {
								// -> before upload file
								resolve(
									this.$store
										.dispatch('addFile', [
											{
												// Copy to prevent maltreatment on the other side
												...result.file,
											},
											progress => {
												// -> on upload file
												result.file.progress = progress;
											},
										])
										.then(f => {
											if(typeof f.id == 'undefined') {
												alert('upload failed');
												this.uploadedFiles = [];
												return false;
											}
											// -> after upload file
											result.file.id = f.id;
											result.file.thumb = f.medium_thumb_path;
											result.file.path = f.path;

											this.uploadedFiles.splice(
												this.uploadedFiles.indexOf(result.file),
												1
											);

											delete result.file.progress;
											delete result.file.data;

											return result.file;
										})
								);
							};
						});
					});
				})
				.then(files => Promise.all(files))
				.then(files => {
					// TODO: Use $store.commit! :)
					this.$store.state.shared.files = this.$store.state.shared.files.concat(
						files
					);
				});

			return false;
		},
		ok() {
			if (this.$store.state.type == 'image' && this.$store.state.subType == 'select') {
				this.$store.dispatch('changeBackground', [this.$store.state.id,this.selectedFiles[0]]);
			} else {

				if (this.options.style) {
					this.$store.getters.activeModules[0].props.url = this.selectedFiles;
					this.$store.commit('dirty');
					return true;
				} else {
					this.options.onselect(
						this.selectedFiles
							.map(fileId => {
								const file = this.allowedFiles.find(file => file.id === fileId);
								return file && file.path && file;
							})
							.filter(EXISTS_FILTER)
					);
				}

			}
		},
	},
};
</script>

<style lang="scss">
.fd-file-chooser {
	.fd-tabbed-pane-view {
		width: 100%;
		height: 400px;

		display: flex;

		.tabs {
			flex: 0 0 150px;
			border-right: 2px solid #c9c9c9;
			margin-right: 15px;

			.tab {
				padding: 7px 10px;
				cursor: pointer;

				&.selected {
					background: #c9c9c9;
					cursor: default;
				}
			}
		}

		.panes {
			flex: 1;
			overflow-y: auto;
		}
	}

	.fd-upload-button {
		order: -1;
		margin-left: 0 !important;
		background: #31b0d5;
	}

	.fd-separator {
		flex: 1;
	}

	.fd-pane-view {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 5px;
		padding: 5px;

		.no-files-warning {
			grid-column: 1 / span 5;
			font-size: larger;
		}

		&.color {
			grid-template-columns: repeat(10, 1fr);

			.label {
				margin-top: -14px;

				.size {
					display: none;
				}
			}
		}
	}
}
</style>


<i18n lang="de_DE">
fd.filechooser.title.image: Bildverwaltung
fd.filechooser.nofiles: Hier sind leider noch keine Dateien vorhanden!
fd.file.group.private: Eigene
fd.file.group.public: Vorlagen
fd.file.group.color: Farben
fd.file.group.style: Style
fd.filechooser.actions.upload: Hochladen
fd.filechooser.actions.ok: Ok
fd.filechooser.actions.cancel: Abbrechen
</i18n>
