export function _createImage(ctx, width, height) {
	return ctx.createImageData(width, height);
}

export function _setColor(image, x, y, color) {
	const index = y * image.width * 4 + x * 4;
	image.data[index + 0] = color[0];
	image.data[index + 1] = color[1];
	image.data[index + 2] = color[2];
	image.data[index + 3] = (color[3] || 1) * 255;
}

export function _setCircle(image, cx, cy, radius, color) {
	for(let x = cx - radius; x < cx + radius; x++) {
		for(let y = cy - radius; y < cy + radius; y++) {
			const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
			if(distance > radius) continue;

			_setColor(
				image,
				x,
				y,
				color instanceof Function ? color(x, y) : color
			);
		}
	}
}

export function _setDonut(image, cx, cy, r1, r2, color) {
	for(let x = cx - r1; x < cx + r1; x++) {
		for(let y = cy - r1; y < cy + r1; y++) {
			const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
			if(distance > r1) continue;
			if(distance < r2) continue;

			_setColor(
				image,
				x,
				y,
				color instanceof Function ? color(x, y) : color
			);
		}
	}
}

export function _setTriangle(image, cx, cy, radius, color) {
	for(let x = cx - radius; x < cx + radius; x++) {
		for(let y = cy - radius; y < cy + radius; y++) {
			const sideLength = radius / (Math.sqrt(3) / 3);
			const area = sideLength ** 2 * Math.sqrt(3) / 4;

			const q = [x, y];
			const p1 = [cx, cy - radius];
			const p2 = [cx - sideLength / 2, cy + radius * 0.5];
			const p3 = [cx + sideLength / 2, cy + radius * 0.5];

			const calcArea = ([ax, ay], [bx, by], [cx, cy]) => Math.abs(
				ax * (by - cy) + bx * (cy - ay) + cx * (ay - by)
			) / 2;

			if(
				calcArea(q, p1, p2) + calcArea(q, p1, p3) + calcArea(q, p2, p3)
				- area > 0.2
			) {
				continue;
			}

			_setColor(
				image,
				x,
				y,
				color instanceof Function ? color(x, y) : color
			);
		}
	}
}

export function _drawImage(ctx, image) {
	ctx.putImageData(image, 0, 0);
}
