export const humanizeBytes = (bytes) => {
	if(!bytes) return '';

	const units = ['TB', 'GB', 'MB', 'KB', 'b'];
	let size, unit;
	for(let i = 0; i < units.length; i++) {
		let cutoff = Math.pow(1024, units.length - 1 - i);
		if(bytes >= cutoff) {
			size = bytes / cutoff;
			unit = units[i];
			break;
		}
	}
	size = Math.round(10 * size) / 10;

	return `${size} ${unit}`;
}
