<script>
import Roll from './Roll.vue';
import ConfigItemView from '../components/ConfigItem.vue';
import SettingsView from '../components/Settings.vue';

export default {
	name: 'RollConfigView',
	extends: SettingsView,
	components: { ConfigItemView },
	computed: {
		icon() {
			return 'spinner11';
		},
		headline() {
			return this.$_('fd.animation.roll.desc');
		},
		config() {
			return [{
				type: 'Number',
				label: this.$_('fd.animations.settings.duration'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].duration || 1,
				events: {
					input: this.setDuration
				}
			}, {
				type: 'Number',
				label: this.$_('fd.animations.settings.delay'),
				value: this.module.animation[this.$store.state.activeAnimationIndex].delay || 0,
				events: {
					input: this.setDelay
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play'),
				events: {
					click: this.startAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.delete'),
				icon: 'bin',
				events: {
					click: this.deleteAnimation
				}
			}, {
				type: 'Button',
				label: this.$_('fd.animations.settings.play.all'),
				events: {
					click: this.startAll
				}
				}];
		}
	},
	methods: {
		setDuration(duration) {
			this.$root.$emit('changeAnimation', {
				type: 'duration',
				value: duration,
				self: this,
			});
		},
		setDelay(delay) {
			this.$root.$emit('changeAnimation', {
				type: 'delay',
				value: delay,
				self: this,
			});
		},
		startAnimation() {
			this.$root.$emit('startAnimation');
		},
		deleteAnimation() {
			this.$store.dispatch('deleteAnimation', this.module);
			this.$root.$emit('update');
		},
		startAll() {
			this.$root.$emit('startAllAnimations');
		},
	}
}

</script>
