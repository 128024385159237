<template>
	<modal-view class="fd-file-chooser"
		:size="600"
		icon="bin"
		:title="$_('fd.deletefiles.title')"
		:dialog="true"
		:buttons="buttons"
	>
		<p>
			<strong>{{$_('fd.deletefiles.attention')}}</strong>
			{{$_('fd.deletefiles.nohistoryback')}}
		</p>
		<p>{{$_('fd.deletefiles.content')}}</p>

		<div class="preview">

			<img
				v-for="file of files"
				:key="file.id"
				:src="file.path"
                style="height:280px"
			/>

		</div>
	</modal-view>
</template>

<script>
import ModalView from './Modal.vue';
import FileView from '../ui/File.vue';

export default {
	name: 'DeleteFilesModalView',
	props: ['files'],
	components: {
		ModalView,
		FileView,
	},
	data() {
		return {
			buttons: [{
				label: this.$_('fd.deletefiles.actions.delete'),
				css: 'attention',
				icon: 'bin',
				click: () => this.deleteFiles()
			}, {
				label: this.$_('fd.deletefiles.actions.cancel'),
				css: 'primary'
			}]
		}
	},
	methods: {
		deleteFiles() {
			this.files.forEach(file => {
				this.$store.dispatch('deleteFile', [file.id]);
			});

			// TODO: Use $store.commit! :)
			this.$store.state.shared.files = this.$store.state.shared.files
				.filter(file => !this.files.includes(file));
		}
	}
}
</script>

<style lang="scss">
.fd-file-chooser {
	.body {
		flex-direction: column;
	}

	.preview {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 5px;
		padding: 5px;
		margin: -5px;

		max-height: 280px;
		overflow-y: auto;

		.fd-file-view {
			cursor: inherit;
		}
	}

	button.attention {
		background: red;
	}
}
</style>

<i18n lang="de_DE">
fd.deletefiles.title: Datei löschen!
fd.deletefiles.attention: Achtung!
fd.deletefiles.nohistoryback: >
	Dieser Schritt kann nicht rückgängig gemacht werden.
fd.deletefiles.content: >
	Sie haben eine Datei zum Löschen ausgewählt. Sind Sie sich sicher, dass Sie
	folgende Datei löschen möchten?
fd.deletefiles.actions.delete: Ja, löschen!
fd.deletefiles.actions.cancel: Abbrechen
</i18n>
